import React from "react";
import "../../styles/PurchasePop.scss";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";

const BuyHeartPop = ({ hearts, cost, setHeartMessagePop, setPaypalButton }) => {
  const { isMobile } = useAppBreakpoints();

  const handleYes = () => {
    setPaypalButton(true);
    setHeartMessagePop(false);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-20">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full items-center justify-center modal-desk-buy-heart-pop">
        <div className="p-3 flex items-center justify-center flex-col">
          <button
            className="absolute top-2 left-2 m-4"
            onClick={(e) => setHeartMessagePop(false)}
          >
            <svg
              className="h-7 w-7 text-gray-700"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          <div className="col-span-2 text-center">
            <div
              className={
                isMobile === true
                  ? "mx-auto max-w-xs"
                  : "flex flex-col items-center justify-center mx-auto max-w-lg"
              }
            >
              <svg
                className="w-16 h-16 mx-auto icon-heart-hearts"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 2000 2000"
                id="Heart"
              >
                <path
                  d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                  fill="#6eb5ed"
                  className="color000000 svgShape"
                ></path>
              </svg>
              <p className="text-[#494949] text-sm">
                {" "}
                You are about to purchase
                <span className="text-[#6eb5ed] font-medium">
                  {" "}
                  {hearts}
                </span>{" "}
                hearts for
                <span className="text-[#6eb5ed] font-medium"> ${cost}.</span>
              </p>

              <div className="flex gap-2 items-center justify-center pt-4">
                <button
                  className="flex items-center justify-around w-fit-content mx-auto btn-add-hearts-cancel"
                  onClick={() => setHeartMessagePop(false)}
                >
                  Cancel
                </button>
                <button
                  className="flex items-center justify-around w-fit-content mx-auto btn-add-hearts-yes"
                  onClick={() => handleYes()}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyHeartPop;
