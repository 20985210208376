import Avatar from "react-avatar";
import "../../styles/UserFollower.scss";
import { Link } from "react-router-dom";
import React, { useRef, useState, useMemo } from "react";
import FilledHeart from "../../assets/icons/Filled_Heart.svg";

function UserFollower({ followerLists, handleScroll, statusText, handleInfo, followerListCall }) {

  const ref = useRef(null);

  const [endOfScroll, setEndOfScroll] = useState(false);
  const [endOfLeftScroll, setEndOfLeftScroll] = useState(false);

  const slideLeft = () => {
    ref.current.scrollLeft -= 300;

    setEndOfLeftScroll(ref.current.scrollLeft == 0);
    setEndOfScroll(false);
  };

  const slideRight = () => {
    ref.current.scrollLeft += 300;

    setEndOfScroll(
      ref.current.scrollWidth -
        ref.current.scrollLeft -
        ref.current.offsetWidth <
        1
    );
    setEndOfLeftScroll(false);
  };

  function stringAvatar(string) {
    var name;
    const formatedName = string?.split(" ").filter(element => element !== null && element !== "");
    // console.log(formatedName)
    if (formatedName?.length > 2) {
      //name = `${string?.split(" ")[0][0]}${string?.split(" ")[2][0] == " " ? string?.split(" ")[1][0]: string?.split(" ")[2][0]}`;
      name = `${formatedName[0][0]}${formatedName[formatedName.length-1][0]}`;
    } else if (formatedName?.length > 1) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length-1][0]}`;
    } else {
      name = string?.slice(0, 1);
    }
    return name.toUpperCase();
  }

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const followerListsMappedData = useMemo(() => {
    return followerLists.map((list, indx) => {
      return (
        <li
          key={list.id + Date.now() + indx}
          className="flex flex-col justify-center items-center user-followers-container-data"
          onClick={() => handleInfo(list, "follower")}
        >
          {list?.profilePic == "" ? (
            <div className="user-followers-img-box">
              <Avatar
                color="#99CEF6"
                round={true}
                className="users-box-img"
                // name={stringAvatar(list?.name)}
                value={stringAvatar(list?.name)}
                style={{
                  boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.2)",
                  position: "relative"
                }}
              />
            </div>
          ) : (
            <div className="user-followers-img-box">
              <Avatar
                color="#99CEF6"
                round={true}
                className="users-box-img"
                src={list?.profilePic}
                style={{
                  boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.2)",
                  position: "relative",
                  objectFit: "cover"
                }}
              />
            </div>
          )}

          <p className="pt-2 name-user-discovery">
            {list?.userName
              ? list?.userName.length > 13
                ? list?.userName.substring(0, 9).concat("...")
                : list?.userName
              : "no name"}
          </p>

          <p className="followers-user-discovery">
            {nFormatter(list?.totalFollowers, 1)}{" "}
            <span>
              {list?.totalFollowers === 1 ? ` follower` : ` followers`}
            </span>
          </p>

          <div className="absolute flex items-center checkbox-heart-discovery">
            <span className="inline-flex items-center justify-center rounded-full cursor-pointer ransition-colors duration-150">
              <img src={FilledHeart} alt="" className="img-heart-discovery"/>
            </span>
          </div>

        </li>
      );
    });
  }, [followerLists]);

  return (
    <>
    {followerListCall ? (
      <>
        <div className="mt-2 md:mt-6">
          <div className="w-full flex flex-col justify-start">
            <div className="animate-pulse bg-gray-200 w-28 md:w-64 h-7 mt:2 md:mt-4"></div>
            <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
              {Array(10).fill(1).map((card, index) => (
                <div className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none" key={index}></div>
              ))}
            </div>
          </div>
        </div>
      </>
    ) : followerLists.length !== 0 ? (
      <div className="div-following-container-divider-discovery">
        <div className="mt-4 md:mt-6">
          <div className="w-full flex flex-col justify-start">

            <div className="w-full flex flex-row justify-between">
              <p className="p-following-title-discovery">Following</p>
              <Link to="/following">
                <p className="p-show-more-discovery">Show more</p>
              </Link>
            </div>


          </div>
        </div>

        <div className="relative">
          <ul
            ref={ref}
            className="user-followers-container"
            onScroll={handleScroll}
          >
            <div
              className={
                endOfLeftScroll
                  ? "user-follower-scroll-left-box user-follower-height"
                  : "user-follower-scroll-left-box"
              }
              onClick={slideLeft}
            >
              <div className="user-follower-scroll-left">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
              </div>
            </div>

            {followerListsMappedData}

            <div
              className={
                endOfScroll
                  ? "user-follower-scroll-right-box user-follower-height"
                  : "user-follower-scroll-right-box"
              }
              onClick={slideRight}
            >
              <div className="user-follower-scroll-right">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>

          </ul>
        </div>
      </div>
    ) : (
      <div className="mt-2 md:mt-6 div-following-container-divider-discovery">
        <div className="w-full flex flex-col justify-start">
          <div className="w-full flex flex-row justify-between">
            <p className="p-following-title-discovery">Following</p>
            <p id="statusText_follower"></p>
          </div>
        </div>
      </div>
    )}
    </>
  );
}
export default UserFollower;
