import "./index.css";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store, persistor } from './redux/store';
import { fingerprintApi } from "./Config/configurations";
import { BrowserRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";

ReactDOM.render(
  <Provider store={store}> {/* Redux */}
    <PersistGate loading={null} persistor={persistor}> {/* Redux Persist */}
      <FpjsProvider loadOptions={{ ...fingerprintApi }}> {/* Finger Print */}
        <React.StrictMode>
          <Router>
            <App />
          </Router>
        </React.StrictMode>
      </FpjsProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
