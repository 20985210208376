import "../../../styles/Performance.scss";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Url, useAxios } from "../../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import FavichatLoading from "../../../assets/gif/Favichat1.gif";
import PerformanceCard from "../../../components/PerformanceCard/PerformanceCard";
import performanceData from "../../../components/PerformanceCard/performanceDummyData";
import { ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser, UpdateSettingSidebarToggleState } from "../../../redux/actions/index";

const Performance = () => {

  let api = useAxios();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [aniLoader, setAniLoader] = useState(false);
  const [performanceValue, setPerformanceValue] = useState([]);

  const getPerformanceData = async () => {
    setAniLoader(true);
    api
      .get(
        `${Url}/users/${userData.userId}?loggedInUserId=${userData.userId}`
      )
      .then((res) => res.data)
      .then(
        (result) => {
          let cashOut = result?.data;
          let calculatedCash =
            cashOut?.earningsToDate !== undefined ||
            cashOut.hasOwnProperty("earningsToDate")
              ? Math.floor(cashOut?.earningsToDate / 100).toFixed(2)
              : "0.00";
          const arr = [
            result?.data?.totalFollowers,
            result?.data?.contentUnLockUsers,
            result?.data?.profileViewed,
            calculatedCash,
          ];
          setPerformanceValue(arr);
          setAniLoader(false);
        },
        (error) => {
          setAniLoader(false);
        }
      );
  };

  useEffect(() => {
    getPerformanceData();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const dynamicStyles = {
    height: `${screenHeight - 72}px`,
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  return (
    <>
      {!(isGuest == true) ? (
        <>
          {aniLoader && 
            <div className="w-full h-3/4 flex justify-center items-center pt-80">
              <div className="flex justify-center items-center">
                <img src={FavichatLoading} className="signin-img-Loading mx-auto" />
              </div>
            </div>
          }
          {!aniLoader && 
            <div className="flex flex-col relative overflow-y-scroll" style={dynamicStyles}>
              <div className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
                <div className="sm:hidden pt-[8px]">
                  <button type="submit" className="mr-[8px]">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                    </svg>
                  </button>
                </div>
                <p className="text-[#494949] font-boboto font-bold title-profile">Performance</p>
              </div>

              <div className="sm:px-12 px-4 sm:mt-6 mt-2 mb-2">
                {!aniLoader && performanceData?.map(({ id, label, imgUrl }) => (
                  <PerformanceCard
                    id={id}
                    label={label}
                    stats={performanceValue[id]}
                    imgUrl={imgUrl}
                  />
                ))}
              </div>
            </div>
          }
        </>
      ) : (
        <div className="profile">
          <div className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px] px-6">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Performance;
