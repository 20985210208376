import "../../styles/Login.scss";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Url } from "../../axios/authAPI";
import { useDispatch } from "react-redux";
import { authApi } from "../../axios/authAPI";
import { Logo } from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import NewConfirmPassword from "./NewConfirmPassword";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import { UpdateUserNameCodeState } from "../../redux/actions/index";

const ForgotPassword = () => {

  const dispatch = useDispatch();
  
  const [showErr, setShowErr] = useState();
  const [loader, setLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [confirmPasswordComponent, setConfirmPasswordComponent] = useState(false);

  const sendOTP = async (e) => {
    e.preventDefault();

    if (username === "") return setShowErr("Type a username");

    setLoader(true);
    setShowErr("");

    const jsonBody = { userName: username };
    dispatch(UpdateUserNameCodeState(username))

    authApi.post(`${Url}/users/password/send`, jsonBody)
      .then((res) => setConfirmPasswordComponent(true))
      .catch((err) => {
        setLoader(false);
        setShowErr(err?.response?.data?.error?.message);
      });
  };

  return (
    <div className="h-screen w-screen flex flex-col fixed items-center justify-start overflow-hidden fixed top-0 left-0">
      {confirmPasswordComponent ? (
        <NewConfirmPassword userName={username} />
      ) : (
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-4 sm:px-6 lg:px-8">

          <Logo/>

          <div className="mx-auto max-w-lg text-center">

            <h4 className="text-lg login-entry-title-tailwind">
              Enter username to reset your password
            </h4>

            <form className="mx-auto mb-0 max-w-md space-y-2 forgotPassword-form-tailwind ">
              <div className="relative">
                <input
                  type="text"
                  required
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full border rounded-lg border-gray-200 p-2 pr-12 text-sm shadow-sm forgotPassword-input-tailwind"
                  placeholder="Username"
                />
              </div>
              {showErr && ( 
                <div className="items-center justify-between signin-label-error-input">
                  <label className="text-red-500 text-sm">{showErr}</label>
                </div>
              )}
              <div className="flex items-center justify-between !mt-3" onClick={sendOTP}>
                <button className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-white gradient-send-code-tailwind" type="submit" disabled={false}>
                  Send me the code
                </button>
              </div>
            </form>

            <div className="flex items-center mt-4 justify-center signin-text-account">
              <p className="mr-2">
                Don't have an account?
              </p>
              <Link to="/sign-up"  className='text-sm text-blue-500 cursor-pointer signin-link-account'>Sign Up</Link>
            </div>

            <div className="flex items-center mt-4 justify-center signin-text-account">
              {loader && <img src={FavichatLoading} className="signin-img-Loading"/>}
            </div>
          </div>

          <div>
            <Footer/>
          </div>

        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
