import "../../styles/PurchasePop.scss";
import "../../styles/Hearts.scss";
import { Url } from "../../axios/authAPI";
import { useAxios } from "../../axios/authAPI";
import BuyHeartPop from "../BuyHeartPop/BuyHeartPop";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {
  UpdatePurchasePopToggleState,
  UpdateHeartsOption,
  UpdateUserHeartsState,
} from "../../redux/actions/index";

function PurchasePop({ setPurchasePopup }) {
  let api = useAxios();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { isMobile } = useAppBreakpoints();
  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;

  const [loader, setLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const [heartActive, setHeartActive] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [showPaypalButton, setPaypalButton] = useState(false);
  const [heartMessagePop, setHeartMessagePop] = useState(false);
  const [endOfScroll, setEndOfScroll] = useState(false);
  const [endOfLeftScroll, setEndOfLeftScroll] = useState(false);

  const userData = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.userId);

  const getHeartPackage = async () => {
    api
      .get(`${Url}/heartPackages/list?limit=100&tag=hearts`)
      .then((res) => res.data)
      .then(
        (result) => {
          setPackages(result?.data?.value);
          if (result?.data?.value.length > 0) {
            setHeartActive(result?.data?.value[0]);
          }
          setLoader(false);
        },
        (error) => {
          setLoader(false);
        }
      );
  };

  useEffect(() => {
    getHeartPackage();
  }, []);

  const handleClick = (id) => {
    setHeartActive(id);
    setPaypalButton(false);
  };

  const btnPaypal = () => {
    setHeartMessagePop(true);
  };

  const slideLeft = async () => {
    const scrollAmount = 400;
    const currentScrollPosition = ref.current.scrollLeft;

    await ref.current.scrollTo({
      left: currentScrollPosition - scrollAmount,
      behavior: "smooth",
    });
    setEndOfLeftScroll(true);
    setEndOfScroll(false);
  };

  const slideRight = async () => {
    const scrollAmount = 400;
    const currentScrollPosition = ref.current.scrollLeft;

    await ref.current.scrollTo({
      left: currentScrollPosition + scrollAmount,
      behavior: "smooth",
    });
    setEndOfLeftScroll(false);
    setEndOfScroll(true);
  };

  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      apiAfterTransaction(details);
      setSuccess(true);
    });
  };

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Add Hearts",
            amount: {
              currency_code: "USD",
              value: heartActive.pricePlan,
            },
          },
        ],
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  const updateHeartsValue = async (addition) => {
    dispatch(
      UpdateHeartsOption({ userId: userData.userId, hearts: addition })
    ).then(() => {
      dispatch(UpdatePurchasePopToggleState(false));
    });
  };

  const apiAfterTransaction = (transactionDetails) => {
    setLoader(true);
    api
      .post(`${Url}/transactions/create`, {
        amount: heartActive.pricePlan,
        heartPackageId: heartActive.id,
        userId: userData.userId,
        paymentMode:
          transactionDetails?.purchase_units[0]?.payments?.captures[0]?.status,
        transactionReferenceId:
          transactionDetails?.purchase_units[0]?.payments?.captures[0]?.id,
      })
      .then((response) => {
        setPurchasePopup(false);
        const hearts = userData.hearts;
        const addition = hearts + heartActive.noOfHearts;
        dispatch(UpdateUserHeartsState(addition));
        updateHeartsValue(addition);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full items-center justify-center modal-desk-purchase-pop">
          <div className="px-2 pt-10 flex items-center justify-center flex-col">
            <button
              className="absolute top-2 left-2 m-1"
              onClick={(e) => dispatch(UpdatePurchasePopToggleState(false))}
            >
              <svg
                className="h-7 w-7 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>

            <div className="w-full text-center">
              <div
                className={
                  (isMobile || isIpad) === true ? "mx-auto" : "mx-auto max-w-lg"
                }
              >
                <div className="flex flex-col justify-center items-center">
                  <div
                    className={
                      (isMobile || isIpad) === true
                        ? "flex justify-between items-center mb-6 div-right-hearts-send-all-discovery transition-all duration-300 ease-in-out"
                        : "flex justify-center items-center div-right-hearts-send-all-discovery transition-all duration-300 ease-in-out"
                    }
                    ref={ref}
                  >
                    {(isMobile) && (
                      <div
                        className={
                          endOfLeftScroll
                            ? "bg-[#99cef6] w-6 h-6 top-1/2 left-0 absolute transform -translate-y-1/2 text-center z-2 bg-blue-300 border border-white cursor-pointer scroll-smooth transition-all duration-300 opacity-30"
                            : "bg-[#99cef6] w-6 h-6 top-1/2 left-0 absolute transform -translate-y-1/2 text-center z-2 bg-blue-300 border border-white cursor-pointer scroll-behavior-smooth transition-all duration-300 ease-in-out hover:h-full"
                        }
                        onClick={slideLeft}
                      >
                        <div className="w-full h-full flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="3"
                            stroke="currentColor"
                            className="w-3 h-3"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 19.5L8.25 12l7.5-7.5"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                    {packages?.map((data) => (
                      <div
                        className={
                          heartActive.id === data.id
                            ? "flex flex-col justify-between items-center maped-heard-active-purchase-pop"
                            : (isMobile || isIpad) === true
                            ? "flex flex-col justify-center items-center maped-heard-mobile"
                            : "flex flex-col justify-center items-center maped-heard-purchase-pop"
                        }
                        key={data.id}
                        onClick={(e) => handleClick(data)}
                      >
                        {data.isRecommended && (
                          <p
                            className={
                              data.isRecommended
                                ? "hearts-recommended mb-2 flex px-[4px] items-center justify-center uppercase text-[10px] font-medium rounded-t-md h-[20px] text-black text-center w-full"
                                : ""
                            }
                          >
                            recommended
                          </p>
                        )}
                        {data.mostPopular == false &&
                          data.isRecommended == false && (
                            <p className="text-[#6eb5ed] flex items-center justify-center uppercase text-sm font-medium mb-2 w-16 h-[20px] px-6 text-center text-white"></p>
                          )}
                        {data.mostPopular && (
                          <p
                            className={
                              data.mostPopular
                                ? "hearts-popular flex items-center justify-center uppercase text-[10px] font-medium w-full h-[20px] px-3 text-center text-white rounded-t-md mb-2"
                                : ""
                            }
                          >
                            popular
                          </p>
                        )}

                        {heartActive.id === data.id ? (
                          <svg
                            className="w-8 h-8 icon-heart-send-all-discovery"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1900 1900"
                            id="Heart"
                          >
                            <path
                              d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                              fill="#6eb5ed"
                              className="color000000 svgShape"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            className="w-8 h-8 icon-second-heart-send-all-discovery"
                            fill="currentColor"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-23.59 -23.59 518.88 518.88"
                            stroke="currentColor"
                            strokeWidth="15.566133000000002"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g>
                                {" "}
                                <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        )}

                        <p className="p-icon-heart-send-all-discovery">
                          {data.noOfHearts}
                        </p>
                        <p className="p-icon-heart-send-all-discovery">
                          ${data.pricePlan}
                        </p>
                      </div>
                    ))}
                  </div>
                  {(isMobile) && (
                    <div
                      className={
                        endOfScroll
                          ? "bg-[#99cef6] w-6 h-6 right-0 top-1/2 absolute transform -translate-y-1/2 text-center z-2 border border-white cursor-pointer scroll-smooth transition-all duration-300 opacity-30 bg-blue-300"
                          : "bg-[#99cef6] w-6 h-6 right-0 top-1/2 absolute transform -translate-y-1/2 text-center z-2 border border-white cursor-pointer scroll-behavior-smooth transition-all duration-300 ease-in-out hover:h-full bg-blue-300"
                      }
                      onClick={slideRight}
                    >
                      <div className="w-full h-full flex items-center justify-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="3"
                          stroke="currentColor"
                          className="w-3 h-3"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M8.25 4.5l7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </div>
                    </div>
                  )}

                  {showPaypalButton ? (
                    <PayPalScriptProvider
                      options={{
                        "client-id": `${process.env.REACT_APP_PAYPAL_ID}`,
                        currency: "USD",
                        "disable-funding": ["credit", "paylater"],
                      }}
                    >
                      <PayPalButtons
                        className="div-paypal-details-btn"
                        style={{
                          layout: "horizontal",
                          color: "blue",
                          margin: "auto",
                        }}
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                      />
                    </PayPalScriptProvider>
                  ) : (
                    <button
                      className="btn-add-hearts-purchase-pop flex items-center"
                      onClick={() => {
                        btnPaypal();
                      }}
                    >
                      <svg
                        className="w-5 h-5 icon-heart-purchase-pop"
                        fill="#ffff"
                        height="224px"
                        width="224px"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="-23.59 -23.59 518.88 518.88"
                        stroke="currentColor"
                        strokeWidth="15.566133000000002"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                      {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-9 h-9 icon-heart-purchase-pop"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                        />
                      </svg> */}
                      <span className="ml-2">Add Hearts</span>
                    </button>
                  )}

                  {heartMessagePop && (
                    <BuyHeartPop
                      hearts={heartActive?.noOfHearts}
                      cost={heartActive?.pricePlan}
                      setHeartMessagePop={setHeartMessagePop}
                      setPaypalButton={setPaypalButton}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasePop;
