import React from "react";
import "../../styles/DiscoverFooter.scss";

const DiscoverFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="discover_footer">
      <p>Copyright &#169; {year} FAVICHAT. All rights reserved</p>
      <hr id="line" />
    </div>
  );
};

export default DiscoverFooter;
