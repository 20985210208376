import "../../../styles/Help.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpCard from "../../../components/HelpCard/HelpCard";
import { UpdateSettingSidebarToggleState } from "../../../redux/actions/index";

const Help = () => {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col h-90vh relative py-4">
      <div className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
        onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
         <div className="sm:hidden pt-[8px]">
           <button type="submit" className="mr-[8px]">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
               <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
              </svg>
           </button>
         </div>
         <p className="text-[#494949] font-boboto font-bold title-profile">Help</p>
      </div>
      <div className="sm:px-2 px-4 sm:mt-6 mt-2 mt-10">
        <Link to={"terms-and-conditions"}>
          <HelpCard label={'Terms and conditions'}/>
        </Link>
        <Link to={"privacy-policy"}>
          <HelpCard label={'Privacy policy'}/>
        </Link>
        <Link to={"support"}>
          <HelpCard label={'Support'}/>
        </Link>
      </div>
    </div>
  );
};

export default Help;
