import "../../styles/InvalidUrlPage.scss";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import FaviLogo from "../../assets/icons/FaviNewLogo.svg";
import { ReactComponent as WifiIcon } from "../../assets/icons/wifi-off.svg";

function InvalidUrlPage({ isNavRequired, isLogIn, errorCode = "404", errorMessage = "Page not found" }) {

  const navigate = useNavigate();

  const redirectHomePage = () => {
    navigate("/discover");
  };

  return (
    <div className="h-screen w-screen flex flex-col justify-start overflow-hidden fixed top-0 left-0">

      {isNavRequired && (<Navbar />)}

      <div className="flex flex-col items-center justify-center h-screen">
        {errorCode.length > 3 ? (
          <WifiIcon />
        ) : (
          <img src={FaviLogo} alt="" className="img-favi-logo"/>
        )}

        {errorCode.length > 3 ? (
          <p className="mt-4 p-invalid-url">No connection</p>
        ) : (
          <p className="p-invalid-url">{errorMessage}</p>
        )}

        {errorCode.length > 3 ? null : (
          <button className="mt-6 btn-SignUp" onClick={ () => redirectHomePage()}>
            Home
          </button>
        )}
      </div>

    </div>
  );
}

export default InvalidUrlPage;