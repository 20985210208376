import React from "react";
import "../../styles/Performance.scss";

function PerformanceCard({ id, label, stats, imgUrl }) {
  return (
    <div className="flex items-center justify-between w-full border border-[#6eb5ed] rounded-lg cards-performance mb-2">
      <div className="flex items-center sm:w-1/2 w-3/4">
        <div className="sm:w-14 sm:h-14 w-12 h-12">
          <img src={imgUrl} alt="" className="object-cover img-cards-performance" />
        </div>
        <p className="text-gray-700 opacity-90 break-words ml-2 p-title-performance">{label}</p>
      </div>
      <div className="text-[#6eb5ed] font-bold p-title-performance">
        {id === 3 ? `$${stats}` : stats}
      </div>
    </div>
  );
}

export default PerformanceCard;