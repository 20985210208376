import "../../styles/PurchasePop.scss";
import { useState, useEffect } from "react";
import ReportUser from "../PopUp/ReportUser";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../axios/authAPI";
import { useSelector, useDispatch } from 'react-redux';
import FavichatLoader from "../../assets/gif/Favichat1.gif";
import { UnblockUser, DeleteConversations, BlockUser } from '../../redux/actions/index';

function DotsModal ({ setIsOpenDotsModal, onRequestClose, blocked, currentChatUserId, currentChatUsername, reasons, isDisabled  }) {

  let api = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDisable, setIsDisable] = useState(isDisabled);
  const [reportPopup, setReportPopup] = useState(false);
  const [loadingText, seLoadingText] = useState("");
  const [loading, seLoading] = useState(false);

  const userId = useSelector((state) => state.user.userId);
  const username = useSelector((state) => state.user.username);

  const handleBlockUser = () => {

    seLoadingText("Blocking...");
    seLoading(true);

    dispatch(BlockUser(api)).then(() => {
      setIsOpenDotsModal(false);
      seLoading(false);
      seLoadingText("");
    });
  }

  const handleUnblockUser = () => {

    seLoadingText("Unblocking...");
    seLoading(true);

    dispatch(UnblockUser(api)).then(() => {
      setIsOpenDotsModal(false);
      seLoading(false);
      seLoadingText("");
    });

  }

  const handleReport = () => {
    setReportPopup(true);
  };

  const handleDelete = () => {
    dispatch(DeleteConversations()).then(() => {
      setIsOpenDotsModal(false);
      navigate("/inbox/start");
    });
  }

  useEffect(() => {
    const handleClickOutsideModal = (event) => {
      if (!event.target.closest('.modal')) {
        onRequestClose();
      }
    };

    document.addEventListener('click', handleClickOutsideModal);

    return () => {
      document.removeEventListener('click', handleClickOutsideModal);
    };
  }, [onRequestClose]);

    return (
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity"></div>

          { loading && (
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full items-center justify-center modal-desk-block-user-pop">
              <div className="p-3 flex items-center justify-center flex-col">
                <img
                  className="loading-dots-modal"
                  src={FavichatLoader}
                  alt=""
                />
                <span className="text-sm pt-4 font-bold">{loadingText}</span>
              </div>
            </div>
          )}

          { !loading && (
            <div className="modal">
              <div className={isDisabled ? "absolute top-1/2 shadow-lg left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[1.5rem] px-0 py-0 border-15px w-[295px] h-[50px] bg-white" : "absolute top-1/2 shadow-lg left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-[1.5rem] px-0 py-0 border-15px w-[295px] h-[155px] bg-white"}>
                  <div className="flex flex-col justify-between divide-y divide-[#EAE9E9]">

                    {!isDisabled ? (
                      <h4
                        className='text-[17px] text-black text-center py-[13px] px-8 hover:bg-[#e4f5fd] cursor-pointer rounded-t-[1.5rem]'
                        onClick={() => {
                            blocked ? handleUnblockUser() : handleBlockUser()
                          }
                        }
                      >
                        {blocked ? "Unblock" : "Block"}
                      </h4>
                    ) : null}
                      <h4
                          className={isDisabled ? 'text-[17px] text-[#FF0000] text-center py-[13px] cursor-pointer px-8 hover:bg-[#e4f5fd] rounded-[1.5rem]': 'text-[17px] text-[#FF0000] text-center py-[13px] cursor-pointer px-8 hover:bg-[#e4f5fd]'}
                          onClick={() => handleDelete()}
                      >
                        Delete
                      </h4>
                      {!isDisabled ? (
                      <h4
                        className='text-[17px] text-black text-center py-[13px] cursor-pointer px-8 hover:bg-[#e4f5fd] rounded-b-[1.5rem]'
                              onClick={handleReport}
                      >
                        Report
                      </h4>
                    ) : null}
                  </div>
                    {reportPopup && (
                      <ReportUser
                        name={currentChatUsername}
                        currentChatUserId={currentChatUserId}
                        reportPopup={setReportPopup}
                        reasons={reasons}
                      />
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
}

export default DotsModal;