import React from "react";

const BackdropPopup = ({ onBackdropPopup }) => {
  return (
    <>
      <div className="popupui" onClick={onBackdropPopup} />
    </>
  );
};

export default BackdropPopup;
