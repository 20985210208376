/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import Cookie from "js-cookie";
import { Url } from "../../axios/authAPI";
import constants from "../../axios/constants";
import heart from "../../assets/gif/Heart.gif";
import { useAxios } from "../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import { guestUserCreds } from "../../Config/configurations";
import { useEffect, useState, useRef, useCallback } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";

import { UpdateDataUserState, UpdateUserDataSignUpOption, UpdateSettingSidebarToggleState, UpdateIsGuestState, UpdateIsLogInState, UpdatePopUpWelcomeState, UpdateOnlineUser, ResetNotUserState, UpdateFirstTimeState, DiscoverModalProfileState, UpdateUserFollowerState, UpdateDataGuestState } from "../../redux/actions/index";

const GuestUserPage = ({ setAuthorized }) => {
  let api = useAxios();
  let source = axios.CancelToken.source();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const buttonRef = useRef(null);
  const called = useRef(false);

  const userData = useSelector((state) => state.user);
  const isLogIn = useSelector((state) => state.isLogIn);
  const isGuestSelector = useSelector((state) => state.isGuest);
  const isDeviceGuest = useSelector((state) => state.isDeviceGuest);

  const [uid, setUid] = useState("");
  const [results, setResults] = useState([]);
  const [guestLabel, setGuestLabel] = useState();
  const [displayButton, setDisplayButton] = useState(true);

  const { isLoading, error, data } = useVisitorData();

  let userDataState = [];

  useEffect(() => {
    !isLoading && setGuestLabel("Loading Favichat");
    error && setGuestLabel({ message: "Error while connecting as Guest User" });
  }, [data?.visitorId, error, isLoading]);

  const getGuestUser = async () => {
    try {
      const url = new URL(window.location.href);
      const getUsername = url.pathname.split("/").pop();

      const userDataSignUp = await axios(`${Url}/users/${constants.signUp}`, {
        method: "POST",
        data: {
          visitorId: data?.visitorId,
          ...guestUserCreds,
          getUsername,
          isGuest: true,
          userName: getUsername,
        },
        headers: { "Content-Type": "application/json" },
      });

      const userDataLogin = await axios(
        `${Url}/users/${constants.login}?isGuest=true`,
        {
          method: "POST",
          data: {
            userName: `guest-${data.visitorId}`,
            password: `Pa12#${data.visitorId}#`,
          },
          headers: { "Content-Type": "application/json" },
        }
      );

      if (userDataLogin?.data !== undefined) {
        userDataState = userDataLogin?.data?.data;
        dispatch(UpdateDataUserState({
            // eslint-disable-next-line no-useless-computed-key
            ["GSI1-PK"]: userDataLogin?.data?.data["GSI1-PK"],
            PK: userDataLogin?.data?.data.PK,
            SK: userDataLogin?.data?.data.SK,
            bio: userDataLogin?.data?.data.bio,
            cashOut: userDataLogin?.data?.data.cashOut,
            contentUnLockUsers: userDataLogin?.data?.data.contentUnLockUsers,
            countryCode: userDataLogin?.data?.data.countryCode,
            createdAt: userDataLogin?.data?.data.createdAt,
            dob: userDataLogin?.data?.data.dob,
            earningsToDate: userDataLogin?.data?.data.earningsToDate,
            fbFollowers: userDataLogin?.data?.data.fbFollowers,
            firebaseUid: userDataLogin?.data?.data.firebaseUid,
            hearts: userDataLogin?.data?.data.hearts,
            userId: userDataLogin?.data?.data.id,
            instaFollowers: userDataLogin?.data?.data.instaFollowers,
            interests: userDataLogin?.data?.data.interests,
            name: userDataLogin?.data?.data.name,
            phoneNumber: userDataLogin?.data?.data.phoneNumber,
            profilePic: userDataLogin?.data?.data.profilePic,
            privacyTag: "EveryOne",
            profileViewed: userDataLogin?.data?.data.profileViewed,
            totalFollowers: userDataLogin?.data?.data.totalFollowers,
            totalFollowing: userDataLogin?.data?.data.totalFollowing,
            twitterFollowers: userDataLogin?.data?.data.twitterFollowers,
            updatedAt: userDataLogin?.data?.data.updatedAt,
            userName: userDataLogin?.data?.data.userName,
            youtubeFollowers: userDataLogin?.data?.data.youtubeFollowers,
          })
        );

        localStorage.setItem("accessToken", userDataLogin?.data?.acessToken);
        localStorage.setItem("refreshToken", userDataLogin?.data?.refreshToken);
        dispatch(UpdateIsGuestState(true));
        dispatch(
          UpdateUserDataSignUpOption({
            userId: userDataState.id,
            bio: userDataState.bio,
            dob: userDataState.dob,
            isOnline: true,
            name: userDataState.name.replace(/\s{2,}/g, " ").trim(),
            userName: userDataState.userName,
            id: userDataState.id,
            privacyTag: "EveryOne",
            totalFollowers: userDataState.totalFollowers,
            photoUrl:
              userDataState.profilePic == undefined
                ? null
                : userDataState.profilePic,
            phoneNumber: userDataState.phoneNumber,
            createdAt: `${Date.now()}`,
            chattingWith: null,
            typing: null,
            pushToken: null,
            isGuest: true,
            hearts: userDataState.hearts,
          })
        )
        called.current = false;
      }
      setResults(userDataSignUp?.data);
    } catch (e) {
        setGuestLabel(
          "The invite link is invalid and will not be accepted. We are redirecting to the main page."
        );
       setTimeout(() => {
          setAuthorized(false);
          navigate("/");
        }, 500);
      console.log({ __Error: e });
    }
  };

  useEffect(async () => {
    if(data === undefined) {
      return;
    }
    if(called.current === true) {
      return;
    }
    called.current = true;
    follow();
  }, [buttonRef, location, data, isLoading]);

  const follow = async() => {
    if (location.pathname.split("/").includes("go")) {
      dispatch(DiscoverModalProfileState(false));
      if (Boolean(data)) {
        const userName = location.pathname.split("/").pop();
        setGuestLabel(`Following ${userName}`);
        try {
          const response = await api.get(`${Url}/users/id/info?userName=${userName}`);
          setUid(response.data.data.uid);
          if (userData.userName !== null) {
            let data = { name: userName, id: response.data.data.uid };
            api.post(`${Url}/follows/${userData?.userId}/add`, {uid: response.data.data.uid})
              .then((response) => {
                dispatch(
                  UpdateUserFollowerState({
                    id: data.id,
                    name: data.name,
                  })
                );
                setAuthorized(true);
                dispatch(UpdateFirstTimeState(true));
                dispatch(UpdatePopUpWelcomeState(true));
                dispatch(UpdateIsLogInState(true));
                navigate("/inbox/start");
              })
              .catch((err) => {
                dispatch(
                  UpdateUserFollowerState({
                    id: data.id,
                    name: data.name,
                  })
                );
                setAuthorized(true);
                dispatch(UpdateFirstTimeState(true));
                dispatch(UpdatePopUpWelcomeState(true));
                dispatch(UpdateIsLogInState(true));
                navigate("/inbox/start");
              });
          } else {
            setGuestLabel(
              `You are logging in as Guest ${(data?.visitorId).slice(-5)}`
            );
            getGuestUser();
          }
        } catch (error) {
          console.log(error);
          if (isDeviceGuest || isLogIn === true) {
            setGuestLabel(
              "The invite link is invalid and will not be accepted. We are redirecting to your account."
            );
            dispatch(
              UpdateUserFollowerState({
                id: data.id,
                name: data.name,
              })
            );
            setAuthorized(true);
            dispatch(UpdateFirstTimeState(false));
            dispatch(UpdateIsLogInState(true));
            setTimeout(() => {
              navigate("/inbox/start");
            }, 500);
          } else {
            setGuestLabel("The invite link is invalid and will not be accepted. We are redirecting to the main page.");
            setTimeout(() => {
          setAuthorized(false);
          navigate("/");
        }, 500);
          }
        }
      }
    }
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-white">
      {displayButton ? (
        <button
          ref={buttonRef}
          hidden
          onClick={() => getGuestUser()}
          disabled={results.isLoading}
        />
      ) : null}
      <div className="text-center">
        {!results.isLoading && results.isSuccess ? (
          <>{null}</>
        ) : (
          <div className="flex flex-col items-center pb-24">
            <p className="mb-4">{guestLabel}</p>
            <div className="w-16 h-16 relative">
              <div className="w-full h-full absolute top-0 left-0 mt-10">
                <img src={FavichatLoading} className="signin-img-Loading" />
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col items-center">
          <p className="mb-4">
            Hi, 👋 this is where <br />
            I chat with my followers, <br />
            give updates, <br />
            send personalized content, <br />
            and more!
          </p>
          <img
            src={heart}
            alt="Centered"
            className="w-60 h-60 max-w-full"
            style={{ width: "60px", height: "60px" }}
          />
        </div>
      </div>
    </div>
  );
};

export const GuestUserWarning = () => {
  
  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);

  let api = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isHide, setIsHide] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const whiteListingUrls = [
    "help",
    "help/privacy-policy",
    "help/terms-and-conditions",
    "help/support",
  ];

  const onSignIn = () => {
    dispatch(ResetNotUserState());
    dispatch(UpdateIsGuestState(true));
    navigate("/?isGuest=true");
  };

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    whiteListingUrls.map((url) => {
      if (location.pathname.includes(url)) {
        setIsHide(true);
      }
    });
  }, [location.pathname, whiteListingUrls]);

  if (isGuest == "true" && !isHide) {
    return (
      <div className="flex pt-8 items-center justify-between">
        <div className="flex items-center justify-between">
          <div className="sm:hidden px-6">
            <div
              className=""
              onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
            >
              <button type="submit">
                <svg
                  className="w-6 h-6 text-black"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex items-center text-lg justify-start sm:justify-start">
            <p className="px-7 md:pl-12">
              This feature is not available for guest user.
              <button className="text-blue-500 text-lg py-2 rounded ml-2">
                <a
                  href="#"
                  onClick={() => {
                    onSignIn();
                  }}
                >
                  Create an account or Sign in
                </a>
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default GuestUserPage;
