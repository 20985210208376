import Cookie from "js-cookie";
import "../../styles/MobilePurchaseHeart.scss";
import { useDispatch } from "react-redux";
import { Url, authApi } from "../../axios/authAPI";
import React, { useEffect, useState } from "react";
import loader from "../../assets/gif/Favichat1.gif";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { BrowserRouter as Router, useNavigate, useLocation } from "react-router-dom";
import { UpdateIsGuestState, UpdateDataUserState, UpdateMobileAppHeartState, UpdateMobileAppCashoutState, UpdateMobileAppSuccessState, ResetState, UpdateErrorLogInState,UpdateOnlineUser,UpdateUserDataSignUp } from "../../redux/actions/index"
import { persistor } from "../../redux/store";
const MobilePurchaseHeart = () => {

  const uuid = window.location.pathname.split("/")[2];
const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  const callApiValidUuid = async () => {
    setLoading(true);
    try {
      const payload = { ssoUuid: uuid };
      const response = await authApi.post(`${Url}/users/sso`, payload);
      const responseData = await response.data;
      if (responseData?.success) {
        const user = responseData?.data;
        const accessToken = responseData?.acessToken;
        const refreshToken = responseData?.refreshToken;
       dispatch(UpdateIsGuestState(false));
      dispatch({ type: 'DATA_USER_STATE', payload: {
                    ["GSI1-PK"]: user["GSI1-PK"],
                    PK: user.PK,
                    SK: user.SK,
                    bio: user.bio,
                    cashOut: user.cashOut,
                    contentUnLockUsers: user.contentUnLockUsers,
                    countryCode: user.countryCode,
                    createdAt: user.createdAt,
                    dob: user.dob,
                    earningsToDate: user.earningsToDate,
                    fbFollowers: user.fbFollowers,
                    firebaseUid: user.firebaseUid,
                    hearts: user.hearts,
                    userId: user.id,
                    instaFollowers: user.instaFollowers,
                    interests: user.interests,
                    name: user.name,
                    phoneNumber: user.phoneNumber,
                    profilePicUrl: user.profilePic,
                    privacyTag: "EveryOne", 
                    profileViewed: user.profileViewed,
                    totalFollowers: user.totalFollowers,
                    totalFollowing: user.totalFollowing,
                    twitterFollowers: user.twitterFollowers,
                    updatedAt: user.updatedAt,
                    userName: user.userName,
                    youtubeFollowers: user.youtubeFollowers
                }});

                dispatch({ type: 'IS_LOGIN_STATE', payload: true });
                dispatch({ type: 'POPUP_WELCOME_STATE', payload: true });

                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);

                dispatch(UpdateUserDataSignUp({
                  userId: user.id,
                  bio: user.bio,
                  dob: user.dob,
                  isOnline: true,
                  name: user.name,
                  userName: user.userName,
                  id: user.id,
                  privacyTag: "EveryOne",
                  totalFollowers: user.totalFollowers,
                  photoUrl: user.profilePic,
                  phoneNumber: user.phoneNumber,
                  createdAt: user.createdAt,
                  chattingWith: null,
                  typing: null,
                  pushToken: null,
                  isGuest: false,
                  hearts: user.hearts,
                }))

        dispatch(UpdateOnlineUser({
          online: true,
        }));

        dispatch(UpdateErrorLogInState(""));
        dispatch({ type: 'POPUP_WELCOME_STATE', payload: true });
        dispatch(UpdateMobileAppHeartState("mobileAppHeart"))
        dispatch(UpdateMobileAppCashoutState("mobileAppCashout"))
        dispatch(UpdateMobileAppSuccessState("mobileAppSuccess"))

        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        if (query.get("type") == "heart") {
          navigate(`/settings/hearts`);
        } else if (query.get("type") == "cashout") {
          navigate(`/settings/cash-out`);
        } else {
          navigate("/invalid-url");
        }
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    dispatch(UpdateErrorLogInState(""));
    dispatch(UpdateOnlineUser({ online: false })).then(() => {
      dispatch(ResetState());
      persistor
        .purge()
        .then(() => {
          navigate("/");
        })
        .catch((error) => {
          console.log("Error purging state:", error);
        });
    });
    }
  };

  useEffect(() => {
    callApiValidUuid();
  }, []);

  return (
    <div>
      {loading ? (
        <div className="mobilePurchaseHeart_div">
          <img src={loader} alt="" />
          <h2>Loading...</h2>
        </div>
      ) : null}
    </div>
  );
};
export default MobilePurchaseHeart;
