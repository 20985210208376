import Avatar from "react-avatar";
import Autolinker from "autolinker";
import BlockForm from "./blockForm";
import MessageForm from "./messageForm";
import DotsModal from "./DotsModal";
import "../../styles/InboxChatbox.scss";
import { useAxios, Url } from "../../axios/authAPI";
import { useSelector, useDispatch } from "react-redux";
import lockIcon from "../../assets/icons/lock-icon.png";
import videoBlur from "../../assets/icons/videoBlur.jpg";
import videoBlock from "../../assets/icons/video_block.png";
import { useLocation, useNavigate } from "react-router-dom";
import FavichatLoader from "../../assets/gif/Favichat1.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import {
  UpdateIsGuestState,
  ResetNotUserState,
  UpdateOnlineUser,
  UpdatePurchasePopToggleState,
  UpdateUserHeartsState,
  UpdateContentMessage,
  GetMessages,
  stopUnsubscribeMessages,
  stopUnsubscribeBockUserToMe,
  stopUnsubscribeTyping,
  GetTyping,
} from "../../redux/actions/index";
import moment from "moment-timezone";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return [size, isMobile, location];
}

function InboxChatbox({ selectChatChannel }) {
  const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const pageSize = 20;
  const isSafari = !!navigator.userAgent.match(/iPhone/i);

  let api = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const infiniteScrollRef = useRef(null);
  const [size, isMobile, location] = useWindowSize();
  const [, windowHeight] = size;

  const user = useSelector((state) => state.user);
  const isGuestRedux = useSelector((state) => state.isGuest);
  const blockUsers = useSelector((state) => state.blockUsers);
  const currentChat = useSelector((state) => state.currentChat);
  const currentUser = useSelector((state) => state.user.userId);
  const blockMeUsers = useSelector((state) => state.blockMeUsers);
  const currentHearts = useSelector((state) => state.user.hearts);
  const lastDoc = useSelector((state) => state.currentChat.lastDoc);
  const messages = useSelector((state) => state.currentChat.messages);
  const currentChatOnline = useSelector((state) => state.currentChat);
  const userIsDisable = useSelector((state) => state.currentChat.isDisable);
  const currentChatUserId = useSelector((state) => state.currentChat.userId);
  const currentConversationId = useSelector((state) => state.currentConversationId);
  const participantsInformation = useSelector((state) => state.currentChat.participants);

  const [reasons, setReason] = useState([]);
  const [loader, setLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isBlocked, setBlocked] = useState(false);
  const [scrollAut, setScrollAut] = useState(true);
  const [avatarName, setAvatarName] = useState("");
  const [urlContent, setUrlContent] = useState("");
  const [typeContent, setTypeContent] = useState(0);
  const [isDisable, setIsDisable] = useState(false);
  const [startAfter, setStartAfter] = useState(null);
  const [userOnline, setUserOnline] = useState(false);
  const [imGuest, setImGuest] = useState(isGuestRedux);
  const [myAvatarName, setMyAvatarName] = useState("");
  const [statusUpdate, setStatusUpdate] = useState("");
  const [loadedImages, setLoadedImages] = useState({});
  const [loadedVideo, setLoadedVideos] = useState({});
  const [openContent, setOpenContent] = useState(false);
  const [isYouBlocked, setYouBlocked] = useState(false);
  const [isPrivacyTag, setIsPrivaciTag] = useState(false);
  const [contendIdBlock, setContendIdBlock] = useState("");
  const [wHeight, setWHeight] = useState(window.innerHeight);
  const [openContentBuy, setOpenContentBuy] = useState(false);
  const [showGuestPopUp, setShowGuestPopUp] = useState(false);
  const [isOpenDotsModal, setIsOpenDotsModal] = useState(false);
  const [isGuest, setIsGuest] = useState(currentChat.isGuest || false);

  const ApiPurchaseContent = () => {
    setLoader(true);
    setOpenContentBuy(false);
    setScrollAut(false);
    api
      .post(`${Url}/content/${contendIdBlock.contentId}/unlock`, {
        uid: currentUser,
        contentUserId: currentChatUserId,
      })
      .then((response) => response.data)
      .then((result) => {
        if (result.data.status) {
          const totalHearts = currentHearts - contendIdBlock.hearts;
          dispatch(UpdateUserHeartsState(totalHearts));
          dispatch(
            UpdateContentMessage({
              currentConversationId: currentConversationId,
              messageId: contendIdBlock.id,
              currentUser: currentUser,
              totalHearts: totalHearts,
            })
          ).then(() => {
            setLoader(false);
            setTimeout(() => {
              setScrollAut(true);
            }, 1000);
          });
        } else {
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const setHeartPopup = () => {
    if (imGuest) {
      setShowGuestPopUp(true);
    } else {
      setOpenContentBuy(false);
      dispatch(UpdatePurchasePopToggleState(true));
    }
  };

  const handleBackChat = () => {
    navigate("/inbox/start");
  };

  const getUsername = (currentChat) => {
    if (currentChat.name !== null && currentChat.name !== undefined) {
      if (currentChat.isGuest || currentChat.name === "Guest" || currentChat.name.includes("guest-")) {
        const guest = currentChat.username.split("-").pop();
        return `Guest ${guest.slice(-5)}`;
      }
      if (isMobile === true) {
        return currentChat.name.length > 11
          ? currentChat.name.slice(0, 11) + "..."
          : currentChat.name;
      } else {
        const truncatedName = currentChat.name;
        return truncatedName;
      }
    }
  };

  const handleOpenDotsModal = () => {
    setIsOpenDotsModal(true);
  };

  const handleCloseDotsModal = () => {
    setIsOpenDotsModal(false);
  };

  const fetchMoreData = () => {
    if (!loading && hasMore) {
      setLoading(true);
      setScrollAut(false);
      dispatch(
        GetMessages({
          conversationId: currentConversationId,
          pageSize: 20,
          startAfter: lastDoc,
        })
      ).then(() => {
        if (lastDoc) {
          setHasMore(true);
          setStartAfter(lastDoc);
        }
        setLoading(false);
        setTimeout(() => {
          setScrollAut(true);
        }, 30000);
      });
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);

    const options = { hour: "numeric", minute: "numeric", hour12: true };

    return date.toLocaleTimeString("en-US", options).toLowerCase();
  };

  function showTime(timestamp, index, message) {
    const today = new Date();
    let date = new Date(timestamp * 1000);
    const rMessages = [...messages].reverse();
    const currentDate = new Date(timestamp * 1000).toLocaleDateString();
    const nextDate =
      index < rMessages.length - 1
        ? new Date(rMessages[index + 1].timestamp * 1000).toLocaleDateString()
        : null;

    const isLastMessageWithDifferentDate = nextDate !== currentDate;

    if (isLastMessageWithDifferentDate) {
      const formattedDate = date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const formattedToday = today.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      const displayDate = formattedDate === formattedToday ? "Today" : formattedDate;

      return (
        <div className="flex items-center">
          <span className="text-gray-500 mx-auto my-3 text-xs">
            {displayDate}
          </span>
        </div>
      );
    }
  }

  const getPhotoUrl = (participantsInformation) => {
    if (participantsInformation.length > 0) {
      const participant = participantsInformation.find(
        (item) => item.userId !== currentUser && item.name !== ""
      );
      if (participant !== undefined && participant.photoUrl) {
        return participant.photoUrl;
      }
    }
    return null;
  };

  const isLink = (message) => {
    const URLReg = new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/
    );
    const linkedText = Autolinker.link(message, {
      newWindow: true,
      className: "link",
      sanitizeHtml: true,
    });
    if (URLReg.test(message)) {
      return <p dangerouslySetInnerHTML={{ __html: linkedText }} />;
    }
    return <p className="whitespace-pre-wrap">{message}</p>;
  };

  const refurbishMyName = (string) => {
    if (string === undefined) {
      return "";
    }

    var name;
    const formatedName = string
      ?.split(" ")
      .filter((element) => element !== null && element !== "");
    if (formatedName?.length > 2) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else if (formatedName?.length > 1) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else {
      name = string?.slice(0, 1);
    }
    if (name) {
      // setAvatarName(name.toUpperCase());
      return name.toUpperCase();
    } else {
      return "";
    }
  };

  const refurbishName = (string) => {
    if (string === undefined) {
      return "";
    }

    var name;
    const formatedName = string
      ?.split(" ")
      .filter((element) => element !== null && element !== "");
    if (formatedName?.length > 2) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else if (formatedName?.length > 1) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else {
      name = string?.slice(0, 1);
    }
    if (name) {
      // setAvatarName(name.toUpperCase());
      return name.toUpperCase();
    } else {
      return "";
    }
  };

  const getReasons = async () => {
    const data = await api
      .get(`${Url}/report-reason/list?limit=5`)
      .then((res) => res.data)
      .then((result) => {
        setReason(result?.data?.values);
      });
  };

  const guestUserLogout = () => {
    if (imGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  useEffect(() => {
    setUserOnline(currentChatOnline.online);
  }, [currentChatOnline]);

  useEffect(async () => {
    if (userIsDisable == true) {
      setStatusUpdate("Can't reply");
    } else {
      if (isBlocked && !isYouBlocked) {
        setStatusUpdate("You have blocked this account");
      } else if (!isBlocked && isYouBlocked) {
        setStatusUpdate("Can't reply");
      } else if (isBlocked && isYouBlocked) {
        setStatusUpdate("You have blocked this account");
      } else if (!isBlocked && !isYouBlocked) {
        switch (currentChat.privacyTag) {
          case "NoOne":
            setIsPrivaciTag(true);
            setStatusUpdate("Can't reply");
            break;
          case "EveryOne":
            setIsPrivaciTag(false);
            setStatusUpdate("Can reply");
            break;
          case "Followers":
            const data = await api.get(
              `${Url}/follows/${user.userId}/user?follower=${location.pathname
                .split("/")
                .pop()}`
            );
            const response = data.data;
            setIsPrivaciTag(!response?.data?.requestedUserStatus);
            setStatusUpdate(
              response?.data?.requestedUserStatus ? "Can reply" : "Can't reply"
            );
          default:
            break;
        }
      }
    }
  }, [currentChat, isYouBlocked, isBlocked]);

  useEffect(() => {
    if (statusUpdate === "Can reply") {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [statusUpdate]);

  useEffect(() => {
    if (location.pathname.startsWith("/inbox/")) {
      dispatch(
        GetMessages({
          conversationId: currentConversationId,
          pageSize: pageSize,
          startAfter: lastDoc,
        })
      );

      dispatch(GetTyping(currentConversationId));
    }

    return () => {
      dispatch(stopUnsubscribeTyping());
      dispatch(stopUnsubscribeMessages());
    };
  }, [location.pathname, currentConversationId]);

  useEffect(() => {
    if (blockMeUsers !== undefined) {
      const exists = blockMeUsers.some((obj) => obj === currentChatUserId);
      setYouBlocked(exists);
    }
  }, [blockMeUsers, currentChatUserId]);

  useEffect(() => {
    if (blockUsers !== undefined) {
      const exists = blockUsers.some((obj) => obj.userId === currentChatUserId);
      setBlocked(exists);
    }
  }, [blockUsers, currentChatUserId]);

  useEffect(() => {
    getReasons();
    const handleResize = () => {
      setWHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const smoothScroll = (element, target, duration) => {
    let start = element.scrollTop,
      change = target - start,
      startTime = performance.now(),
      val,
      now,
      elapsed,
      t;

    function animateScroll(currentTime) {
      now = currentTime;
      elapsed = (now - startTime) / 1000;
      t = elapsed / duration;

      element.scrollTop = start + change * Math.easeInOutQuad(t);

      if (t < 1) window.requestAnimationFrame(animateScroll);
    }

    window.requestAnimationFrame(animateScroll);
  };

  Math.easeInOutQuad = function (t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  useEffect(() => {
    if(isIOS){
      return;
    }
    if (scrollAut) {
      const scrollDiv = infiniteScrollRef.current;
      if (scrollDiv && scrollDiv.el) {
        let targetPosition = scrollDiv.el.scrollHeight;
        smoothScroll(scrollDiv.el, targetPosition, 1);
      }
    }
  }, [messages]);

  if (!messages) {
    return null;
  }

  return (
    <>
      {openContent && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-black bg-opacity-100 transition-opacity" />
            <button
              className={`image-crossButton-shadow rounded-full fixed top-2 left-2 m-1 ${
                isMobile ? "z-10" : ""
              }`}
              onClick={() => setOpenContent(false)}
            >
              <svg
                className="h-8 w-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {typeContent === 1 && (
              <img
                className={`sm:absolute md:w-5/6 md:h-[97%] ${
                  isMobile ? "w-full h-full fixed inset-0" : ""
                } object-contain`}
                src={urlContent}
                alt="Message Image"
              />
            )}
            {typeContent === 2 && (
              <video
                className={`sm:absolute md:w-5/6 md:h-5/6 ${
                  isMobile ? "w-full h-full fixed inset-0" : "object-contain"
                }`}
                disablePictureInPicture
                controls
                autoPlay
                loop
                playsInline
              >
                <source src={urlContent}></source>
              </video>
            )}
            {typeContent === 3 && (
              <img
                className={`absolute ${
                  isMobile ? "w-full h-auto" : ""
                } object-contain`}
                src={urlContent}
                alt="Message Image"
              />
            )}
          </div>
        </div>
      )}

      {openContentBuy && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full items-center justify-center modal-desk-buy-content-pop">
              <div className="p-2 flex items-center justify-center flex-col">
                <button
                  className="absolute top-3 left-3 m-1"
                  onClick={(e) => setOpenContentBuy(false)}
                >
                  <svg
                    className="h-7 w-7 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                {currentHearts < contendIdBlock.hearts ? (
                  <div className="pt-8 flex items-center justify-center flex-col">
                    <svg
                      className="w-12 h-12 heart-img-left"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1900 1900"
                      id="Heart"
                      fill="currentColor"
                    >
                      <path
                        d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                        fill="currentColor"
                        className="color000000 svgShape"
                      ></path>
                    </svg>

                    <span className="txt-ops-buy">Oops</span>
                    <p className="txt-ops-confirm-buy">
                      You don’t have enough hearts!
                    </p>

                    <div className="flex flex-col justify-center md:justify-between items-center w-full pb-4">
                      <button
                        className="btn-buy-pop-confirm"
                        autoFocus
                        onClick={() => setHeartPopup()}
                      >
                        Purchase Hearts
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="pt-8 flex items-center justify-center flex-col">
                    <svg
                      className="w-12 h-12 heart-img-left"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1900 1900"
                      id="Heart"
                      fill="currentColor"
                    >
                      <path
                        d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                        fill="currentColor"
                        className="color000000 svgShape"
                      ></path>
                    </svg>

                    <p className="txt-confirm-buy">
                      {" "}
                      Confirm giving{" "}
                      <span className="txt-price-buy">
                        {contendIdBlock.hearts}
                      </span>{" "}
                      hearts to unlock and get noticed!🙂
                    </p>

                    <div className="flex flex-row justify-evenly items-center w-full pb-4">
                      <button
                        className="btn-buy-pop-cancel"
                        autoFocus
                        onClick={(e) => setOpenContentBuy(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-buy-pop-confirm"
                        autoFocus
                        onClick={() => ApiPurchaseContent()}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                )}
              </div>
              
            </div>
            {showGuestPopUp && (
                  <>
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50 transition-opacity">
                      <div className={`absolute ${isMobile ? "inset-x-0 bottom-0 rounded-t-3xl pb-8 pt-10" : "md:w-[625px] rounded-3xl py-12 w-1/2 md:h-32"} bg-white px-4 shadow-lg`}>
                        <button
                          className="absolute top-0 left-1 m-4"
                          onClick={() => {
                            setOpenContentBuy(false);
                            setShowGuestPopUp(false);
                          }}
                        >
                          <svg
                            className="h-7 w-7 text-gray-700"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                        <div className="flex items-center justify-center div-guest-send-all-discovery">
                          <p>
                            This feature is not available for guest user.
                            <button
                              onClick={() => {
                                dispatch(
                                  UpdateOnlineUser({
                                    online: false,
                                  })
                                ).then(() => {
                                  guestUserLogout();
                                });
                              }}
                            >
                              Create an account or Sign in
                            </button>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
          </div>
        </div>
      )}

      <div
        className={`flex flex-col overflow-hidden ${
          isSafari ? "" : "h-screen"
        }`}
        style={{
          height: `${
            isSafari ? "-webkit-fill-available" : `${windowHeight - 64}px`
          }`,
        }}
      >
        <div
          className={`flex ${
            isMobile ? "" : "justify-between"
          } items-center bg-[#F3F3F3] px-1 py-2 border-b sticky top-0`}
        >
          {isMobile === true && (
            <button
              onClick={() => {
                handleBackChat();
              }}
              className="hover:bg-gray-200 py-1 px-1 rounded-full"
              type="submit"
              aria-label="back"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="gray"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
          )}

          <div className="flex items-center space-x-2 relative">
            {userOnline && !isDisable && (
              <div className="w-[0.7rem] h-[0.7rem] left-8 bottom-1 rounded-full bg-green-500 absolute z-10" />
            )}

            {isGuest ? (
              <Avatar color="#99CEF6" round={true} value={"G"} size="36" />
            ) : (
              <>
                {currentChat.name !== null &&
                  currentChat.name !== undefined && (
                    <>
                      {currentChat.photoUrl !== null &&
                      currentChat.photoUrl !== "" ? (
                        <img
                          className="w-9 h-9 rounded-full object-cover"
                          src={currentChat.photoUrl}
                          alt="Avatar"
                        />
                      ) : (
                        <>
                          <Avatar
                            color="#99CEF6"
                            round={true}
                            value={refurbishName(currentChat.name)}
                            size="36"
                            style={{
                              position: "relative",
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
              </>
            )}

            <div className="flex flex-col px-2">
              <div className="flex flex-row -mt-1 ">
                <div>
                  {currentChat ? (
                    <>
                      <span className="text-base text-blue-600 font-medium">
                        {getUsername(currentChat)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="text-base text-blue-600 font-medium">
                        {getUsername(currentChat)}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="-mt-2">
                <span
                  style={{ fontSize: "10px" }}
                  className="text-xs text-gray-500"
                >
                  {statusUpdate}
                </span>
              </div>
            </div>

            <div className="flex flex-row items-start" />
            <span className="text-lg font-medium" />
          </div>

          <button onClick={handleOpenDotsModal} className="p-2 ml-auto">
            <svg
              className="w-6 h-6 text-blue"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fillRule="evenodd"
                d="M10 18a2 2 0 100-4 2 2 0 000 4zM10 6a2 2 0 100-4 2 2 0 000 4z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {isOpenDotsModal && (
          <DotsModal
            setIsOpenDotsModal={setIsOpenDotsModal}
            onRequestClose={handleCloseDotsModal}
            blocked={isBlocked}
            currentChatUserId={currentChatUserId}
            currentChatUsername={currentChat.name}
            reasons={reasons}
            isDisabled={userIsDisable}
          />
        )}

        <div className="flex-1 overflow-hidden bg-white" id="chat-scroll">
          <div className="container mx-auto">
            <InfiniteScroll
              dataLength={messages.length}
              next={fetchMoreData}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                paddingRight: "1rem",
              }}
              inverse={true}
              hasMore={hasMore}
              loader={
                loading && (
                  <div className="flex justify-center items-center">
                    <img
                      className="loading-scroll-chat"
                      src={FavichatLoader}
                      alt=""
                    />
                  </div>
                )
              }
              height={isMobile ? wHeight - 212 : wHeight - 242}
              scrollThreshold={0.2}
              scrollableTarget="scrollableDiv"
              ref={infiniteScrollRef}
            >
              <>
                {[...messages].reverse().map((message, index) => {
                  return (
                    <>
                      <div
                        className={`flex items-center space-x-2 pl-4 ${
                          message.senderId !== currentUser ? "" : "justify-end"
                        }`}
                        key={index}
                      >
                        {message.senderId !== currentUser ? (
                          <>
                            <div className="flex justify-center pb-1">
                              {getPhotoUrl(participantsInformation) !== null ? (
                                <img
                                  className="w-8 h-8 rounded-full object-cover"
                                  src={getPhotoUrl(participantsInformation)}
                                  alt="Avatar"
                                />
                              ) : (
                                <Avatar
                                  color="#99CEF6"
                                  round={true}
                                  value={
                                    isGuest
                                      ? "G"
                                      : refurbishName(
                                          participantsInformation.find(
                                            (item) =>
                                              item.userId !== currentUser &&
                                              item.name !== ""
                                          )?.name
                                        )
                                  }
                                  size="32"
                                  style={{
                                    position: "relative",
                                  }}
                                />
                              )}
                              <div className="flex flex-col pl-1">
                                <div
                                  className={`bg-slate-100 ${
                                    message.content !== ""
                                      ? ""
                                      : message.type !== 0
                                      ? ""
                                      : "py-1 px-2"
                                  } ${
                                    message.contentUrl !== "" &&
                                    message.type !== 3
                                      ? "chat-content-chatbox"
                                      : ""
                                  } rounded-xl`}
                                >
                                  {/* {message.type !== 3 && ( */}
                                  <p
                                    className={`whitespace-pre-wrap text-black ${
                                      message.content !== "" ? "py-1 px-2" : ""
                                    } txt-content-chatbox`}
                                  >
                                    {isLink(message.content)}
                                  </p>
                                  {/* )} */}
                                  {message.type === 1 &&
                                    (message.contentBlur === false ? (
                                      <img
                                        className={`rounded-xl max-w-full object-cover img-content-chatbox cursor-pointer`}
                                        src={message.contentUrl}
                                        alt="Message Image"
                                        onClick={() => {
                                          setUrlContent(message.contentUrl);
                                          setTypeContent(message.type);
                                          setOpenContent(true);
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          if (!userIsDisable) {
                                            setContendIdBlock(message);
                                            setOpenContentBuy(true);
                                          }
                                        }}
                                      >
                                        <img
                                          className={`rounded-xl max-w-full object-cover img-content-chatbox`}
                                          src={videoBlur}
                                          alt="Message Image"
                                        />
                                        {loader &&
                                        contendIdBlock.contentId ===
                                          message.contentId ? (
                                          <></>
                                        ) : (
                                          <img
                                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 img-content-block"
                                            src={lockIcon}
                                            alt="Message Image block"
                                            width={100}
                                          />
                                        )}
                                      </div>
                                    ))}
                                  {message.type === 2 &&
                                    (message.contentBlur === false ? (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          setUrlContent(
                                            message.contentUrl + "#t=0.001"
                                          );
                                          setTypeContent(message.type);
                                          setOpenContent(true);
                                        }}
                                      >
                                        <video
                                          className={`rounded-xl max-w-full object-cover img-content-chatbox`}
                                          muted
                                        >
                                          <source
                                            src={message.contentUrl + "#t=0.001"}
                                          />
                                        </video>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-12 h-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 heart-img"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </div>
                                    ) : (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          if (!userIsDisable) {
                                            setContendIdBlock(message);
                                            setOpenContentBuy(true);
                                          }
                                        }}
                                      >
                                        <img
                                          className={`rounded-xl max-w-full object-cover img-content-chatbox`}
                                          src={videoBlur}
                                          alt="Message Image"
                                        />
                                        {loader &&
                                        contendIdBlock.contentId ===
                                          message.contentId ? (
                                          <></>
                                        ) : (
                                          <img
                                            src={videoBlock}
                                            className="w-12 h-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 heart-img"
                                          />
                                        )}
                                      </div>
                                    ))}
                                  {message.type === 3 && (
                                    <img
                                      className="rounded-xl max-w-full object-cover img-content-chatbox cursor-pointer"
                                      src={message.contentUrl}
                                      alt="Message Image"
                                      onClick={() => {
                                        setUrlContent(message.contentUrl);
                                        setTypeContent(1);
                                        setOpenContent(true);
                                      }}
                                    />
                                  )}
                                  {message.type !== 0 && message.type !== 3 && (
                                    <div
                                      className={`flex items-center ${
                                        message.type !== 0 ? "p-2" : ""
                                      }`}
                                    >
                                      {message.contentBlur === false ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className={`w-6 h-6 unlock-img-left ${
                                            message.hearts === 0 ? "hidden" : ""
                                          }`}
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="w-6 h-6 lock-img-left"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                          />
                                        </svg>
                                      )}
                                      <svg
                                        fill="currentColor"
                                        className="w-6 h-6 heart-img-left ml-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 1900 1900"
                                        id="Heart"
                                      >
                                        <path
                                          d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                                          fill="currentColor"
                                          className="color000000 svgShape"
                                        ></path>
                                      </svg>
                                      <p
                                        className={`text-black ml-1 txt-content-left-chatbox`}
                                      >
                                        {message.hearts}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {formatTimestamp(
                                  [...messages].reverse()[
                                    index === 0 ? index : index - 1
                                  ].timestamp
                                ).toString() ===
                                formatTimestamp(message.timestamp).toString() ? (
                                  <>
                                    {index === 0 ? (
                                      <>
                                        <span className="text-xs text-gray-500 text-left pl-1">
                                          {formatTimestamp(
                                            moment(message?.timestamp, "x")
                                              .tz(moment.tz.guess())
                                              .format("x")
                                          ).toString()}
                                        </span>
                                      </>
                                    ) : (
                                      <div className="p-1" />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span className="text-xs text-gray-500 text-left pl-1">
                                      {formatTimestamp(
                                        moment(message?.timestamp, "x")
                                          .tz(moment.tz.guess())
                                          .format("x")
                                      ).toString()}
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="flex justify-center pb-1">
                              <div className="flex flex-col pr-1">
                                <div
                                  className={`bg-[#99cef6] ${
                                    message.content !== ""
                                      ? ""
                                      : message.type !== 0
                                      ? ""
                                      : "py-1 px-2"
                                  } ${
                                    message.contentUrl !== "" &&
                                    message.type !== 3
                                      ? "chat-content-chatbox"
                                      : ""
                                  } rounded-xl`}
                                >
                                  {/* {message.type !== 3 && ( */}
                                  <p
                                    className={`whitespace-pre-wrap text-black ${
                                      message.content !== "" ? "py-1 px-2" : ""
                                    } txt-content-right-chatbox`}
                                  >
                                    {isLink(message.content)}
                                  </p>
                                  {/* )} */}
                                  {message.type === 1 &&
                                    (message.contentBlur === false ? (
                                      <img
                                        className={` rounded-xl max-w-full object-cover img-content-chatbox cursor-pointer`}
                                        src={message.contentUrl}
                                        alt="Message Image"
                                        onClick={() => {
                                          setUrlContent(message.contentUrl);
                                          setTypeContent(message.type);
                                          setOpenContent(true);
                                        }}
                                      />
                                    ) : message.contentBlur === true &&
                                      message.senderId === currentUser ? (
                                      <img
                                        key={message.contentUrl}
                                        className={`rounded-xl max-w-full object-cover img-content-chatbox cursor-pointer`}
                                        src={message.contentUrl}
                                        alt="Message Image"
                                        // onLoad={handleImageLoaded}
                                        onClick={() => {
                                          setUrlContent(message.contentUrl);
                                          setTypeContent(message.type);
                                          setOpenContent(true);
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          if (!userIsDisable) {
                                            setContendIdBlock(message);
                                            setOpenContentBuy(true);
                                          }
                                        }}
                                      >
                                        <img
                                          className={`rounded-xl max-w-full object-cover img-content-chatbox`}
                                          src={videoBlur}
                                          alt="Message Image"
                                        />
                                        <img
                                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 img-content-block"
                                          src={lockIcon}
                                          alt="Message Image block"
                                          width={100}
                                        />
                                      </div>
                                    ))}
                                  {message.type === 2 &&
                                    (message.contentBlur === false ? (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          setUrlContent(
                                            message.contentUrl + "#t=0.001"
                                          );
                                          setTypeContent(message.type);
                                          setOpenContent(true);
                                        }}
                                      >
                                        <video
                                          className={`rounded-xl max-w-full object-cover img-content-chatbox`}
                                          muted
                                        >
                                          <source
                                            src={message.contentUrl + "#t=0.001"}
                                          />
                                        </video>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-12 h-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 heart-img"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </div>
                                    ) : message.contentBlur === true &&
                                      message.senderId === currentUser ? (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          setUrlContent(
                                            message.contentUrl + "#t=0.001"
                                          );
                                          setTypeContent(message.type);
                                          setOpenContent(true);
                                        }}
                                      >
                                        <video
                                          key={message.contentUrl}
                                          className={`rounded-xl max-w-full object-cover img-content-chatbox`}
                                          muted
                                          // onLoadedData={handleVideoLoaded}
                                          // onLoadedMetadata={handleVideoLoaded}
                                          // poster={!loadedVideo[message.contentUrl] ? RippleLoader : ''}
                                        >
                                          <source
                                            src={message.contentUrl + "#t=0.001"}
                                          />
                                        </video>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-12 h-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 heart-img"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </div>
                                    ) : (
                                      <div
                                        className="relative cursor-pointer"
                                        onClick={() => {
                                          if (!userIsDisable) {
                                            setContendIdBlock(message);
                                            setOpenContentBuy(true);
                                          }
                                        }}
                                      >
                                        <img
                                          className="rounded-xl max-w-full object-cover img-content-chatbox"
                                          src={videoBlur}
                                          alt="Message Image"
                                        />
                                        <img
                                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 img-content-block"
                                          src={lockIcon}
                                          alt="Message Image block"
                                          width={100}
                                        />
                                      </div>
                                    ))}
                                  {message.type === 3 && (
                                    <img
                                      className="rounded-xl max-w-full object-cover img-content-chatbox cursor-pointer"
                                      src={message.contentUrl}
                                      alt="Message Image"
                                      onClick={() => {
                                        setUrlContent(message.contentUrl);
                                        setTypeContent(1);
                                        setOpenContent(true);
                                      }}
                                    />
                                  )}
                                  {message.type !== 0 && message.type !== 3 && (
                                    <div
                                      className={`flex items-center ${
                                        message.type !== 0 ? "p-[0.2rem]" : ""
                                      }`}
                                    >
                                      {message.contentBlur === false &&
                                      message.hearts !== 0 ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="w-6 h-6 unlock-img-left-white"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                          />
                                        </svg>
                                      ) : (
                                        message.contentBlur === true &&
                                        message.hearts !== 0 && (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth="1.5"
                                            stroke="currentColor"
                                            className="w-6 h-6 lock-img"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                                            />
                                          </svg>
                                        )
                                      )}

                                      <svg
                                        className="w-6 h-6 heart-img ml-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 1900 1900"
                                        id="Heart"
                                        fill="currentColor"
                                      >
                                        <path
                                          d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                                          fill="currentColor"
                                          className="color000000 svgShape"
                                        ></path>
                                      </svg>

                                      <p
                                        className={`text-black ml-1 txt-content-right-chatbox`}
                                      >
                                        {message.hearts}
                                      </p>
                                    </div>
                                  )}
                                </div>
                                {formatTimestamp(
                                  [...messages].reverse()[
                                    index === 0 ? index : index - 1
                                  ].timestamp
                                ).toString() ===
                                formatTimestamp(message.timestamp).toString() ? (
                                  <>
                                    {index === 0 ? (
                                      <>
                                        <span className="text-xs text-gray-500 text-right">
                                          {formatTimestamp(
                                            moment(message?.timestamp, "x")
                                              .tz(moment.tz.guess())
                                              .format("x")
                                          ).toString()}
                                        </span>
                                      </>
                                    ) : (
                                      <div className="p-1" />
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span className="text-xs text-gray-500 text-right">
                                      {formatTimestamp(
                                        moment(message?.timestamp, "x")
                                          .tz(moment.tz.guess())
                                          .format("x")
                                      ).toString()}
                                    </span>
                                  </>
                                )}
                              </div>

                              {user.profilePicUrl !== "" &&
                              user.profilePicUrl !== null ? (
                                <img
                                  className="w-8 h-8 rounded-full object-cover"
                                  src={user.profilePicUrl}
                                  alt="Avatar"
                                />
                              ) : (
                                <Avatar
                                  color="#99CEF6"
                                  round={true}
                                  value={
                                    imGuest ? "G" : refurbishMyName(user.name)
                                  }
                                  size="32"
                                  style={{
                                    position: "relative",
                                  }}
                                />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                      {showTime(message.timestamp, index, message)}
                    </>
                  )
                })}
              </>
            </InfiniteScroll>
          </div>
        </div>

        {!isBlocked && (
          <MessageForm
            isBlocked={isYouBlocked}
            isPrivacyTag={isPrivacyTag}
            isDisabled={isDisable}
            infiniteScrollRef={infiniteScrollRef}
          />
        )}

        {isBlocked && <BlockForm />}
      </div>
    </>
  );
}

export default InboxChatbox;
