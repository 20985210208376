import "../../styles/Interests.scss";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { Url, useAxios } from "../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import FaviNewLogo from "../../assets/icons/FaviNewLogo.svg";
import GuestToastBar from "../../components/GuestComponents/Toast/Toast";
import { UpdateOnlineUser, UpdateMobileNavbarToggleState, UpdateLoadingState, UpdateIsGuestState, UpdateDataUserSignUpState, UpdateErrorLogInState } from "../../redux/actions/index";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useLayoutEffect(() => {

    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return [size, isMobile, location];
}

const FaviSwitch = ({ enabled, onChange, value }) => {
  return (
    <Switch
      checked={enabled}
      onChange={(e) => onChange(value)}
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-all duration-200 ${
        enabled ? "bg-[#99cef6]" : "bg-gray-400"
      }`}
    >
      <span
        className={`${
          enabled ? "translate-x-6 bg-sky-500 border-solid" : "translate-x-1 bg-white"
        } inline-block w-4 h-4 transform  rounded-full transition-transform duration-200`}
      />
      <div
        className={`absolute inset-y-0 ${
          enabled ? "left-1 " : "right-1"
        } flex items-center justify-center`}
      >
        {enabled ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-4 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-4 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15"
            />
          </svg>
        )}
      </div>
    </Switch>
  );
};

const Interest = () => {

  const url = `${Url}/interests/list`;

  let api = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reducerSignup = useSelector((state) => state);
  const userData = useSelector((state) => state.user);

  const [data, setData] = useState();
  const [state, setState] = useState();
  const [error, setError] = useState("");
  const [signUpInfo, setSignUpInfo] = useState(userData);
  const [dialogMessage, setDialogMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState([]);

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);

  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1

  const handleChange = (event) => {
    setError("");

    // let indexInterest = isButtonDisabled.findIndex((item) => item === event);
    // if (indexInterest !== 1) {
    //   isButtonDisabled.splice(indexInterest, 1);
    // } else {
    //   isButtonDisabled.push(event);
    // }

    let stateIndex = state.findIndex((item) => item.name === event);
    let copyOfStateIndex = [...state];
    let isChecked = copyOfStateIndex[stateIndex].checked;
    let amountOfStateIndex = state.filter((item) => item.checked);

    if (!isChecked && amountOfStateIndex.length > 2) {
      setIsDialogboxOpen(true);
      setDialogMessage(`3 interests max`);
      return;
    }

    if (isChecked) {
      copyOfStateIndex[stateIndex] = {
        ...copyOfStateIndex[stateIndex],
        checked: false,
      };
    } else {
      copyOfStateIndex[stateIndex] = {
        ...copyOfStateIndex[stateIndex],
        checked: true,
      };
    }

    let interestSelect = state.filter((item) => item.checked === true);

    setState(copyOfStateIndex);
  };

  const handleEmpty = () => {
    setIsDialogboxOpen(true);
    setDialogMessage("At least 1 interest must be selected");
  };

  const updateInterest = () => {

    dispatch(UpdateMobileNavbarToggleState(false));
    dispatch(UpdateLoadingState(true));

    const payload = {
      ...signUpInfo,
      profilePic: signUpInfo.profilePicUrl,
    }

    api.put(`${Url}/users/${userData.userId}`, payload)
      .then((res) => {

        const user = res?.data?.data;

        dispatch(UpdateIsGuestState(false));
        dispatch(UpdateDataUserSignUpState({
          "GSI1-PK": user["GSI1-PK"],
          bio: user.bio,
          cashOut: user.cashOut,
          contentUnLockUsers: user.contentUnLockUsers,
          countryCode: user.countryCode,
          createdAt: user.createdAt,
          dob: user.dob,
          earningsToDate: user.earningsToDate,
          fbFollowers: user.fbFollowers,
          firebaseUid: user.firebaseUid,
          hearts: user.hearts,
          userId: user.id,
          instaFollowers: user.instaFollowers,
          interests: user.interests,
          name: user.name,
          phoneNumber: user.phoneNumber,
          profilePicUrl: user.profilePic,
          profileViewed: user.profileViewed,
          totalFollowers: user.totalFollowers,
          totalFollowing: user.totalFollowing,
          twitterFollowers: user.twitterFollowers,
          updatedAt: user.updatedAt,
          userName: user.userName,
          youtubeFollowers: user.youtubeFollowers,
        }))

        dispatch(UpdateOnlineUser({
          online: true,
        }));

        dispatch(UpdateErrorLogInState(""));

        navigate("/inbox/start");

      })
      .catch((err) => console.log(err));
  }

  const handleValue = async (jsonData) => {
    let filterInterests = jsonData.filter((x) => x.checked == true);

    let result = filterInterests.map((x) => {
      const logic = data.find((el) => el.title === x.name);

      return {
        id: logic?.id,
        title: x.name,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
      };
    });

    setSignUpInfo({
      ...signUpInfo,
      interests: result,
    });
  };

  useEffect(() => {
    if (state !== undefined) {
      handleValue(state);
    }
  }, [state]);

  useEffect(() => {
    if (userData.interests?.length !== 0) {
      navigate("/inbox/start");
    }
  });

  useEffect(() => {
    setSignUpInfo(userData);
  }, [userData]);

  useEffect(() => {
    //disable back button functionality
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });

    const fetchInterests = async (url) => {
      const data = await api.get(`${url}?limit=100`);
      const response = await data.data;
      const newD = await response?.data?.values;
      setData(newD);
      const interest = newD.map((x) => x.title);
      const lo = interest.map(function (value, key) {
        return {
          name: value,
          checked: false,
        };
      });
      setState(lo);
    };

    fetchInterests(url);
  }, []);

  const handleCloseDialog = () => {
    setIsDialogboxOpen(false);
  };
  const handleResize = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [size, isMobile, location] = useWindowSize();
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col fixed items-center justify-start overflow-hidden fixed top-0 left-0">
      <div className="w-full flex flex-col items-center justify-center px-4">

        <img
          alt="FaviChat logo"
          className="w-24 h-24 transform scale-75"
          src={FaviNewLogo}
        />
        <p className="label-legend-intests">
          Choose three favorite topics to view or get featured in.
        </p>

        <div className="flex flex-col items-center justify-center w-80 lg:w-100 mx-auto overflow-y-scroll scrollbar-thumb-red-500 scrollbar-track-gray-200">
          <div interest list section className={`w-full ${isIpad ? "interests-scroll-tablet" : "interests-scroll"}`}>
            {state &&
              state.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row justify-between border-t border-[#e3f3ff] py-1.5 px-0"
                  >
                    <div>
                      <p className="text-gray-800 text-xl">{item?.name}</p>
                    </div>
                    <div className="items-center justify-center flex pr-1">
                      <FaviSwitch
                        enabled={item.checked}
                        onChange={handleChange}
                        value={item.name}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className={`flex flex-col items-center justify-center p-6 ${isIOS && "standalone" in window.navigator && window.navigator.standalone ? "pb-8" : ""} sm:max-w-sm ${reducerSignup.loading ? "" : "w-full"}`}>
          {reducerSignup.loading ? (
            <div className="loader loading-icon-interests">
              <img src={FavichatLoading} />
            </div>
          ) : (
            <div
              onClick={
                signUpInfo?.interests?.length == 0
                  ? () => handleEmpty()
                  : () => updateInterest()
              }
            >
              <button
                type="submit"
                className={`bg-[#58a1f5ab] rounded-lg px-5 py-2 text-white continue-btn-interests`}
                // disabled={isButtonDisabled.length === 0 ? true : false}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <GuestToastBar
          isOpened={isDialogboxOpen}
          handleClose={handleCloseDialog}
          isOnlyMsg
          message={dialogMessage}
        />

      </div>
    </div>
  );
};

export default Interest;