import React from "react";

function HelpCard({ label, imgUrl }) {
  return (
    <div className="w-full flex items-center justify-between mb-4 py-2 pl-4 sm:pr-24 pr-10">
      <div className="flex items-center sm:w-1/2 w-3/4">
        {imgUrl && <div className="sm:w-14 sm:h-14 w-12 h-12">
          <img src={imgUrl} alt="" className="w-full h-full object-cover" />
        </div>}
        <p className="text-black text-lg break-words sm:ml-4 ml-1">{label}</p>
      </div>
    </div>
  );
}

export default HelpCard;
