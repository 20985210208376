import { useLayoutEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import InfoPopUp from "../../components/PopUp/InfoPopUp";
import UserList from "../../components/DiscoverPage/UserList/UserList";
import { CleanCurrentChatState, UpdateCurrentConversationIdState } from "../../redux/actions/index";

function DiscoverPage(props) {

  const dispatch = useDispatch();

  const isGuest = useSelector((state) => state.isGuest);

  const [guestSignUpToggle, setGuestSignUpToggle] = useState(true);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useLayoutEffect(() => {
    dispatch(CleanCurrentChatState())
    dispatch(UpdateCurrentConversationIdState(null))
  }, []);

  return (
    <div className="h-screen w-screen flex flex-col justify-start overflow-hidden fixed top-0 left-0">
      <Navbar />
      <UserList />
      {guestSignUpToggle && isGuest == true && isMobile ? (
        <InfoPopUp showBtn={false} />
      ) : null}
    </div>
  );
}
export default DiscoverPage;
