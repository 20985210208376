import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import termsPdf from "../../Document/Privacy_Terms.pdf";

export const TermsAndConditions = () => {
  return (
    <>
      <div className="flex items-center justify-center px-4">
        <div className="bg-white rounded-lg overflow-hidden transform transition-all fixed inset-0 overflow-hidden mx-7 my-3">
          <div className="px-4 py-2 flex justify-between items-center title-Modal-TermsPolicy">
            <div className="text-center flex-1">
              <h2 className="text-lg font-medium text-black-800 title-Modal-TermsPolicy">
                Terms & Conditions and Privacy Policy
              </h2>
            </div>
          </div>
          <div className="px-4 py-2 overflow-y-auto w-full h-full">
            <div className="flex-1">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <Viewer fileUrl={termsPdf} />
              </Worker>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
