import "../../styles/LandingPage.scss";
import MainLogo from "../../assets/icons/FaviNewLogo.svg";

export const Logo = () => {
  
  return (
    <div className="flex flex-col items-center justify-center pt-10">
      <img src={MainLogo} alt="Logo" className="h-28 w-32" />
      <p className="textLogo-LandingPage">
        One Place,{" "}
        <span className="textLogoSpan-LandingPage">Your Interests.</span>
      </p>
      <p className="textLogo-LandingPage">Let's Chat!</p>
    </div>
  );
};
