import "../../../styles/Swap.scss";
import { Switch } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FavichatBig from "../../../assets/gif/Favichat1.gif";
import InfiniteScroll from "react-infinite-scroll-component";
import { Url, authApi, useAxios } from "../../../axios/authAPI";
import GuestToastBar from "../../../components/GuestComponents/Toast/Toast";
import FavichatLoader from "../../../components/SkeletonLoader/FavichatLoader";
import React, { useEffect, useState, useCallback, useLayoutEffect } from "react";
import { UpdateSettingSidebarToggleState, UpdateUserDataInterestState, ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser } from "../../../redux/actions/index";

const FaviSwitch = ({ enabled, onChange, value }) => {
  return (
    <Switch
      checked={enabled}
      onChange={(e) => onChange(value)}
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-all duration-200 ${
        enabled ? "bg-[#99cef6]" : "bg-gray-400"
      }`}
    >
      <span
        className={`${
          enabled ? "translate-x-6 bg-sky-500" : "translate-x-1 bg-white"
        } inline-block w-4 h-4 transform  rounded-full transition-transform duration-200`}
      />
      <div
        className={`absolute inset-y-0 ${
          enabled ? "left-1 " : "right-1"
        } flex items-center justify-center`}
      >
        {enabled ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-4 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-4 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15"
            />
          </svg>
        )}
      </div>
    </Switch>
  );
};

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);

  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useLayoutEffect(() => {

    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return [size, isMobile, location];
}

const InterestsSetting = () => {

  let api = useAxios();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [size, isMobile, location] = useWindowSize();

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);

  const [data, setData] = useState();
  const [state, setState] = useState([]);
  const [aniLoader, setAniLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [showErrMessage, setShowErrMessage] = useState("");
  const [loggedInUser, setLoggedInUser] = useState(userData);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [changeSwitch, setChangeSwitch] = useState(false);

  const url = `${Url}/interests/list`;

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    const filterData = loggedInUser?.interests?.map((x) => x.title);

    const fetchInterests = async (url) => {
      try {
        setIsLoading(true);

        const data = await api.get(`${url}?limit=100`);

        const response = data.data;
        const newD = await response?.data?.values;
        setData(newD);
        const interest = newD.map((x) => x.title);
        const lo = interest.map(function (value, key) {
          return {
            name: value,
            checked: filterData?.includes(value) ? true : false,
          };
        });
        setIsLoading(false);
        setState(lo);
      } catch (err) {
        setShowErrMessage("Server network issues, Please try again later.");
        setIsLoading(false);
      }
    };

    fetchInterests(url);
  }, []);

  const handleChange = (event) => {
    let stateIndex = state.findIndex((item) => item.name === event);
    let copyOfStateIndex = [...state];
    let isChecked = copyOfStateIndex[stateIndex].checked;
    let amountOfStateIndex = state.filter((item) => item.checked);

    if (!isChecked && amountOfStateIndex.length > 2) {
      setIsDialogboxOpen(true);
      setDialogMessage(`3 interests max`);
      return;
    }
    if (isChecked) {
      copyOfStateIndex[stateIndex] = {
        ...copyOfStateIndex[stateIndex],
        checked: false,
      };
    } else {
      copyOfStateIndex[stateIndex] = {
        ...copyOfStateIndex[stateIndex],
        checked: true,
      };
    }
    setChangeSwitch(true);
    setState(copyOfStateIndex);
  };

  const handleButtonClick = () => {
    if (changeSwitch) {
      onSubmitData();
    }
  };

  const handleValue = async (jsonData) => {
    let filterInterests = jsonData.filter((x) => x.checked === true);
    let result = filterInterests.map((x) => {
      const logic = data.find((el) => el.title === x.name);
      return {
        id: logic?.id,
        title: x.name,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
      };
    });

    setLoggedInUser((prevState) => {
      return {
        ...prevState,
        interests: result,
      };
    });
  };

  useEffect(() => {
    if (state !== undefined) {
      handleValue(state);
    }
  }, [state]);

  const onSubmitData = useCallback(() => {
    if(loggedInUser?.interests?.length === 0) {
      return;
    }
    setAniLoader(true);
    api
      .put(`${Url}/users/${loggedInUser.userId}`, {...loggedInUser,
      profilePic: loggedInUser?.profilePicUrl,})
      .then((res) => {
        dispatch(UpdateUserDataInterestState(loggedInUser.interests));
        setIsDialogboxOpen(true);
        setDialogMessage("Updated");
        setAniLoader(false);
      })
      .catch((err) => {
        setAniLoader(false);
        setDialogMessage(err?.response?.data?.error?.message);
      });
      setChangeSwitch(false);
  }, [loggedInUser]);

  const handleCloseDialog = () => {
    setAniLoader(false);
    setIsDialogboxOpen(false);
  };

  useEffect(() => {
    if (loggedInUser?.interests?.length === 0) {
      setIsDialogboxOpen(true);
      setDialogMessage("At least 1 interest must be selected");
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
      setIsDialogboxOpen(false);
    }
  }, [loggedInUser?.interests?.length]);

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const dynamicStyles = {
    height: `${screenHeight - 72}px`,
  };

  return (
    <>
      {!(isGuest === true) ? (
        <div className={isMobile ? "" : "interestSetting"} style={dynamicStyles}>
          {isLoading ? (
            <FavichatLoader />
          ) : (
            <>
              <div
                className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
                onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
              >
                <div className="sm:hidden pt-[8px]">
                  <button type="submit" className="mr-[8px]">
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      ></path>
                    </svg>
                  </button>
                </div>
                <p className="text-[#494949] font-boboto font-bold title-profile">
                  Interests
                </p>
              </div>

              <div className="pb-4">
                <p className="text-start text-[#494949] font-normal px-8 p-subtitle-hearts">
                  Choose three favorite topics to view or get featured in.
                </p>
              </div>

              <div className="container mx-auto flex flex-col items-center justify-center" id="scrollableDiv">
                <InfiniteScroll
                  dataLength={state.length}
                  height={isMobile ? screenHeight - 260 : screenHeight - 230}
                  scrollThreshold={0.2}
                  scrollableTarget="scrollableDiv"
                  style={{ width:  isMobile ? (screenWidth - 49) : '23rem'}}
                >
                  {showErrMessage !== "" || state === undefined ? (
                    <p>{showErrMessage}</p>
                  ) : (
                    state.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-row justify-between border-t border-[#e3f3ff] py-1.5 px-0"
                      >
                        <div>
                          <p className="text-gray-800 text-xl">{item?.name}</p>
                        </div>
                        <div className="items-center justify-center flex pr-1">
                          <FaviSwitch
                            enabled={item.checked}
                            onChange={handleChange}
                            value={item.name}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </InfiniteScroll>
              </div>

              <div className="m-auto md:w-[80%] w-[100%] p-6">
                {!aniLoader ? (
                  <div className="flex items-center justify-center">
                  <button
                    onClick={handleButtonClick}
                    type="submit"
                    className={`${isButtonDisabled ? "bg-[#58a1f5ab]" : "bg-[#58a1f5]"} border border-[#58a1f5] text-btn-save-profile rounded-full text-white font-bold cursor-pointer px-[3rem] py-[.18rem] transition duration-300 ease-in-out hover:bg-white hover:text-[#58a1f5]`}
                    disabled={!changeSwitch || isButtonDisabled}
                  >
                    Save
                  </button>
                </div>
                ) : (
                  <div className="flex items-center justify-center ">
                    <img
                      src={FavichatBig}
                      alt=""
                      className="save_ripple_loader"
                    />
                  </div>
                )}
              </div>

              <GuestToastBar
                isOpened={isDialogboxOpen}
                handleClose={handleCloseDialog}
                isOnlyMsg
                message={dialogMessage}
              />
            </>
          )}
        </div>
      ) : (
        <div className="profile">
          <div className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px] px-6">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InterestsSetting;
