import Avatar from "react-avatar";
import "../../styles/UserSearch.scss";
import debounce from "lodash.debounce";
import { Url } from "../../axios/authAPI";
import { useAxios } from "../../axios/authAPI";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UpdateDiscoveryModalProfileState } from "../../redux/actions/index";

const UserSearch = ({ inputRefSearch }) => {

  let api = useAxios();
  const dispatch = useDispatch();

  const accessToken = localStorage.getItem("accessToken");

  const userData = useSelector((state) => state.user);

  const [searchData, setSearchData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearcedhUser, setIsSearcedhUser] = useState(false);

  const onHandleSearch = useCallback(
    debounce(async (searchQuery) => {
      const queryParams = {
        query: {
          query: `select * from user-index where (isDisable = false OR isDisable IS NULL) AND upper(userName) like upper('${searchQuery}%') OR upper(name) like upper('${searchQuery}%') OR upper(substring(name, locate(' ', name) + 1)) like upper('${searchQuery}%') limit 10 offset 0`,
        },
      };

      const searchUserData = await api.post(
        `${Url}/users/search/sql?loggedInUserId=${userData.userId}`,
        queryParams
      );

      const arrSchema = searchUserData?.data.data.schema;
      function getIndex(name) {
        return arrSchema.findIndex((obj) => obj.name === name);
      }
      const users = searchUserData?.data.data.datarows.map((user) => ({
        userName:
          user[getIndex("name")].length > 13
            ? user[getIndex("name")].slice(0, 13) + "..."
            : user[getIndex("name")],
        profilePic: user[getIndex("profilePic")],
        id: user[getIndex("PK")].replace("FC-USER#", ""),
      }));
      setSearchData(users);
    }, 500),
    [accessToken]
  );

  const onSearch = (event) => {
    const {
      target: { value },
    } = event;
    setSearchQuery(value);
    onHandleSearch(value);
  };

  const sideBarDetail = (data) => {
    data.show = true;
    data.apiCall = true;
    setIsSearcedhUser(false);
    setSearchQuery("");
    dispatch(UpdateDiscoveryModalProfileState(data));
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const DummyImageGenerator = ({ data, style }) => {
    return (
      <div
        className="dummy-image-generator-search-discovery"
        style={{
          background: getRandomColor(),
          ...style,
        }}
      >
        <p className="dummy-image-name-search-discovery">
          {data?.name
            .split(" ")
            .map((d) => d[0])
            .join(" ")}
        </p>
      </div>
    );
  };

  return (
    <div className="relative flex items-center">
      <svg onClick={(e) => { setSearchQuery(""); setIsSearcedhUser(false);}}xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute left-0 w-4 h-4 ml-3 cursor-pointer">
        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
      <input
        type="text"
        placeholder="Search"
        value={searchQuery}
        onChange={onSearch}
        disabled={isSearcedhUser}
        ref={inputRefSearch}
        className="w-full pl-10 pr-10 py-2 input-search-discovery"

      />
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="absolute right-0 w-4 h-4 mr-3">
        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>

      {searchData?.length && searchQuery !== "" && !isSearcedhUser ? (
        <div className="absolute z-10 mt-2 w-full bg-white rounded-lg shadow-lg overflow-y-auto top-full drop-down-users-search-discovery">
          {searchData?.map((data, i) => (
            <div key={i} className="flex items-center p-2 hover:bg-gray-100 drop-down-options-users-search-discovery" onClick={() => sideBarDetail(data)}>
              {data.profilePic ? (
                <Avatar
                  alt={data.userName || data.profilePic}
                  src={data.profilePic}
                  round={true}
                  size="25"
                />
              ) : (
                <DummyImageGenerator
                  data={data._source}
                  style={{ borderRadius: "100%" }}
                />
              )}
              <span>{data.userName || data.profilePic}</span>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default UserSearch;