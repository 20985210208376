import Avatar from "react-avatar";
import { Url } from "../../axios/authAPI";
import InfoPopUp from "../PopUp/InfoPopUp";
import { useAxios } from "../../axios/authAPI";
import React, { useEffect, useState } from "react";
import MsgIcon from "../../assets/icons/msgIcon.png";
import { useNavigate, Link } from "react-router-dom";
import Favichat from "../../assets/gif/Favichat1.gif";
import { useDispatch, useSelector } from "react-redux";
import guestImage from "../../assets/icons/guestImage.png";
import check from "../../assets/icons/Check_icon.svg";
import HeartWhite from "../../assets/icons/heat-white1.png";
import FilledHeart from "../../assets/icons/Filled_Heart.svg";
import GuestToastBar from "../../components/GuestComponents/Toast/Toast";
import { UpdateMobileNavbarToggleState, UpdateSettingSidebarToggleState, UpdateInboxBarToggleState, UpdateDataUnFollowingPage, UpdateDataFollowingPage, CleanCurrentChatState, GetCurrentChatUserData, stopUnsubscribeMessages, UpdateCurrentConversationIdState, UpdateOnlineUser, UpdateIsGuestState, ResetNotUserState } from "../../redux/actions/index";

const UserModalFollowing = ({ info, close, followerLike }) => {

  let api = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isGuest = useSelector((state) => state.isGuest);
  const userData = useSelector((state) => state.user);
  const userId = useSelector((state) => state.user.userId);
  const username = useSelector((state) => state.user.username);
  const conversationsUnsorted = useSelector((state) => state.allConversations);

  const [showData, setData] = useState();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(true);
  const [totalFollowers, setTotalFollowers] = useState();
  const [insideLoader, setInsideLoader] = useState(false);
  const [heartLike, setHeartLike] = useState(followerLike);
  const [shareLinkLoader, setShareLinkLoader] = useState(false);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [betterExpPopupModal, setBetterExpPopupModal] = useState(false);
  const [messageError, setMessageError] = useState(); 
  const copied = `${window.location.origin}/go/${userData?.userName}`;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    const fetchData = async (userID) => {
      try {
        const response = await api.get(`${Url}/users/${info?.id}?loggedInUserId=${userData.userId}`);
        const responseData = response.data;
        setData(responseData?.data);
        setTotalFollowers(responseData?.data?.totalFollowers);
        setLoader(false);
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setMessageError(true);
          }
        } finally {
          setLoader(false);
        }
      };
      fetchData(info?.id);
      if (info?.apiCall === true) {
        callApiFollowers();
      }
    }, []);

  if (shareLinkLoader === true) {
    setTimeout(() => {
      setShareLinkLoader(false);
    }, 5000);
  }

  const refurbishName = (string) => {
    var name;
    const formatedName = string
      ?.split(" ")
      .filter((element) => element !== null && element !== "");
    // console.log(formatedName)
    if (formatedName?.length > 2) {
      //name = `${string?.split(" ")[0][0]}${string?.split(" ")[2][0] == " " ? string?.split(" ")[1][0]: string?.split(" ")[2][0]}`;
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else if (formatedName?.length > 1) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else {
      name = string?.slice(0, 1);
    }
    if(name){
      return name.toUpperCase();
    } else{
      return "";
    }
  }

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const callApiFollowers = async () => {
    const loggedInUser = JSON.parse(localStorage.getItem("userData"));

    const data = await api.get(
      `${Url}/follows/${loggedInUser?.id}/user?follower=${info?.id}`
    );

    const response = data.data;
    response?.data?.loggedInUserStatus
      ? setHeartLike(true)
      : setHeartLike(false);
  };

  const followFunctionality = async (id) => {
    setInsideLoader(true);
    heartLike ? unfollowApi(id) : followApi(id);
  };

  const followApi = async (id) => {
    api
      .post(`${Url}/follows/${userData.userId}/add`, { uid: id })
      .then((response) => response.data)
      .then((result) => {
        if (result?.status) {
          setHeartLike(true);
          setTotalFollowers((prev) => prev + 1);
          setInsideLoader(false);

          dispatch(UpdateDataFollowingPage(showData?.id));
        } else if (!result?.success && result?.error?.id === 403) {
          setInsideLoader(false);
          setMessage(result?.error?.message);
          setIsDialogboxOpen(true);
        } else {
          setInsideLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const unfollowApi = async (id) => {
    api
      .put(`${Url}/follows/${userData.userId}/remove`, { uid: id })
      .then((response) => {
        setHeartLike(false);
        setTotalFollowers((prev) => prev - 1);
        setInsideLoader(false);
        dispatch(UpdateDataUnFollowingPage(showData?.id));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  }

  const getNewData = async () => {
    const currentChat = conversationsUnsorted.filter((index) => index.participants[0] === showData?.id);
    dispatch(CleanCurrentChatState());
    dispatch(UpdateCurrentConversationIdState(currentChat.length > 0 ? currentChat[0]?.conversationId : null));
    dispatch(stopUnsubscribeMessages());
    dispatch(GetCurrentChatUserData(showData?.id));
    navigate(`/inbox/${showData?.id}`);
    dispatch(UpdateInboxBarToggleState(false));
    close();
  };

  const handleCloseDialog = () => {
    setIsDialogboxOpen(false);
  };

  return (
    <>
      <div className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
          <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-lg usermodal-desk-nav">
            <div className="p-6">
            {messageError === true ? (
                <p class="text-center font-bold">Favichat user not found</p>
              ) : (
                <>

              {loader === true ? (
                <div className="relative flex flex-col justify-center items-center content-desk-nav">
                  <div className="animate-pulse bg-gray-200 h-32 w-32 rounded-full"></div>
                  <div className="animate-pulse bg-gray-200 w-64 h-8 mt-4"></div>
                  <div className="animate-pulse bg-gray-200 w-32 h-8 mt-4"></div>
                  <div className="animate-pulse bg-gray-200 w-64 h-16 mt-4"></div>
                </div>
              ) : (
                <>
                  <div className="relative flex justify-center items-center content-desk-nav">
                    {isGuest === true && userData.userId === info?.id
                      ?
                        <img className="object-cover rounded-full img-avatar-guest-nav" src={guestImage} alt="" />
                      :
                        <>
                          { showData?.profilePic === undefined || showData.profilePic.length === 0 || showData?.profilePic === ""
                            ?
                              <>
                                <Avatar
                                  color="#99CEF6"
                                  // name={refurbishName(showData?.name)}
                                  round={true}
                                  value={refurbishName(showData?.name)}
                                  size="150"
                                  style={{
                                    boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.2)",
                                    position: "relative"
                                  }}
                                />
                                {
                                  userData.userId === info?.id
                                    ? ""
                                    :
                                      <>
                                        {!insideLoader && (
                                          <div className="absolute flex items-center checkbox-heart-modal-nav2">
                                            <span
                                              onClick={() => followFunctionality(showData?.id)}
                                              className="inline-flex items-center justify-center rounded-full cursor-pointer ransition-colors duration-150 img-heart-modal-nav"
                                            >
                                              {heartLike ? (
                                                <img src={FilledHeart} alt="" className="img-heart-modal-nav"/>
                                              ) : (
                                                <img src={HeartWhite} alt="" className="img-heart-modal-nav"/>
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                }
                              </>
                            :
                              <>
                                <Avatar
                                  src={showData.profilePic}
                                  round={true}
                                  size="150"
                                  style={{
                                    boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.2)",
                                    position: "relative"
                                  }}
                                />
                                {
                                  userData.userId === info?.id
                                    ? ""
                                    :
                                      <>
                                        {!insideLoader && (
                                          <div className="absolute flex items-center checkbox-heart-modal-nav2">
                                            <span
                                              onClick={() => followFunctionality(showData?.id)}
                                              className="inline-flex items-center justify-center rounded-full cursor-pointer ransition-colors duration-150 img-heart-modal-nav"
                                            >
                                              {heartLike ? (
                                                <img src={FilledHeart} alt="" className="img-heart-modal-nav"/>
                                              ) : (
                                                <img src={HeartWhite} alt="" className="img-heart-modal-nav"/>
                                              )}
                                            </span>
                                          </div>
                                        )}
                                      </>
                                }
                              </>
                          }
                        </>
                    }
                  </div>

                  <div className="flex flex-col justify-center items-center content-desk-nav">
                    <p className="p-name-modal-nav">{showData?.name}</p>
                    <p className="p-username-modal-nav">@{showData?.userName}</p>

                    {isGuest === true && userData.userId === info?.id
                      ? ""
                      :
                        <>
                          {userData.userId === showData?.id
                            ?
                              <div className="flex justify-center items-center content-info-data-modal-nav">

                                <div className="flex flex-col justify-center items-center content-followers-modal-nav">
                                  <p className="content-followers-p-modal-nav">{nFormatter(totalFollowers, 1)}</p>
                                  <span className="content-followers-span-modal-nav">
                                    {totalFollowers === 1 ? ` Follower` : ` Followers`}
                                  </span>
                                </div>

                                <div className="flex flex-col justify-center items-center content-followers-modal-nav">
                                  <p className="content-followers-p-modal-nav">{showData?.hearts}</p>
                                  <span className="content-followers-span-modal-nav">Hearts</span>
                                </div>

                                <div className="flex flex-col justify-center items-center content-followers-modal-nav">
                                  <p className="content-followers-p-modal-nav">{showData?.totalFollowing}</p>
                                  <span className="content-followers-span-modal-nav">Following</span>
                                </div>

                              </div>
                            :
                              <div className="flex flex-col justify-center items-center content-followers-user-modal-nav">
                                <p className="content-followers-p-user-modal-nav">{nFormatter(totalFollowers, 1)}</p>
                                <span className="content-followers-span-user-modal-nav">
                                  {totalFollowers === 1 ? ` Follower` : ` Followers`}
                                </span>
                              </div>
                          }
                        </>
                    }

                    <p className="content-bio-data-modal-nav">
                      {(showData?.bio == "" || showData?.bio == null)? (
                        <p className="content-bio-empty-modal-nav">No bio</p>
                      ) : (
                        showData?.bio
                      )}
                    </p>

                    <div className="content-div-loading-modal-nav">
                      {insideLoader ? (
                        <img
                          className="content-img-loading-modal-nav"
                          src={Favichat}
                          alt="...loading"
                        />
                      ) : (
                        showData?.id !== userData.userId && (
                          <img
                            className="content-img-loading-modal-nav cursor-pointer hover:scale-110 transition-transform duration-300"
                            src={MsgIcon}
                            onClick={getNewData}
                            alt="Favi Msg Icon"
                          />
                        )
                      )}
                    </div>

                    {userData.userId === showData?.id && !showData?.userName.includes("guest")
                      ?
                        <div className="flex justify-center items-center content-div-loading-modal-nav">
                          <button
                            onClick={() => {
                              navigator.clipboard.writeText(copied)
                            }}
                          >
                            <div className="flex justify-center items-center">
                              <p onClick={() => {setShareLinkLoader(true);}} className="content-p-copy-modal-nav">Share inbox link</p>
                              {shareLinkLoader && (
                               <img src={check} alt="check link" className="w-6 h-6 content-icon-copy-modal-nav" />
                              )}
                            </div>
                          </button>
                        </div>
                      : null
                    }

                    {isGuest == true && userData.userId == info?.id
                      ?
                        <div className="content-div-account-modal-nav">
                          <Link to="/">
                            <p onClick={() => {
                              dispatch(
                                UpdateOnlineUser({
                                  online: false,
                                })
                              ).then(() => {
                                guestUserLogout();
                              });
                            }}
                            className="content-p-account-modal-nav">
                              Create an account or Sign in
                            </p>
                          </Link>
                        </div>
                      : ""
                    }
                  </div>
                </>
              )}
              </>
              )}

              {userData.userId == info?.id && (
                <Link
                  to="/settings/profile"
                  className="absolute top-2 right-2 m-4 md:hidden"
                  onClick={() => { dispatch(UpdateSettingSidebarToggleState(true)); close(); dispatch(UpdateMobileNavbarToggleState(false));}}
                >
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-8 w-8 text-gray-700">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
                    </svg>
                  </div>
                </Link>
              )}

              <button className="absolute top-2 left-2 m-4" onClick={() => close()}>
                <svg className="h-7 w-7 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>

            </div>
          </div>
        </div>
      </div>

      {betterExpPopupModal && isGuest === true && isMobile ? (
        <InfoPopUp showBtn={false} setBetterExpPopupModal={setBetterExpPopupModal}/>
      ) : null}

      <GuestToastBar
        isOpened={isDialogboxOpen}
        handleClose={handleCloseDialog}
        isOnlyMsg
        message={message}
      />

    </>
  );
};
export default UserModalFollowing;
