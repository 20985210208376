import Avatar from "react-avatar";
import "../../styles/InterestUserPage.scss";
import HeartWhite from "../../assets/icons/heat-white1.png";
import FilledHeart from "../../assets/icons/Filled_Heart.svg";
import { useSelector } from "react-redux";

const UserProfile = ({ list, handleInfo }) => {

  function stringAvatar(name) {
    const formatedName = name?.split(" ");
    return (
        formatedName?.length > 2
          ? `${formatedName[0]} ${formatedName[2]}`
          : formatedName?.length > 1
          ? `${formatedName[0]} ${formatedName[1]}`
          : name
    );
  }

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }
  const userData = useSelector((state) => state.user);


  const renderHearts = (list) => {
    if (userData.userId === list?.id) {
      return null
    }
    if (list?.is_following) {
      return (<div className="absolute flex items-center checkbox-heart-following-discovery">
      <span className="inline-flex items-center justify-center rounded-full cursor-pointer ransition-colors duration-150">
        <img src={FilledHeart} alt="" className="img-heart-discovery"/>
      </span>
    </div>)
    } else {
      return (
        <div className="absolute flex items-center checkbox-heart-following-discovery">
        <span className="inline-flex items-center justify-center rounded-full cursor-pointer ransition-colors duration-150">
          <img src={HeartWhite} alt="" className="img-heart-discovery"/>
        </span>
      </div>
      )
    }
  }

  return (
    <li
      key={list.id + Date.now()}
      className="flex flex-col justify-center items-center user-followers-container-data"
      onClick={() => handleInfo(list)}
    >
      {list?.profilePic == "" ? (
        <div className="user-followers-img-box">
          <Avatar
            color="#99CEF6"
            round={true}
            className="users-box-img"
            name={stringAvatar(list?.name)}
            value={list?.name}
            style={{
              boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.2)",
              position: "relative"
            }}
          />
        </div>
      ) : (
        <div className="user-followers-img-box">
          <Avatar
            color="#99CEF6"
            round={true}
            className="users-box-img"
            src={list?.profilePic}
            style={{
              boxShadow: "0px 0px 4px 4px rgba(0, 0, 0, 0.2)",
              position: "relative",
              objectFit: "cover"
            }}
          />
        </div>
      )}

      {renderHearts(list)}

      <p className="pt-2 name-user-discovery">
        {list?.userName
          ? list?.userName.length > 13
            ? list?.userName.substring(0, 9).concat("...")
            : list?.userName
          : "no name"}
      </p>

      <p className="followers-user-discovery">
        {nFormatter(list?.totalFollowers, 1)}{" "}
        <span>
          {list?.totalFollowers === 1 ? ` follower` : ` followers`}
        </span>
      </p>

    </li>
  );
};

export default UserProfile;
