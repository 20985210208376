import { Url } from "../../axios/authAPI";
import "../../styles/InterestUserPage.scss";
import { useAxios } from "../../axios/authAPI";
import FollowingProfile from "./FollowingProfile";
import UserSearch from "../UserSearch/UserSearch";
import Favichat from "../../assets/gif/Favichat.gif";
import { useSelector, useDispatch } from "react-redux";
import SendAllModal from "../SendAllModal/SendAllModal";
import UserModalFollowing from "../UserDetailsModal/UserModalFollowing";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { UpdateDataFollowingState } from "../../redux/actions/index";

const FollowingDataPage = () => {
  let api = useAxios();
  const dispatch = useDispatch();
  const inputRefSearch = useRef(null);

  const [pk, setPK] = useState("");
  const [lastRec, setLastRec] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [stopScrollFn, setStopScrollFn] = useState(true);
  const [followingData, setFollowingData] = useState([]);
  const [creatorDetails, setCreatorDetails] = useState();
  const [followerLike, setFollowerLike] = useState(true);
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [sendAllModalToggle, setSendAllModalToogle] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const userData = useSelector((state) => state.user);
  const followingUserData = useSelector((state) => state.dataFollowing);

  const getUsersByInterest = useCallback(async (pk) => {
    try {
      if (pk !== "NO MORE ITEMS") {
        const response = await api.get(
          `${Url}/users/${userData.userId}/followers?limit=100&lastRec=${pk}`
        );
        const responseData = response?.data?.data;
        if (responseData.lastRec !== "NO MORE ITEMS") {
          setFollowingData(prev => [...prev, ...responseData.values]);
          setPK(responseData.lastRec);
        } else {
          setStopScrollFn(false);
        }
        dispatch(
          UpdateDataFollowingState(
            [...followingUserData, ...responseData.values].map(x => ({ ...x, is_following: true }))
          )
        );
      } else {
        setStopScrollFn(false);
      }
    } catch (err) {
      console.log(err);
      setStopScrollFn(false);
    } finally {
      setUserDataLoading(false);
      setLoading(false);
    }
  }, [followingUserData, pk, userData.userId]);

  useEffect(() => {
    const handleUserDataNew = async () => {
      dispatch(UpdateDataFollowingState([]));
      setFollowingData([]);
      setLoading(true);
      const response = await api.get(
        `${Url}/users/${userData.userId}/followers?limit=100`
      );

      const responseData = await response?.data?.data;

      setFollowingData(responseData?.values);

      dispatch(
        UpdateDataFollowingState(
          responseData?.values.map((x) => ({ ...x, is_following: true }))
        )
      );
      setPK(responseData.lastRec);
      setLoading(false);
      setUserDataLoading(false);
    };

    handleUserDataNew();
  }, []);

  const handleScrollCheck = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (!userDataLoading && scrollHeight - scrollTop <= clientHeight + 100) {
      setLoading(true);
      getUsersByInterest(pk);
    }
  };

  const handleInfo = (id, follow) => {
    setShowModal(true);
    setCreatorDetails(id);
    setFollowerLike(follow);
  };

  const handleCloseProfile = (bool) => {
    setShowModal(false);
    setCreatorDetails("");
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dynamicStyles = {
    height: `${screenHeight - 48}px`,
  };

  return (
    <div
      style={dynamicStyles}
      className={`h-screen w-screen flex flex-col justify-start top-0 left-0 relative ${
        sendAllModalToggle ? "content-discovery active" : "content-discovery"
      }`}
      onScroll={stopScrollFn ? handleScrollCheck : undefined}
    >
      <div
        className="flex justify-between w-full h-20 pt-2 md:pt-4"
        id="topScroll"
      >
        <div className="flex items-center div-title-header-discovery">
          <h1 className="title-header-following-discovery">Following</h1>
        </div>
        <div className="flex justify-start items-center div-search-header-discovery">
          <UserSearch inputRefSearch={inputRefSearch} />
        </div>
      </div>

      {userDataLoading ? (
        <>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ul className="user-followers-container-following">
          {followingUserData?.map((user, index) => {
            return (
              <FollowingProfile key={index} list={user} handleInfo={handleInfo} />
            );
          })}
      </ul>
      )}

      {loading ? (
        <div className="w-full flex flex-row justify-center user-list-loading-container">
          <img src={Favichat} alt="" className="users-ripple-loader" />
          <p>Loading...</p>
        </div>
      ) : null}

      {showModal && (
        <UserModalFollowing
          info={creatorDetails}
          close={handleCloseProfile}
          followerLike={followerLike}
        />
      )}

      {sendAllModalToggle && (
        <SendAllModal setSendAllModalToogle={setSendAllModalToogle} />
      )}
    </div>
  );
};

export default FollowingDataPage;
