import * as Yup from "yup";
import "../../styles/Signup.scss";
import "../../styles/InputForm.scss";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import Favichat from "../../assets/gif/Favichat.gif";
import userIcon from "../../assets/icons/useIcon.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import MainLogo from "../../assets/icons/FaviNewLogo.svg";
import { db, storage } from "../../Config/configurations";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { UpdateErrorLogInState, SignUpData } from "../../redux/actions/index";

export const Signup = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useVisitorData();
  const [searchParams] = useSearchParams();

  const signupInfo = useSelector((state) => state);

  const [picture, setPicture] = useState("");
  const [progress, setProgress] = useState(0);
  const [interest, setInterest] = useState([]);
  const [muiLoad, setMuiLoad] = useState(false);
  const [boolSave, setBoolSave] = useState(true);
  const [profilePic, setProfilePic] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [userIcons, setuserIcons] = useState(userIcon);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [dataSignup, setData] = useState({ name: "", userName: "", dob: "", phoneNumber: "", password: "", profilePic: "", interests: [], firebaseUid: "", countryCode: "", bio: "", visitorId: ""});

  const dynamicStyles = {
    height: `${screenHeight}px`,
  };

  const today = new Date();
  let yyyy = today.getFullYear();
  yyyy = parseInt(yyyy) - 13;

  let mm = today.getMonth() + 1;
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = yyyy + "/" + mm + "/" + dd;

  let password;

  const formSchema = Yup.object().shape({
    firstname: Yup.string()
      .required("What's your name?")
      .min(
        2,
        "First name should be minimum 2 characters and maximum 30 characters"
      )
      .max(
        30,
        "First name should be minimum 2 characters and maximum 30 characters"
      )
      .matches("^[A-Za-z\\s]+$", "Use alphabetical characters"),
    lastname: Yup.string()
      .required("Last name should not be blank")
      .min(
        2,
        "Last name should be minimum 2 characters and maximum 30 characters"
      )
      .max(
        30,
        "Last name should be minimum 2 characters and maximum 30 characters"
      )
      .matches("^[A-Za-z\\s]+$", "Use alphabetical characters"),
    userName: Yup.string()
      .required("What's your username?")
      .min(
        2,
        "Username should be minimum 2 characters and maximum 30 characters"
      )
      .max(
        30,
        "Username should be minimum 2 characters and maximum 30 characters"
      )
      .matches(
        "^[a-zA-Z0-9_.]{2,}$",
        "Username should not contain any special characters other than '.' and '_'"
      ),
    password: Yup.string()
      .required("Password should not be blank")
      .min(
        8,
        "Password must have at least 8 characters \nMinimum 1 uppercase \nMinimum 1 lowercase \nMinimum 1 number\nMinimum 1 special character."
      )
      .max(
        23,
        "Password must have at least 8 characters \nMinimum 1 uppercase \nMinimum 1 lowercase \nMinimum 1 number\nMinimum 1 special character."
      )
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        "Password must have at least 8 characters \nMinimum 1 uppercase \nMinimum 1 lowercase \nMinimum 1 number\nMinimum 1 special character."
      ),
    password_repeat: Yup.string()
      .required("Confirm Password should not be blank")
      .min(8, "Password and Confirm Password must be the same")
      .max(23, "Password and Confirm Password must be the same")
      .oneOf([Yup.ref("password")],"Password and Confirm password must be the same."
    ),
    dob: Yup.date()
      .nullable()
      .transform((value, originalValue) => {
        const date = new Date(originalValue);
        const year = date.getFullYear();

        if (!isNaN(year) && year < 100) {
          return new Date("1800/01/01");
        }
        return value;
      })
      .min(new Date("1900/01/01"), "Date of birth should be later than 01/01/1900.")
      .max(new Date(formattedToday), "You must be 13+ to create an account")
      .typeError("Select Date Of Birth")
      .required("Select Date Of Birth"),
    tac: Yup.boolean().oneOf([true], "Please accept terms and conditions"),
  });

  const { register, handleSubmit, formState: { errors }, watch, reset, setValue,} = useForm({mode: "onTouched",reValidateMode: "onBlur",resolver: yupResolver(formSchema)});

  password = watch("password", "");
  
  const changeStatusUserName = (e) => {
    if (signupInfo.error === "Username already exists") {
      dispatch(UpdateErrorLogInState(""));
    }
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  const uploadFiles = (file) => {
    if (!file) return;
    const sotrageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProfilePic(downloadURL);
          setMuiLoad(false);
        });
      }
    );
  };
  
  const onChangePicture = (e) => {

    setMuiLoad(true);

    const reader = new FileReader();

    if (e.target.files[0]) {

      const file = e.target.files[0];
      const slice = file.slice(0, 4);
      reader.readAsArrayBuffer(slice);

      reader.onloadend = function () {
        const buffer = reader.result;

        if (!(buffer instanceof ArrayBuffer)) {
          console.error("Buffer is not an ArrayBuffer:", buffer);
          return;
        }

        const view = new DataView(buffer);
        let signature = "";

        for (let i = 0; i < 4; i++) {
          signature += String.fromCharCode(view.getUint8(i));
        }

        if ( signature === "\x89PNG" || signature === "\xFF\xD8\xFF\xE0" || signature === "\xFF\xD8\xFF\xE1") {

          setPicture(e.target.files[0]);
          uploadFiles(file);

          reader.addEventListener("load", () => {
            setuserIcons(reader.result);
          });
          reader.readAsDataURL(e.target.files[0]);
          setErrorMessage("");
        } else {
          setErrorMessage("Oops! Image only 😁");
        }
      };
    }
  };

  function formatDate(date) {
    let d = new Date(date), month = "" + (d.getMonth() + 1), day = "" + d.getDate(), year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  const updateData = (jsonData) => {

    let payload = {};
    const guestUserValue = searchParams.get("isGuest") === "true" ? true : false;

    if(guestUserValue === true) localStorage.setItem("guestUserName", signupInfo.user.userName);

    props.isGuest
      ? payload = {
        ...dataSignup,
        name: `${jsonData.firstname.replace(/^\s+|\s+$/g, "")} ${jsonData.lastname.replace(/^\s+|\s+$/g,"")}`,
        userName: `${jsonData.userName.replace(/\s+/g, "")}`,
        dob: formatDate(`${jsonData.dob}`),
        phoneNumber: props.number,
        password: `${jsonData.password}`,
        profilePic: profilePic.length == 0 ? undefined : profilePic,
        interests: interest,
        firebaseUid: props.firebaseUid,
        countryCode: props.countryCode,
        bio: "",
        visitorId: data.visitorId,
        isGuest: true,
      }
      : payload = {
        ...dataSignup,
        name: `${jsonData.firstname} ${jsonData.lastname}`,
        userName: `${jsonData.userName.replace(/\s+/g, "")}`,
        dob: formatDate(`${jsonData.dob}`),
        phoneNumber: props.number,
        password: `${jsonData.password}`,
        profilePic: profilePic.length == 0 ? undefined : profilePic,
        interests: interest,
        firebaseUid: props.firebaseUid,
        countryCode: props.countryCode,
        bio: "",
      };

    setData(payload);

    if (guestUserValue) {
      dispatch(SignUpData(payload, props.isGuest, navigate));
    } else {
      dispatch(SignUpData(payload, props.isGuest, navigate));
    }

    setBoolSave(false);
    setShowButton(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(signupInfo.error !== ""){
      setShowButton(true);
    }
  }, [signupInfo])

  useEffect(() => {
    setData({
      ...dataSignup,
      interests: interest,
    });
  }, [interest]);

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-start sign-up-screen" style={dynamicStyles}>
      <div className="w-full flex flex-col items-center justify-center px-4">

        <img alt="FaviChat logo" className="w-24 h-24 transform scale-75" src={MainLogo} />

        <div className="w-full sm:max-w-sm px-6 sm:px-0">
          <div className="image-upload div-avatar-signup flex items-center justify-center">
            <div className="relative">

              <label htmlFor="file-input" className="cursor-pointer flex items-center justify-center">
                <img className={`img-avatar-signup object-cover ${
                  picture
                    ? "signUp__container-mid-userImg"
                    : "signUp__container-mid-defaultImg"
                  }`}
                  id="blah"
                  src={userIcons}
                  alt="logo"
                  onChange={onChangePicture}
                />
              </label>

              {muiLoad && (
                <img
                  src={Favichat}
                  alt=""
                  style={{
                    height: "6rem",
                    width: "6rem",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  }}
                />
              )}

            </div>

            <div>
              <label htmlFor="file-input">
                <input
                  required
                  onChange={onChangePicture}
                  id="file-input"
                  type="file"
                  accept="image/jpeg, image/png"
                  className="hidden"
                />
              </label>
              <p className="required-field text-center mt-2 text-xs text-red-600">
                {errorMessage}
              </p>
            </div>

          </div>

          <form
            className="flex flex-col items-center w-full"
            onSubmit={handleSubmit((data) => {updateData(data)})}
          >

            <div className="w-full div-input-signup">
              <input
                type="text"
                {...register("firstname")}
                className="border rounded-lg border-gray-200 input-signup w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                placeholder="First Name"
              />
              <p className="text-red-500 text-xs text-left mt-1">
                {errors?.firstname?.message}
              </p>
            </div>

            <div className="w-full div-input-signup">
              <input
                type="text"
                {...register("lastname")}
                className="border rounded-lg border-gray-200 input-signup w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                placeholder="Last Name"
              />
              <p className="text-red-500 text-xs text-left mt-1">
                {errors?.lastname?.message}
              </p>
            </div>

            <div className="w-full div-input-signup">
              <input
                type="text"
                {...register("userName")}
                className="border rounded-lg border-gray-200 input-signup w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                placeholder="Username (no '@' or '#')"
                onChange={(e) => changeStatusUserName(e)}
              />
              <p className="text-red-500 text-xs text-left mt-1">
                {errors?.userName?.message == undefined ? (
                  signupInfo.error === "Username already exists" && (
                    <> Username already exists</>
                  )
                ): (
                  <>{errors?.userName?.message}</>
                )}
              </p>
            </div>

            <div className="relative w-full div-input-signup">
              <div className="relative">
                <input
                  type={passwordShown ? "text" : "password"}
                  {...register("password")}
                  className="border !pr-14 rounded-lg border-gray-200 input-signup w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                  placeholder="Password"
                />
                {!passwordShown ? (
                  <span
                    onClick={() => setPasswordShown(true)}
                    className="absolute inset-y-0 right-0 grid place-content-center px-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="img-eyes-signup signin-input-field-iconeye"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  </span>
                ) : (
                  <span
                    onClick={() => setPasswordShown(false)}
                    className="absolute inset-y-0 right-0 grid place-content-center px-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="img-eyes-signup signin-input-field-iconeye"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  </span>
                )}
              </div>
              <p className="text-red-500 text-xs text-left mt-1 whitespace-pre-line">
                {errors?.password?.message}
              </p>
            </div>

            <div className="relative w-full div-input-signup">
              <div className="relative">
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  className="!pr-14 border rounded-lg border-gray-200 input-signup w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                  placeholder="Re-enter Password"
                  {...register("password_repeat")}
                />
                {!confirmPasswordShown ? (
                  <span
                    onClick={() => setConfirmPasswordShown(true)}
                    className="absolute inset-y-0 right-0 grid place-content-center px-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="img-eyes-signup signin-input-field-iconeye"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  </span>
                ) : (
                  <span
                    onClick={() => setConfirmPasswordShown(false)}
                    className="absolute inset-y-0 right-0 grid place-content-center px-4"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="img-eyes-signup signin-input-field-iconeye"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  </span>
                )}
              </div>
              <p className="text-red-500 text-xs text-left mt-1">
                {errors?.password_repeat?.message}
              </p>
            </div>

            <div className="block w-full div-input-signup">
              <p className="font-bold text-sm">Date of birth</p>
              <p id="date_para" className="text-gray-500 text-xs">
                This will not be shown on your profile.
              </p>
            </div>

            <div className="relative w-full flex flex-col div-input-signup">
              <input
                type="date"
                {...register("dob")}
                className="border rounded-lg border-gray-200 w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300 date-picker-signup input-signup-dob"
                placeholder="dd/mm/yyyy"
                max="9999-12-31"
              />
              <p className="text-red-500 text-xs text-left mt-1">
                {errors?.dob?.message}
              </p>
            </div>

            {!signupInfo.error.includes("2 validation errors detected") || !signupInfo.error.includes("Username already exists") && <div className="required-fieldtext">{signupInfo.error}</div>}

            <div className={`py-4 sm:max-w-sm px-8 sm:px-0 ${signupInfo.loading ? "" : "w-full"}`}>
              {signupInfo.loading ? (
                <div className="loader loading-icon-interests">
                  <img src={FavichatLoading} alt="Loading icon" />
                </div>
              ) : (showButton && (
                <button
                  type="submit"
                  className="rounded-lg bg-blue-500 px-5 py-2 text-white continue-btn-signup"
                  disabled={muiLoad}
                >
                  Continue
                </button>
              ))}
            </div>

          </form>

        </div>

      </div>
    </div>
  )
}