import GifModal from "./GifModal";
import "../../styles/MessageForm.scss";
import ProfileType from "./ProfileType";
import ContentModal from "./ContentModal";
import { useSelector, useDispatch } from "react-redux";
import UploadIcon from "../../assets/icons/uploadIcon.png";
import UpladGifSVG from "../../assets/icons/gifNewIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { EmojiPicker } from "../chatTool/emojiPicker/EmojiPicker";
import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { UpdatePurchasePopToggleState, AddMessage, UpdateTypingChat, ReadMessage } from "../../redux/actions/index";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const [isMobile, setIsMobile] = useState(false);
  
  const location = useLocation();

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return [size, isMobile, location];
}

const MessageForm = ({ isBlocked, isPrivacyTag, isDisabled, infiniteScrollRef }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timeoutRef = useRef(null);
  const textareaRef = useRef(null);
  const [size, isMobile, location] = useWindowSize();

  const [type, setType] = useState("");
  const [open, setOpen] = useState(false);
  const [gifImg, setGifImg] = useState("");
  const [showDiv, setShowDiv] = useState(true);
  const [loadGif, setLoadGid] = useState(false);
  const [gifHeight, setGifHeight] = useState(1);
  const [onlineData, setOnlineData] = useState();
  const [isTyping, setIsTyping] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [messageText, setMessageText] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [showGrid, toggleGrid] = useState(() => false);
  const [contentModal, setContentModal] = useState(false);
  const [textMessageModal, setTextMessageModal] = useState("");

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const userId = useSelector((state) => state.user.userId);
  const username = useSelector((state) => state.user.userName);
  const messages = useSelector((state) => state.conversations);
  const currentConversationId = useSelector((state) => state.currentConversationId);
  const participantsInformation = useSelector((state) => state.currentChat.participants);
  const [toConversationID, setToConversationID] = useState(window.location.pathname.split("/")[2]);

  const toggleGifGrid = () => toggleGrid((prev) => !prev);

  const loggedIn = userData;

  const handleResize = () => {
    const divElement = document.getElementById('divGif');

    if (divElement !== null) {
      setGifHeight(divElement.clientHeight);
    }
  };

  const smoothScroll = (element, target, duration) => {
    let start = element.scrollTop,
        change = target - start,
        startTime = performance.now(),
        val, now, elapsed, t;
  
    function animateScroll(currentTime) {
      now = currentTime;
      elapsed = (now - startTime) / 1000;
      t = (elapsed / duration);
  
      element.scrollTop = start + change * Math.easeInOutQuad(t);
  
      if (t < 1) window.requestAnimationFrame(animateScroll);
    }
  
    window.requestAnimationFrame(animateScroll);
  };
  
  Math.easeInOutQuad = function (t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };
  
  const setGif = async (gif) => {
    dispatch(AddMessage({
        currentConversationId: currentConversationId,
        message: {
          content: textMessage,
          sender: userId,
          type: 3,
          contentUrl: `https://media.giphy.com/media/${gif.id}/giphy.gif`,
          heartActive: 0,
          platform: isMobile,
        },
    })).then(() => {
      const scrollDiv = infiniteScrollRef.current;
      if (scrollDiv && scrollDiv.el) {
        let targetPosition = scrollDiv.el.scrollHeight;
        smoothScroll(scrollDiv.el, targetPosition, 1);
      }
    })
  };

  const onGifClick = (gif) => {
    setGifImg(gif);
    setOpen(true);
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    event.target.value !== "" ? setType("search") : setType("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const notText = /^[\r\n]*$/.test(messageText);
    const spaceWhite = /^[\n ]+$/.test(messageText);
    if (messageText && !notText && !spaceWhite) {
      const scrollDiv = infiniteScrollRef.current;
      if (scrollDiv && scrollDiv.el) {
        let targetPosition = scrollDiv.el.scrollHeight;
        smoothScroll(scrollDiv.el, targetPosition, 1);
      }
      dispatch(AddMessage({
        currentConversationId: currentConversationId,
        message: {
          content: messageText.trim(),
          sender: userId,
          type: 0,
          contentUrl: "",
          heartActive: 0,
          platform: isMobile,
        },
      })).then(() => {
        const scrollDiv = infiniteScrollRef.current;
        if (scrollDiv && scrollDiv.el) {
          let targetPosition = scrollDiv.el.scrollHeight;
          scrollDiv.el.scrollTop = 0;
        }
      })
      setIsTyping(false);
      clearTimeout(timeoutRef.current);
      setMessageText("");
      setShowDiv(true);
    }
  };

  const handleChangeTextArea = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 0 && isMobile) {
      setShowDiv(false);
    } else {
      setShowDiv(true);
    }
  };

  const handleTyping = () => {

    const userWithPhotoUrl = participantsInformation.find((item) => item.userId === userId);

    const data = {
      conversationId: currentConversationId,
      userId: userId,
      username: username,
      photoUrl: userWithPhotoUrl ? userWithPhotoUrl.photoUrl : null,
    };

    if (!isTyping){
      setIsTyping(true);

      dispatch(UpdateTypingChat({ ...data, isTyping: true }));

      setTimeout(() => {
        setIsTyping(false);
        dispatch(UpdateTypingChat({ ...data, isTyping: false }));
      }, 3000);
    }
  };

  const setEmoji = (emoji) => {
    if (messageText.length < 299) {
      setMessageText(messageText + emoji);
    }
  };

  const onApproveGif = () => {
    setGif(gifImg);
    toggleGifGrid();
    setOpen(false);
  };

  const handleCancel = () => {
    setType("");
    setSearchQuery("");
    setOpen(false);
    setGifImg("");
  };

  useEffect(() => {
    const computeRows = () => {
      const lineHeight = parseFloat(
        getComputedStyle(textareaRef.current).lineHeight
      );
      const oneRowHeight =
        lineHeight +
        parseFloat(getComputedStyle(textareaRef.current).paddingTop) +
        parseFloat(getComputedStyle(textareaRef.current).paddingBottom);
      textareaRef.current.style.height = "auto";
      const rows = Math.ceil(textareaRef.current.scrollHeight / oneRowHeight);
      textareaRef.current.style.height = `${rows * oneRowHeight}px`;
    };
    computeRows();
    window.addEventListener("resize", computeRows);
    return () => window.removeEventListener("resize", computeRows);
  }, [messageText]);

  // useEffect(() => {
  //   dispatch(ReadMessage(currentConversationId));
  // }, [messages]);

  useEffect(() => {
    const divElement = document.getElementById('divGif');

    if (divElement !== null) {
      setGifHeight(divElement.clientHeight);
    }
  }, [loadGif]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const eventKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!messageText.trim()) {
        event.preventDefault();
      }
    }
  };

  const dynamicStyles = {
    height: isMobile ? `${gifHeight - 98}px` : '350px'
  };

  return (
    <>
      <div className={`flex flex-col px-4 pb-4 bg-white bottom-0 landscape:sticky landscape:bottom-auto md:sticky ${isMobile ? "w-screen portrait:fixed" : "portrait:sticky"} ${showDiv ? "" : "w-screen"}`}>
        
        <ProfileType isBlocked={isBlocked} />

        <div className="flex items-center justify-between">
          
          <textarea
            ref={textareaRef}
            className={`flex-1 px-2 pt-3 max-h-[3rem] pl-4 border rounded-3xl resize-none focus:outline-none focus:ring-1 focus:ring-gray-100 leading-6 min-h-[3rem] textarea-with-scroll`}
            minrows={1}
            maxrows={4}
            placeholder="Chat..."
            value={messageText}
            onChange={(e) => {
              setMessageText(e.target.value);
              handleTyping();
              handleChangeTextArea(e);
            }}
            onKeyDown={(e) => {
              if(!isMobile && e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
            onKeyPress={eventKeyPress}
            disabled={isBlocked || isDisabled ? true : isPrivacyTag }
          />

            <button
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{
                backgroundColor: isHovered ? "#6eb5ed" : "#6eb5ed",
              }}
              className="p-2 ml-2 bg-blue-500 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50"
              onClick={handleSubmit}
              disabled={isBlocked || isDisabled ? true : isPrivacyTag }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-4 w-4 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4l8 8-8 8"
                />
              </svg>
            </button>

          {showDiv && (
            <div style={{ display: "none !important" }}>
              <div className="flex justify-center items-center">
                <button
                  aria-label="Favorite"
                  onClick={(e) => {
                    isGuest == true
                      ? navigate("/settings/hearts")
                      : dispatch(UpdatePurchasePopToggleState(true));
                  }}
                  className="ml-2 focus:outline-none focus:ring-2 focus:ring-white rounded-full "
                >
                  <svg
                    className="inboxChatbox-bottom-icon h-9 w-9 text-gray-500"
                    fill="#6eb5ed"
                    viewBox="0 0 24 24"
                    stroke="#6eb5ed"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                    />
                  </svg>
                </button>
                <button
                  aria-label="upload"
                  onClick={(e) => {
                    setContentModal(!contentModal);
                  }}
                  disabled={isBlocked || isDisabled ? true : isPrivacyTag }
                  className={`UploadIcon-btn p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-white ${
                    false ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <img src={UploadIcon} alt="" className="uploadIcon " />
                </button>
                {!isMobile && (
                  <div className="pt-1">
                    <EmojiPicker onEmoji={setEmoji} disabled={isBlocked || isDisabled ? true : isPrivacyTag } />
                  </div>
                )}
                <button
                  type="button"
                  disabled={isBlocked || isDisabled ? true : isPrivacyTag }
                  onClick={toggleGifGrid}
                  className="ml-2  btn-upload-gif"
                >
                  <img alt="Upload GIF" src={UpladGifSVG} className="img-gif" />
                </button>
              </div>
            </div>
          )}

        </div>
      </div>

      {contentModal && (
        <ContentModal
          setContentModal={setContentModal}
          name={onlineData?.name}
          textMessage={textMessageModal}
          id={loggedIn?.id}
          toConversationID={toConversationID}
          setTextMessage={setTextMessageModal}
          isGuest={onlineData?.isGuest}
          infiniteScrollRef={infiniteScrollRef}
        />
      )}

      {showGrid && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
            <div id="divGif" className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full fixed items-center justify-center modal-desk-send-all-gifs-discovery gif-modal">
              <div className="mt-4 flex items-center justify-center flex-col">
                <button
                  className="absolute top-2 left-2"
                  onClick={() => {
                    toggleGifGrid();
                    handleCancel();
                    setLoadGid(false);
                  }}
                >
                  <svg
                    className="h-7 w-7 text-gray-700"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <input
                  className="w-3/5 border rounded-lg border-gray-200 p-2 text-sm shadow-sm left-2 h-9 mb-4"
                  type="text"
                  placeholder="Search for GIF's"
                  value={searchQuery}
                  onChange={handleChange}
                />

                <div style={dynamicStyles} className="flex justify-center gif-modal overflow-auto w-full">
                  <GifModal
                    type={type}
                    searchText={searchQuery}
                    setLoadGid={setLoadGid}
                    onGifClick={(gif, e) => {
                      e.preventDefault();
                      onGifClick(gif);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {open && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full fixed items-center justify-center modal-desk-send-all-gifs-conf-discovery">
              <div className="p-3 flex items-center justify-center flex-col">
                <img
                  className="h-auto w-[180px]"
                  src={`https://media.giphy.com/media/${gifImg.id}/giphy.gif`}
                  alt="...loading"
                />
                <p className="gif-confirm-title">
                  Do you want to send this GIF?
                </p>
                <div className="flex flex-row justify-center items-center w-full">
                  <button
                    className="btn-gif-confirm-cancel"
                    autoFocus
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-gif-confirm"
                    onClick={onApproveGif}
                    autoFocus
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default MessageForm;
