import Avatar from "react-avatar";
import "../../../styles/Trigger.scss";
import { Url } from "../../../axios/authAPI";
import "../../../styles/BlockedUsers.scss";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../../axios/authAPI";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FavichatLoader from "../../../components/SkeletonLoader/FavichatLoader";
import { ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser, UpdateSettingSidebarToggleState, UnblockUser } from "../../../redux/actions/index";

const BlockedUsers = () => {

  let api = useAxios();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState();
  const [searchText, setSearchText] = useState("");
  const [blockedList, setBlockedList] = useState();
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const blockUsers = useSelector((state) => state.blockUsers);

  const handleUnBlock = async (toConversationID) => {
    setLoader(true);
   dispatch(UnblockUser({api, toConversationID})).then(() => {
      setLoader(false); 
    });
  };

  function stringAvatar(name) {
    const formatedName = name?.split(" ");
    return (
        formatedName?.length > 2
          ? `${formatedName[0]} ${formatedName[2]}`
          : formatedName?.length > 1
          ? `${formatedName[0]} ${formatedName[1]}`
          : name
    );
  }

  const filterList = (list, searchValue) => {
    return list.filter((item) => {
      const fullName = `${item.name}`.replace(/\s+/g, "").toLowerCase();
      const fullUsername = `${item.userName}`.replace(/\s+/g, "").toLowerCase();
      const trimmedSearchValue = searchValue.replace(/\s+/g, "").toLowerCase();
      let guestname = ""
      if (item.name.toLowerCase().startsWith('guest')) {
        let name =  item.userName.charAt(0).toUpperCase() + item.userName.slice(1)
        name = name.split('-')
        const suffix = item.userName.substr(item.userName.length - 5)
        guestname = (name[0]+suffix).toLowerCase()
      }
      return (
        fullName.includes(trimmedSearchValue) ||
        fullUsername.includes(trimmedSearchValue) ||
        guestname.includes(trimmedSearchValue)
      );
    });
  };

  useEffect(async () => {
    const newBlockUsers = blockUsers.filter(user => !user.isDisable);
    newBlockUsers.reverse();
    setBlockedList(newBlockUsers);
    setFilterData(newBlockUsers);
  }, [blockUsers]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (searchText.length > 0) {
      setFilterData(filterList(blockedList, searchText));
    }
    if (searchText.length === 0 || blockedList.length === 0) {
      setFilterData(blockedList);
    }
    if (searchText.length > 1 && blockedList.length === 0) {
      setFilterData(blockedList);
    }

  }, [searchText, blockedList]);

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const displayName = (userName) => {
    if (userName.toLowerCase().startsWith('guest')) {
      let name =  userName.charAt(0).toUpperCase() + userName.slice(1)
      name = name.split('-')
      const suffix = userName.substr(userName.length - 5)
      return name[0] + ' ' + suffix
    }
    return userName.length > 15 ? userName.slice(0, 15) + "..." : userName
  }

  const dynamicStyles = {
    maxHeight: `${screenHeight - 230}px`,
  };
  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };


  return (
    <>
      {!(isGuest === true) ? (
        <>
          {loader && <FavichatLoader />}
            <div>
              <div className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
                onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
                  <div className="sm:hidden pt-[8px]">
                    <button type="submit" className="mr-[8px]">
                      <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                      </svg>
                    </button>
                  </div>
                <p className="text-[#494949] font-boboto font-bold title-profile">Blocked users</p>
              </div>

              {filterData && (
                <div className="w-[100%] mt-[2rem] py-[0rem] sm:px-[6.5rem] px-6 max-sm:px-[20px] pb-6">
                  <input className="text-[16px] w-[100%] p-[15px] rounded-[1rem] bg-[#f3f3f3] focus:outline-none focus:ring-[1px] focus:ring-[#2f6f9e] focus:border-transparent"
                    type="text"
                    placeholder="Blocked users"
                    id="block_search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </div>
              )}
              <div className={`w-full flex-col sm:px-[6.5rem] px-[0px] max-sm:px-[20px] flex overflow-y-auto`} style={dynamicStyles}>
                {filterData === undefined ? (
                  <FavichatLoader />
                ) : filterData?.length === 0 ? (
                  <>
                    <h1 className="text-[#8080809A] text-[20px] font-bold text-center">No blocked users</h1>
                  </>
                ) : (
                  filterData.map((item, index) => {
                    const { userId, userName, photoUrl, name } = item;
                    return (
                      <div className="w-[100%] flex flex-row items-center justify-between py-[11px] px-[1rem] max-sm:px-[.2rem] border-b-[1px] border-b-solid border-b-[#e3f3ff]" key={userId}>
                        <div className="flex items-center w-[80%]">
                          {photoUrl === "" || photoUrl === null || photoUrl === undefined ? (
                            <Avatar
                              color="#99CEF6"
                              round={true}
                              className="users-box-img-block"
                              name={stringAvatar(name)}
                              value={userName}
                              style={{
                                position: "relative"
                              }}
                            />
                          ) : (
                            <img src={photoUrl} className="rounded-full h-[4rem] w-[4rem] mr-[10px] object-cover" />
                          )}
                          <p className="text-[#494949] text-[20px] font-normal text-left pl-2">
                            {name.includes("Guest") ? ( displayName(userName) ) : ( displayName(name) )}
                          </p>
                        </div>
                        <div className="blocked_button_section cursor-pointer">
                          <p className="bg-[#58a1f5] border border-[#6eb5ed] rounded-full text-[#fff] font-bold text-[18px] py-[4px] px-[20px]"
                            onClick={() => {
                            !loader && handleUnBlock(userId)}
                            } 
                          >
                            Unblock
                          </p>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>

            </div>
        </>
      ) : (
        <div className="profile">
          <div className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
            <div className="sm:hidden pt-[8px] px-6">
              <button type="submit" className="mr-[8px]">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BlockedUsers;
