import React from "react";
import "../../styles/Login.scss";
import '../../styles/LandingPage.scss';
import { useDispatch } from "react-redux";
import { requestForToken } from "../../Config/configurations";
import bellNotifications from "../../assets/icons/bellNotifications.png";
import { UpdatePopUpWelcomeState, UpdatePushToken } from "../../redux/actions/index";

const NotificationsPopUp = ({ setPermission, userData }) => {

  const dispatch = useDispatch();

  const close = () => {
    localStorage.setItem("askedPermission", true);
    dispatch(UpdatePopUpWelcomeState(false));
    localStorage.setItem('isWelcomePopupOpen', true);
  };

  const Notifications = async () => {
    const response = await requestForToken();
    if(response !== false){
      dispatch(UpdatePushToken({id:userData.userId,pushToken:response}))
      setPermission(true);
    } else {
      setPermission(false);
    }
    close();
  };

  const requestPermission = async () => {
    try {
      Notification.requestPermission().then((permission) => {
        console.log(JSON.stringify(permission))
        if (permission === "granted") {
          Notifications();
        }
      });
    } catch (error) {
      console.log("Error Notifications: " + error);
    }
  };

  const handleAccept = () => {
    const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        if (!isIOS){
        requestPermission();
        } else{
        var ua = navigator.userAgent || navigator.vendor;
        var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
        var str = navigator.userAgent;
        var i = str.indexOf("Instagram");
        if (isInstagram) {
        if (/iPad|iPhone|iPod/.test(ua)) {
            //window.location.href = 'googlechrome://example.com';
            return;
        } else {
            // Web page is not opened from Instagram browser
            console.log("from iphone", isIOS);
            if (
            (isIOS &&
                "standalone" in window.navigator &&
                window.navigator.standalone) ||
            !isIOS
            ) {
            requestPermission();
            }
        }
        } else {
        // Web page is not opened from Instagram browser
        console.log("from iphone", isIOS);
        if (
            (isIOS &&
            "standalone" in window.navigator &&
            window.navigator.standalone) ||
            !isIOS
        ) {
            requestPermission();
        }
        }
    }
    close();
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-40 backdrop-blur-[2px] transition-opacity"></div>
      <div
        className={`translate-y-0 w-full h-48 bg-white shadow-xl rounded-t-3xl transition-transform duration-300 ease-in-out`}
        style={{ border: '1px solid #b8b7b7'}}
      >
        <div className="flex justify-between items-center rounded-t-3xl p-4">
          <button
            className="p-2 rounded-full transition-colors duration-300 absolute"
            onClick={close}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 close-Drawer-LandingPage">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="w-10 h-10 flex justify-center items-center mx-auto">
            <img
              src={bellNotifications}
              alt="Bell Notifications"
              className="object-contain flex"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        </div>
        <div className="flex justify-center h-full">
          <div className="text-center">
            <p className="font-bold text-lg pb-4">
              Turn on notifications to have more fun!
            </p>
            <button className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-white signin-btn-signin" onClick={handleAccept}>
              Accept notifications
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPopUp;