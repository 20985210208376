import axios from "axios";
import { useDispatch } from "react-redux";
import { ResetState } from "../redux/actions/index";

const { REACT_APP_PROJECT_ID } = process.env;

const { host } = window.location;

let baseURL = "";

if (host.includes("dev") || host.includes("localhost")) {
  baseURL = "https://t0q722dyj9.execute-api.us-east-1.amazonaws.com/dev";
} else if (host.includes("uat")) {
  baseURL = "https://pg3qt4kqld.execute-api.us-east-1.amazonaws.com/uat";
} else if (host.includes("load")) {
  baseURL = "https://6uyz22vxna.execute-api.us-east-1.amazonaws.com/load";
} else if (host.includes("qa")) {
  baseURL = "https://xz6hoo6an5.execute-api.us-east-1.amazonaws.com/qa";
} else {
  baseURL = "https://jh9zhcupsl.execute-api.us-east-1.amazonaws.com/prd";
}

export const authApi = axios.create({
  baseURL,
});

export const Url = baseURL;

export const useAxios = () => {
  const dispatch = useDispatch();
  const axiosInstance = axios.create();

  const refreshAccessToken = async () => {
    return await axios
      .post(`${baseURL}/users/refresh/token`, {
        refreshToken: localStorage.getItem("refreshToken"),
      })
      .catch((error) => {
        if (error.response) {
          dispatch(ResetState());
          localStorage.clear();
          window.location.assign(
            process.env.PUBLIC_URL
              ? process.env.PUBLIC_URL
              : `${window.location.href.split("/")[0]}//${
                  window.location.href.split("/")[2]
                }/`
          );
        }
      });
  };

  axiosInstance.interceptors.request.use(
    async (req) => {
      req.headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      };

      return req;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        localStorage.setItem("accessToken", access_token.data.data.accessToken);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + access_token.data.data.accessToken;
        return axiosInstance(originalRequest);
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
