import React from "react";
import "../../styles/GradientButton.scss";

function Gradient(props) {
  const { children } = props;

  return (
    <button className="gradient" type="submit" disabled={props.disabled}>
      {children}
    </button>
  );
}
export default Gradient;
export const GradientSignup = (props) => {
  const { children } = props;

  return (
    <button className="gradientSignup" type="submit" disabled={props.disabled}>
      {children}
    </button>
  );
};
