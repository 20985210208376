import "../../styles/MessageForm.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useState, useLayoutEffect, useEffect } from "react";
import EllipsisLoader from "../../assets/icons/EllipsisLoader.gif";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
  
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
  
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
        setIsMobile(window.innerWidth < 640);
      }
  
      window.addEventListener("resize", updateSize);
      updateSize();
  
      return () => window.removeEventListener("resize", updateSize);
    }, []);
  
    return [size, isMobile, location];
}

const ProfileType = ({isBlocked}) => {

    const [chatName, setChatName] = useState([]);
    const [currentType, setCurrentType] = useState({photoUrl: "",userId: "", username: ""});

    const userId = useSelector((state) => state.user.userId);
    const userTyping = useSelector((state) => state.isTyping);
    const username = useSelector((state) => state.currentChat);

    const [size, isMobile, location] = useWindowSize();

    useEffect(() => {
        setCurrentType(userTyping);
    }, [userTyping]);

    useEffect(() => {
        setChatName(username);
    }, [username]);

    const refurbishName = (conversation) => {
        try{
          let string = conversation.name;
          var name;
          const formatedName = string
            ?.split(" ")
            .filter((element) => element !== null && element !== "");
          if (formatedName?.length > 2) {
            name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
          } else if (formatedName?.length > 1) {
            name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
          } else {
            name = string?.slice(0, 1);
          }
          if(name){
            return name.toUpperCase();
          } else{
            return "";
          }
        } catch(error){
          console.log(error)
          return ""
        }
    }

    const AvatarType = React.memo(({currentType}) => (
      <div className="flex items-center" >
        {currentType.photoUrl !== "" && (
          <img className="w-5 h-5 rounded-full object-cover mr-2" src={currentType.photoUrl} alt="Avatar"/>
        )}
        {currentType.photoUrl === "" && (
          <div className='avatar-text-div-inbox-form mr-2'>
            <span className='avatar-text-span-inbox-form'>{refurbishName(chatName)}</span>
          </div>
        )}
        {currentType.photoUrl === null && (
          <div className='avatar-text-div-inbox-form mr-2'>
            <span className='avatar-text-span-inbox-form'>{refurbishName(chatName)}</span>
          </div>
        )}
        <img src={EllipsisLoader} alt="" width={30} height={30}/>
      </div>
    ))

    return (
        <div className="h-8" style={{ minHeight: '2rem'}}>
            {currentType !== undefined && currentType.userId !== null && currentType.userId !== ""  && currentType.userId !== userId && !isBlocked && (
              <AvatarType currentType={currentType}/>
            )}
        </div>
    );
};

export default ProfileType;