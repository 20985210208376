import Navbar from "../../components/Navbar/Navbar";
import FollowersDataPage from "../../components/FollowersPage/FollowersDataPage";

const FollowersPage = () => {
  return (
    <div className="h-screen w-screen flex flex-col justify-start overflow-hidden fixed top-0 left-0">
      <Navbar />
      <FollowersDataPage />
    </div>
  );
};

export default FollowersPage;
