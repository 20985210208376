import { Url } from "../../axios/authAPI";
import { useAxios } from "../../axios/authAPI";
import FollowersProfile from "./FollowersProfile";
import UserSearch from "../UserSearch/UserSearch";
import Favichat from "../../assets/gif/Favichat.gif";
import { useSelector, useDispatch } from "react-redux";
import SendAllModal from "../SendAllModal/SendAllModal";
import UserModalFollower from "../UserDetailsModal/UserModalFollower";
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  UpdateDataFollowersState,
  CleanDataFollowersState,
} from "../../redux/actions/index";

const FollowersDataPage = () => {
  let api = useAxios();
  const dispatch = useDispatch();
  const inputRefSearch = useRef(null);

  const [pk, setPK] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [creatorDetails, setCreatorDetails] = useState();
  const [stopScrollFn, setStopScrollFn] = useState(true);
  const [followerLike, setFollowerLike] = useState(true);
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [sendAllModalToggle, setSendAllModalToogle] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const userData = useSelector((state) => state.user);
  const followerUserData = useSelector((state) => state.dataFollowers);

  const getUsersByInterest = useCallback(async (pk) => {
    try {
      if (pk !== "NO MORE ITEMS") {
        const response = await api.get(
          `${Url}/users/${userData.userId}/followers-list?limit=100&lastRec=${pk}`
        );
        const responseData = response?.data?.data;

        if (responseData.values && responseData.values.length > 0) {
          dispatch(UpdateDataFollowersState([...new Set([...followerUserData, ...responseData.values])]));
        }
        if (responseData.lastRec !== "NO MORE ITEMS") {
          setPK(responseData.lastRec);
          setLoading(true);
        } else {
          setLoading(false);
          setStopScrollFn(false);
        }
      } else {
        setStopScrollFn(false);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setStopScrollFn(false);
      setLoading(false);
    }
  }, [followerUserData, pk]);

  useEffect(() => {
    const handleUserDataNew = async () => {
      dispatch(CleanDataFollowersState());
      setLoading(true);
      const response = await api.get(
        `${Url}/users/${userData.userId}/followers-list?limit=100`
      );
      const responseData = await response?.data?.data;
      dispatch(UpdateDataFollowersState(responseData?.values));
      setPK(responseData?.lastRec);
      setLoading(false);
      setUserDataLoading(false);
    };

    handleUserDataNew();
  }, []);

  const handleScrollCheck = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;
    if (!userDataLoading && scrollHeight - scrollTop <= clientHeight + 100) {
      setLoading(true);
      getUsersByInterest(pk);
    }
  };

  const handleCloseProfile = (bool) => {
    setShowModal(false);
    setCreatorDetails("");
  };

  const handleInfo = (id, follow) => {
    setShowModal(true);
    setCreatorDetails(id);
    setFollowerLike(follow);
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dynamicStyles = {
    height: `${screenHeight - 48}px`,
  };

  return (
    <div
      style={dynamicStyles}
      className={`w-screen flex flex-col justify-start top-0 left-0 relative ${
        sendAllModalToggle ? "content-discovery active" : "content-discovery"
      }`}
      onScroll={stopScrollFn ? handleScrollCheck : undefined}
    >
      <div
        className="flex justify-between w-full h-20 pt-2 md:pt-4"
        id="topScroll"
      >
        <div className="flex items-center div-title-header-discovery">
          <h1 className="title-header-following-discovery">Followers</h1>
        </div>
        <div className="flex justify-start items-center div-search-header-discovery">
          <UserSearch inputRefSearch={inputRefSearch} />
        </div>
      </div>

      {userDataLoading ? (
        <>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ul className="user-followers-container-following">
          {followerUserData?.map((follower, index) => (
            <FollowersProfile key={index} list={follower} handleInfo={handleInfo} />
          ))}
        </ul>
      )}

      {showModal && (
        <UserModalFollower
          info={creatorDetails}
          close={handleCloseProfile}
          followerLike={followerLike}
        />
      )}

      {sendAllModalToggle && (
        <SendAllModal setSendAllModalToogle={setSendAllModalToogle} />
      )}

      {loading ? (
        <div className="w-full flex flex-row justify-center user-list-loading-container">
          <img src={Favichat} alt="" className="users-ripple-loader" />
          <p>Loading...</p>
        </div>
      ) : null}
    </div>
  );
};

export default FollowersDataPage;
