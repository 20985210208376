import "../../styles/LargeSizePopUp.scss";

const LargeSizePopUp = ({ handleCloseAlert }) => {
  return (
    <>
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-lg modal-send-all-discovery">
        <div className="p-6 flex items-center justify-center flex-col">
          <h1 className="title-send-all-discovery">Alert</h1>
          <p className="p-send-all-discovery">Error. Video file should be less than 80MB.</p>
          <button className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-white button-send-all-discovery" onClick={(e) => handleCloseAlert()}>
            Ok
          </button>
        </div>
      </div>
  </>
  
  );
};

export default LargeSizePopUp;
