import "../../styles/Navbar.scss";
import Avatar from "react-avatar";
import { persistor } from '../../redux/store';
import React, { useState, useEffect } from "react";
import bell from "../../assets/icons/bell_white.svg";
import { useNavigate, Link } from "react-router-dom";
import DialogBox from "../../components/Dialog/Dialog";
import { useDispatch, useSelector } from "react-redux";
import HomeImg from "../../assets/icons/navBar_home.png";
import InboxImg from "../../assets/icons/navBar_Inbox.png";
import guestImage from "../../assets/icons/guestImage.png";
import HeartIcon from "../../assets/icons/logout-heart-icon.png";
import FaviLogo from "../../assets/icons/Final_logo_white 1.png";
import HomeImgWhite from "../../assets/icons/navBar_home_white.png";
import InboxImgWhite from "../../assets/icons/navBar_inbox_white.png";
import {
  UpdateDiscoveryModalProfileState,
  UpdateSettingSidebarToggleState,
  UpdateInboxBarToggleState,
  UpdateMobileNavbarToggleState,
  RemovePushToken,
  UpdateAskPermissionState,
  UpdateOnlineUser,
  ResetState,
} from "../../redux/actions/index";

function Navbar() {
  const activePageIcon = window.location.pathname;;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hearts, setHearts] = useState(0);
  const [profilePic, setProfilePic] = useState();
  const [iconHover, setIconHover] = useState(false);
  const [iconHover1, setIconHover1] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [countNotification, setCountNotification] = useState(0);
  const [profileData, setProfileData] = useState({
    userName: "",
    id: "",
    show: "",
  });

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const inboxCount = useSelector((state) => state.inboxCount);
  const mobileNavbarToggle = useSelector((state) => state.mobileNavbarToggle);

  function formatHearts(hearts) {
    if (hearts >= 1000) {
      var num = hearts / 1000;
      var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
      return with2Decimals + "k";
    }
    return hearts !== null && hearts.toString();
  }

  const sideBarDetail = () => {
    dispatch(
      UpdateDiscoveryModalProfileState({
        show: true,
        apiCall: true,
        userName: userData.userName,
        profilePic: userData.profilePicUrl,
        id: userData.userId,
      })
    );
  };

  const LoadDataOnlyOnceGuest = () => {
    return (
      <>
        <div
          className="flex flex-col items-center justify-center div-avatar-user-nav"
          onClick={sideBarDetail}
        >
          <Avatar className="avatar-user-nav" src={guestImage} round={true} />
          <p className="p-user-nav">Guest</p>
        </div>
      </>
    );
  };

  const LoadDataOnlyOnce = () => {
    if (profilePic == undefined || profilePic == "") {
      return (
        <div
          className="flex flex-col items-center justify-center div-avatar-user-nav"
          onClick={sideBarDetail}
        >
          <div
            className={`${
              mobileNavbarToggle
                ? "avatar-text-div-navbar-mobile"
                : "avatar-text-div-navbar"
            }`}
          >
            <span
              className={`${
                mobileNavbarToggle
                  ? "avatar-text-span-navbar-mobile"
                  : "avatar-text-span-navbar"
              }`}
            >
              {refurbishName(userData.name)}
            </span>
          </div>
          <p className="p-user-nav">
            {userData.userName !== undefined && userData.userName !== null
              ? userData.userName.length > 13
                ? userData.userName.substring(0, 13).concat("..")
                : userData.userName
              : ""}
          </p>
        </div>
      );
    } else {
      return (
        <div
          className="flex flex-col items-center justify-center div-avatar-user-nav"
          onClick={sideBarDetail}
        >
          <img
            src={profilePic}
            alt="Avatar"
            className="avatar-user-nav rounded-full object-cover"
          />
          <p className="p-user-nav">
            {userData.userName !== undefined && userData.userName !== null
              ? userData.userName.length > 13
                ? userData.userName.substring(0, 13).concat("..")
                : userData.userName
              : ""}
          </p>
        </div>
      );
    }
  };

  const refurbishName = (string) => {
    var name;
    const formatedName = string
      ?.split(" ")
      .filter((element) => element !== null && element !== "");
    if (formatedName?.length > 2) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else if (formatedName?.length > 1) {
      name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
    } else {
      name = string?.slice(0, 1);
    }

    if (name) {
      return name.toUpperCase();
    } else {
      return "";
    }
  };

  const inboxRedirect = () => {
    navigate("/inbox/start");
  };

  const inboxDispatchFnc = (e) => {
    dispatch(UpdateInboxBarToggleState(true));
  };

  const settingDispatchFnc = (e) => {
    dispatch(UpdateSettingSidebarToggleState(true));
  };

  const btnLogout = () => {
    setIsDialogOpen((s) => !s);
  };

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    dispatch(UpdateMobileNavbarToggleState(false));
  };

  const onDialogYesClick = () => {
    const pushToken = localStorage.getItem("pushToken");
    if(pushToken !== undefined){
      dispatch(
        RemovePushToken({
          id: userData.userId,
          pushToken: pushToken,
        })
      );
    }

    // localStorage.setItem("askedPermission", false);

    dispatch(UpdateOnlineUser({ online: false })).then(() => {
      dispatch(UpdateMobileNavbarToggleState(false));
      dispatch(ResetState());
      persistor.purge();
      localStorage.removeItem("pushToken");
      navigate("/");
    });
  };

  useEffect(() => {
    setProfilePic(userData.profilePicUrl);
  }, [userData.profilePicUrl]);

  useEffect(() => {
    setHearts(userData.hearts);
  }, [userData.hearts]);

  useEffect(() => {
    setProfileData({
      userName: userData.userName,
      id: userData.userId,
      show: true,
    });
  }, [userData]);

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    if (userData.isDisable) {
      const pushToken = localStorage.getItem("pushToken");
      if(pushToken !== undefined){
        dispatch(
          RemovePushToken({
            id: userData.userId,
            pushToken: pushToken,
          })
        );
      }

      // localStorage.setItem("askedPermission", false);

      dispatch(UpdateOnlineUser({ online: false })).then(() => {
        dispatch(UpdateMobileNavbarToggleState(false));
        dispatch(ResetState());
        persistor.purge();
        localStorage.removeItem("pushToken");
        //localStorage.clear();
        navigate("/");
      });
    }
  }, [userData.isDisable]);

  // useEffect(() => {
  //   dispatch(UpdateOnlineUser({ online: false }));
  // }, []);

  useEffect(() => {
    setCountNotification(inboxCount.length);
  }, [inboxCount]);

  return (
    <nav className="navbar-nav">
      <div className="relative flex items-center justify-between px-4 sm:px-12 max-w-8xl header-navbar">
        <div className="flex items-center justify-center">
          <Link to="/discover" className="link-logo-nav">
            <img className="favichat-icon" src={FaviLogo} alt="FaviChat" />
          </Link>

          <div className="hidden md:block">
            <div className="flex items-center justify-center ml-0 pt-[.7rem]">
              <Link to="/discover">
                {isGuest == true ? (
                  <LoadDataOnlyOnceGuest />
                ) : (
                  <LoadDataOnlyOnce />
                )}
              </Link>
            </div>
          </div>
        </div>

        <div className="hidden md:block flex items-center justify-center">
          <div className="flex items-center justify-center ml-6 pt-[.7rem]">
            <Link to="/discover">
              <div className="flex flex-col items-center justify-center div-icons-nav">
                <img
                  className="img-icons-nav"
                  src={
                    iconHover || activePageIcon.includes("discover")
                      ? HomeImg
                      : HomeImgWhite
                  }
                  alt=""
                />
                <p
                  className={
                    activePageIcon.includes("discover")
                      ? "txt-home-active-nav"
                      : "txt-home-nav"
                  }
                >
                  Home
                </p>
              </div>
            </Link>

            <a className="ml-8 cursor-pointer" onClick={inboxRedirect}>
              <div className="flex flex-col relative items-center justify-center div-icons-nav">
                <img
                  className="img-icons-nav"
                  src={
                    iconHover1 || activePageIcon.includes("inbox")
                      ? InboxImg
                      : InboxImgWhite
                  }
                  alt=""
                />
                {countNotification > 0 && (
                  <p className="notification-inbox-nav">
                  </p>
                )}
                <p
                  className={
                    activePageIcon.includes("inbox")
                      ? "txt-home-active-nav"
                      : "txt-home-nav"
                  }
                >
                  Inbox
                </p>
              </div>
            </a>
          </div>
        </div>

        <div className="hidden md:block flex items-center justify-center">
          <div className="flex items-center justify-center ml-8">
            <Link to="/settings/hearts">
              <div className="flex flex-col items-center justify-center div-icons-nav">
                <svg
                  className={`icons-heart-settings-navbar ${
                    activePageIcon.includes("hearts")
                      ? "icon-heart-active-nav"
                      : "icon-heart-nav"
                  }`}
                  fill="currentColor"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-23.59 -23.59 518.88 518.88"
                  stroke="currentColor"
                  strokeWidth="15.566133000000002"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                    </g>{" "}
                  </g>
                </svg>
                <p
                  className={
                    activePageIcon.includes("hearts")
                      ? "txt-home-active-nav"
                      : "txt-home-nav"
                  }
                >
                  {formatHearts(hearts)}
                </p>
              </div>
            </Link>
            <Link
              to="/settings/profile"
              onClick={(e) => settingDispatchFnc()}
              className="ml-8"
            >
              <div className="flex flex-col items-center justify-center div-icons-nav">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className={`icons-heart-settings-navbar ${
                    activePageIcon.includes("hearts")
                      ? "icon-heart-active-nav"
                      : activePageIcon.includes("settings")
                      ? "icon-heart-active-nav"
                      : "icon-heart-nav"
                  }`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                  />
                </svg>
                <p
                  className={
                    activePageIcon.includes("settings")
                      ? "txt-home-active-nav"
                      : "txt-home-nav"
                  }
                >
                  Settings
                </p>
              </div>
            </Link>
          </div>
        </div>

        {(window.location.pathname == "/discover" ||
          window.location.pathname.startsWith("/settings")) && (
          <div className="md:hidden flex items-center justify-center div-icon-inbox-mobile-nav">
            <div className="flex items-center justify-center ml-8">
              <a onClick={inboxRedirect}>
                <div
                  className="flex flex-col relative items-center justify-center div-icons-nav"
                  onClick={(e) => inboxDispatchFnc()}
                >
                  <img className="img-icons-nav" src={InboxImgWhite} alt="" />
                  {countNotification > 0 && (
                    <p className="notification-inbox-nav">
                    </p>
                  )}
                </div>
              </a>
            </div>
          </div>
        )}

        {window.location.pathname.startsWith("/inbox") && (
          <div className="md:hidden flex items-center justify-center div-icon-inbox-mobile-nav">
            <div className="flex items-center justify-center ml-8">
              <Link to="/discover">
                <div
                  className="flex flex-col relative items-center justify-center div-icons-nav"
                  onClick={(e) => inboxDispatchFnc()}
                >
                  <img className="img-icons-nav" src={HomeImgWhite} alt="" />
                </div>
              </Link>
            </div>
          </div>
        )}

        <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:hidden">
          <button
            className="inline-flex items-center justify-center p-2 rounded-md"
            onClick={(e) => dispatch(UpdateMobileNavbarToggleState(true))}
          >
            <svg
              className={`${
                mobileNavbarToggle ? "hidden" : "block"
              } icons-heart-settings-navbar menu-icon-nav`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
      </div>

      {mobileNavbarToggle && (
        <div
          className="fixed inset-0 z-20 bg-white opacity-60"
          style={{
            backdropFilter: "blur(10px)",
            WebkitBackdropFilter: "blur(10px)",
          }}
          onClick={(e) => dispatch(UpdateMobileNavbarToggleState(false))}
        />
      )}

      <div
        className={`${
          mobileNavbarToggle
            ? "translate-x-0 ease-out"
            : "translate-x-full ease-in"
        } fixed z-20 inset-y-0 right-0 transform transition duration-300 menu-mobile-settings-navbar navbar-nav`}
      >
        <div className="flex items-center justify-end">
          <button
            className="text-white focus:outline-none focus:text-white"
            onClick={(e) => dispatch(UpdateMobileNavbarToggleState(false))}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="icons-heart-settings-navbar"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </button>
        </div>

        <nav className="mt-8 flex items-center justify-center">
          <div>
            <div className="flex items-center justify-center">
              {isGuest == true ? (
                <LoadDataOnlyOnceGuest />
              ) : (
                <LoadDataOnlyOnce />
              )}
            </div>

            <div
              onClick={(e) => {
                settingDispatchFnc();
                dispatch(UpdateMobileNavbarToggleState(false));
              }}
            >
              <Link to="/settings/profile">
                <div className="flex items-center justify-left pt-8 div-icons-nav">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className={`icons-mobile-nav`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    />
                  </svg>
                  <p className="txt-settings-right-nav">Settings</p>
                </div>
              </Link>
            </div>

            <div>
              <Link to="#">
                <div className="flex items-center justify-left pt-3 div-icons-nav">
                  <div className="icons-mobile-nav items-center flex">
                    <img src={bell} className="icons-mobile-nav-bell mx-auto" />
                  </div>
                  <p className="txt-settings-right-nav">Notifications</p>
                </div>
              </Link>
            </div>

            {!(isGuest == true) ? (
              <div onClick={btnLogout} className="cursor-pointer">
                <div className="flex items-center justify-left pt-3 div-icons-nav">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="icons-mobile-nav"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p className="txt-settings-right-nav">Logout</p>
                </div>
              </div>
            ) : null}
          </div>

          <div className="flex items-center justify-center fixed bottom-20">
            <Link
              to="/settings/hearts"
              className="block rounded-xl relative shadow-xl card-mobile-nav pl-4 pr-4"
            >
              <img
                src={HeartIcon}
                alt=""
                className="icon-heart-card-mobile-nav"
              />
              <h3 className="mt-3 text-lg font-bold text-center txt-card-mobile-nav">
                {formatHearts(hearts)}
              </h3>
              <div
                onClick={() => {
                  dispatch(UpdateSettingSidebarToggleState(false));
                  dispatch(UpdateMobileNavbarToggleState(false));
                }}
              >
                <button className="btn-card-mobile-nav">Add more</button>
              </div>
            </Link>
          </div>
        </nav>
      </div>

      {isDialogOpen && (
        <DialogBox
          dialogTitle={"Are you sure you want to logout?"}
          isDialogOpen={isDialogOpen}
          setOpenDialog={setIsDialogOpen}
          onDialogYesClick={onDialogYesClick}
        />
      )}
    </nav>
  );
}

export default Navbar;
