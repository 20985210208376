import Navbar from "../../components/Navbar/Navbar";
import FollowingDataPage from "../../components/FollowingPage/FollowingDataPage";

const FollowingPage = () => {
  return (
    <div className="h-screen w-screen flex flex-col justify-start overflow-hidden fixed top-0 left-0">
      <Navbar />
      <FollowingDataPage />
    </div>
  );
};

export default FollowingPage;
