import "../../styles/Otp.scss";
import "../../styles/Signup.scss";
import Terms from "../SignUp/Terms";
import "../../styles/InputForm.scss";
import "../../styles/Verification.scss";
import { Signup } from "../SignUp/Signup";
import { useDispatch } from "react-redux";
import { Url } from "../../axios/authAPI";
import "react-phone-number-input/style.css";
import { persistor } from "../../redux/store";
import { Logo } from "../../components/Logo/Logo";
import React, { useEffect, useState } from "react";
import { auth } from "../../Config/configurations";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import setUpReCaptcha from "../../_helpers/otpFirebase";
import en from "react-phone-number-input/locale/en.json";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import {
  UpdateErrorLogInState,
  ResetState,
  UpdateMobileNavbarToggleState,
} from "../../redux/actions/index";
import { formatPhoneNumber } from "../../_helpers/formatPhoneNumber";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";

export const Verification = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [err, setErr] = useState("");
  const [errTc, setErrTC] = useState("");
  const [flag, setFlag] = useState(false);
  const [number, setNumber] = useState("");
  const [counter, setCounter] = useState(0);
  const [confirm, setConfirm] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [checking, setChecking] = useState(false);
  const [countryVal, setCountryVal] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [firebaseUid, setfirebaseUid] = useState("");
  const [showSignUp, setShowSignUp] = useState(false);
  const [actualNumber, setActualNumber] = useState("");
  const [showResendCode, setShowResendCode] = useState(true);
  const [checkingMessage, setCheckingMessage] = useState("");
  const [recaptcha, setRecaptcha] = useState(null);

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" },
      auth
    );
    setRecaptcha(recaptchaVerifier);

    return () => {
      if (recaptcha) {
        recaptcha.clear();
      }
    };
  }, []);

  const getVisitorCountry = () => {
    return new Promise((resolve, reject) => {
      window
        .fetch("https://ip2c.org/self")
        .then((response) => response.text())
        .then((data) => {
          const [status, country] = String(data).split(";");
          if (status !== "1") {
            setCountryVal("+1");
            throw new Error("Unable to fetch country");
          }
          let val = "+" + getCountryCallingCode(country);
          setCountryVal(val);
          resolve(country);
        })
        .catch(() => {
          setCountryVal("+1");
          resolve("US");
        });
    });
  };

  const getOtp = async (e) => {
    e.preventDefault();

    setCheckingMessage("Validating phone number");
    setErr("");

    let str = countryVal + number;
    let jsonPhoneNumber = str.replace(/[- )(]/g, "");

    setActualNumber(str.replace(/[- )(]/g, ""));

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: jsonPhoneNumber,
      }),
    };

    try {
      if (number === "" || number == undefined)
        return setErr("Phone number cannot be blank");

      if (!isChecked) {
        return setErrTC("Please accept terms and conditions");
      }

      setChecking(true);

      const response = await fetch(
        `${Url}/users/phone/available`,
        requestOptions
      );
      const responseData = await response.json();

      if (!responseData?.success) {
        setChecking(false);
        setCheckingMessage("");
        if (responseData?.error?.message.includes("Invalid")) {
          setErr("Invalid phone number");
        } else {
          setErr(responseData?.error?.message);
        }
      }

      if (responseData?.success) {
        dispatch(UpdateErrorLogInState(""));

        setChecking(true);
        setCheckingMessage("Generating captcha...");
        setUpReCaptcha();
        setCounter(60);
        setDisabled(true);

        // let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, jsonPhoneNumber, recaptcha)
          .then((result) => {
            setChecking(false);
            setCheckingMessage("");
            setShowResendCode(false);
            setConfirm(result);
          })
          .catch((err) => {
            // appVerifier.clear();
            setChecking(false);
            setCheckingMessage("");

            setErr("Please try again later");
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resendOtp = async () => {
    signInWithPhoneNumber(auth, actualNumber, window.recaptchaVerifier)
      .then((result) => {
        setConfirm(result);
        setShowResendCode(false);
      })
      .catch((err) => {
        console.log(err);
        setErr("Please try again later");
      });
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    try {
      setErr("");

      if (otp === "" || otp === null)
        return setErr("Please enter the 6 digit code.");
      if (otp.length !== 6) return setErr("Please enter the 6 digit code.");

      await confirm
        .confirm(otp)
        .then((result) => {
          const user = result.user;

          setfirebaseUid(user.uid);
          setShowSignUp(true);
        })
        .catch((error) => {
          setErr("Please enter the correct 6 digit code.");
        });
    } catch (err) {
      console.log(err);
      setErr("Please enter the correct 6 digit code.");
    }
  };

  const handleFormat = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setNumber(formattedPhoneNumber);
    setErr("");
  };

  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setOtp(result);
  };

  useEffect(() => {
    getVisitorCountry();
  }, []);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter, resendOtp]);

  if (showSignUp) {
    const guestUserValue =
      searchParams.get("isGuest") === "true" ? true : false;
    return (
      <Signup
        number={actualNumber}
        firebaseUid={firebaseUid}
        countryCode={countryVal}
        isGuest={guestUserValue}
      />
    );
  }

  if (confirm?.verificationId) {
    setTimeout(() => {
      setFlag(true);
    }, 1000);
  }

  const navigateLogin = (event) => {
    localStorage.removeItem("pushToken");
    // localStorage.setItem("askedPermission", false);
    // dispatch(UpdateOnlineUser({ online: false })).then(() => {
    dispatch(UpdateMobileNavbarToggleState(false));
    dispatch(ResetState());
    persistor.purge();
    navigate("/login");
    // });
  };

  return (
    <>
      <div className="h-screen w-screen flex flex-col fixed items-center justify-start overflow-hidden fixed top-0 left-0">
        <div className="pt-6">
          <Logo />
        </div>

        <div className="w-full flex flex-col items-center justify-center p-4">
          {!flag ? (
            <p className="text-center mx-auto phone-label-legend !p-0">
              Enter your phone number
            </p>
          ) : (
            <p className="text-center mx-auto phone-label-legend">
              Enter code sent to <br />
              {"XXX XXX " + String(countryVal + number).slice(-4)}
            </p>
          )}

          <div className="pt-2 mx-auto max-w-screen-xl px-2 sm:px-6 lg:px-8">
            <form
              noValidate
              onSubmit={getOtp}
              className="flex flex-col items-center"
              style={{ display: !flag ? "block" : "none" }}
            >
              <div className="flex flex-row">
                <select
                  value={countryVal}
                  className="calling-code"
                  onChange={(event) => {
                    if (event.target.value) {
                      const val =
                        "+" + getCountryCallingCode(event.target.value);
                      setCountryVal(val);
                    }
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                >
                  <option key={0} value={countryVal}>
                    {countryVal}
                  </option>
                  {getCountries().map((country, index) => (
                    <option key={index + 1} value={country}>
                      {en[country]} +{getCountryCallingCode(country)}
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  value={number}
                  onChange={(e) => handleFormat(e)}
                  placeholder="XXX-XXX-XXXX"
                  className="border rounded-lg border-gray-200 text-sm placeholder-gray-300 shadow-sm focus:border-gray-200 pr-12 input-signup-number signup-input-phone-number-verification"
                />
              </div>

              <div className="pt-2">
                <label className="block mx-auto text-red-500 text-sm text-center signup-label-error-input">
                  {err}
                </label>
              </div>

              <button className="px-4 py-2 rounded signin-btn-sign-up">
                {!flag ? "Sign Up" : "Continue"}
              </button>

              <div className="signUp__container-mid-TC flex items-center pt-2">
                <input
                  className="signUp__mid-TC-input"
                  type="checkbox"
                  name="signUpTC"
                  id="signupTC"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked((prev) => !prev);
                  }}
                />
                <Terms />
              </div>

              <div className="pt-4">
                <label className="block mx-auto text-red-500 text-sm text-center signup-label-error-input">
                  {!isChecked && errTc}
                </label>
              </div>

              {checking && (
                <div className="verification_checking_validation items-center justify-center pt-2">
                  <img src={FavichatLoading} className="signin-img-Loading" />
                  <p
                    className="text-gray-800 text-xs text-center verification-error-label"
                    id="checking_message"
                  >
                    {" "}
                    {checkingMessage}{" "}
                  </p>
                </div>
              )}
            </form>
          </div>

          <div className="mx-auto max-w-screen-xl px-2 sm:px-6 lg:px-8">
            <form
              noValidate
              onSubmit={verifyOtp}
              className="flex flex-col items-center"
              style={{ display: flag ? "block" : "none" }}
            >
              <div className="flex flex-row ml-2">
                <input
                  type="otp"
                  placeholder="XXXXXX"
                  className="flex justify-center border w-full rounded-lg border-gray-200 p-4 placeholder-gray-300 shadow-sm focus:border-gray-200 text-center ::placeholder input-signup-number signup-input-phone-number-verification"
                  value={otp}
                  maxLength="6"
                  onChange={handleChange}
                />
              </div>

              <div id="recaptcha-container" />

              <div className="pt-2">
                <div className="block mx-auto text-red-500 text-sm text-center signup-label-error-input">
                  {err}
                </div>
              </div>

              <button className="px-4 py-2 rounded signin-btn-sign-up">
                {!flag ? "Sign Up" : "Continue"}
              </button>

              <div className="resend-otp-container">
                <button
                  onClick={() => {
                    setErr("");
                    setShowResendCode(true);
                    resendOtp();
                    setCounter(60);
                    setDisabled(true);
                  }}
                  className={
                    showResendCode === true
                      ? "disabled-resend-otp-verification"
                      : "resend-otp-verification"
                  }
                  disabled={showResendCode}
                >
                  Resend code
                </button>
              </div>
            </form>
          </div>

          <footer className="p-4 w-full sm:max-w-sm">
            <div className="flex items-center justify-center signup-text-account-verification pt-1">
              <p className="mr-2">Already have an account?</p>
              <div
                onClick={() => {
                  navigateLogin();
                }}
                className="cursor-pointer signup-link-account-verification"
              >
                <p>Sign In</p>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};
