import "../../../styles/Hearts.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Url, useAxios } from "../../../axios/authAPI";
import React, { useState, useEffect, useRef } from "react";
import { useAppBreakpoints } from "../../../hooks/useAppBreakpoints";
import BuyHeartPop from "../../../components/BuyHeartPop/BuyHeartPop";
import GuestToastBar from "../../../components/GuestComponents/Toast/Toast";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import FavichatLoading from "../../../assets/gif/Favichat1.gif";
import {
  UpdateSettingSidebarToggleState,
  UpdateMobileAppHeartState,
  UpdateUserHeartsState,
  UpdateHeartsOption,
  ResetNotUserState,
  UpdateIsGuestState,
  UpdateOnlineUser,
} from "../../../redux/actions/index";

const Hearts = () => {
  let api = useAxios();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useAppBreakpoints();
  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1
  || window.innerWidth < 995;
  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const mobileAppHeart = useSelector((state) => state.mobileAppHeart);
  const mobileAppSuccess = useSelector((state) => state.mobileAppSuccess);

  const [loader, setLoader] = useState(false);
  const [packages, setPackages] = useState([]);
  const [success, setSuccess] = useState(false);
  const [orderID, setOrderID] = useState(false);
  const [heartActive, setHeartActive] = useState(0);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [endOfScroll, setEndOfScroll] = useState(false);
  const [paypalButton, setPaypalButton] = useState(false);
  const [heartMessagePop, setHeartMessagePop] = useState(false);
  const [endOfLeftScroll, setEndOfLeftScroll] = useState(false);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [showMobileToast, setShowMobileToast] = useState(mobileAppHeart);
  const [showMobileSuccess, setShowMobileSuccess] = useState(mobileAppSuccess);

  let calculatedHearts = userData.hearts;

  const getHeartPackage = async () => {
    setLoader(true);
    api
      .get(`${Url}/heartPackages/list?limit=100&tag=hearts`)
      .then((res) => res.data)
      .then(
        (result) => {
          setLoader(false);
          setPackages(result?.data?.value);
          if (result?.data?.value.length > 0) {
            setHeartActive(result?.data?.value[0]);
          }
        },
        (error) => {
          setLoader(false);
        }
      );
  };

  useEffect(() => {
    showMobileToast === "mobileAppHeart"
      ? setIsDialogboxOpen(true)
      : setIsDialogboxOpen(false);
    getHeartPackage();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (id) => {
    setHeartActive(id);
    setPaypalButton(false);
  };

  const btnPaypal = () => {
    setHeartMessagePop(true);
  };

  const handleCloseDialog = () => {
    dispatch(UpdateMobileAppHeartState(null));
    setIsDialogboxOpen(false);
  };

  const slideRight = () => {
    const currentScrollLeft = ref.current.scrollLeft;
    const targetScrollLeft = currentScrollLeft + 400;
    const maxScrollLeft = ref.current.scrollWidth - ref.current.offsetWidth;

    const newScrollLeft = Math.min(targetScrollLeft, maxScrollLeft);

    ref.current.scrollTo({
      left: newScrollLeft,
      behavior: "smooth",
    });

    setEndOfScroll(newScrollLeft >= maxScrollLeft);
    setEndOfLeftScroll(false);
  };

  const slideLeft = () => {
    const currentScrollLeft = ref.current.scrollLeft;
    const targetScrollLeft = currentScrollLeft - 400;

    const newScrollLeft = Math.max(targetScrollLeft, 0);

    ref.current.scrollTo({
      left: newScrollLeft,
      behavior: "smooth",
    });

    setEndOfLeftScroll(newScrollLeft === 0);
    setEndOfScroll(false);
  };

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Add Hearts",
            amount: {
              currency_code: "USD",
              value: heartActive.pricePlan,
            },
          },
        ],

        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      apiAfterTransaction(details);
      setSuccess(true);
    });
  };

  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  const apiAfterTransaction = (transactionDetails) => {
    setLoader(true);
    api
      .post(`${Url}/transactions/create`, {
        amount: heartActive.pricePlan,
        heartPackageId: heartActive.id,
        userId: userData.userId,
        paymentMode:
          transactionDetails?.purchase_units[0]?.payments?.captures[0]?.status,
        transactionReferenceId:
          transactionDetails?.purchase_units[0]?.payments?.captures[0]?.id,
      })
      .then((response) => {
        if (response.status != 200) {
          setLoader(false);
        } else {
          setShowPopupSuccess(true);
          const hearts = userData.hearts;
          const addition = hearts + heartActive.noOfHearts;
          dispatch(UpdateUserHeartsState(addition));
          setLoader(false);
          updateHeartss(addition);
        }
        setPaypalButton(false);
      })
      .catch((err) => {
        setPaypalButton(false);
        setLoader(false);
      });
  };

  const updateHeartss = async (addition) => {
    dispatch(
      UpdateHeartsOption({ userId: userData.userId, hearts: addition })
    ).then(() => {
      dispatch(UpdateUserHeartsState(addition));
    });
  };

  const handleCloseButton = () => {
    setShowPopupSuccess(false);
  };

  const appDeepLinking = () => {
    const userAgent = navigator.userAgent;
    if (/android/i.test(userAgent)) {
      window.open("http://favifun", "_blank").focus();
    } else {
      window.open("com.favifun://deeplink", "_blank").focus();
    }
  };

  const data = ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5", "Item 6"];

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const dynamicStyles = {
    height: `${screenHeight - 72}px`,
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  return (
    <>
      {!(isGuest == true) ? (
        <div className="overflow-y-scroll" style={dynamicStyles}>
          {loader ? <div className="w-full h-3/4 flex justify-center items-center pt-80">
              <div className="flex justify-center items-center">
                <img src={FavichatLoading} className="signin-img-Loading mx-auto" />
              </div>
            </div> : <> {showPopupSuccess && showMobileSuccess == "mobileAppSuccess" && (
            <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-25 z-50">
              <div className="relative w-[90%] sm:w-5/6 h-48 sm:h-44 px-8 py-8 bg-white rounded-2xl flex flex-col items-center justify-center z-50 gap-4">
                <button
                  className="absolute top-3 left-3"
                  onClick={() => handleCloseButton()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>

                <p className="text-2xl font-extrabold text-[#656565]">
                  Purchase successful
                  {/* <span className="text-[#6eb5ed] font-medium">successful</span> */}
                </p>
                <div>
                  <span className="text-[#bab8b8] text-xl font-medium">
                    Continue on web browser or
                    </span>{" "}
                    <br></br>
                    <div className="flex justify-center">
                    <span
                    className="text-[#b6d7f5] font-semibold underline text-xl"
                    onClick={() => appDeepLinking()}
                  >
                    go back to the app
                  </span>
                    </div>
                 
                </div>
              </div>
            </div>
          )}

          <div
            className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
            onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
          >
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px]">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
            </div>
            <p className="text-[#494949] font-boboto font-bold title-profile">
              Hearts
            </p>
          </div>

          <p className="text-start text-[#494949] font-normal px-8 p-subtitle-hearts">
            Hearts can be used to unlock content
          </p>


          {!loader && (
            <>
              <div className="mx-auto card-header-hearts">
                <div className="bg-white border border-gray-300 rounded-2xl flex items-center justify-around mx-auto px-2 py-12 card-count-hearts">
                  <p className="font-normal text-[#494949] pl-5 card-balance-hearts">
                    Balance
                  </p>

                  <svg
                    className="icon-heart-hearts mx-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 2000 2000"
                    id="Heart"
                  >
                    <path
                      d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                      fill="#6eb5ed"
                      className="color000000 svgShape"
                    ></path>
                  </svg>

                  <span className="font-normal text-[#6eb5ed] pr-5 card-number-hearts">
                    {calculatedHearts}
                  </span>
                </div>
              </div>

              <div className="flex px-[30px] font-bold text-[#494949] pl-30 div-p-add-heart-hearts">
                <p className="p-add-heart-hearts">Add Hearts</p>
              </div>

              <p className="text-start text-[#494949] font-normal px-8 p-subtitle-hearts">
                Purchase more hearts to unlock content
              </p>

              <div className="relative px-8 overflow-hidden flex items-center justify-center">
                <div
                  className={`flex overflow-x-auto whitespace-nowrap space-x-1 ${
                    (isMobile || isIpad) ? "div-hearts" : ""
                  }`} ref={ref}>
                  {/* <div
                    className="flex overflow-x-auto whitespace-nowrap space-x-1"
                    ref={ref}
                  > */}
                    {(isMobile || isIpad) && (
                      <div
                        className={
                          endOfLeftScroll
                            ? "w-10 h-10 top-1/2 left-0 absolute transform -translate-y-1/2 text-center z-2 bg-blue-300  cursor-pointer scroll-smooth"
                            : "w-10 h-10 top-1/2 left-0 absolute transform -translate-y-1/2 text-center z-2 bg-blue-300  cursor-pointer scroll-behavior-smooth transition-all duration-300 ease-in-out hover:h-full"
                        }
                        onClick={slideLeft}
                      >
                        <div className="w-full h-full flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 48 48"
                            strokeWidth="5"
                            stroke="currentColor"
                            className={`w-4 bg-[#6eb5ed] ${
                              endOfLeftScroll ? " h-24" : "h-4"
                            } transition-height duration-300 ease-in-out`}
                            x="50%"
                            y="50%"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M30 12l-12 12 12 12"
                              transform="translate(1 1)"
                            />
                          </svg>
                        </div>
                      </div>
                    )}

                    {packages?.map((data) => (
                      <div
                        key={data.id}
                        onClick={(e) => handleClick(data)}
                        className={
                          heartActive.id === data.id
                            ? "cursor-pointer border rounded-lg hover-dad mr-1 flex-none cards-heart-hearts"
                            : "cursor-pointer rounded-lg hover-dad mr-1 flex-none"
                        }
                      >
                        {data.isRecommended && (
                          <p
                            className={
                              data.isRecommended
                                ? "mb-2 px-5 p-1 flex items-center justify-center uppercase font-medium rounded-t-md text-black text-center w-full hearts-recommended max-w-[75px]"
                                : "text-2xl font-extrabold tracking-normal text-[#6eb5ed] m-0"
                            }
                          >
                            recommended
                          </p>
                        )}

                        {data.mostPopular == false &&
                          data.isRecommended == false && (
                            <p className="mb-2 px-5 text-[.6rem] flex items-center justify-center uppercase font-medium w-full p-1 text-center text-white w-[75px]">
                              blank
                            </p>
                          )}

                        {data.mostPopular && (
                          <p
                            className={
                              data.mostPopular
                                ? "mb-2 px-5 flex items-center justify-center uppercase font-medium w-full p-1 text-center text-white rounded-t-md hearts-popular max-w-[75px]"
                                : "hidden font-extrabold tracking-normal text-[#6eb5ed] m-0"
                            }
                          >
                            popular
                          </p>
                        )}

                        {heartActive.id === data.id ? (
                          <svg
                            className="ul-list-icons-hearts mx-auto transition-all duration-100 ease-in-out hover-son icon-heart-hearts"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1900 1900"
                            id="Heart"
                          >
                            <path
                              d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                              fill="#6eb5ed"
                              className="color000000 svgShape"
                            ></path>
                          </svg>
                        ) : (
                          <svg
                            className="ul-list-icons-hearts mx-auto transition-all duration-100 ease-in-out hover-son icon-heart-hearts"
                            fill="currentColor"
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="-23.59 -23.59 518.88 518.88"
                            stroke="currentColor"
                            strokeWidth="15.566133000000002"
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g>
                                {" "}
                                <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        )}

                        <p className="px-2 tracking-wider text-base font-extrabold tracking-normal text-[#6eb5ed] m-0 text-center ul-list-p-hearts">
                          {data.noOfHearts}
                        </p>
                        <p className="px-2 text-center tracking-wider text-base font-extrabold tracking-normal text-[#6eb5ed] m-0 ul-list-p-hearts">
                          ${data.pricePlan}
                        </p>
                      </div>
                    ))}

                    {(isMobile || isIpad) && (
                      <div
                        className={
                          endOfScroll
                            ? "w-10 h-10 right-0 top-1/2 absolute transform -translate-y-1/2 text-center z-2  cursor-pointer scroll-smooth bg-blue-300"
                            : "w-10 h-10 right-0 top-1/2 absolute transform -translate-y-1/2 text-center z-2  cursor-pointer scroll-behavior-smooth transition-all duration-300 ease-in-out hover:h-full bg-blue-300"
                        }
                        onClick={slideRight}
                      >
                        <div className="w-full h-full flex items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 48 48"
                            strokeWidth="5"
                            stroke="currentColor"
                            className={`w-4 bg-[#6eb5ed] ${
                              endOfScroll ? " h-24" : "h-4"
                            } transition-height duration-300 ease-in-out`}
                            x="50%"
                            y="50%"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M18 12l12 12-12 12"
                              transform="translate(1 1)"
                            />
                          </svg>
                        </div>
                      </div>
                    )}
                  {/* </div> */}
                </div>
              </div>
            </>
          )}

          {!loader && (
            <>
              <div className="m-auto md:w-[100%] w-[100%] bottom-8 pb-8 pt-4">
                {paypalButton ? (
                  <PayPalScriptProvider
                    options={{
                      "client-id": `${process.env.REACT_APP_PAYPAL_ID}`,
                      currency: "USD",
                      "disable-funding": ["credit", "paylater"],
                    }}
                  >
                    <PayPalButtons
                      className="flex items-center justify-around rounded-lg mx-auto leading-5 btn-paypal-hearts"
                      style={{
                        layout: "horizontal",
                        color: "blue",
                        margin: "auto",
                      }}
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                    />
                  </PayPalScriptProvider>
                ) : (
                  <button
                    className="flex items-center justify-around w-fit-content mx-auto rounded-full btn-add-hearts"
                    onClick={() => {
                      btnPaypal();
                    }}
                  >
                    <svg
                      className="w-5 h-5 mx-auto"
                      fill="#ffff"
                      height="224px"
                      width="224px"
                      version="1.1"
                      id="Capa_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-23.59 -23.59 518.88 518.88"
                      stroke="currentColor"
                      strokeWidth="15.566133000000002"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>

                    <p>Add Hearts</p>
                  </button>
                )}
              </div>
            </>
          )}

          <GuestToastBar
            isOpened={isDialogboxOpen}
            handleClose={handleCloseDialog}
            isOnlyMsg={false}
            btnText={" go back to the app"}
            message={"Continue on web browser or"}
          />

          {heartMessagePop && (
            <BuyHeartPop
              hearts={heartActive?.noOfHearts}
              cost={heartActive?.pricePlan}
              setHeartMessagePop={setHeartMessagePop}
              setPaypalButton={setPaypalButton}
            />
            )}
          </>}
         
        </div>
      ) : (
        <div className="profile">
          <div
            className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
            onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
          >
            <div className="sm:hidden pt-[8px] px-6">
              <button type="submit" className="mr-[8px]">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Hearts;
