import React, { useEffect } from "react";
import "../../styles/InboxDummyPage.scss";
import { useDispatch, useSelector } from "react-redux";
import FaviNewLogo from "../../assets/icons/FaviNewLogo.svg";
import { UpdateInboxBarToggleState } from "../../redux/actions/index";

function InboxDummyPage() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UpdateInboxBarToggleState(true));
  }, []);

  return (
    <div className="inboxDummyPage bg-white">
      <div className="InboxDummyPage__container">
        <div className="InboxDummyPage__container-img">
          <img  src={FaviNewLogo} alt="" />
        </div>
        <div className="InboxDummyPage__container-title">
          <p className="text-xl text-blue">Find people to chat with on the Favichat home page.</p>
        </div>
      </div>
    </div>
  );
}
export default InboxDummyPage;
