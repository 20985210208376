import { Url } from "../../axios/authAPI";
import MessagePopup from "./MessagePopup";
import { useEffect, useState } from "react";
import { useAxios } from "../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import FavichatBig from "../../assets/gif/Favichat1.gif";
import FavichatLoader from "../../components/SkeletonLoader/FavichatLoader";

function ReportUser({ reportPopup, name, currentChatUserId, reasons }) {

  let api = useAxios();
  let status = 200

  const [list, setList] = useState("")
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [stateMessage, setStateMessage] = useState(false);
  const [errorMessage, setErrorMessage]= useState(false);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [submitReport, setSubmitReport] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");

  const userId = useSelector((state) => state.user.userId)

  function firstName(name) {
    let formatedName;
    formatedName = name;
    if (formatedName.length > 10) {
      formatedName = formatedName.slice(0, 10) + "...";
    }
    return formatedName.charAt(0).toUpperCase() + formatedName.slice(1);
  }

  useEffect(() => {
    if(selectedReason !== ""){
      setButtonEnable(true);
    }
  }, [ selectedReason]);

  useEffect(() => {
    if(submitReport ){
      setButtonEnable(false);
      setLoader(true);
    }
  }, [ submitReport]);

  useEffect(() => {
    if(selectedReason === ""){
      setButtonEnable(false);
    }
  }, [ selectedReason]);

  const handleSubmit = async (e)=> {
    if (selectedReason !== "") {
      setSubmitReport(true);
      e.preventDefault();
      await api
      .post(`${Url}/report-user/${userId}`, {
        uId: currentChatUserId,
        reasonId : selectedReason
      })
      .then((response) => {
        status = response.status;  // update status based on response
        if(status === 200){
          setLoader(false);
          setSelectedReason("")
          setMessage(<>You reported <span className="text-black">{firstName(name)}</span> successfully</>)
          setStateMessage(true)
        }
      })
      .catch((err) => {
        setLoader(false);
        setSelectedReason("")
        setMessage(<>Reporting <span className="text-black">{firstName(name)}</span> unsuccessfull</>)
        setStateMessage(true)
      });
    }else{
      setErrorMessage(true);
    }
  };

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-gray-500 bg-opacity-50 backdrop-filter backdrop-blur-lg z-50">
      <div className={selectedReason === "" ? "w-[500px] h-[158px] bg-white rounded-2xl shadow-lg" : "w-[500px] h-[190px] bg-white rounded-2xl shadow-lg"}>
        <div className="absolute justify-start p-3 px-2" >
          <button className="text-gray-500 hover:text-gray-800" onClick={() => reportPopup(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="px-6 py-0 mt-4">
          <h2 className="text-xl font-['Helvetica'] font-bold text-center">
            Report <span className="text-[#95c4fa]">{firstName(name)}</span>
          </h2>
          <form className="mt-4">
          <div className="mb-4">
            <div className="relative">
              <select
                className="appearance-none w-72 bg-white border border-gray-300 py-3 pl-6 pr-8 rounded-full leading-tight focus:outline-none focus:border-blue-500"
                value={selectedReason}
                onChange={(e) => {
                  setSelectedReason(e.target.value);
                }}
                >
                  <option value="">Select reason</option>
                    {reasons?.map((data) => (
                      <option key={data.id} value={data?.id}>
                        {data?.reason}
                      </option>
                    ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-5 text-gray-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                </svg>
              </div>
            </div>
          </div>
            <div className="flex justify-center pb-4">
                {buttonEnable && (
                <button
                className="w-[290px] py-2 text-[#58a1f5] text-lg font-bold bg-white border border-[#58a1f5] rounded-full transition-colors duration-300 hover:bg-[#58a1f5] hover:text-white"
                type="submit"
                onClick={handleSubmit}
                >Report</button>)}
                {loader && <div className="flex mt-0 justify-center pb-4">
                      <img
                        src={FavichatBig}
                        alt=""
                        className="save_ripple_loader"
                      />
                </div>}
            </div>
          </form>
        </div>
      </div>
      {stateMessage && <MessagePopup message={message} stateMessage={setStateMessage} reportPopup={reportPopup} />}
    </div>
  );
}
export default ReportUser;