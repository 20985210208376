import { useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import InterestUserPage from "../../components/DiscoverInterestPage/InterestUserPage";

const DiscoverInterestPage = () => {
  const location = useLocation();

  const pathNameDetails = location?.pathname.split("/");
  
  return (
    <div className="h-screen w-screen flex flex-col justify-start overflow-hidden fixed top-0 left-0">
      <Navbar />
      <InterestUserPage interestName={pathNameDetails[2]} id={pathNameDetails[3]}/>
    </div>
  );
};

export default DiscoverInterestPage;
