import React from "react";
import "../../styles/InfoPopUp.scss";
import { Url, useAxios } from "../../axios/authAPI";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdatePopUpWelcomeState, UpdateOnlineUser, ResetNotUserState, UpdateIsGuestState } from "../../redux/actions/index";
import { useEffect, useState } from "react";

const WelcomeFavichat = ({isMobile, isGuest }) => {

  let api = useAxios();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const [isWelcomePopupOpen, setIsWelcomePopupOpen] = useState(null);

  const close = () => {
    dispatch(UpdatePopUpWelcomeState(false));
    setIsWelcomePopupOpen(true);
    localStorage.setItem('isWelcomePopupOpen', true);
  };

  const guestUserLogout = () => {
    if (isGuest === true) {
      close();
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-60 backdrop-blur-[2px] transition-opacity"></div>
      <div
        className={`translate-y-0 w-full h-40 bg-white shadow-xl rounded-t-3xl transition-transform duration-300 ease-in-out`}
        style={{ border: '1px solid #8f8f8f'}}
      >
        <div className="flex justify-between items-center rounded-t-3xl">
          <button
            className="p-2 rounded-full transition-colors duration-300"
            onClick={() => close()}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 close-Drawer-LandingPage">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex justify-center h-full text-xl">
          <div className="text-center">
            {!isGuest && (<p className="mt-4 pb-6">
              Hi, 👋 welcome to Favichat! Chat with your favorite creators and friends.
            </p>)}
            {isGuest && 
            <p onClick={() => {
              dispatch(
                UpdateOnlineUser({
                  online: false,
                })
              ).then(() => {
                guestUserLogout();
              });
            }}
            className=" drawer-TextAccount-LandingPage pb-2">
                Create an account or Sign in
              </p>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default WelcomeFavichat;
