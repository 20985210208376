import { Link } from "react-router-dom";
import "../../../styles/PrivacyProfile.scss";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import privacyPdf from "../../../Document/Privacy.pdf";
import FavichatLoading from "../../../assets/gif/Favichat1.gif";

const PrivacyProfile = () => {

  const loader = () => {
    return <img src={FavichatLoading} className="signin-img-Loading" />
  }

  return (
    <div className="privacy-profile">

      <div className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]">
        <div className="sm:hidden pt-[8px]">
          <Link to="/settings/help">
            <button type="submit" className="mr-[8px]">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
              </svg>
            </button>
          </Link>
        </div>
        <h1 className="text-[#494949] text-[20px] font-boboto font-bold">Privacy Policy</h1>
      </div>

      <div>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
          <Viewer  renderLoader={loader} fileUrl={privacyPdf} />
        </Worker>
      </div>

    </div>
  );
};

export default PrivacyProfile;
