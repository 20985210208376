import "../../styles/InboxSidebar.scss";
import { FixedSizeList as List } from 'react-window';
import { useSelector, useDispatch } from 'react-redux';
import send_all from "../../assets/icons/send_all.png";
import { useNavigate, useLocation } from 'react-router-dom';
import loader from "../../assets/gif/Favichat1.gif";
import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { stopUnsubscribeConversations, ReadMessage, SearchConversations, UpdateSendAllToggleState, GetConversations, CleanCurrentChatState, UpdateCurrentConversationIdState, GetCurrentChatUserData, stopUnsubscribeCurrentChatUser, GetBlockUsers, GetBlockUsersToMe } from "../../redux/actions/index";

function useWindowSize() {

  const [size, setSize] = useState([0, 0]);
  const [isMobile, setIsMobile] = useState(false);

  const location = useLocation();

  useLayoutEffect(() => {

    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return [size, isMobile, location];
}

function InboxSidebar() {

  const isSafari = !!navigator.userAgent.match(/iPhone/i);

  const isItemLoaded = index => !!conversations[index];

  const chatList = useRef(null);
  const listRef = useRef();
  const intervalRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [size, isMobile, location] = useWindowSize();
  const [, windowHeight] = size;
  const isIpad = !!(navigator.userAgent.match(/(iPad)/) || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;

  const [textSearch, setTextSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
   const blockUsers = useSelector((state) => state.blockUsers);
  const isGuest = useSelector((state) => state.isGuest);
  const userId = useSelector((state) => state.user.userId);
  const username = useSelector((state) => state.user.userName);
  const all = useSelector((state) => state.allConversations);
  const statusScroll = useSelector((state) => state.statusScrollChatList);
  const conversationsUnsorted = useSelector((state) => state.conversations);
  const conversationsFilter = useSelector((state) => state.conversationsFilter);
  const currentConversationId = useSelector((state) => state.currentConversationId);

  const refurbishName = (conversation) => {
    try{
      let string
      if(conversation !== undefined && conversation.participantsInformation && conversation.participantsInformation.length > 0){
        const participant = conversation.participantsInformation.find((item) => item.userId === conversation.participants[0]);
        string = participant.name
      }
      var name;
      const formatedName = string
        ?.split(" ")
        .filter((element) => element !== null && element !== "");
      if (formatedName?.length > 2) {
        name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
      } else if (formatedName?.length > 1) {
        name = `${formatedName[0][0]}${formatedName[formatedName.length - 1][0]}`;
      } else {
        name = string?.slice(0, 1);
      }
      if(name){
        return name.toUpperCase();
      } else{
        return "";
      }
    } catch(error){
      console.log(error)
      return ""
    }
  }

  const UnReadNotification = ({conversation}) => {

    const indexRead = conversation.readByCounter.findIndex(
      (value) => value.userId === userId
    );

    if (indexRead === -1) {
      return <div className="w-2 h-2 rounded-full flex items-center justify-center" />
    }

    if (conversation.readByCounter.length !== 0 && (conversation.readByCounter[indexRead].userId !== conversation.participants[0] && (conversation.readByCounter[indexRead].unReadCounter !== 0 || (conversation.readByCounter[indexRead].unReadContentCounter !== 0 && conversation.readByCounter[indexRead].unReadContentCounter !== undefined)))) {
      return <div className="w-2 h-2 rounded-full bg-blue flex items-center justify-center" />
    } else {
      return <div className="w-2 h-2 rounded-full flex items-center justify-center" />
    }
  }

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  const cleanSearch = () => {
    setIsSearch(false);
    setTextSearch("");
    if (all.length !== 0 ) {
      dispatch(SearchConversations({
        search: "",
        conversations: all,
      }))
    }
  }

  const handleSearch = (e) => {
    setTextSearch(e.target.value);

    if (e.target.value !== "") {
      setIsSearch(true);
      dispatch(SearchConversations({
        search: e.target.value,
        conversations: all,
      }))
    } else {
      setIsSearch(false);
      cleanSearch();
    }
  }

  const BubblesMessage = ({conversation}) => {
    const indexRead = conversation.readByCounter.findIndex(
      (value) => value.userId === userId
    );

    if (indexRead === -1) {
      return null;
    }

    if (conversation.readByCounter.length !== 0 && (conversation.readByCounter[indexRead].userId !== conversation.participants[0] && conversation.readByCounter[indexRead].unReadContentCounter !== 0)) {

      const renderContent = (imageSrc) => {
        return Array.from({ length: conversation.readByCounter[indexRead].unReadContentCounter }).map((item, index) => {
          if (index < 3) {
            return imageSrc ?
              <img key={index} src={imageSrc} alt="Avatar" className={`${isIpad ? "w-5 h-5" : "w-6 h-6"} rounded-full object-cover ml-1`} /> :
              <div key={index} className='avatar-text-div-content-inbox-sidebar bubbles-avatar'>
                <span className='avatar-text-span-content-inbox-sidebar'>{refurbishName(conversation)}</span>
              </div>;
          } else {
            return null;
          }
        });
      }

      const imgSrc = image(conversation);
      return renderContent(imgSrc);

    } else {
      return null;
    }
  }

  const GifMessage = ({conversation}) => {

    const indexRead = conversation.readByCounter.findIndex(
      (value) => value.userId === userId
    );

    if (indexRead === -1) {
      return <span className="ml-1">Gif</span>
    }

    if (conversation.readByCounter.length !== 0 && (conversation.readByCounter[indexRead].userId !== conversation.participants[0] && (conversation.readByCounter[indexRead].unReadCounter !== 0 || (conversation.readByCounter[indexRead].unReadContentCounter !== 0 && conversation.readByCounter[indexRead].unReadContentCounter !== undefined)))) {
      const indexReceiver = conversation.aDeleted.findIndex(
      (value) => value.userId === userId
    );
      if (indexReceiver !== -1 && conversation?.aDeleted[indexReceiver]?.timestampMessage > conversation.lastMessage?.timestamp) {
      return ""
    }
      return <span className="ml-1 new-message-inbox-sidebar">Gif</span>
    } else {
      return <span className="ml-1">Gif</span>
    }
  }

  const VideoMessage = ({conversation}) => {

    const indexRead = conversation.readByCounter.findIndex(
      (value) => value.userId === userId
    );

    if (indexRead === -1) {
      return <span className="ml-1">Video</span>
    }

    if (conversation.readByCounter.length !== 0 && (conversation.readByCounter[indexRead].userId !== conversation.participants[0] && (conversation.readByCounter[indexRead].unReadCounter !== 0 || (conversation.readByCounter[indexRead].unReadContentCounter !== 0 && conversation.readByCounter[indexRead].unReadContentCounter !== undefined)))) {
      const indexReceiver = conversation.aDeleted.findIndex(
      (value) => value.userId === userId
    );
      if (indexReceiver !== -1 && conversation?.aDeleted[indexReceiver]?.timestampMessage > conversation.lastMessage?.timestamp) {
      return ""
    }
      return <span className="ml-1 new-message-inbox-sidebar">Video</span>
    } else {
      return <span className="ml-1">Video</span>
    }
  }

  const PhotoMessage = ({conversation}) => {

    const indexRead = conversation.readByCounter.findIndex(
      (value) => value.userId === userId
    );

    if (indexRead === -1) {
      return <span className="ml-1">Photo</span>
    }

    if (conversation.readByCounter.length !== 0 && (conversation.readByCounter[indexRead].userId !== conversation.participants[0] && (conversation.readByCounter[indexRead].unReadCounter !== 0 || (conversation.readByCounter[indexRead].unReadContentCounter !== 0 && conversation.readByCounter[indexRead].unReadContentCounter !== undefined)))) {
      const indexReceiver = conversation.aDeleted.findIndex(
      (value) => value.userId === userId
    );
      if (indexReceiver !== -1 && conversation?.aDeleted[indexReceiver]?.timestampMessage > conversation.lastMessage?.timestamp) {
      return ""
    }
      return <span className="ml-1 new-message-inbox-sidebar">Photo</span>
    } else {
      return <span className="ml-1">Photo</span>
    }
  }

  const ContentMessage = ({conversation}) => {

    const indexRead = conversation.readByCounter.findIndex(
      (value) => value.userId === userId
    );

    if (indexRead === -1) {
      return <span className="">{conversation.lastMessage.content}</span>
    }

    if (conversation.readByCounter.length !== 0 && (conversation.readByCounter[indexRead].userId !== conversation.participants[0] && (conversation.readByCounter[indexRead].unReadCounter !== 0 || (conversation.readByCounter[indexRead].unReadContentCounter !== 0 && conversation.readByCounter[indexRead].unReadContentCounter !== undefined)))) {
      return <span className="new-message-inbox-sidebar">{conversation.lastMessage.content}</span>
    } else {
      const indexReceiver = conversation.aDeleted.findIndex(
      (value) => value.userId === userId
      );
      
      if (indexReceiver !== -1 && conversation?.aDeleted[indexReceiver]?.timestampMessage > conversation.lastMessage?.timestamp) {
      return ""
    }
        return <span className="">{conversation.lastMessage.content}</span>
    }
  }

  const image = (conversation) => {
    if(conversation !== undefined && conversation.participantsInformation && conversation.participantsInformation.length > 0){
      const picture = conversation.participantsInformation.find((item) => item.userId === conversation.participants[0]);
      if(picture !== undefined && picture.photoUrl !== ""){
        return conversation.participantsInformation.find((item) => item.userId === conversation.participants[0]).photoUrl
      }
    } 
    return null
  }

  const ChatTimestamp = React.memo(({ timestamp, userId }) => {
    const timeRef = useRef(null);

    const typeMessageSend = (timestamp) => {
      const currentDate = new Date();
      let messageDate;

      const getReadByCounterFollower = timestamp.readByCounter.findIndex((index) => index.userId !== userId);
      const getReadByCounterUser = timestamp.readByCounter.findIndex((index) => index.userId === userId);

      if (timestamp.readByCounter.length !== 0) {
        if(getReadByCounterUser !== -1 && timestamp.lastMessage.senderId === userId && timestamp.readByCounter[getReadByCounterUser].lastProvisionalTimestamp !== "") {
          messageDate = new Date(timestamp.readByCounter[getReadByCounterUser].lastProvisionalTimestamp * 1000);
        } else if (getReadByCounterFollower !== -1 && timestamp.lastMessage.senderId === userId && timestamp.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp !== "") {
          messageDate = new Date(timestamp.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp * 1000);
        } else if (timestamp.lastMessage.sendAllTimestamp !== "") {
          messageDate = new Date(timestamp.lastMessage.sendAllTimestamp * 1000);
        } else {
          messageDate = new Date(timestamp.lastMessage.timestamp * 1000);
        }

        const timeDifference = currentDate - messageDate;
        const minutesAgo = Math.floor(timeDifference / (1000 * 60));
        const hoursAgo = Math.floor(timeDifference / (1000 * 60 * 60));
        const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const monthsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const yearsAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30 * 12));
        
        if (minutesAgo < 3) {
          return 'Just now';
        } else if (minutesAgo < 60) {
          return `${minutesAgo} minute${minutesAgo === 1 ? '' : 's'}`;
        } else if (hoursAgo < 24) {
          return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'}`;
        } else if (daysAgo < 30){
          return `${daysAgo} day${daysAgo === 1 ? '' : 's'}`;
        } else if (monthsAgo < 12){
          return `${monthsAgo} month${monthsAgo === 1 ? '' : 's'}`;
        } else {
          return `${yearsAgo} year${yearsAgo === 1 ? '' : 's'}`;
        }
      }
    };

    useEffect(() => {
      const updateTimestamp = () => {
        if (timeRef.current) {
          timeRef.current.textContent = typeMessageSend(timestamp);
        }
      };

      const interval = setInterval(updateTimestamp, 30000);

      updateTimestamp();

      return () => clearInterval(interval);
    }, [timestamp]);

    return (
      <span ref={timeRef}></span>
    );
  });

  const usernameParticipant = (conversation) => {
    if (conversation.participantsInformation && conversation.participantsInformation.length > 0) {
      const participant = conversation.participantsInformation.find((item) => item.userId === conversation.participants[0]);
      if (participant !== undefined && participant.isGuest !== undefined && participant.name !== undefined) {
        if (participant.isGuest || participant.name === "Guest" || participant.name.includes("guest-")) {
          const guest = participant.username.split("-").pop();
          const truncatedName = `Guest ${guest.slice(-5)}`;
          return truncatedName.length > 20 ? truncatedName.slice(0, 20) + "..." : truncatedName;
        }
        const truncatedName = participant.name;
        return truncatedName.length > 20 ? truncatedName.slice(0, 20) + "..." : truncatedName;
      }
    }
    return null;
  }

  const handleClick = (conversationId, userId, conversation) => {

    if (currentConversationId !== conversationId || location.pathname === "/inbox/start") {
      setIsSearch(false);
      cleanSearch();
      dispatch(CleanCurrentChatState());
      dispatch(UpdateCurrentConversationIdState(conversationId));
      dispatch(stopUnsubscribeCurrentChatUser());
      dispatch(GetCurrentChatUserData(userId));
      dispatch(ReadMessage(conversationId));

      navigate(`/inbox/${userId}`);

      // intervalRef.current = setInterval(() => {
      //   const userIndex = conversationsUnsorted.findIndex(u => u.conversationId === conversationId);
      //   if (userIndex !== -1 && listRef.current) {
      //     listRef.current.scrollToItem(userIndex, 'end');
      //   }

      //   if (window.location.pathname === `/inbox/${userId}`) {
      //     clearInterval(intervalRef.current);
      //   }
      // }, 250);

    }
  };

  // useEffect(() => {
  //   return () => {
  //     if (intervalRef.current) {
  //       clearInterval(intervalRef.current);
  //     }
  //   };
  // }, []);

  const loadMoreItems = ({scrollOffset}) => {
    const visibleHeight = screenHeight - 120;
    const totalScrollHeight = conversations.length * 65;

    if (scrollOffset >= totalScrollHeight - visibleHeight) {
      // dispatch(stopUnsubscribeConversations()).then(() => {
        dispatch(GetConversations({
          userId: userId,
          userName: username
        }));
      // })
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    dispatch(GetBlockUsersToMe())

    return () => {
      const mobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

      if (mobile) {
        setTimeout(() => {
          setIsSearch(false);
          cleanSearch();
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    if(statusScroll === "send") {
      chatList.current.scrollTop = 0;
      dispatch(GetConversations({
        userId: userId,
        userName: username,
        new: true,
      }));
    }
  }, [statusScroll])

  useEffect(() => {

    let aConversations;

    if (isSearch) {
      aConversations = conversationsFilter;
    } else {
      aConversations = conversationsUnsorted;
    }

    const filteredConversations = aConversations.filter(conversation => {
      const indexReceiver = conversation.aDeleted.findIndex(
      (value) => value.userId === userId)
      if (!conversation.aDeleted || conversation.aDeleted.length === 0) {
        if (blockUsers.some((obj) => obj.userId === conversation?.participants[0])) {
          return true
        } else if (conversation?.lastMessage?.type == 0 && conversation?.lastMessage?.content !== "" || conversation?.lastMessage?.type == 1 || conversation?.lastMessage?.type == 2 || conversation?.lastMessage?.type == 3)  {
          return true
        }
        return false;
      } else {
        if (blockUsers.some((obj) => obj.userId === conversation?.participants[0]) && conversation?.aDeleted[indexReceiver]?.timestampMessage < conversation.lastMessage?.timestamp) {
          return true
        }else if (((conversation?.lastMessage?.type == 0 && conversation?.lastMessage?.content !== "") || conversation?.lastMessage?.type == 1 || conversation?.lastMessage?.type == 2 || conversation?.lastMessage?.type == 3) && conversation?.aDeleted[indexReceiver]?.timestampMessage > conversation.lastMessage?.timestamp) {
          return true
        } 
        return !conversation.aDeleted.some(deletedMessage => (
        deletedMessage.isDeleted === true && deletedMessage.userId === userId
      ));
    }     
    });

    filteredConversations.sort((a, b) => {
      const getTimestamp = (obj) => {

        const getReadByCounterFollower = obj.readByCounter.findIndex((index) => index.userId !== userId);
        const getReadByCounterUser = obj.readByCounter.findIndex((index) => index.userId === userId);
        if (obj.readByCounter.length !== 0) {
          if (getReadByCounterUser !== -1 && obj.lastMessage.senderId === userId && obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp !== "") {
            return obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp;
          } else if (getReadByCounterFollower !== -1 && obj.lastMessage.senderId === userId && obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp !== "") {
            return obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp;
          } else if (obj.lastMessage.sendAllTimestamp !== "") {
            return obj.lastMessage.sendAllTimestamp;
          } else {
            return obj.lastMessage.timestamp;
          }
        } else {
          return obj.lastMessage.timestamp; 
        }
      };

      const timestampA = getTimestamp(a);
      const timestampB = getTimestamp(b);

      return timestampB - timestampA;
    });

    setConversations(filteredConversations);
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, [conversationsUnsorted, conversationsFilter,blockUsers]);

  // const dynamicStyles = {
  //   height: `${screenHeight - 120}px`,
  // };

  function Row({ index, style }) {
    const conversation = conversations[index];
    return (
      <div
        style={{ ...style, borderBottom: "1px solid #d0e7f8" }}
        className={`flex items-center p-3 ${isIpad ? "pl-1" : ""} shadow-sm space-x-3 cursor-pointer ${currentConversationId === conversation.conversationId && location.pathname !== "/inbox/start" ? 'bg-[#99cef69c]' : 'bg-white'}`}
        key={conversation.conversationId}
        onClick={() => handleClick(conversation.conversationId, conversation.participants[0], conversation)}
      >
        <div className="flex-shrink-0 w-[6px]">
          <UnReadNotification conversation={conversation} />
        </div>
        <div className="flex-shrink-0">
          {(image(conversation) !== null) ? (
            <img src={ image(conversation) } alt="Avatar" className="w-10 h-10 rounded-full object-cover" />
          ) : (
              conversation.participantsInformation.length > 0 ?
            <div className='avatar-text-div-inbox-sidebar'>
              <span className='avatar-text-span-inbox-sidebar'>{refurbishName(conversation)}</span>
            </div> : <div className="animate-pulse bg-gray-200 h-10 w-10 rounded-full"></div>
          )}
        </div>
        <div className="flex flex-col flex-1 truncate">
            {conversation.participantsInformation.length > 0 ? <div className="flex items-center justify-between"> <span className="font-normal text-sm text-gray-900 chat-name">{
              usernameParticipant(conversation)}</span>
            <span className="text-xs text-gray-500"><ChatTimestamp timestamp={conversation} userId={userId} /></span> </div> :
           <div className="flex items-center justify-between"><div className="animate-pulse bg-gray-200 w-32 h-3 chat-name"></div></div>
              }  
          <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500 w-1/2">
              {conversation.participantsInformation.length > 0 ? conversation.lastMessage.type === 0 || conversation.lastMessage.content !== "" 
                ?
                <div className='min-w-0 truncate'>
                  <ContentMessage conversation={conversation} />
                </div>
                : conversation.lastMessage.type === 1
                  ?
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
                      </svg>
                      <PhotoMessage conversation={conversation} />
                    </div>
                  : conversation.lastMessage.type === 2
                  ?
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path d="M4.5 4.5a3 3 0 00-3 3v9a3 3 0 003 3h8.25a3 3 0 003-3v-9a3 3 0 00-3-3H4.5zM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06z" />
                      </svg>
                      <VideoMessage conversation={conversation} />
                  </div>
                  :
                    <div className="flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z" clipRule="evenodd" />
                      </svg>
                      <GifMessage conversation={conversation} />
                    </div> : <div className="animate-pulse bg-gray-200 w-32 h-3 mt-2"></div>
              }
            </span>
            <div className="flex items-center justify-between">
              <BubblesMessage conversation={conversation} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`flex flex-col overflow-hidden ${isSafari ? "" : "h-screen"}`} style={{ height: `${isSafari ? "-webkit-fill-available" : `${windowHeight - 64}px`}`}}>
        
        <div className="flex items-center bg-[#F3F3F3]">
          <input type="text" placeholder="Search" className="p-4 pl-14 pr-3 bg-[#F3F3F3] shadow-sm w-full focus:outline-none focus:ring-1 focus:ring-gray-100" onChange={handleSearch} value={textSearch}/>
          <svg
            className="absolute top-0 left-0 m-4 h-5 w-5 text-gray-400 cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={cleanSearch}
          >
            <path
              fillRule="evenodd"
              d="M13.414 12l3.293 3.293a1 1 0 01-1.414 1.414L12 13.414l-3.293 3.293a1 1 0 01-1.414-1.414L10.586 12l-3.293-3.293a1 1 0 111.414-1.414L12 10.586l3.293-3.293a1 1 0 111.414 1.414L13.414 12z"
              clipRule="evenodd"
            />
          </svg>
          <button className="hover:bg-gray-200 py-2 px-2 rounded-full " type="submit" aria-label="search">
            <svg className="w-6 h-6"  stroke="lightgray" viewBox="0 0 24 24">
              <path strokeWidth=".5" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
            </svg>
          </button>
        </div>
        {isLoading === true ? (
            <div className="flex flex-col my-52">
              <img className="w-6 h-6 m-auto" src={loader} sx="[object Object]"  alt="Icon message"></img>
              <p className="text-black font-sans text-sm font-bold text-center"> Loading chats</p>
          </div>
          ) :
          conversations.length === 0 ? (
            <div className="flex flex-col my-52">
              <img className="w-13 h-13 m-auto" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKFSURBVHgB7dzZTtxAEIXhgqxDAslV3v/9IpSEsO8+wiMNEuDu6uqhPP4/qcWNRzPycS9ulzEDAAAAAAAA5mHP4nwY2ufx75LcbrRmEYEohMPx75LdDO1saBfWoDUQBfHdsOl0aP/NqWV4IYyXaaTQhX5tDt5AVkM7MrxGoSiQO6u0bz7fDFMOzcETyKex4W2uFacnkKUta1tUrzw/Wr2Sz4SsyWdg6lxUX7yeQKZoIvtty/DLgkcM76SOTggkGQJJhkCSIZBkCCQZAkmGQJIhkGQIJBkCSabHXpaelv20WFfW+Kzanh6qfbFY4Rd0j0D0I1cW68HaA9FWePTvCseQlQyBJEMgycwlkIjf2WO+DDeXQCKqImdRmDGnHtISyrp4Lb0e3fh+aJdWRyf868QxqnM6Np+Soj795nuro2V0aNA9AtE9wz+rN1UwoKtcBXpnVkdBTg1XKsz4Y/V0o7mzRQ7nBcfoSq+pmjywsvrjku/eimyBPBQcdzS2t67MvfGYH1amdRcgTKaloMZvlfKX1MSql2jOUUGzxn4V5ilMDU0a2tQzSsd2vTpQXRTdS7a1uXqJTmbJuKxjVta2P6UgauekrrIte9VLNLmWDF0Rjrf4XUUy3ofo1TDPKq3WX0s0VK1lvTHUJHti/ZxYool8k+emRstI18soC6QFw2nNBzw9JF03T6z6tQxPIFeGUjeVx7sC0UrI9YbpwujC3dpLnz0n3F3hWil6N8bUS7R+j67i2BWazF1De8tOpcZHQnlO50NhuO/+I/byFaqWwge2XApC2z4KomkVGv0UTRt7+zaTp3NB1v8JKNUWDAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAeAePGt9VpVpCy4UAAAAASUVORK5CYII=" sx="[object Object]"  alt="Icon message"></img>
              <p className="text-black font-sans text-base font-bold text-center">
                {isSearch === true ? (
                  "No results found."
                ) : (
                  "Your inbox is empty."
                  )}
                  </p>
              <p className="text-black font-sans text-base font-normal text-center"> Find people to chat with on Favichat home page.</p>
            </div>
          ) : (
            <List
              ref={listRef}
              height={screenHeight - 120}
              itemCount={conversations.length}
              itemSize={65}
              width="100%"
              onScroll={loadMoreItems}
            >
              {Row}
            </List>
          )}

          {isGuest === false && (
            (/iPhone/.test(navigator.userAgent) && !window.MSStream) ? (
              <button className="w-12 h-12 fixed right-8 bottom-10 rounded-full shadow-lg flex items-center justify-center btn-send-all" onClick={(e) => {dispatch(UpdateSendAllToggleState(true)); }}>
                <img src={send_all} alt="" className="send-all-icon"/>
              </button>
            ) : (
              isIpad ? (
              <button className="w-12 h-12 absolute right-6 bottom-16 rounded-full shadow-lg flex items-center justify-center btn-send-all" onClick={(e) => {dispatch(UpdateSendAllToggleState(true)); }}>
                <img src={send_all} alt="" className="send-all-icon"/>
              </button>
              ) : (
              <button className="w-12 h-12 absolute right-8 bottom-16 sm:right-4 sm:bottom-8 rounded-full shadow-lg flex items-center justify-center btn-send-all" onClick={(e) => {dispatch(UpdateSendAllToggleState(true)); }}>
                <img src={send_all} alt="" className="send-all-icon"/>
              </button>
              )
          ))}
        {/* </div> */}

      </div>
    </>
  );
}

export default InboxSidebar;
