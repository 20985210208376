import * as Yup from "yup";
import "../../../styles/Profile.scss";
import { useForm } from "react-hook-form";
import "../../../styles/InboxChatbox.scss";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { signInWithPhoneNumber } from "firebase/auth";
import { yupResolver } from "@hookform/resolvers/yup";
import { Url, useAxios } from "../../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import Favichat from "../../../assets/gif/Favichat.gif";
import en from "react-phone-number-input/locale/en.json";
import { storage } from "../../../Config/configurations";
import { auth, db } from "../../../Config/configurations";
import userImage from "../../../assets/icons/useIcon.png";
import setUpReCaptcha from "../../../_helpers/otpFirebase";
import FavichatBig from "../../../assets/gif/Favichat1.gif";
import React, { useCallback, useEffect, useState } from "react";
import { formatPhoneNumber } from "../../../_helpers/formatPhoneNumber";
import { GradientSignup } from "../../../components/Button/GradientButton";
import GuestToastBar from "../../../components/GuestComponents/Toast/Toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import FavichatLoader from "../../../components/SkeletonLoader/FavichatLoader";
import {
  getCountryCallingCode,
  getCountries,
} from "react-phone-number-input/input";
import {
  UpdateSettingSidebarToggleState,
  UpdateUserProfilePicConversation,
  UpdatePhoneNumberUser,
  UpdateUserProfile,
  UpdateDataUserProfilePicState,
  ResetNotUserState,
  UpdateIsGuestState,
  UpdateOnlineUser,
} from "../../../redux/actions/index";
import useAppBreakpoints from "../../../hooks/useAppBreakpoints";
export const Profile = () => {
  let api = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);

  const [otp, setOtp] = useState("");
  const [err, setErr] = useState("");

  const [number, setNumber] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [counter, setCounter] = useState(0);
  const [message, setMessage] = useState("");
  const [country, setCountry] = useState("");
  const [confirm, setConfirm] = useState("");
  const [picture, setPicture] = useState("");
  const [progress, setProgress] = useState(0);
  const [muiLoad, setMuiLoad] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [generate, setGenerate] = useState(true);
  const [checking, setChecking] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [countryVal, setCountryVal] = useState("");
  const [aniLoader, setAniLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [actualNumber, setActualNumber] = useState("");
  const [userIcons, setuserIcons] = useState(userImage);
  const [otpAniLoader, setOtpAniLoader] = useState(false);
  const [showResendCode, setShowResendCode] = useState(true);
  const [checkingMessage, setCheckingMessage] = useState("");
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [exsistingData, setExsistingData] = useState();
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [newNumber, setNewNumber] = useState();
  const { isMobile } = useAppBreakpoints();

  const [profilePicture, setProfilePic] = useState();

  const copied = `${window.location.origin}/go/${
    exsistingData === undefined ? "" : exsistingData.userName
  }`;

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("What's your name?")
      .min(2, "Your preferred name between 2 and 60 characters")
      .max(60, "Your preferred name between 2 and 60 characters")
      .matches("^[A-Za-z\\s]+$", "Use alphabetical characters"),
    userName: Yup.string()
      .required("What's your username?")
      .min(2, "Type a username between 2 and 30 characters")
      .max(30, "Type a username between 2 and 30 characters")
      .matches(
        "^[a-zA-Z0-9_.]{2,}$",
        "Username should not contain any special characters other than '.' and '_'"
      ),
    bio: Yup.string().max(300, "You have exceeded the character limit"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onBlur",
    resolver: yupResolver(formSchema),
  });

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    api
      .get(`${Url}/users/${userData.userId}?loggedInUserId=${userData.userId}`)
      .then((res) => {
        setExsistingData(userData);
        setProfilePic(
          userData.profilePicUrl === "" || userData.profilePicUrl === null
            ? userData.profilePicUrl === null
              ? ""
              : userData.profilePicUrl
            : userData.profilePicUrl
        );
        setPageLoad(false);
      })
      .catch((err) => {
        setPageLoad(false);
      });
  }, []);

  const updateProfilePic = () => {
    dispatch(
      UpdateUserProfilePicConversation({
        userId: exsistingData.userId,
        username: exsistingData.userName,
        profilePicUrl: exsistingData?.profilePicUrl,
        name: exsistingData?.name,
      })
    );
  };

  const onSubmitData = () => {
    setAniLoader(true);

    const payloadProfile = exsistingData;
    payloadProfile["profilePic"] = exsistingData.profilePicUrl;

    api
      .put(`${Url}/users/${userData.userId}`, payloadProfile)
      .then((res) => {
        const user = res?.data?.data;

        dispatch(
          UpdateUserProfilePicConversation({
            userId: user.id,
            username: user.userName,
            profilePicUrl: user.profilePic,
            name: user.name,
            bio: user.bio,
          })
        );

        dispatch(
          UpdateUserProfile({
            bio: user.bio,
            name: user.name,
            userName: user.userName,
          })
        ).then(() => {
          dispatch(UpdateDataUserProfilePicState(user.profilePic));
          setMessage("Updated!");
          setDisabledSave(true);
          setIsDialogboxOpen(true);
          setAniLoader(false);
        });
      })
      .catch((err) => {
        console.log(err?.response?.data?.code);
        setAniLoader(false);
        setErr(err?.response?.data?.error?.message);
      });
  };

  const handleEvent = (e) => {
    setErr();
    setExsistingData({
      ...exsistingData,
      profilePicUrl: profilePicture,
      [e.target.name]: e.target.value,
    });
    setDisabledSave(false);
  };

  const uploadFiles = (file) => {
    if (!file) return;
    setDisabledSave(true);
    const sotrageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setProfilePic(downloadURL);
          setExsistingData({
            ...exsistingData,
            profilePicUrl: downloadURL,
          });
          setMuiLoad(false);
          setDisabledSave(false);
        });
      }
    );
  };

  const onChangePicture = (e) => {
    const reader = new FileReader();
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const slice = file.slice(0, 4);
      reader.readAsArrayBuffer(slice);
      reader.onloadend = function () {
        const buffer = reader.result;
        const view = new DataView(buffer);
        let signature = "";

        for (let i = 0; i < 4; i++) {
          signature += String.fromCharCode(view.getUint8(i));
        }

        if (
          signature === "\x89PNG" ||
          signature === "\xFF\xD8\xFF\xE0" ||
          signature === "\xFF\xD8\xFF\xE1" ||
          (signature >= "\xFF\xD8\xFF\xE0" && signature <= "\xFF\xD8\xFF\xEF")
        ) {
          setMuiLoad(true);
          setPicture(e.target.files[0]);
          uploadFiles(file);
        } else {
          setIsDialogboxOpen(true);
          setMessage("Oops! Image only 😁");
        }
      };
    }
  };

  const handleCloseDialog = () => {
    setIsDialogboxOpen(false);
  };

  const handleOpenDialog = () => {
    setMessage(`Copied link`);

    setIsDialogboxOpen(true);
  };

  const getVisitorCountry = () => {
    setCountryVal("+1");
    return new Promise((resolve, reject) => {
      window
        .fetch("https://ip2c.org/self")
        .then((response) => response.text())
        .then((data) => {
          const [status, country] = String(data).split(";");
          if (status !== "1") {
            setCountryVal("+1");
            throw new Error("Unable to fetch country");
          }
          let val = "+" + getCountryCallingCode(country);
          setCountryVal(val);
          resolve(country);
        })
        .catch(() => {
          setCountryVal("+1");
          resolve("US");
        });
    });
  };

  const getOtp = async (e) => {
    e.preventDefault();

    setOtpErr("");
    setCheckingMessage("Validating phone number");

    setOtpAniLoader(true);

    let str = countryVal + number;
    let jsonPhoneNumber = str.replace(/[- )(]/g, "");
    setActualNumber(str.replace(/[- )(]/g, ""));

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumber: jsonPhoneNumber,
      }),
    };

    try {
      if (number === "" || number === undefined)
        return setOtpErr("Type your phone number");
      setChecking(true);
      const response = await fetch(
        `${Url}/users/phone/available`,
        requestOptions
      );
      const responseData = await response.json();
      if (!responseData?.success) {
        setChecking(false);
        setCheckingMessage("");
        if (responseData?.error?.message.includes("Invalid")) {
          setOtpAniLoader(false);
          setOtpErr("Hmm... Invalid phone number");
        } else {
          setOtpAniLoader(false);
          setOtpErr(responseData?.error?.message);
        }
      }

      if (responseData?.success) {
        //setNumber("");
        setChecking(true);
        setCheckingMessage("Generating captcha...");
        setCounter(60);
        setUpReCaptcha();
        let appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, jsonPhoneNumber, appVerifier)
          .then((result) => {
            setChecking(false);
            setCheckingMessage("");
            setConfirm(result);
            setOtpAniLoader(false);
            setShowResendCode(false);
          })
          .catch((err) => {
            console.log(err)
            appVerifier.recaptcha.reset();
            appVerifier.clear();
            setOtpAniLoader(false);
            setOtpErr("Please try again later");
            setChecking(false);
            setCheckingMessage("");
          });
      }
    } catch (error) {
      setOtpAniLoader(false);
    }
  };

  const handleFormat = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setNumber(formattedPhoneNumber);
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    try {
      setErr("");

      if (otp === "" || otp === null) {
        return setOtpErr("Please enter the 6 digit code");
      }
      if (otp.length !== 6) {
        return setOtpErr("Please enter the 6 digit code");
      }
      if (confirm?.verificationId) {
        try {
          const response = await confirm.confirm(otp);
          if (response?.user) {
            setAniLoader(true);
            exsistingData.phoneNumber = actualNumber;
            exsistingData.countryCode = countryVal;
            exsistingData.profilePic = exsistingData.profilePicUrl;
            api
              .put(`${Url}/users/${userData.userId}`, exsistingData)
              .then((res) => {
                const user = res?.data?.data;

                dispatch(
                  UpdatePhoneNumberUser({
                    phoneNumber: user.phoneNumber,
                  })
                );

                updateProfilePic();

                if (res?.data?.success) {
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 2000);
                }
              })
              .catch((err) => {
                setAniLoader(false);
                setOtpErr(err?.response?.data?.error?.message);
              });
          }
        } catch (err) {
          setAniLoader(false);
          setOtpErr("Please enter the correct 6 digit code");
        }
      }
    } catch (err) {
      setAniLoader(false);

      setOtpErr("Please enter the correct 6 digit code");
    }
  };

  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");

    setOtp(result);
  };

  const resendOtp = async () => {
    signInWithPhoneNumber(auth, actualNumber, window.recaptchaVerifier)
      .then((result) => {
        setConfirm(result);
        setShowResendCode(false);
      })
      .catch((err) => {
        setErr("Please try again after some time !!");
      });
  };
  useEffect(() => {
    if (confirm?.verificationId) {
      setTimeout(() => {
        setGenerate(false);
      }, 1000);
    }
  }, [confirm]);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    getVisitorCountry();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsDialogboxOpen(false);
    }, 5000);
  }, [isDialogboxOpen]);

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const dynamicStyles = {
    height: `${screenHeight - 72}px`,
  };

  const handleChangeMobileNo = (event) => {
    setUpReCaptcha();
    getVisitorCountry();
    setShowModal(true);
  };

  return (
    <>
      {!(isGuest === true) ? (
        <div className="profile" style={dynamicStyles}>
          <div
            className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
            onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
          >
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px]">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
            </div>
            <p className="text-[#494949] font-boboto font-bold title-profile">
              Profile
            </p>
          </div>

          <GuestToastBar
            isOpened={isDialogboxOpen}
            handleClose={handleCloseDialog}
            message={message}
            isOnlyMsg={true}
          />

          <div className="md:w-[60%] w-[80%] mx-auto">
            {pageLoad ? (
              <FavichatLoader />
            ) : (
              <>
                <div className="mb-[1rem]">
                  <div className="flex items-center justify-center relative">
                    <div className="relative">
                      <label htmlFor="file-input">
                        <img
                          src={
                            profilePicture === "" ||
                            profilePicture === undefined
                              ? userImage
                              : profilePicture
                          }
                          className={
                            profilePicture === "" ||
                            profilePicture === undefined
                              ? "image_section"
                              : "image_section_upload"
                          }
                          onChange={onChangePicture}
                          alt=""
                        />
                      </label>

                      {muiLoad && (
                        <img
                          src={Favichat}
                          alt=""
                          style={{
                            height: "6rem",
                            width: "6rem",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        />
                      )}
                    </div>

                    <div>
                      <label htmlFor="file-input">
                        <input
                          className="profile-hidden-input"
                          required
                          onChange={onChangePicture}
                          id="file-input"
                          type="file"
                          accept="image/png, image/jpeg"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div id="bio-link">
                  <p className="text-[#0090ff] text-[13px] mr-[1px] text-copy-link-profile">
                    Share inbox link
                  </p>
                  <button
                    onClick={() => {
                      navigator.clipboard.writeText(copied);
                      handleOpenDialog();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      className="h-6 w-6 cursor-pointer"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path
                        fill="#99cef6"
                        d="M16 1H4a2 2 0 00-2 2v14h2V3h12V1zm3 4H8a2 2 0 00-2 2v14c0 1.1.9 2 2 2h11a2 2 0 002-2V7a2 2 0 00-2-2zm0 16H8V7h11v14z"
                      />
                    </svg>
                  </button>
                </div>

                <div>
                  <div className="w-full div-input-profile">
                    <input
                      type="text"
                      {...register("name")}
                      className="border rounded-lg border-gray-200 input-profile w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                      placeholder="Name"
                      value={
                        exsistingData === undefined ? "" : exsistingData.name
                      }
                      onChange={handleEvent}
                    />
                    <p className="text-red-500 text-xs text-left mt-1">
                      {errors?.name?.message}
                    </p>
                  </div>

                  <div className="w-full div-input-profile">
                    <input
                      type="text"
                      {...register("userName")}
                      className="border rounded-lg border-gray-200 input-profile w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                      placeholder="Username"
                      value={
                        exsistingData === undefined
                          ? ""
                          : exsistingData.userName
                      }
                      onChange={handleEvent}
                    />
                    <p className="text-red-500 text-xs text-left mt-1">
                      {errors?.userName?.message}
                    </p>
                    {err === "Username already exists" && (
                      <div className="verification__otp-form-error">
                        <div className="block text-left text-red-500 text-[16px] font-normal m-[6.16px] whitespace-pre-line">
                          {err}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-full change_phoneText div-input-profile">
                    <input
                      type="text"
                      className="border rounded-lg border-gray-200 input-profile w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                      placeholder="Mobile Number"
                      value={
                        exsistingData === undefined
                          ? ""
                          : exsistingData.phoneNumber
                      }
                      disabled
                      id="phone_textField"
                    />
                    <p
                      className="absolute top-0 right-0 input-btn-profile rounded-[10px] flex justify-center items-center px-[9.25px] z-50 font-medium border border-[#58a1f5] text-white bg-[#58a1f5] cursor-pointer"
                      onClick={() => {
                        handleChangeMobileNo();
                      }}
                    >
                      Change
                    </p>
                    <p className="text-red-500 text-xs text-left mt-1">
                      {errors?.phoneNumber?.message}
                    </p>
                  </div>

                  <div>
                    <label id="bio">
                      <p className="text-[#494949] font-medium text.right p-bio-profile">
                        Short Bio
                      </p>
                    </label>
                    <textarea
                      type="text"
                      {...register("bio")}
                      className="border rounded-lg border-gray-200 input-profile w-full text-sm placeholder-gray-400 focus:border-gray-300 focus:ring-1 focus:ring-gray-300"
                      placeholder="Enter your bio..."
                      rows={3}
                      value={
                        exsistingData === undefined ? "" : exsistingData.bio
                      }
                      onChange={handleEvent}
                      maxLength={300}
                    />

                    <p className="text-[#6eb5ed] text-right p-lenght-profile">
                      {exsistingData === undefined
                        ? ""
                        : exsistingData?.bio?.length}
                      /300
                    </p>
                    <p className="text-red-500 text-xs text-left mt-1">
                      {errors?.bio?.message}
                    </p>
                  </div>

                  <div
                    className={`fixed inset-0 bg-white z-${(theme) =>
                      theme.zIndex.drawer + 90}`}
                    style={{ display: `${saveLoader ? "block" : "none"}` }}
                  >
                    {saveLoader && (
                      <div className="relative">
                        <img
                          src={Favichat}
                          alt=""
                          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 profile_ripple_loader"
                        />
                      </div>
                    )}
                  </div>

                  {showModal === true && (
                    <div className="Modal">
                      <div className="modal_content">
                        {generate === true ? (
                          <div className="content">
                            <div
                              onClick={() => {
                                setShowModal(false);
                                setOtpErr("");
                                setNumber("");
                                setGenerate(true);
                                //setNewNumber(exsistingData === undefined ? "" : exsistingData.phoneNumber);
                                //setNumber("44993424");
                              }}
                            >
                              <svg
                                className="icon_close w-6 h-6 cursor-pointer"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>
                            <div className="verification__entry-info">
                              <h4
                                verification__entry-info
                                className="text-[#6eb5ed] text-center text-[20px] font-normal mb-[7px]"
                              >
                                Enter your phone number
                              </h4>
                            </div>
                            <div className="verification__phnumber-form">
                              <form
                                noValidate
                                onSubmit={getOtp}
                                style={{ display: "block" }}
                              >
                                <div className="verification-phnumber-input-container">
                                  <div className="verification-phnumber-input">
                                    <select
                                      value={countryVal}
                                      className="calling-code"
                                      onChange={(event) => {
                                        if (event.target.value) {
                                          const val =
                                            "+" +
                                            getCountryCallingCode(
                                              event.target.value
                                            );
                                          setCountryVal(val);
                                        }
                                      }}
                                      onKeyDown={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <option key={0} value={countryVal}>
                                        {countryVal}
                                      </option>
                                      {getCountries().map((country, index) => (
                                        <option key={index + 1} value={country}>
                                          {en[country]}+
                                          {getCountryCallingCode(country)}
                                        </option>
                                      ))}
                                    </select>
                                    <input
                                      type="tel"
                                      value={number}
                                      onChange={(e) => handleFormat(e)}
                                      placeholder="XXX-XXX-XXXX"
                                      className="border h-full number-codes"
                                    />
                                  </div>
                                </div>
                                <div className="verification__phnumber-form-error text-center">
                                  <div className="required-fieldtext-center text-center">
                                    {otpErr}
                                  </div>
                                </div>
                                <div className="verification__phnumber-form-btn">
                                  <GradientSignup type="submit">
                                    Generate code
                                  </GradientSignup>
                                </div>

                                {checking && (
                                  <div className="verification_checking">
                                    <div className="verification_checking_validation">
                                      <div className="relative inline-block w-8 h-8 mr-2">
                                        <div className="absolute inset-0 flex items-center justify-center">
                                          <div className="relative w-4 h-4">
                                            <div className="absolute top-0 left-0 w-full h-full border-2 border-t-gray-700 rounded-full animate-spin"></div>
                                          </div>
                                        </div>
                                      </div>
                                      <p id="checking_message">
                                        {checkingMessage}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        ) : (
                          <div className="content">
                            <div
                              onClick={() => {
                                setGenerate(true);
                                setNumber("");
                                setShowModal(false);
                                setOtpErr("");
                                setOtp("");
                              }}
                            >
                              <svg
                                className="icon_close cursor-pointer w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </div>

                            <div className="verification__entry-info text-center">
                              <h4
                                verification__entry-info
                                className="text-[20px] flex justify-center text-[#6EB5ED] font-normal mb-2"
                              >
                                Enter code sent to <br />
                                {"XXX XXX " +
                                  String(countryVal + number).slice(-4)}
                              </h4>
                            </div>

                            <div className="verification__otp-form ml-4 mr-4">
                              <form
                                noValidate
                                onSubmit={verifyOtp}
                                style={{ display: "block" }}
                              >
                                <div className="verification__otp-form-input pl-8 pr-8">
                                  <input
                                    type="otp"
                                    placeholder="XXXXXX"
                                    className="form-control"
                                    value={otp}
                                    maxlength="6"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div id="recaptcha-container" />
                                <div className="verification__otp-form-error">
                                  <div className="required-field text-center text-red-500">
                                    {otpErr}
                                  </div>
                                </div>
                                <div className="verification__otp-form-btn">
                                  <div className="verification__otp-form-btn-style">
                                    {otpAniLoader ? (
                                      <div>
                                        <img
                                          src={FavichatBig}
                                          alt=""
                                          className="save_ripple_loader"
                                        />
                                      </div>
                                    ) : (
                                      <GradientSignup type="submit">
                                        Continue
                                      </GradientSignup>
                                    )}
                                  </div>
                                </div>
                                <div className="resend-otp-container">
                                  <button
                                    onClick={() => {
                                      setErr("");
                                      setShowResendCode(true);
                                      resendOtp();
                                      setCounter(60);
                                    }}
                                    className={
                                      showResendCode === true
                                        ? "disabled_resend_otp"
                                        : "resend_otp"
                                    }
                                    disabled={showResendCode}
                                  >
                                    Resend code
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {err !== "" && err !== "Username already exists" && (
                    <div className="verification__otp-form-error">
                      <div className="block text-left text-red-500 text-[16px] font-normal m-[6.16px] whitespace-pre-line">
                        {err}
                      </div>
                    </div>
                  )}

                  <div className="m-auto md:w-[47%] w-[80%]">
                    {aniLoader ? (
                      <div className="flex items-center justify-center">
                        <img
                          src={FavichatBig}
                          alt=""
                          className={`save_ripple_loader ${
                            isMobile ? "fixed bottom-2" : ""
                          }`}
                        />
                      </div>
                    ) : (
                      <div className="flex items-center justify-center">
                        <button
                          disabled={disabledSave}
                          onClick={handleSubmit(onSubmitData)}
                          className={`profile-save-btn bg-[#58a1f5] disabled:opacity-75
                          border border-[#58a1f5] text-btn-save-profile rounded-full
                          text-white font-bold cursor-pointer px-[3rem] py-[.18rem]
                          transition duration-300 ease-in-out hover:bg-white hover:text-[#58a1f5]
                          ${isMobile ? "fixed bottom-2" : ""}`}
                        >
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="profile">
          <div
            className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
            onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
          >
            <div className="sm:hidden pt-[8px] px-6">
              <button type="submit" className="mr-[8px]">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                    dispatch(
                      UpdateOnlineUser({
                        online: false,
                      })
                    ).then(() => {
                      guestUserLogout();
                    });
                  }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Profile;
