import "../../styles/Setting.scss";
import sideBarData from "./sideBarData.json";
import { persistor } from "../../redux/store";
import { useNavigate } from "react-router-dom";
import { Link, Outlet } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import DialogBox from "../../components/Dialog/Dialog";
import LogoutImg from "../../assets/icons/logout-img.png";
import React, { useState, useEffect, useRef } from "react";
import { requestForToken } from "../../Config/configurations";
import WelcomeSafari from "../../components/PopUp/WelcomeSafari";
import { GuestUserWarning } from "../GuestUserPage/GuestUserPage";
import BackdropPopup from "../../components/BackdropPopup/BackdropPopup";
import NotificationsPopUp from "../../components/PopUp/NotificationsPopUp";
import {
  UpdateSettingSidebarToggleState,
  UpdateErrorLogInState,
  RemovePushToken,
  ResetState,
  UpdateOnlineUser,
  UpdatePushToken,
  CleanCurrentChatState,
  UpdateCurrentConversationIdState,
} from "../../redux/actions/index";

const Setting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isReady, setIsReady] = useState(false);
  const [isPopOpen, setIsPopOpen] = useState(false);
  const [permission, setPermission] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [instagramBrowser, setInstagramBrowser] = useState(false);
  const [displaySafariPopUp, setDisplaySafariPopUp] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [settingtoggleActive, setSettingToggleActive] = useState(false);

  const userData = useSelector((state) => state.user);
  const settingToggleStatus = useSelector((state) => state.settingBarToggle);
  const askedPermission = useSelector((state) => state.askedPermission);
  const isGuest = useSelector((state) => state.isGuest);
  const isIpad =
    !!(
      navigator.userAgent.match(/(iPad)/) ||
      (navigator.platform === "MacIntel" &&
        typeof navigator.standalone !== "undefined")
    ) && navigator.maxTouchPoints > 1;

  const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    isIpad;

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const btnLogout = () => {
    setIsDialogOpen((s) => !s);
  };

  const onAddNotif = () => {
    setIsPopOpen(true);
  };

  const onLinkClick = () => {
    const scrollTop = document.querySelector(".data-list-settings").scrollTop;
    dispatch(UpdateSettingSidebarToggleState(false));
    setTimeout(() => {
      document.querySelector(".data-list-settings").scrollTop = scrollTop;
    }, 1);
  };

  useEffect(() => {
    try {
      if (!isIOS) {
        localStorage.setItem("askedPermission", false);
        if (Notification.permission === "granted") {
          localStorage.setItem("askedPermission", true);
          Notifications();
        } else if (Notification.permission === "denied") {
          localStorage.setItem("askedPermission", true);

        }
      } else {
        let ua = navigator.userAgent || navigator.vendor;
        let isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
        if (isInstagram) {
          if (/iPad|iPhone|iPod/.test(ua)) {
            setInstagramBrowser(true);
            setDisplaySafariPopUp(true);
            return;
          } else {
          }
        }
      }
      if (!isIOS) {
        localStorage.setItem("askedPermission", false);
        if (Notification.permission === "granted") {
          localStorage.setItem("askedPermission", true);
          Notifications();
        } else if (Notification.permission === "denied") {
          localStorage.setItem("askedPermission", true);
        }
      } else {
        var ua = navigator.userAgent || navigator.vendor;
        var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
        var str = navigator.userAgent;
        var i = str.indexOf("Instagram");
        if (isInstagram) {
          if (/iPad|iPhone|iPod/.test(ua)) {
            setInstagramBrowser(true);
            setDisplaySafariPopUp(isIOS);
            //window.location.href = 'googlechrome://example.com';
            return;
          } else {
            // Web page is not opened from Instagram browser
            setDisplaySafariPopUp(isIOS);
            console.log("from iphone", isIOS);
            if (
              (isIOS &&
                "standalone" in window.navigator &&
                window.navigator.standalone) ||
              !isIOS
            ) {
              if (Notification.permission === "granted") {
                localStorage.setItem("askedPermission", true);

                Notifications();
              } else if (Notification.permission === "denied") {
                localStorage.setItem("askedPermission", true);
              }
            }
          }
        } else {
          const isIOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          // Web page is not opened from Instagram browser
          setDisplaySafariPopUp(isIOS);
          console.log("from iphone", isIOS);
          if (
            (isIOS &&
              "standalone" in window.navigator &&
              window.navigator.standalone) ||
            !isIOS
          ) {
            if (Notification.permission === "granted") {
              localStorage.setItem("askedPermission", true);
              Notifications();
            } else if (Notification.permission === "denied") {
              localStorage.setItem("askedPermission", true);
            }
          }
        }
      }

      dispatch(CleanCurrentChatState());
      dispatch(UpdateCurrentConversationIdState(null));
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (permission === true && userData.pushToken !== null) {
      if (!instagramBrowser) {
        dispatch(
          UpdatePushToken({
            id: userData.userId,
            pushToken: userData.pushToken,
          })
        );
      }
    }
  }, [permission]);

  const Notifications = async () => {
    const response = await requestForToken();
    if (response !== false) {
      dispatch(UpdatePushToken({ id: userData.userId, pushToken: response }));
      setPermission(true);
    } else {
      setPermission(false);
    }
  };

  const onDialogYesClick = () => {
    const pushToken = localStorage.getItem("pushToken");
    if (pushToken !== undefined) {
      dispatch(
        RemovePushToken({
          id: userData.userId,
          pushToken: pushToken,
        })
      );
    }
    dispatch(UpdateErrorLogInState(""));
    dispatch(UpdateOnlineUser({ online: false })).then(() => {
      dispatch(ResetState());
      //localStorage.clear();
      
      persistor
      .purge()
      .then(() => {
        navigate("/");
        })
        .catch((error) => {
          console.log("Error purging state:", error);
        });
    });
  };

  const ajusteAltura = isMobile ? 100 : 120;

  const dynamicStyles = {
    height: `${screenHeight - ajusteAltura}px`,
  };

  return (
    <>
      <div className="h-screen w-screen flex flex-col justify-start overflow-hidden fixed top-0 left-0">
        <Navbar />
        <div className="flex">
          <div className="flex content-body-settings">
            <div
              className={
                settingToggleStatus
                  ? "content-left-settings active"
                  : "content-left-settings"
              }
            >
              <div className="flex header-content-settings">
                <button
                  className="flex items-center block sm:hidden ml-4"
                  onClick={(e) =>
                    dispatch(UpdateSettingSidebarToggleState(false))
                  }
                >
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                  >
                    <path
                      className="text-[#494949]"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5L8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>

                <div className="flex header-settings">
                  <p id="title-setting">Settings</p>
                </div>
                <p
                  onClick={onAddNotif}
                  className="absolute right-3 text-[10px] notif-text text-[#2f6f9e] underline cursor-pointer"
                >
                  {isIOS &&
                    ("standalone" in window.navigator &&
                    window.navigator.standalone
                      ? "Get notifications"
                      : "Get notifications add app to homescreen")}
                </p>
              </div>

              <div className="data-list-settings" style={dynamicStyles}>
                <ul className="list-sidebar">
                  {sideBarData.map((val, i) => {
                    return (
                      <Link key={i} to={val.link} onClick={onLinkClick}>
                        <li
                          key={i}
                          className="row"
                          id={
                            window.location.pathname == val.link ? "active" : ""
                          }
                        >
                          <div className="item-container">
                            <div>
                              <p className="text-[#2f6f9e] font-semibold text-lg sm:text-sm lg:text-lg">
                                {val.title}
                              </p>
                            </div>
                            <div>
                              <p id="desc">{val.subHeading}</p>
                            </div>
                          </div>
                        </li>
                      </Link>
                    );
                  })}

                  {!isGuest && (
                    <div
                      className="logout-content-settings"
                      onClick={btnLogout}
                    >
                      <div className="setting-logout">
                        <p>Logout</p>
                        <div className="logout-img-settings">
                          <img src={LogoutImg} alt="" />
                        </div>
                      </div>
                    </div>
                  )}
                </ul>
              </div>
            </div>

            <div className="relative body-right-settings">
              <GuestUserWarning />
              <Outlet />
            </div>

            {settingtoggleActive ? (
              <BackdropPopup
                onBackdropPopup={() => setSettingToggleActive(false)}
              />
            ) : null}
          </div>
        </div>
      </div>

      {isPopOpen &&
        (!isIOS ||
        (displaySafariPopUp &&
          "standalone" in window.navigator &&
          window.navigator.standalone) ? (
          <NotificationsPopUp
            setPermission={setPermission}
            setIsPopOpen={setIsPopOpen}
          />
        ) : (
          <WelcomeSafari setIsPopOpen={setIsPopOpen} />
        ))}

      {isDialogOpen && (
        <DialogBox
          dialogTitle={"Are you sure you want to logout?"}
          isDialogOpen={isDialogOpen}
          setOpenDialog={setIsDialogOpen}
          onDialogYesClick={onDialogYesClick}
        />
      )}
    </>
  );
};

export default Setting;
