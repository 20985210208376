import "../../styles/ReportPopup.scss";

function MessagePopup({message, stateMessage, reportPopup}) {

    return (
      <div className="report_popUp">
        <div className="report_container">
          <div className="absolute justify-start py-1 px-2" >
            <button className="text-gray-500 hover:text-gray-800"
              onClick={(e) => {
                stateMessage(false)
                reportPopup(false);
              }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
              </div>
              <div>
                <form>
                    <h4 className="report_style_black">{message}</h4>
                </form>
          </div>
        </div>
      </div>
    );
  }

  export default MessagePopup;

