import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../Config/configurations";

const setUpReCaptcha = () => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response);
        },
        "expired-callback": () => {},
      },
      auth
    );
  }
  window.recaptchaVerifier.render();
};

export default setUpReCaptcha;
