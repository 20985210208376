import "../../styles/Login.scss";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { persistor } from '../../redux/store';
import { Logo } from "../../components/Logo/Logo";
import Modal from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useLayoutEffect } from "react";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  UpdateErrorLogInState,
  LogInState,
  ResetState,
  UpdateIsGuestState,
} from "../../redux/actions/index";

export const Login = () => {
  const accessToken = localStorage.getItem("accessToken");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [passwordShown, setPasswordShown] = useState(false);

  const state = useSelector((state) => state);
  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);

  /* Clean data redux */
  useEffect(() => {
    dispatch(ResetState());
    persistor.purge();
    localStorage.removeItem("pushToken");
    //localStorage.clear();

    setTimeout(() => {
      dispatch(UpdateIsGuestState(false));
      dispatch(UpdateErrorLogInState(""));
    }, 1000);
  }, []);

  /* validate data and navigate inbox page */
  useEffect(() => {
    if (userData.userId !== null) {
      if (Object.keys(userData).length !== 0 && userData.interests !== null) {
        if (userData.interests.length !== 0 && accessToken !== null) {
          dispatch(UpdateIsGuestState(false));
          navigate("/inbox/start");
        } else if (
          !(isGuest == true) &&
          accessToken !== null &&
          userData.interests.length == 0
        ) {
          navigate("/interest");
        }
      }
    }
  }, [userData, accessToken]);

  return (
    <div
      className={`h-screen w-screen flex flex-col fixed items-center justify-start overflow-y-scroll fixed top-0 left-0`}
    >
      <Logo />
      <div className="mx-auto max-w-screen-xl px-4 py-6 sm:py-2 sm:px-6 lg:px-8 signIn-wrapper">
        <div className="mx-auto max-w-lg text-center pt-2">
          <p className="text-lg signIn-title">
            Type in your username and password
          </p>
        </div>

        <form
          className="mx-auto mb-0 max-w-md space-y-4 pt-8 pb-8"
          noValidate
          onSubmit={handleSubmit((data) => dispatch(LogInState(data)))}
          onFocus={() => dispatch(UpdateErrorLogInState(""))}
        >
          <div>
            <div className="relative">
              <input
                type="text"
                placeholder="Username"
                className="w-full border rounded-lg border-gray-200 p-3 pr-12 text-sm shadow-sm signin-input-field"
                {...register("userName", { required: "What's your username?" })}
              />
            </div>
            {errors.userName && (
              <div className="items-center justify-between signin-label-error-input">
                <label className="text-red-500 text-sm">
                  {errors.userName.message}
                </label>
              </div>
            )}
          </div>

          <div>
            <div className="relative">
              <input
                type={!passwordShown ? "password" : "text"}
                placeholder="Password"
                className="w-full border rounded-lg border-gray-200 p-3 pr-12 text-sm shadow-sm signin-input-field"
                {...register("password", {
                  required: "Password should not be blank",
                })}
              />
              {!passwordShown ? (
                <span
                  onClick={() => setPasswordShown(true)}
                  className="absolute inset-y-0 right-0 grid place-content-center px-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-6 h-6 signin-input-field-iconeye"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  onClick={() => setPasswordShown(false)}
                  className="absolute inset-y-0 right-0 grid place-content-center px-4"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="h-6 w-6 signin-input-field-iconeye"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </span>
              )}
            </div>
            {errors.password && (
              <div className="items-center justify-between signin-label-error-input">
                <label className="text-red-500 text-sm">
                  {errors.password.message}
                </label>
              </div>
            )}
          </div>

          <div className="flex items-center justify-end">
            <Link
              to="/reset-password"
              className="text-sm text-gray-500 cursor-pointer signin-label-forgotpass"
            >
              <p>Forgot password?</p>
            </Link>
          </div>

          <div
            className={`flex items-center justify-center text-justify
            }`}
          >
            <label className="text-red-500 text-sm signin-label-error text-justify w-64">
              {state.error}
            </label>
          </div>

          <div className="flex items-center justify-between">
            <button className="inline-block rounded-lg bg-blue-500 px-2 py-2 text-white signin-btn-signin">
              Sign In
            </button>
          </div>
        </form>

        <div className="flex items-center mt-2 justify-center signin-text-account">
          {state?.loading ? (
            <img src={FavichatLoading} className="signin-img-Loading" />
          ) : (
            <>
              <p className="mr-2">Don't have an account?</p>
              <Link
                to="/sign-up"
                className="text-sm text-blue-500 cursor-pointer signin-link-account"
              >
                <p>Sign Up</p>
              </Link>
            </>
          )}
        </div>
      </div>

      <Modal isLandingPage={false} />
    </div>
  );
};
