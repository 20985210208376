import User from "./Users";
import "../../../styles/UserList.scss";
import UserFollower from "../UserFollower";
import { Url } from "../../../axios/authAPI";
import { useAxios } from "../../../axios/authAPI";
import UserSearch from "../../UserSearch/UserSearch";
import { useSelector, useDispatch } from "react-redux";
import Favichat from "../../../assets/gif/Favichat.gif";
import DiscoverFooter from "../../Footer/DiscoverFooter";
import UserModal from "../../UserDetailsModal/UserModal";
import send_all from "../../../assets/icons/send_all.png";
import { useCallback, useEffect, useState, useRef, useMemo } from "react";
import { UpdateSendAllToggleState, UpdateDataDiscoveryState, UpdateDataDiscoveryFollowState, CleanDataDiscoveryState } from "../../../redux/actions/index";

const UserList = () => {

  let api = useAxios();
  const dispatch = useDispatch();
  const inputRefSearch = useRef(null);

  const [limit, setLimit] = useState(50);
  const [lastRec, setLastRec] = useState("");
  const [loading, setLoading] = useState(false);
  const [statusText, setStatusText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [followerLike, setFollowerLike] = useState();
  const [newUserData, setNewUserData] = useState([]);
  const [showFooter, setShowFooter] = useState(false);
  const [followerList, setFollowerList] = useState([]);
  const [creatorDetails, setCreatorDetails] = useState();
  const [stopScrollFn, setStopScrollFn] = useState(true);
  const [isBackBtnOnTop, setIsBackBtnOnTop] = useState(false);
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [followerListCall, setFollowerListCall] = useState(false);
  const [sendAllModalToggle, setSendAllModalToogle] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const accessToken = localStorage.getItem("accessToken");

  const userData = useSelector((state) => state.user);
  const usersData = useSelector((state) => state.dataDiscovery);
  const isFollowingData = useSelector((state) => state.isFollowing);

  const userFollowersUrl = (id, limit) => `${Url}/users/${id}/followers?limit=${limit}`;

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getFollowers = useCallback(async () => {
    setStatusText("");
    setFollowerListCall(true);
    try {
      setTimeout(async() => {
        if (localStorage.getItem("accessToken") !== null) {
          const userFollowerPromise = await api.get(
            userFollowersUrl(userData.userId, limit)
          );
    
          const [userFollowerData] = await Promise.all([userFollowerPromise]);
    
          userFollowerData?.data?.data?.values.length !== 0
            ? setFollowerList(
                userFollowerData?.data.data.values?.map((value) => value.follower)
              )
            : setFollowerList([]);
          setStatusText("");
          setFollowerListCall(false);
    
          if (creatorDetails !== undefined) {
            dispatch(UpdateDataDiscoveryFollowState(usersData));
          }
        }
      }, 1000);
    } catch (error) {
      if (error.isAxiosError) {
        setFollowerListCall(false);
        setStatusText(
          "We have technical issue to fetch your data, please try again"
        );
        console.log({ error });
      }
    }
  }, [userData.userId, limit, accessToken, isFollowingData]);

  const handleScroll = useCallback(
    (evt) => {
      const { scrollLeft, offsetWidth, scrollWidth } = evt.currentTarget;
      if (scrollLeft + offsetWidth === scrollWidth) {
        if (!loading) {
          setTimeout(() => {
            setLimit((state) => state + 10);
          }, 1000);
        }
      }
    },

    [loading]
  );

  const getUsersByInterest = useCallback(async (lastRecValue) => {
    setStopScrollFn(false);
    try {
      const response = await api.get(
        `${Url}/users/interests/users?limit=5&userId=${userData.userId}&lastRec=${lastRecValue}`
      );

      const responseData = response?.data?.data;
      if (responseData[responseData.length - 1]?.lastRec) {
        setNewUserData((prev) => [...prev, ...responseData]);
        dispatch(UpdateDataDiscoveryState(responseData));
        setLastRec(responseData[0]?.lastRec);
        setUserDataLoading(false);
        setStopScrollFn(true);
        setLoading(true);
      } else {
        setNewUserData((prev) => [...prev, ...responseData]);
        dispatch(UpdateDataDiscoveryState(responseData));
        setUserDataLoading(false);
        setStopScrollFn(false);

        setLoading(false);
        setShowFooter(true);
      }
    } catch (err) {
      console.log(err);
      setStopScrollFn(false);
    }
  }, []);

  useEffect(() => {
    let isInterestFlag = true;
    isInterestFlag && getFollowers();
    return () => (isInterestFlag = false);
  }, [getFollowers]);

  useEffect(() => {
    const handleUserDataNew = async () => {
      dispatch(CleanDataDiscoveryState());
      if (lastRec === "") {
        const response = await api.get(
          `${Url}/users/interests/users?limit=7&userId=${userData.userId}`
        );
        
        const responseData = response?.data?.data;
        dispatch(UpdateDataDiscoveryState(responseData));
        setLastRec(responseData[0]?.lastRec);
        setUserDataLoading(false);
        setLoading(true);
      }
    };

    handleUserDataNew();
  }, []);

  const handleInfo = (id, act) => {
    setFollowerLike(act);
    setShowModal(true);
    setCreatorDetails(id);
  };

  const scrollup = () => {
    document.getElementById("topScroll").scrollIntoView({ top: 0, behavior: "smooth" });
  };

  const handleScrollCheck = (e) => {
    topArrowBtnVisibility();
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (inputRefSearch.current !== null) {
      inputRefSearch.current.blur();
    }

    if (
      scrollHeight - scrollTop <=
      clientHeight + 100
    ) {
      setLoading(true);
      getUsersByInterest(lastRec);
    }
  };

  const topArrowBtnVisibility = () => {
    if (document.querySelector(".content-discovery").scrollTop > 50) {
      setIsBackBtnOnTop(true);
    } else {
      setIsBackBtnOnTop(false);
    }
  };

  const handleCloseProfile = (bool) => {
    setShowModal(false);
    setCreatorDetails("");
  };

  const usersMappedData = useMemo(() => {
    return usersData.map((interest) => {
      const objectShowMore = {
        name: interest?.name,
        id: interest?.id,
      };
      return (
        <User
          key={interest?.toString()}
          interest={interest?.name}
          userList={interest?.values}
          handleScroll={handleScroll}
          handleInfo={handleInfo}
          interestLink={objectShowMore}
        />
      );
    });
  }, [usersData]);

  const dynamicStyles = {
    height: `${screenHeight - 72}px`,
  };

  return (
    <div style={dynamicStyles} className={`w-screen flex flex-col justify-start ${sendAllModalToggle ? "content-discovery active" : "content-discovery"}`} onScroll={stopScrollFn ? handleScrollCheck : undefined}>
      <div className="flex justify-between w-full h-20 pt-4 md:pt-4" id="topScroll">
        <div className="flex items-center div-title-header-discovery">
          <h1 className="title-header-discovery">Favichat</h1>
        </div>
        <div className="flex justify-start items-center div-search-header-discovery">
          <img
            onClick={(e) => dispatch(UpdateSendAllToggleState(true))}
            src={send_all}
            alt=""
            className="mr-4 icon-send-all-heart-discovery"
          />
          <UserSearch inputRefSearch={inputRefSearch} />
        </div>
      </div>

      <UserFollower
        followerLists={followerList}
        handleScroll={handleScroll}
        statusText={statusText}
        handleInfo={handleInfo}
        followerListCall={followerListCall}
      />

      {userDataLoading ? (
        <>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="animate-pulse bg-gray-200 w-28 md:w-64 h-7 mt:2 md:mt-4"></div>
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10).fill(1).map((card, index) => (
                  <div className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none" key={index}></div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="animate-pulse bg-gray-200 w-28 md:w-64 h-7 mt:2 md:mt-4"></div>
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10).fill(1).map((card, index) => (
                  <div className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none" key={index}></div>
                ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="animate-pulse bg-gray-200 w-28 md:w-64 h-7 mt:2 md:mt-4"></div>
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10).fill(1).map((card, index) => (
                  <div className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none" key={index}></div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{usersMappedData}</>
      )}

      {isBackBtnOnTop && (
        <div className="user-list-scroll-top-btn"  onClick={scrollup}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 scroll-top-btn">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75" />
          </svg>
        </div>
      )}

      {showModal && (
        <UserModal
          info={creatorDetails}
          close={handleCloseProfile}
          followerLike={followerLike}
        />
      )}

      {loading && (
        <div className="w-full flex flex-row justify-center user-list-loading-container">
          <img src={Favichat} alt="" className="users-ripple-loader" />
          <p>Loading...</p>
        </div>
      )}

      {showFooter && <DiscoverFooter />}

    </div>
  );
};
export default UserList;
