import '../../styles/LandingPage.scss';
import React, { useState } from "react";
import termsPdf from "../../Document/Terms.pdf";
import AllPagesPDF from "../pdfComponent/all-pages";

export default function Modal() {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <footer className="relative bottom-0 left-0 w-full text-center py-6 footer-wrapper">
        <p className="text-TermsConditions text-[#8f8f8f]" onClick={handleOpenModal}>
          Terms and conditions
        </p>
      </footer>
      {isOpen && (
        <div className="fixed z-10 inset-0 flex items-center justify-center">
          <div className="flex items-center justify-center px-4">
            <div className="fixed inset-0 transition-opacity" onClick={handleCloseModal}>
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
            </div>

            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all fixed inset-0 overflow-hidden mx-7 my-7">
              <div className="px-4 py-2 flex justify-between items-center title-Modal-TermsConditions">
                <button
                  onClick={handleCloseModal}
                  className="rounded-full p-1 close-Modal-TermsConditions"
                >
                  <svg className="h-6 w-6 text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="text-center flex-1">
                  <h2 className="text-lg font-medium text-gray-800 text-Modal-TermsConditions">Terms and conditions</h2>
                </div>
              </div>
              <div className="px-4 py-2 overflow-y-auto w-full h-full">
                <div className="flex-1">
                  <AllPagesPDF pdf={termsPdf}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}