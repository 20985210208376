import { useState, useEffect } from 'react';

export const useAppBreakpoints = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isMiniLaptop, setIsMiniLaptop] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateBreakpoints = () => {
      setIsMobile(window.matchMedia('(max-width: 639px)').matches);
      setIsTablet(window.matchMedia('(max-width: 767px)').matches);
      setIsMiniLaptop(window.matchMedia('(max-width: 1023px)').matches);
      setIsDesktop(window.matchMedia('(min-width: 1024px)').matches);
    };

    updateBreakpoints();

    window.addEventListener('resize', updateBreakpoints);
    return () => {
      window.removeEventListener('resize', updateBreakpoints);
    };
  }, []);

  return {
    isTablet,
    isMobile,
    isMiniLaptop,
    isDesktop,
  };
};

export default useAppBreakpoints;
