import React from "react";
import "../../styles/CashoutTransactionCard.scss";
import moment from "moment";

function CashoutTransactionCard({
  amount,
  date,
  status,
  transactionID,
  data,
  paymentMethod,
}) {

    const isIpad = !!(navigator.userAgent.match(/(iPad)/)
  || (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;
  if(isIpad === true){
    
  return (
    <div className="flex justify-between border-b border-gray-300 w-full items-stretch px-8 py-2">
      <div className="w-7/10">
        <div className="items-start justify-center">
          <p className="text-sm font-normal text-gray-900 mb-1">{moment(date).format("ll")}</p>
        </div>
        <div >
          <p className="text-sm font-normal text-gray-600 opacity-60 sm:text-xs mb-1">ID - {transactionID}</p>
        </div>
        <div >
          <p className="font-normal text-gray-600 opacity-60 text-xs">{paymentMethod}</p>
        </div>
      </div>
      <div className="justify-around w-3/10">
        <div className="flex justify-center items-center text-green-600 font-normal sm:flex sm:items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
          </svg>

          <p className="text-lg"> ${amount}</p>
        </div>
        <div >
          <p className="font-normal text-gray-600 opacity-60 text-xs">status - {status}</p>
        </div>
      </div>
    </div>
  );
} else {
  return (
    <div className="flex md:items-center justify-between border-b border-gray-300 w-full items-stretch px-8 py-2 md:px-12 md:py-4">
      <div className="md:flex md:justify-around w-7/10 md:w-3/5">
        <div className="sm:items-start sm:justify-center">
          <p className="text-sm font-normal text-gray-900 mb-1 md:text-base">{moment(date).format("ll")}</p>
        </div>
        <div >
          <p className="text-sm font-normal text-gray-600 opacity-60 sm:text-xs mb-1 md:text-base">ID - {transactionID}</p>
        </div>
        <div >
          <p className="font-normal text-gray-600 opacity-60 text-xs md:text-base">{paymentMethod}</p>
        </div>
      </div>
      <div className="md:flex md:justify-around justify-around w-3/10 md:w-2/5">
        <div className="flex justify-center items-center text-green-600 font-normal sm:flex sm:items-center">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
          </svg>

          <p className="text-lg md:w-20"> ${amount}</p>
        </div>
        <div >
          <p className="font-normal text-gray-600 opacity-60 text-xs md:text-base ">status - {status}</p>
        </div>
      </div>
    </div>
  )
}
}

export default CashoutTransactionCard;
