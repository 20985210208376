import "./Style.scss";
import sleepIcon from "../../assets/icons/sleepIcon.png";

const DialogBox = ({ setOpenDialog, dialogTitle, onDialogYesClick }) => {
  const onDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <div className="fixed z-20 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm backdrop-blur-md transition-opacity"
          onClick={onDialogClose}
        ></div>
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-lg logout-modal-nav">
          <div className="flex items-center justify-center px-6 py-4">
            <h2 className="logout-title-modal-nav">{dialogTitle}</h2>
          </div>

          <div className="flex justify-end items-center px-6 py-4">
            <button
              className="flex flex-col items-center justify-center mr-2 px-3 py-2 rounded-md group"
              onClick={onDialogClose}
            >
              <div className="group-hover:scale-125 transition-all duration-300">
                <svg
                  className="w-7 h-7 inline-block text-[#58a1f5]"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1900 1900"
                  id="Heart"
                >
                  <path
                    d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                    fill="currentColor"
                    className="color000000 svgShape"
                  ></path>
                </svg>
              </div>
              <span className="logout-no-modal-nav">No</span>
            </button>

            <button
              className="flex flex-col items-center justify-center mr-2 px-3 py-2 rounded-md group"
              onClick={onDialogYesClick}
            >
              <div className="group-hover:scale-125 transition-all duration-300">
                <img
                  src={sleepIcon}
                  alt="sleepicon"
                  className="w-8 h-8 object-contain"
                />
              </div>
              <span className="logout-no-modal-nav">Yes</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DialogBox;
