import '../../styles/LandingPage.scss';
import MainLogo from "../../assets/icons/FaviNewLogo.svg";

export const LogoLandingPage = () => {
  
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={MainLogo} alt="Logo" className="h-32 w-32 mt-20" />
      <p className="textLogo-LandingPage">
        One Place, <span className="textLogoSpan-LandingPage">Your Interests.</span>
      </p>
      <p className="textLogo-LandingPage">
        Let's Chat!
      </p>
    </div>
  );
};
