import axios from "axios";
import "../../styles/LandingPage.scss";
import { Url } from "../../axios/authAPI";
import Modal from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import coverMobile from "../../videos/final_video.mp4";
import InfoPopUp from "../../components/PopUp/InfoPopUp";
import React, { useRef, useEffect, useState } from "react";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import SafariPopUp from "../../components/PopUp/SafariPopUp";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { LogoLandingPage } from "../../components/Logo/LogoLandingPage";
import {
  UpdatePopUpWelcomeState,
  UpdateIsGuestState,
  UpdateDataGuestState,
  UpdateIsLogInState,
  UpdateDeviceGuestState,
} from "../../redux/actions/index";

export const LandingPage = () => {
  let source = axios.CancelToken.source();
  const showPopUpWelcome = localStorage.getItem("isWelcomePopupOpen") === null;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isIpad =
    !!(
      navigator.userAgent.match(/(iPad)/) ||
      (navigator.platform === "MacIntel" &&
        typeof navigator.standalone !== "undefined")
    ) && navigator.maxTouchPoints > 1;
  const isIOS =
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    isIpad;

  const videoRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { isLoading, error, data } = useVisitorData();

  const [guestData, setGuestData] = useState([]);
  const [isGuestSet, setIsGuestSet] = useState(false);
  const [goGuest, setGoGuest] = useState(false);
  const [loadingGuest, setLoadingGuest] = useState(true);

  const userID = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const isDeviceGuest = useSelector((state) => state.isDeviceGuest);
  const isLogIn = useSelector((state) => state.isLogIn);

  useEffect(() => {
    const video = videoRef.current;
    if (video !== null) {
      const onVideoMetadataLoaded = () => {
        if (video.videoWidth > 0 && video.videoHeight > 0) {
          video.play();
        }
      };
      video.addEventListener("loadedmetadata", onVideoMetadataLoaded);
      return () => {
        video.removeEventListener("loadedmetadata", onVideoMetadataLoaded);
      };
    }
  }, []);

  useEffect(() => {
    //disable back button functionality
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  },[])

  /* Option signin */
  const signIn = () => {
    source.cancel();
    navigate("/login");
  };

  /* Option signup */
  const signUp = () => {
    source.cancel();
    navigate(isGuestSet ? "/sign-up?isGuest=true" : "/sign-up");
  };

  /* Get data guest user */
  useEffect(async () => {
    if (isLogIn === true) {
      navigate("/inbox/start");
      return;
    }
    setIsGuestSet(searchParams.get("isGuest") === "true" ? true : false);
    let source = axios.CancelToken.source();
    try {
      if (data?.visitorId) {
        const userData = await axios(`${Url}/users/signin/guest`, {
          method: "POST",
          data: {
            visitorId: data?.visitorId,
          },
          headers: { "Content-Type": "application/json" },
          cancelToken: source.token,
        });
        if (
          Object.keys(userData.data.data).length !== 0 &&
          (userID.userName === null || userID.userName.includes("guest"))
        ) {
          setGuestData(userData);
          setLoadingGuest(false);
          setGoGuest(true);
          dispatch(UpdateDeviceGuestState(true));
          setGuestData(userData);
        } else if (Object.keys(userData.data.data).length === 0) {
          setGoGuest(false);
          setLoadingGuest(false);
          dispatch(UpdateIsGuestState(false));
          dispatch(UpdateDeviceGuestState(false));
        }
      }
    } catch (error) {
      setLoadingGuest(false);
       dispatch(UpdateDeviceGuestState(false));
      console.log(error);
    }
  }, [data]);

  /* Option Continue as guest */

    
  const IsGuestNavigate = async () => { 
    IsGuestF(guestData);
    dispatch(UpdateIsLogInState(true));
    dispatch(UpdatePopUpWelcomeState(true));
    navigate("/inbox/start");
  }

  const IsGuestF = async (guestData) => {
    dispatch(UpdateIsGuestState(true));
    dispatch(
      UpdateDataGuestState({
        bio: guestData.data.data.bio,
        cashOut: guestData.data.data.cashOut,
        contentUnLockUsers: guestData.data.data.contentUnLockUsers,
        countryCode: guestData.data.data.countryCode,
        createdAt: guestData.data.data.createdAt,
        dob: guestData.data.data.dob,
        earningsToDate: guestData.data.data.earningsToDate,
        fbFollowers: guestData.data.data.fbFollowers,
        firebaseUid: guestData.data.data.firebaseUid,
        hearts: guestData.data.data.hearts,
        userId: guestData.data.data.id,
        instaFollowers: guestData.data.data.instaFollowers,
        interests: guestData.data.data.interests,
        name: guestData.data.data.name,
        phoneNumber: guestData.data.data.phoneNumber,
        profilePicUrl: guestData.data.data.profilePic,
        profileViewed: guestData.data.data.profileViewed,
        totalFollowers: guestData.data.data.totalFollowers,
        totalFollowing: guestData.data.data.totalFollowing,
        twitterFollowers: guestData.data.data.twitterFollowers,
        updatedAt: guestData.data.data.updatedAt,
        userName: guestData.data.data.userName,
        youtubeFollowers: guestData.data.data.youtubeFollowers,
      })
    );
    localStorage.setItem("accessToken", guestData.data.acessToken);
    localStorage.setItem("refreshToken", guestData.data.refreshToken);
  };

  return (
    <>
      {(isMobile !== null && showPopUpWelcome) ? (
        (isIOS === true &&  !("standalone" in window.navigator && window.navigator.standalone)) ? (
          <SafariPopUp isMobile={isMobile} />
        ) : (
          <InfoPopUp isMobile={isMobile} />
        )
      ) : null}

      <div className="h-screen w-screen flex flex-col fixed items-center justify-start overflow-hidden fixed top-0 left-0">
        {isMobile && (
          <video
            ref={videoRef}
            className="cover-Video"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={coverMobile} type="video/mp4" />
          </video>
        )}

        <div className="hidden sm:block">
          <LogoLandingPage />
        </div>

        <div className="fixed flex flex-col bottom-32 items-center justify-center w-full">
          <button button className="btn-SignUp" onClick={signUp}>
            Sign Up
          </button>
          <button className="btn-SignIn" onClick={signIn}>
            Sign In
          </button>

          {!loadingGuest && goGuest === true && (
            <button className="btn-Guest" onClick={IsGuestNavigate}>
              Continue as Guest
            </button>
          )}

          {loadingGuest && !isMobile && (
            <img src={FavichatLoading} className="img-Loading-LandingPage" />
          )}
        </div>

        <Modal isLandingPage={true} />
      </div>
    </>
  );
};
