import React from "react";
import '../../styles/LandingPage.scss';
import { Url, useAxios } from "../../axios/authAPI";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import iosShare from "../../assets/icons/shareIcon.jpg";
import bellNotifications from "../../assets/icons/bellNotifications.png";
import { UpdatePopUpWelcomeState, ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser } from "../../redux/actions/index";


const WelcomeSafari = ({ setIsPopOpen, isGuest }) => {

  let api = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const user = useSelector((state) => state.user);

  const handleClose = () => {
    dispatch(UpdatePopUpWelcomeState(false));
    if(setIsPopOpen){
      setIsPopOpen(false)
    }
  };

  const guestUserLogout = () => {
    if (isGuest === true) {
      handleClose();
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      <div className="fixed inset-0 bg-gray-600 bg-opacity-40 backdrop-blur-[2px] transition-opacity"></div>
      <div
        className={`translate-y-0 w-full ${isGuest ? "h-80" : "h-64"} bg-white shadow-xl rounded-t-3xl transition-transform duration-300 ease-in-out`}
        style={{ border: '1px solid #b8b7b7'}}
      >
        <div className="flex justify-between items-center rounded-t-3xl p-4">
          <button
            className="p-2 rounded-full transition-colors duration-300 absolute"
            onClick={handleClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 close-Drawer-LandingPage">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="w-10 h-10 flex justify-center items-center mx-auto">
            <img
              src={bellNotifications}
              alt="Bell Notifications"
              className="object-contain flex"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          </div>
        </div>
        <div className="flex justify-center h-full">
          <div className="text-center">
            <p className="font-bold text-lg pb-4">
              Turn on notifications to have more fun!
            </p>
            <p className="pb-8 text-sm">
                1. Open up Safari browser favi.fun
              <br/>
                2. Click this icon on the bottom in the browser
              <br/>
                3. Add to homescreen
              <div className="flex justify-center pt-4">
                <img src={iosShare} alt="iOS share" className="w-6" />
              </div>
              {isGuest && 
              <p onClick={() => {
                dispatch(
                  UpdateOnlineUser({
                    online: false,
                  })
                ).then(() => {
                  guestUserLogout();
                });
              }}
              className="mt-4 drawer-TextAccount-LandingPage">
                  Create an account or Sign in
                </p>
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeSafari;