import { useAxios, Url } from "../../axios/authAPI";
import Navbar from "../../components/Navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { requestForToken } from "../../Config/configurations";
import WelcomeSafari from "../../components/PopUp/WelcomeSafari";
import InboxSidebar from "../../components/InboxPage/InboxSidebar";
import UserModal from "../../components/UserDetailsModal/UserModal";
import WelcomeFavichat from "../../components/PopUp/WelcomeFavichat";
import NotificationsPopUp from "../../components/PopUp/NotificationsPopUp";
import React, { useState, useLayoutEffect, useEffect, useCallback } from 'react';
import { UpdatePushToken, UpdateFirstTimeState } from "../../redux/actions/index";

function useWindowSize() {
  
  const [size, setSize] = useState([0, 0]);
  const [isMobile, setIsMobile] = useState(false);
  
  const location = useLocation();

  useLayoutEffect(() => {

    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      setIsMobile(window.innerWidth < 640);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return [size, isMobile, location];
}

function InboxPage() {

  let api = useAxios();
  const dispatch = useDispatch();
  const locationUrl = useLocation();
  const [size, isMobile, location] = useWindowSize();
  const [windowHeight] = size;

  const [permission, setPermission] = useState(false);
  const [followerList, setFollowerList] = useState([]);
  const [dataFollower, setDataFollower] = useState([]);
  const [openFollower, setOpenFollower] = useState(false);
  const [instagramBrowser, setInstagramBrowser] = useState(false);
  const [displaySafariPopUp, setDisplaySafariPopUp] = useState(false);

  const isSafari = !!navigator.userAgent.match(/iPhone/i);
  const isTargetPath = locationUrl.pathname.includes('/inbox/start');
  const shouldShowOutlet = isMobile && location.pathname !== "/inbox/start";
  const shouldShowSidebar = isMobile && location.pathname === "/inbox/start";
  const isIpad = !!(navigator.userAgent.match(/(iPad)/)
|| (navigator.platform === "MacIntel" && typeof navigator.standalone !== "undefined")) && navigator.maxTouchPoints > 1;
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream || isIpad;

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const firstTime = useSelector((state) => state.firstTime);
  const userFollower = useSelector((state) => state.userFollower);
  const askedPermission = localStorage.getItem("askedPermission");
  const showPopUpWelcome = useSelector((state) => state.popUpWelcome);
  
  const userFollowersUrl = (id, limit) => `${Url}/users/${id}/followers?limit=${limit}`;

  const handleCloseDataFollower = () => {
    setOpenFollower(false);
    dispatch(UpdateFirstTimeState(false))
    localStorage.removeItem("userFollower");
  };

  const result = displaySafariPopUp
  ? ("standalone" in window.navigator && window.navigator.standalone)
    ? true
    : false
  : true;

  const popUpWelcome = localStorage.getItem("isWelcomePopupOpen");

  const Notifications = async () => {
    const response = await requestForToken();
    if(response !== false){
      dispatch(UpdatePushToken({id:userData.userId,pushToken:response}))
      setPermission(true);
    } else {
      setPermission(false)
    }
  };

  const getFollowers = async () => {
    const userFollowerData = await api.get(userFollowersUrl(userData.userId, 10));

    setFollowerList(userFollowerData?.data.data.values?.map((value) => value.follower));
  };

  useEffect(() => {
    isGuest === true && getFollowers();
  }, []);

  useEffect(() => {
    try{
      if (!isIOS){
        localStorage.setItem("askedPermission", false);
        if (Notification.permission === "granted"){
          localStorage.setItem("askedPermission", true);
          Notifications();
        } else if (Notification.permission === "denied"){
          localStorage.setItem("askedPermission", true);
        }
      } else {
        let ua = navigator.userAgent || navigator.vendor;
        let isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
        if (isInstagram){
          if (/iPad|iPhone|iPod/.test(ua)){
            setInstagramBrowser(true);
            setDisplaySafariPopUp(true);
            return;
          } else{
  
          }
        }
      }
      if (!isIOS){
        localStorage.setItem("askedPermission", false);
          if(Notification.permission === "granted"){
            localStorage.setItem("askedPermission", true);
            Notifications()
          } else if (Notification.permission === "denied"){
            localStorage.setItem("askedPermission", true);
          }
        } else{
        var ua = navigator.userAgent || navigator.vendor;
        var isInstagram = ua.indexOf("Instagram") > -1 ? true : false;
        var str = navigator.userAgent;
        var i = str.indexOf("Instagram");
        if (isInstagram) {
          if (/iPad|iPhone|iPod/.test(ua)) {
            setInstagramBrowser(true);
            setDisplaySafariPopUp(isIOS);
            //window.location.href = 'googlechrome://example.com';
            return;
          } else {
            // Web page is not opened from Instagram browser
            setDisplaySafariPopUp(isIOS);
            console.log("from iphone", isIOS);
            if (
              (isIOS &&
                "standalone" in window.navigator &&
                window.navigator.standalone) ||
              !isIOS
            ) {
              if(Notification.permission === "granted"){
                localStorage.setItem("askedPermission", true);
                
                Notifications()
              } else if (Notification.permission === "denied"){
                localStorage.setItem("askedPermission", true);
              }
            }
          }
        } else {
          const isIOS =
            /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          // Web page is not opened from Instagram browser
          setDisplaySafariPopUp(isIOS);
          console.log("from iphone", isIOS);
          if (
            (isIOS &&
              "standalone" in window.navigator &&
              window.navigator.standalone) ||
            !isIOS
          ) {
            if(Notification.permission === "granted"){
              localStorage.setItem("askedPermission", true);

              Notifications()
            } else if (Notification.permission === "denied"){
              localStorage.setItem("askedPermission", true);

            }
          }
        }
      }
    } catch(error){
      console.log(error)
    }
  }, []);

  useEffect(() => {
    if (permission === true && userData.pushToken !== null) {
      if (!instagramBrowser) {
        dispatch(UpdatePushToken({
          id: userData.userId,
          pushToken: userData.pushToken
        }));
      }
    }
  }, [permission]);

  useEffect( () => {
    setDataFollower(userFollower);
    if (firstTime === true) {
      setOpenFollower(true);
    }
  }, [])

  return (
    <div className="h-screen w-screen flex flex-col justify-start fixed top-0 left-0">
      <Navbar />

      <div className={`flex flex-1  ${isSafari ? "" : "h-screen"}`} style={{ height: `${isSafari ? "-webkit-fill-available" : `${windowHeight - 64 }px`}`}}>
        <div style={{borderRight:"1px solid #e5e7eb"}} className={`flex flex-col relative overflow-y-auto ${shouldShowSidebar ? "w-full" : "hidden"} ${isIpad ? "sm:w-[33%]" : "sm:w-1/4"} sm:block`}>
          <InboxSidebar />
        </div>

        <div className={`flex flex-col flex-1 bg-gray-100 ${shouldShowOutlet ? "w-full" : "hidden"} sm:block`}>
          <Outlet />
        </div>
      </div>
  
      {showPopUpWelcome === true && popUpWelcome === null && (
        <WelcomeFavichat showBtn={false} isGuest={isGuest} />
      )}
      {showPopUpWelcome === true && askedPermission !== "true" && !isIpad && result && (
        <NotificationsPopUp setPermission={setPermission} userData={userData} />
      )}

      {openFollower && isTargetPath && (
        <div className="userModal_open">
          <UserModal showPopUpWelcome={showPopUpWelcome} info={dataFollower} close={handleCloseDataFollower} />
        </div>
      )}

    </div>
  );
}

export default InboxPage;
