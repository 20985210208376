import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import termsPdf from "../../Document/Terms.pdf";
export default function AllPages(props) {
  return (
    <div className="w-full h-full relative">
      <Worker
        className="w-full"
        workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js"
      >
        <Viewer fileUrl={termsPdf} />
      </Worker>
    </div>
  );
}
