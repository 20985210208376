import { v4 as uuidv4 } from "uuid";
import constants from "../../axios/constants";
import { db } from "../../Config/configurations";
import { authApi, Url, useAxios } from "../../axios/authAPI";
import { doc, updateDoc, getDoc, setDoc, collection, query, where, getDocs, orderBy, startAfter, limit, onSnapshot, collectionGroup } from "firebase/firestore";


let unsubscribeConversations = null;
let unsubscribeList = [];

let unsubscribeUserData = null;
let unsubscribeNotifications = null;
let unsubscribeCurrentChatUser = null;
let unsubscribeBockUserToMe = null;
let unsubscribeMessages = null;
let unsubscribeTyping = null;
let unsubscribeBlockUser = null;


/* Reset states in Redux */
export const ResetState = () => ({
    type: 'RESET_STATE'
});

/* Reset states in Redux */
export const ResetNotUserState = () => ({
  type: 'RESET_NOT_USER_STATE'
});

/* Reset states in Redux */
export const DiscoverModalProfileState = (data) => ({
    type: 'DISCOVER_MODAL_PROFILE_STATE',
    payload: data
});

/* Update popup welcome Redux */
export const UpdatePopUpWelcomeState = (data) => ({
    type: 'POPUP_WELCOME_STATE',
    payload: data
});

/* Update is guest Redux */
export const UpdateIsGuestState = (data) => ({
    type: 'IS_GUEST_STATE',
    payload: data
});

/* Update data guest Redux */
export const UpdateDataGuestState = (data) => ({
    type: 'DATA_GUEST_STATE',
    payload: data
});

/* Update guest device Redux */
export const UpdateDeviceGuestState = (data) => ({
    type: 'DEVICE_GUEST_STATE',
    payload: data
});

/* Update data guest Redux */
export const UpdateDataUserSignUpState = (data) => ({
    type: 'DATA_USER_SIGNUP_STATE',
    payload: data
});

/* Update is login Redux */
export const UpdateIsLogInState = (data) => ({
    type: 'IS_LOGIN_STATE',
    payload: data
});

/* Update error login Redux */
export const UpdateErrorLogInState = (data) => ({
    type: 'ERROR_LOGIN_STATE',
    payload: data
});

/* Update user name code Redux */
export const UpdateUserNameCodeState = (data) => ({
    type: 'USER_NAME_CODE_STATE',
    payload: data
});

/* Update mobile navbar toggle Redux */
export const UpdateMobileNavbarToggleState = (data) => ({
    type: 'MOBILE_NAVBAR_TOGGLE_STATE',
    payload: data
});

/* Update loading Redux */
export const UpdateLoadingState = (data) => ({
    type: 'LOADING_LOGIN_STATE',
    payload: data
});

/* Update settings sidebar toggle Redux */ 
export const UpdateSettingSidebarToggleState = (data) => ({
    type: 'SETTING_SIDEBAR_TOGGLE_STATE',
    payload: data
});

/* Update inbox sidebar toggle Redux */ 
export const UpdateInboxBarToggleState = (data) => ({
    type: 'INBOXBAR_TOGGLE_STATE',
    payload: data
});

/* Update ask permission Redux */ 
export const UpdateAskPermissionState = (data) => ({
    type: 'UPDATE_ASK_PERMISSION_STATE',
    payload: data
});

/* Update ask permission Redux */ 
export const UpdateFirstTimeState = (data) => ({
    type: 'UPDATE_FIRST_TIME_STATE',
    payload: data
});

/* Update push token Redux */ 
export const UpdatePushTokenState = (data) => ({
    type: 'REMOVE_PUSH_TOKEN_STATE',
    payload: data
});

/* Update send all toggle Redux */ 
export const UpdateSendAllToggleState = (data) => ({
    type: 'UPDATE_SEND_ALL_TOGGLE_STATE',
    payload: data
});

/* Clean current chat Redux */ 
export const CleanCurrentChatState = (data) => ({
    type: 'CLEAN_CURRENT_CHAT_STATE',
    payload: data
});

/* Clean current chat Redux */ 
export const UpdateCurrentConversationIdState = (data) => ({
    type: 'UPDATE_CURRENT_CONVERSATION_ID_STATE',
    payload: data
});

/* Clean current chat Redux */ 
export const UpdatePurchasePopToggleState = (data) => ({
    type: 'UPDATE_PURCHASE_POP_TOGGLE_STATE',
    payload: data
});

/* Clean current chat Redux */ 
export const UpdateUserHeartsState = (data) => ({
    type: 'UPDATE_USER_HEARTS_STATE',
    payload: data
});

/* Update send all tog Redux */ 
export const UpdateSendAllTogState = (data) => ({
  type: 'UPDATE_SEND_ALL_TOG_STATE',
  payload: data
});

/* Update send all tog Redux */ 
export const UpdateCurrentChatUserIdState = (data) => ({
  type: 'UPDATE_CURRENT_CHAT_USER_ID_STATE',
  payload: data
});

/* Update data discovery Redux */ 
export const UpdateDataDiscoveryState = (data) => ({
  type: 'UPDATE_DATA_DISCOVERY_STATE',
  payload: data
});

/* Update data discovery follor Redux */ 
export const UpdateDataDiscoveryFollowState = (data) => ({
  type: 'UPDATE_DATA_DISCOVERY_FOLLOW_STATE',
  payload: data
});

/* Clean data discovery follor Redux */ 
export const CleanDataDiscoveryState = (data) => ({
  type: 'CLEAN_DATA_DISCOVERY_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateDiscoveryModalProfileState = (data) => ({
  type: 'UPDATE_DISCOVER_MODAL_PROFILE_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateIsFollowingState = (data) => ({
  type: 'UPDATE_IS_FOLLOWING_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateDataFollowingState = (data) => ({
  type: 'UPDATE_DATA_FOLLOWING_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateDataFollowersState = (data) => ({
  type: 'UPDATE_FOLLOWERS_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const CleanDataFollowersState = (data) => ({
  type: 'CLEAN_DATA_FOLLOWERS_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateInterestPageState = (data) => ({
  type: 'UPDATE_INTEREST_PAGE_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const CleanInterestState = (data) => ({
  type: 'CLEAN_INTEREST_PAGE_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateScrollPositionState = (data) => ({
  type: 'UPDATE_SCROLL_POSITION_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateUserFollowerState = (data) => ({
  type: 'UPDATE_USER_FOLLOWER_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateDataUserState = (data) => ({
  type: 'DATA_USER_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateDataUserProfilePicState = (data) => ({
  type: 'UPDATE_USER_PROFILE_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateMobileAppCashoutState = (data) => ({
  type: 'UPDATE_MOBILE_APP_CASHOUT_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateMobileAppHeartState = (data) => ({
  type: 'UPDATE_MOBILE_APP_HEART_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateMobileAppSuccessState = (data) => ({
  type: 'UPDATE_MOBILE_APP_SUCCESS_STATE',
  payload: data
});

/* Update profile modal discovery Redux */ 
export const UpdateUserDataInterestState = (data) => ({
  type: 'UPDATE_USER_DATA_INTEREST_STATE',
  payload: data
});





/*  ***************************************************************************     */



/* Function login Redux */
export const LogInState = (data, navigate, isGuest) => {
    return (dispatch) => {
        dispatch({ type: 'LOADING_LOGIN_STATE', payload: true });

        return authApi.post(`/users/${constants.login}?isGuest=false`, data)
            .then(async (res) => {
                const user = res.data?.data;
                const accessToken = res?.data?.acessToken;
                const refreshToken = res?.data?.refreshToken;

                dispatch({ type: 'DATA_USER_STATE', payload: {
                    ["GSI1-PK"]: user["GSI1-PK"],
                    PK: user.PK,
                    SK: user.SK,
                    bio: user.bio,
                    cashOut: user.cashOut,
                    contentUnLockUsers: user.contentUnLockUsers,
                    countryCode: user.countryCode,
                    createdAt: user.createdAt,
                    dob: user.dob,
                    earningsToDate: user.earningsToDate,
                    fbFollowers: user.fbFollowers,
                    firebaseUid: user.firebaseUid,
                    hearts: user.hearts,
                    userId: user.id,
                    instaFollowers: user.instaFollowers,
                    interests: user.interests,
                    name: user.name,
                    phoneNumber: user.phoneNumber,
                    profilePicUrl: user.profilePic,
                    privacyTag: "EveryOne", 
                    profileViewed: user.profileViewed,
                    totalFollowers: user.totalFollowers,
                    totalFollowing: user.totalFollowing,
                    twitterFollowers: user.twitterFollowers,
                    updatedAt: user.updatedAt,
                    userName: user.userName,
                    youtubeFollowers: user.youtubeFollowers
                }});

                dispatch({ type: 'IS_LOGIN_STATE', payload: true });
                dispatch({ type: 'POPUP_WELCOME_STATE', payload: true });

                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);

                dispatch(UpdateUserDataSignUp({
                  userId: user.id,
                  bio: user.bio,
                  dob: user.dob,
                  isOnline: true,
                  name: user.name,
                  userName: user.userName,
                  id: user.id,
                  privacyTag: "EveryOne",
                  totalFollowers: user.totalFollowers,
                  photoUrl: user.profilePic,
                  phoneNumber: user.phoneNumber,
                  createdAt: user.createdAt,
                  chattingWith: null,
                  typing: null,
                  pushToken: null,
                  isGuest: false,
                  hearts: user.hearts,
                }, navigate, isGuest))

            })
            .catch((err) => {
                if (err?.message.includes("Network Error")) {
                    dispatch({ type: 'ERROR_LOGIN_STATE', payload: "We've been facing some server issues, Please try again later."});
                } else {
                    if(err?.message.includes("403")){
                        let contactStr = "admin@favi.fun";

                        dispatch({ type: 'ERROR_LOGIN_STATE', payload: `Your account is under review, contact us at ${contactStr}`});
                    } else {
                        dispatch({ type: 'ERROR_LOGIN_STATE', payload: "Oops, incorrect username or password, try again."});
                    }
                }
            })
    }
};

/* Function logout Redux */
export const SignUpData = (data, isGuest, navigate) => {
    return (dispatch) => {
        dispatch({ type: 'LOADING_LOGIN_STATE', payload: true });

        const url = isGuest ? constants.guestSignup : constants.signUp;

        return authApi.post(`/users/${url}`, data)
            .then(async (res) => {
            dispatch(UpdateIsGuestState(false));
                dispatch(LogInState({
                    userName: data.userName,
                    password: data.password,
                    profilePic: data.profilePic
                }, navigate, isGuest));
  
            })
            .catch((err) => {
                if (err?.message.includes("Network Error")) {
                    dispatch({ type: 'ERROR_LOGIN_STATE', payload: "We've been facing some server issues, Please try again later."});
                } else {
                    const data = err?.response?.data?.error?.message;
                    dispatch({ type: 'ERROR_LOGIN_STATE', payload: data});
                }
            })

    }
};

/* Function update profilePic Url in Redux */
export const UpdateProfilePicUrl = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            if (state.currentChat.participants !== undefined) {
        
                const updateOnChat = state.currentChat.participants.findIndex((participantInformation) => participantInformation.userId === data.profilePicUrl[0]?.userId);

                if (updateOnChat !== -1) {

                  const updatedParticipants = [...state.currentChat.participants];

                  updatedParticipants[updateOnChat] = {
                    ...updatedParticipants[updateOnChat],
                    photoUrl: data.profilePicUrl[0].photoUrl,
                  };

                  dispatch({ type: 'UPDATE_CURRENT_PARTICIPANTS_STATE', payload: updatedParticipants });
                }
              }
            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function typing chatbox in Redux */
export const UpdateTyping = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            if (data.userId === undefined) {
                if (state.currentChat.userId === data[0].userId) { 
                    dispatch({ type: 'UPDATE_TYPING_STATE', payload: {
                        username: data[0].username,
                        userId: data[0].userId,
                        photoUrl: data[0].photoUrl
                    }});
                } else {
                    dispatch({ type: 'UPDATE_TYPING_STATE', payload: {
                        username: null,
                        userId: null,
                        photoUrl: null
                    }});
                }
            }
            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function save conversations to Redux */
export const SaveConversations = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            if (data.length === 1) {
                let newConversation =  data[0];
                newConversation["participants"] = data[0].participants.filter((e) => e !== state.user.userId);

                const index = state.allConversations.findIndex((value) => value.id === newConversation.conversationId);
                
                const aConversations = [...state.allConversations];
                if (index !== -1) {
                    aConversations.splice(index, 1);
                    aConversations.unshift(newConversation);
                } else {
                    aConversations.push(newConversation);
                }

                const deleteNewConversation = aConversations.filter(({ lastMessage }) => {
                  if (lastMessage.senderId !== state.user.userId) return true;
                  if (lastMessage.senderId === state.user.userId && !lastMessage.isNewConversation) return true;
                  return false;
                });

                const filteredConversations = deleteNewConversation.filter((conversation) =>  {
                  return !(conversation.readByCounter.length === 0 && conversation.lastMessage.senderId !== state.user.userId);
                });

                filteredConversations.sort((a, b) => {
                  const getTimestamp = (obj) => {
    
                    const getReadByCounterFollower = obj.readByCounter.findIndex((index) => index.userId !== state.user.userId);
                    const getReadByCounterUser = obj.readByCounter.findIndex((index) => index.userId === state.user.userId);
    
                    if (obj.readByCounter.length !== 0) {
                      if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp !== "") {
                        return obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp;
                      } else if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp !== "") {
                        return obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp;
                      } else if (obj.lastMessage.sendAllTimestamp !== "") {
                        return obj.lastMessage.sendAllTimestamp;
                      } else {
                        return obj.lastMessage.timestamp;
                      }
                    } else {
                      return obj.lastMessage.timestamp; 
                    }
                  };
        
                  const timestampA = getTimestamp(a);
                  const timestampB = getTimestamp(b);
        
                  return timestampB - timestampA;
                });

                dispatch({ type: 'UPDATE_CONVERSATIONS_STATE', payload: {
                    conversations: filteredConversations,
                    allConversations: aConversations,
                    statusScrollChatList: "",
                }});

                if(window.location.pathname.startsWith(`/inbox/${state.currentChat.userId}`)) {
                  const indexConversation = aConversations.findIndex(
                    (value) => value.conversationId === state.currentConversationId
                  );

                  if (indexConversation !== -1){
                    const indexRead = aConversations[indexConversation].readByCounter.findIndex(
                      (value) => value.userId === state.user.userId
                    );

                    if (indexRead !== -1 && (aConversations[indexConversation].readByCounter[indexRead].unReadCounter !== 0 || (aConversations[indexConversation].readByCounter[indexRead].unReadContentCounter !== 0 && aConversations[indexConversation].readByCounter[indexRead].unReadContentCounter !== undefined))) {
                      const aReadyByCounter = JSON.parse(JSON.stringify(aConversations[indexConversation].readByCounter));
                      aReadyByCounter[indexRead].unReadCounter = 0;
                      aReadyByCounter[indexRead].unReadContentCounter = 0;
                      aReadyByCounter[indexRead].lastProvisionalTimestamp = aReadyByCounter[indexRead].lastProvisionalTimestamp;

                      await updateDoc(doc(db, "conversations", state.currentConversationId), {
                        readByCounter: aReadyByCounter,
                      });
                    }
                  }
                }

            } else {

                const gruposVistos = {};
                const newConversation = data.filter((obj) =>
                    !gruposVistos[obj.conversationId] &&
                    (gruposVistos[obj.conversationId] = true)
                );

                const deleteNewConversation = newConversation.filter(({ lastMessage }) => {
                  if (lastMessage.senderId !== state.user.userId) return true;
                  if (lastMessage.senderId === state.user.userId && !lastMessage.isNewConversation) return true;
                  return false;
                });

                const filteredConversations = deleteNewConversation.filter((conversation) =>  {
                  return !(conversation.readByCounter.length === 0 && conversation.lastMessage.senderId !== state.user.userId);
                });

                filteredConversations.sort((a, b) => {
                  const getTimestamp = (obj) => {
    
                    const getReadByCounterFollower = obj.readByCounter.findIndex((index) => index.userId !== state.user.userId);
                    const getReadByCounterUser = obj.readByCounter.findIndex((index) => index.userId === state.user.userId);
    
                    if (obj.readByCounter.length !== 0) {
                      if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp !== "") {
                        return obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp;
                      } else if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp !== "") {
                        return obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp;
                      } else if (obj.lastMessage.sendAllTimestamp !== "") {
                        return obj.lastMessage.sendAllTimestamp;
                      } else {
                        return obj.lastMessage.timestamp;
                      }
                    } else {
                      return obj.lastMessage.timestamp; 
                    }
                  };
        
                  const timestampA = getTimestamp(a);
                  const timestampB = getTimestamp(b);
        
                  return timestampB - timestampA;
                });

                dispatch({ type: 'UPDATE_CONVERSATIONS_STATE', payload: {
                    conversations: filteredConversations,
                    allConversations: newConversation,
                    statusScrollChatList: "",
                }});
            }
            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update conversations to Redux */
export const UpdateConversations = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const index = state.allConversations.findIndex((value) => value.id === data[0].id);

            const aConversations = [...state.allConversations];

            if (JSON.stringify(aConversations[index]) !== JSON.stringify(data[0])) {
              
              if (index !== -1) {
                  aConversations.splice(index, 1);
                  aConversations.unshift(data[0]);
              } else {
                  aConversations.push(data[0]);
              }

              const deleteNewConversation = aConversations.filter(({ lastMessage }) => {
                if (lastMessage.senderId !== state.user.userId) return true;
                if (lastMessage.senderId === state.user.userId && !lastMessage.isNewConversation) return true;
                return false;
              });

              const filteredConversations = deleteNewConversation.filter((conversation) =>  {
                return !(conversation.readByCounter.length === 0 && conversation.lastMessage.senderId !== state.user.userId);
              });

              filteredConversations.sort((a, b) => {
                const getTimestamp = (obj) => {

                  const getReadByCounterFollower = obj.readByCounter.findIndex((index) => index.userId !== state.user.userId);
                  const getReadByCounterUser = obj.readByCounter.findIndex((index) => index.userId === state.user.userId);

                  if (obj.readByCounter.length !== 0) {
                    if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp !== "") {
                      return obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp;
                    } else if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp !== "") {
                      return obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp;
                    } else if (obj.lastMessage.sendAllTimestamp !== "") {
                      return obj.lastMessage.sendAllTimestamp;
                    } else {
                      return obj.lastMessage.timestamp;
                    }
                  } else {
                    return obj.lastMessage.timestamp; 
                  }
                };
      
                const timestampA = getTimestamp(a);
                const timestampB = getTimestamp(b);
      
                return timestampB - timestampA;
              });

              dispatch({ type: 'UPDATE_CONVERSATIONS_STATE', payload: {
                  conversations: filteredConversations,
                  allConversations: aConversations,
              }});

              if(window.location.pathname.startsWith(`/inbox/${state.currentChat.userId}`)) {
                const indexConversation = aConversations.findIndex(
                  (value) => value.conversationId === state.currentConversationId
                );

                if (indexConversation !== -1){
                  const indexRead = aConversations[indexConversation].readByCounter.findIndex(
                    (value) => value.userId === state.user.userId
                  );

                  if (indexRead !== -1 && (aConversations[indexConversation].readByCounter[indexRead].unReadCounter !== 0 || (aConversations[indexConversation].readByCounter[indexRead].unReadContentCounter !== 0 && aConversations[indexConversation].readByCounter[indexRead].unReadContentCounter !== undefined))) {
                    const aReadyByCounter = JSON.parse(JSON.stringify(aConversations[indexConversation].readByCounter));
                    aReadyByCounter[indexRead].unReadCounter = 0;
                    aReadyByCounter[indexRead].unReadContentCounter = 0;
                    aReadyByCounter[indexRead].lastProvisionalTimestamp = aReadyByCounter[indexRead].lastProvisionalTimestamp;

                    await updateDoc(doc(db, "conversations", state.currentConversationId), {
                      readByCounter: aReadyByCounter,
                    });
                  }
                }
              }
            } else {
              if(window.location.pathname.startsWith(`/inbox/${state.currentChat.userId}`)) {
                const indexConversation = aConversations.findIndex(
                  (value) => value.conversationId === state.currentConversationId
                );

                if (indexConversation !== -1){
                  const indexRead = aConversations[indexConversation].readByCounter.findIndex(
                    (value) => value.userId === state.user.userId
                  );

                  if (indexRead !== -1 && (aConversations[indexConversation].readByCounter[indexRead].unReadCounter !== 0 || (aConversations[indexConversation].readByCounter[indexRead].unReadContentCounter !== 0 && aConversations[indexConversation].readByCounter[indexRead].unReadContentCounter !== undefined))) {
                    const aReadyByCounter = JSON.parse(JSON.stringify(aConversations[indexConversation].readByCounter));
                    aReadyByCounter[indexRead].unReadCounter = 0;
                    aReadyByCounter[indexRead].unReadContentCounter = 0;
                    aReadyByCounter[indexRead].lastProvisionalTimestamp = aReadyByCounter[indexRead].lastProvisionalTimestamp;

                    await updateDoc(doc(db, "conversations", state.currentConversationId), {
                      readByCounter: aReadyByCounter,
                    });
                  }
                }
              }
            }

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function remove conversations to Redux */
export const RemoveConversations = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const index = state.allConversations.findIndex((value) => value.id === data[0]);
            const aConversations = [...state.allConversations];

            if (index !== -1) {
                aConversations.splice(index, 1);
            }

            dispatch({ type: 'UPDATE_CONVERSATIONS_STATE', payload: {
                conversations: aConversations,
                allConversations: aConversations,
            }});

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function search conversations to Redux */
export const SearchConversations = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            if (data.search === "") {
              dispatch({ type: 'SEARCH_CONVERSATIONS_STATE_CLEAN' });
            } else {
              const result = state.conversations.filter((item) => {
                const receiverId = item.participants[0];
                const index = item.participantsInformation.findIndex(
                  (value) => value.userId === receiverId
                );
                const actualName = item.participantsInformation[index].name === "Guest" && item.participantsInformation[index].isGuest === true
                  ? `Guest ${item.participantsInformation[index].username.split('-')[1]?.slice(-5)}` 
                  : item.participantsInformation[index].name;

                return actualName?.toLowerCase().startsWith(data.search.toLowerCase().trim());
              });

              dispatch({ type: 'SEARCH_CONVERSATIONS_STATE', payload: result });
            }

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update current chat data to Redux */
export const UpdateCurrentChatData = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            if (state.currentChat.userId === data.userId) {

                const index = state.currentChat.participants.findIndex((value) => value.userId === data.userId);

                if (index === -1 ||state.currentChat.participants[index].photoUrl === data.photoUrl) {

                    dispatch({ type: 'UPDATE_CURRENT_CHAT_DATA_STATE', payload: {
                        online: data.online,
                        username: data.username,
                        userId: data.userId,
                        privacyTag: data.privacyTag,
                        photoUrl: data.photoUrl,
                        userWithoutConversation: data.userWithoutConversation,
                        name: data.name,
                        isDisable: data.isDisable,
                        isGuest: data.isGuest,
                    }});
                } else {
                    const participants = [...state.currentChat.participants];

                    if (participants[index].photoUrl !== data.photoUrl) {
                        participants[index].photoUrl = data.photoUrl;

                        dispatch({ type: 'UPDATE_CURRENT_PARTICIPANTS_STATE', payload: participants });
                    }
        
                    dispatch({ type: 'UPDATE_CURRENT_CHAT_DATA_STATE', payload: {
                        online: data.online,
                        username: data.username,
                        userId: data.userId,
                        privacyTag: data.privacyTag,
                        photoUrl: data.photoUrl,
                        userWithoutConversation: data.userWithoutConversation,
                        name: data.name,
                        isDisable: data.isDisable,
                        isGuest: data.isGuest,
                    }});
                }
            }
            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update read message to Redux */
export const UpdateReadMessage = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const index = state.currentChat.messages.findIndex((value) =>
                value.senderId === data.senderId &&
                value.timestamp === data.timestamp &&
                value.content === data.content
            );

            const aConversations = [...state.currentChat.messages];

            if (index !== -1) {
              aConversations.splice(index, 1, data);
            }

            dispatch({ type: 'UPDATE_CURRENT_MESSAGES_STATE', payload: aConversations });

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update current participants to Redux */
export const UpdateCurrentParticipants = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const updateOnConversation = state.allConversations.findIndex((conversation) => conversation.conversationId === data);
            if(updateOnConversation === -1) {
              const getParticipantInformation = async (document) => {
                return getDoc(doc(db, "users", document.id)).then((snap) => ({
                  ...document.data(),
                  userId: document.id,
                  name: snap.data().name,
                  isGuest: snap.data().isGuest
                }));
              };

              const q = query(
                collection(db, `conversations`),
                where("participants", "array-contains", state.user.userId))
                
              const querySnapshot = await getDocs(q);
              let currentChatId = null;
              querySnapshot.forEach((doc) => {
                if(doc.data().participants.find((participant) => participant === state.currentChat.userId)) {
                  currentChatId = doc.id;
                }
              });
              if(currentChatId === null) return;
              const participantsInformation = await getDocs(collection(db, "conversations", currentChatId, "participants"))
                .then((participantSnapshot) => {
                    const participantPromises = participantSnapshot.docs.map(getParticipantInformation);
                    return Promise.all(participantPromises);
                })
              dispatch({ type: 'UPDATE_CURRENT_PARTICIPANTS_STATE', payload: participantsInformation});
              return "Ok"
            }
            dispatch({ type: 'UPDATE_CURRENT_PARTICIPANTS_STATE', payload: state.allConversations[updateOnConversation].participantsInformation});

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update current participants to Redux */
export const UpdateMessagesConversationOne = (data) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();

            if (state.currentChat.userId === data.senderId || state.user.userId === data.senderId) {

                let newMessages = [...state.currentChat.messages];
                newMessages.push(data);
        
                newMessages.sort(function (a, b) {
                  return a.timestamp - b.timestamp;
                });
        
                newMessages = newMessages.filter((message, index, self) => {
                  return (
                    index ===
                    self.findIndex(
                      (m) =>
                        m.timestamp === message.timestamp &&
                        m.content === message.content &&
                        m.contentBlur === message.contentBlur &&
                        m.contentUrl === message.contentUrl
                    )
                  );
                });
        
                dispatch({ type: 'UPDATE_CURRENT_MESSAGES_STATE', payload: newMessages});
                
                if(window.location.pathname.startsWith(`/inbox/${state.currentChat.userId}`)) {
                  
                  const notRead = newMessages.filter(
                    (i) => i.readBy[0].read === false && i.readBy[0].userId === state.user.userId
                  );

                  if (notRead.length !== 0) {
                    for (let index = 0; index < notRead.length; index++) {
                      await updateDoc(doc(db,"conversations",state.currentConversationId,"messages",notRead[index].id),{
                        readBy: [{
                          userId: notRead[index].readBy[0].userId,
                          read: true,
                        },],
                      });
                    }
                  }
                }

              }
            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update current participants to Redux */
export const UpdateMessagesConversation = (data) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();

            const newMessages = data.filter((message) => {
                return !state.currentChat.messages.some(
                  (existingMessage) => existingMessage.id === message.id
                );
            });
        
            let aMessages = [...newMessages, ...state.currentChat.messages];
        
            aMessages.sort(function (a, b) {
                return a.timestamp - b.timestamp;
            });
        
            aMessages = aMessages.filter((message, index, self) => {
                return (
                  index ===
                  self.findIndex(
                    (m) =>
                      m.timestamp === message.timestamp &&
                      m.content === message.content &&
                      m.contentBlur === message.contentBlur &&
                      m.contentUrl === message.contentUrl
                  )
                );
            });

            dispatch({ type: 'UPDATE_CURRENT_MESSAGES_STATE', payload: aMessages});
            
            if(window.location.pathname.startsWith(`/inbox/${state.currentChat.userId}`)) {
              
              const notRead = aMessages.filter(
                (i) => i.readBy[0].read === false && i.readBy[0].userId === state.user.userId
                );
                
                if (notRead.length !== 0) {
                  for (let index = 0; index < notRead.length; index++) {
                    await updateDoc(doc(db,"conversations",state.currentConversationId,"messages",notRead[index].id),{
                      readBy: [{
                        userId: notRead[index].readBy[0].userId,
                        read: true,
                      },],
                    });
                }
              }
            }

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function update current participants to Redux */
export const DeleteConversationOption = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const index = state.allConversations.findIndex(
                (value) => value.conversationId === data.conversationId
            )

            const aConversations = [...state.allConversations];
            if (index !== -1) {
                aConversations.splice(index, 1);
            }
        
            const deleteNewConversation = aConversations.filter(({ lastMessage }) => {
              if (lastMessage.senderId !== state.user.userId) return true;
              if (lastMessage.senderId === state.user.userId && !lastMessage.isNewConversation) return true;
              return false;
            });

            const filteredConversations = deleteNewConversation.filter((conversation) =>  {
              return !(conversation.readByCounter.length === 0 && conversation.lastMessage.senderId !== state.user.userId);
            });

            filteredConversations.sort((a, b) => {
              const getTimestamp = (obj) => {

                const getReadByCounterFollower = obj.readByCounter.findIndex((index) => index.userId !== state.user.userId);
                const getReadByCounterUser = obj.readByCounter.findIndex((index) => index.userId === state.user.userId);

                if (obj.readByCounter.length !== 0) {
                  if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp !== "") {
                    return obj.readByCounter[getReadByCounterUser].lastProvisionalTimestamp;
                  } else if (obj.lastMessage.senderId === state.user.userId && obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp !== "") {
                    return obj.readByCounter[getReadByCounterFollower].lastProvisionalTimestamp;
                  } else if (obj.lastMessage.sendAllTimestamp !== "") {
                    return obj.lastMessage.sendAllTimestamp;
                  } else {
                    return obj.lastMessage.timestamp;
                  }
                } else {
                  return obj.lastMessage.timestamp; 
                }
              };
    
              const timestampA = getTimestamp(a);
              const timestampB = getTimestamp(b);
    
              return timestampB - timestampA;
            });

            // dispatch({ type: 'SEARCH_CONVERSATIONS_STATE', payload: filteredConversations});
            dispatch({ type: 'UPDATE_CURRENT_MESSAGES_STATE', payload: []});

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Function block users add to Redux */
export const BlockUsersAddOption = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          if (data.length > 1) {
            dispatch({ type: 'UPDATE_BLOCK_USERS_DATA_STATE', payload: data});
          } else if (data.length === 1) {
            const exists = state.blockUsers.some((obj) =>
              obj.userId === data[0].userId &&
              obj.userName === data[0].userName
            );

            if (!exists) {
              const aBlockUsers = [...state.blockUsers];
              aBlockUsers.push(data[0]);
              dispatch({ type: 'UPDATE_BLOCK_USERS_DATA_STATE', payload: aBlockUsers});
            }
          }

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Function update data following to Redux */
export const UpdateDiscoveryData = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          const newFollowData = state.dataDiscovery;

          newFollowData.map((items) =>
            items.values.map((user) => {
              if (user?.id === data) {
                user.is_following = true;
                user.totalFollowers = user.totalFollowers + 1;
              }
            })
          );
          
          dispatch({ type: 'UPDATE_DISCOVERY_DATA_STATE', payload: newFollowData});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Function update data following to Redux */
export const UpdateDataUnFollowing = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();
          
          const newUnFollowData = state.dataDiscovery;

          newUnFollowData.map((items) =>
            items.values.map((user) => {
              if (user?.id === data) {
                user.is_following = false;
                user.totalFollowers = user.totalFollowers - 1;
              }
            })
          );

          dispatch({ type: 'UPDATE_DISCOVERY_DATA_STATE', payload: newUnFollowData});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Function update data following to Redux */
export const UpdateDataFollowingPage = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();
          
          const newFollowingData = state.dataFollowing;

          newFollowingData.map((item) => {
            if (item.follower.id === data) {
              item.follower.totalFollowers = item.follower.totalFollowers + 1;
              item.is_following = true;
            }
          });

          dispatch({ type: 'UPDATE_DATA_FOLLOWING_STATE', payload: newFollowingData});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Function update data following to Redux */
export const UpdateDataUnFollowingPage = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();
          
          const newUnFollowingData = state.dataFollowing;

          newUnFollowingData.map((item) => {
            if (item.follower.id === data) {
              item.follower.totalFollowers = item.follower.totalFollowers - 1;
              item.is_following = false;
            }
          });

          dispatch({ type: 'UPDATE_DATA_FOLLOWING_STATE', payload: newUnFollowingData});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Update profile modal discovery Redux */ 
export const UpdateDataInterestState = (data) => {
  return async (dispatch, getState) => {
    try {

        const state = getState();
        
        const newUnFollowData = state.dataInterest;

        newUnFollowData.map((item) => {
          if (item?.id === data) {
            item.is_following = false;
            item.totalFollowers = item.totalFollowers - 1;
          }
        });


        dispatch({ type: 'UPDATE_DATA_INTEREST_STATE', payload: newUnFollowData});

        return 'Ok';
    } catch (error) {
        console.log(`Error updateOnlineUser ${error}`)
    } 
  }
}

/* Update profile modal discovery Redux */ 
export const UpdateDataInterestFollowState = (data) => {
  return async (dispatch, getState) => {
    try {

        const state = getState();
        
        const newFollowData = state.dataInterest;

        newFollowData.map((item) => {
          if (item?.id === data) {
            item.is_following = true;
            item.totalFollowers = item.totalFollowers + 1;
          }
        });


        dispatch({ type: 'UPDATE_DATA_INTEREST_STATE', payload: newFollowData});

        return 'Ok';
    } catch (error) {
        console.log(`Error updateOnlineUser ${error}`)
    } 
  }
}

/* Function update data following to Redux */
export const UpdateDataFollowersPage = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();
          
          const newFollowersData = state.dataFollowers;

          newFollowersData.map((item) => {
            if (item.follower.id === data) {
              item.follower.totalFollowers = item.follower.totalFollowers + 1;
              item.is_following = true;
            }
          });

          dispatch({ type: 'UPDATE_FOLLOWERS_STATE', payload: newFollowersData});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Function update data following to Redux */
export const UpdateDataUnFollowersPage = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();
          
          const newUnFollowersData = state.dataFollowers;

          newUnFollowersData.map((item) => {
            if (item.follower.id === data) {
              item.follower.totalFollowers = item.follower.totalFollowers - 1;
              item.is_following = false;
            }
          });

          dispatch({ type: 'UPDATE_FOLLOWERS_STATE', payload: newUnFollowersData});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};



/*  ***************************************************************************     */



/* Update online/offline user in Firebase */
export const UpdateOnlineUser = (data) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();

            await updateDoc(doc(db,"users",data.userId === undefined ? state.user.userId : data.userId),{
                isOnline: data.online,
            });

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update user data sign up in Firebase */
export const UpdateUserDataSignUp = (data, navigate, isGuest) => {
    return async (dispatch, getState) => {
        try {

          const state = getState();

            const docRef = doc(db, "users", `${data.userId}`);
            const docSnap = await getDoc(docRef);

            const userNameSignUp = localStorage.getItem("guestUserName") === null ? data.userName : localStorage.getItem("guestUserName");

            const q = query(collectionGroup(db, "participants"),where("username", "==", userNameSignUp));
            const querySnapshot = await getDocs(q);

            if (docSnap.exists()) {
                await updateDoc(docRef, {
                  bio: data.bio,
                  dob: data.dob,
                  isOnline: true,
                  name: data.name.replace(/\s{2,}/g, " ").trim(),
                  userName: data.userName,
                  userId: data.userId,
                  privacyTag: docSnap.data().privacyTag === null || docSnap.data().privacyTag === undefined ? "EveryOne" : docSnap.data().privacyTag,
                  totalFollowers: data.totalFollowers,
                  photoUrl: data.photoUrl == undefined ? null : data.photoUrl,
                  phoneNumber: data.phoneNumber,
                  createdAt: `${Date.now()}`,
                  pushToken: null,
                  isGuest: data.isGuest,
                  hearts: data.hearts,
                });

                if(isGuest) {
                  dispatch({ type: 'UPDATE_USER_PROFILE_STATE', payload: data.photoUrl});

                  if (isGuest !== undefined) {
                    await updateDoc(doc(db, "users", state.user.userId), {
                      photoUrl: data.photoUrl,
                      userName: data.userName,
                      name: data.name,
                    });
                  } else {
                    await updateDoc(doc(db, "users", state.user.userId), {
                      photoUrl: data.photoUrl,
                    });
                  }
            
                  for (const docSnap of querySnapshot.docs) {
                    try {
                      const subcollectionDocRef = docSnap.ref;
                      const conversationParents = docSnap.ref.parent.parent.id;
                  
                      await updateDoc(subcollectionDocRef, {
                        photoUrl: data.photoUrl,
                        username: data.userName,
                      });
                  
                      await updateDoc(doc(db, "conversations", conversationParents), {
                        isProfilePicChanged: true,
                      });
                    } catch (error) {
                      console.error("Error al actualizar el documento:", error);
                    }
                  }
                }

                dispatch({ type: 'ERROR_LOGIN_STATE', payload: ""});

                dispatch(UpdateOnlineUser({
                  online: true,
                  userId: data.userId
                }));

                if(navigate) navigate(`/interest`);

            } else {
                await setDoc(docRef, {
                  bio: data.bio,
                  dob: data.dob,
                  isOnline: true,
                  name: data.name.replace(/\s{2,}/g, " ").trim(),
                  userName: data.userName,
                  userId: data.userId,
                  privacyTag: "EveryOne",
                  totalFollowers: data.totalFollowers,
                  photoUrl: data.photoUrl == undefined ? null : data.photoUrl,
                  phoneNumber: data.phoneNumber,
                  createdAt: `${Date.now()}`,
                  pushToken: null,
                  isGuest: data.isGuest,
                  hearts: data.hearts,
                });

                if(isGuest) {
                  dispatch({ type: 'UPDATE_USER_PROFILE_STATE', payload: data.photoUrl});

                  if (data.guestFlow !== undefined) {
                    await updateDoc(doc(db, "users", state.user.userId), {
                      photoUrl: data.photoUrl,
                      userName: data.userName,
                      name: data.name,
                    });
                  } else {
                    await updateDoc(doc(db, "users", state.user.userId), {
                      photoUrl: data.photoUrl,
                    });
                  }
            
                  for (const docSnap of querySnapshot.docs) {
                    try {
                      const subcollectionDocRef = docSnap.ref;
                      const conversationParents = docSnap.ref.parent.parent.id;
                  
                      await updateDoc(subcollectionDocRef, {
                        photoUrl: data.photoUrl,
                        username: data.userName,
                      });
                  
                      await updateDoc(doc(db, "conversations", conversationParents), {
                        isProfilePicChanged: true,
                      });
                    } catch (error) {
                      console.error("Error al actualizar el documento:", error);
                    }
                  }
                }

                dispatch({ type: 'ERROR_LOGIN_STATE', payload: ""});

                navigate(`/interest`);
            }

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Remove user token in Firebase */
export const RemovePushToken = (data) => {
    return async (dispatch, getState) => {
        try {

            const docRef = doc(db, "users", `${data.id}`);
            const docSnap = await getDoc(docRef);

            const pushToken = data.pushToken;
            let pushTokens = docSnap.data().pushToken || [];

            if (typeof pushTokens === "string") {
                pushTokens = [pushTokens];
            }
            
            pushTokens = pushTokens.filter((token) => token !== pushToken);
            if (docSnap.exists()) {
                await updateDoc(docRef, {
                  pushToken: pushTokens,
                });
            }

            dispatch({ type: 'REMOVE_PUSH_TOKEN_STATE', payload: null });

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update user token in Firebase */
export const UpdatePushToken = (data) => {
    return async (dispatch, getState) => {
        try {

            const checkForRepeatedPushToken = async (pushToken) => {
                const usersRef = collection(db, "users");

                const queryUsers = query(usersRef,where("pushToken", "array-contains", pushToken));

                const snapshot = await getDocs(queryUsers);
                snapshot.forEach(async (docUser) => {

                  const userRef = doc(db, "users", `${docUser.id}`);

                  let pushTokens = docUser.data().pushToken || [];
                  pushTokens = pushTokens.filter((token) => token !== pushToken);

                  await updateDoc(userRef, {
                    pushToken: pushTokens,
                  });
                });
            };

            const pushToken = data.pushToken;
            if (pushToken) {
                const docRef = doc(db, "users", `${data.id}`);
                const docSnap = await getDoc(docRef);

                let pushTokens = docSnap.data().pushToken || [];
                if (typeof pushTokens === "string") {
                    pushTokens = [pushTokens];
                }

                if (docSnap.exists() && pushTokens.includes(pushToken) === false) {
                    pushTokens.push(pushToken);
                    await checkForRepeatedPushToken(pushToken);
                    await updateDoc(docRef, {
                        pushToken: pushTokens,
                    });
                }
            }

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Get conversations in Firebase */
export const GetConversations = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const lastMessage = state.allConversations[state.allConversations.length - 1];

            const q = query(
                collection(db, "conversations"),
                orderBy("lastMessage.timestamp", "desc"),
                ...(data.new ? [] : [startAfter(lastMessage.lastMessage.timestamp)]),
                where("participants", "array-contains", state.user.userId),
                limit(50)
            );

            const getParticipantInformation = (document) => {
                return getDoc(doc(db, "users", document.id)).then((snap) => ({
                  ...document.data(),
                  userId: document.id,
                  name: snap.data().name,
                  isGuest: snap.data().isGuest
                }));
            };
            
            unsubscribeConversations = onSnapshot(q, (querySnapshot) => {
                const typeStatusPromises = querySnapshot.docChanges().map((change) => {
                    switch (change.type) {
                        case "added":
                        case "modified": {
                            return getDocs(collection(db, "conversations", change.doc.id, "participants"))
                            .then((participantSnapshot) => {
                                const participantPromises = participantSnapshot.docs.map(getParticipantInformation);
                                return Promise.all(participantPromises);
                            })
                            .then((participantsInformationArray) => {
                                const serializableItem = {
                                    ...change.doc.data(),
                                    id: change.doc.id,
                                    conversationId: change.doc.id,
                                    participants: change.doc.data().participants.filter((e) => e !== state.user.userId),
                                    participantsInformation: participantsInformationArray,
                                };

                                if (change.type === "modified") {
                                    dispatch(UpdateProfilePicUrl({
                                        conversationId: change.doc.id,
                                        profilePicUrl: participantsInformationArray.filter((e) => e.userId === state.user.userId),
                                    }));

                                    return updateDoc(doc(db, "conversations", change.doc.id), { isProfilePicChanged: false})
                                        .then(() => {
                                            if (change.doc.data().typing) {
                                                dispatch(UpdateTyping({
                                                    userId: change.doc.data().typing.userId || "",
                                                    username: change.doc.data().typing.username || "",
                                                }));
                                            }

                                            return { type: change.type, data: serializableItem };
                                        });
                                }

                                return { type: change.type, data: serializableItem };
                            })
                        }
                        case "removed": {
                            return { type: change.type, data: change.doc.id };
                        }
                    }
                });

                Promise.all(typeStatusPromises).then((results) => {
                    results.forEach((result) => {
                        if (result) {
                            switch (result.type) {
                                case "added": 
                                    const aConversations = [result.data];
                                    dispatch(SaveConversations(aConversations));
                                    break;
                                case "modified":
                                    dispatch(UpdateConversations([result.data]));
                                    break;
                                case "removed":
                                    dispatch(RemoveConversations([result.data]));
                                    break;
                                default:
                                    break;
                            }
                        }
                    })
                })
            });

            unsubscribeList.push(unsubscribeConversations);

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Get notifications in Firebase */
export const GetNotifications = (data) => {
  return async (dispatch, getState) => {
      try {
          const state = getState();
          const q = query(
              collection(db, "conversations"),
              orderBy("lastMessage.timestamp", "desc"),
              where("participants", "array-contains", data.userId),
              limit(50)
          );

          unsubscribeNotifications = onSnapshot(q, (querySnapshot) => {
              const notifications = querySnapshot.docChanges()
                .filter((change) => change.doc.data().readByCounter.some(item => item.userId === data.userId))
                .map((change) => ({ type: change.type, data: change.doc.data() }));

              processNotifications(dispatch, getState, notifications);
          });

          return 'Ok';
      } catch (error) {
          dispatch({ type: 'NOTIFICATION_ERROR', error });
          console.error(`Error in GetNotifications: ${error}`);
      }
  }
};

// Function to process and save notifications
const processNotifications = (dispatch, getState, notifications) => {
  notifications.forEach((notification) => {
      if (notification) {
          if (notification.type === "added") {
              dispatch(SaveCountNotifications([notification.data]));
          } else if (notification.type === "modified") {
              dispatch(UpdateCountNotifications([notification.data]));
          }
      }
  });
};

// Redux action to save new notifications
export const SaveCountNotifications = (data) => {
  return async (dispatch, getState) => {
      try {
          updateNotificationsState(dispatch, getState, data);
          return 'Ok';
      } catch (error) {
          console.error(`Error in SaveCountNotifications: ${error}`);
      }
  }
};

// Redux action to update existing notifications
export const UpdateCountNotifications = (data) => {
  return async (dispatch, getState) => {
      try {
          updateNotificationsState(dispatch, getState, data);
          return 'Ok';
      } catch (error) {
          console.error(`Error in UpdateCountNotifications: ${error}`);
      }
  }
};

// Common function to update notifications state
const updateNotificationsState = (dispatch, getState, data) => {
  const state = getState();
  const index = state.inboxCount.findIndex((value) =>
      value.participants[0] === data[0].participants[0] &&
      value.participants[1] === data[0].participants[1]
  );

  let updatedInboxCount = [...state.inboxCount];
  if (index !== -1) {
      updatedInboxCount[index] = data[0];
  } else {
      updatedInboxCount.push(data[0]);
  }

  const totalNotification = updatedInboxCount.filter((message) =>
      message.readByCounter.some((counter) =>
          counter.unReadContentCounter !== undefined &&
          (counter.unReadCounter !== 0 || counter.unReadContentCounter !== 0) &&
          counter.userId === state.user.userId
      )
  );
  dispatch({ type: 'INBOX_COUNT_STATE', payload: totalNotification });
};

/* Get user data in Firebase */
export const GetUserData = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const docRef = doc(db, "users", `${state.user.userId}`);

            unsubscribeUserData = onSnapshot(docRef, (snapshot) => {

              const userInfo = snapshot.data();

              if (userInfo !== undefined) {

                dispatch({ type: 'UPDATE_USER_DATA_NAVBAR_STATE', payload: {
                    hearts: userInfo.hearts,
                    totalFollowers: userInfo.totalFollowers,
                    profilePicUrl: userInfo.photoUrl,
                    isDisable: userInfo.isDisable,
                    privacyTag: userInfo.privacyTag,
                    name: userInfo.name,
                    userName: userInfo.userName,
                    phoneNumber: userInfo.phoneNumber,
                    bio: userInfo.bio,
                } });
              }
            });

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Get current chat user data in Firebase */
export const GetCurrentChatUserData = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            dispatch({ type: 'UPDATE_CURRENT_CHAT_USER_ID_STATE', payload:data });

            const thereAreConversation = state.conversations.find((conversation) => {
                return conversation.participants.find((participant) => participant === data);
            });

            const docRef = doc(db, "users", `${data}`);

            unsubscribeCurrentChatUser = onSnapshot(docRef, (snapshot) => {

              const userInfo = snapshot.data();

              if (userInfo !== undefined)
                dispatch(UpdateCurrentChatData({
                    online: userInfo.isOnline,
                    username: userInfo.userName,
                    userId: data,
                    privacyTag: userInfo.privacyTag,
                    photoUrl: userInfo.photoUrl,
                    userWithoutConversation:
                      thereAreConversation === undefined ? true : false,
                    name: userInfo.name,
                    isDisable: userInfo.isDisable,
                    isGuest: userInfo.isGuest
                }));
            });

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Get my users block in Firebase */
export const GetBlockUsers = (data) => {
  return async (dispatch, getState) => {
      try {
          const docRef = doc(db, "users", data);
          let userUnsubscribers = [];

          const manageUserSnapshot = async (userDoc, userId, userName) => {
              if (userDoc.exists()) {
                  const userData = userDoc.data();
                  return {
                      userId,
                      userName,
                      photoUrl: userData.photoUrl,
                      name: userData.name,
                      isDisable: userData.isDisable
                  };
              }
              return null;
          };

          const updateBlockUsers = async (blockUsers) => {
              let users = [];
              for (let user of blockUsers) {
                  const snap = await getDoc(doc(db, "users", user.userId));
                  const userInfo = await manageUserSnapshot(snap, user.userId, user.userName);
                  if (userInfo) {
                      users.push(userInfo);
                  }
              }
              dispatch({ type: 'UPDATE_BLOCK_USERS_DATA_STATE', payload: users });
          };

          unsubscribeBlockUser = onSnapshot(docRef, (docSnapshot) => {
              if (docSnapshot.exists() && docSnapshot.data().blockedUsers !== undefined) {
                  userUnsubscribers.forEach(unsubscribe => unsubscribe());
                  userUnsubscribers = [];

                  let blockUsers = docSnapshot.data().blockedUsers.filter(user => user.userId !== "");
                  for (let user of blockUsers) {
                      const userRef = doc(db, "users", user.userId);
                      const unsubscribe = onSnapshot(userRef, (userDoc) => {
                          manageUserSnapshot(userDoc, user.userId, user.userName).then(userInfo => {
                              if (userInfo) {
                                  updateBlockUsers(blockUsers);
                              }
                          });
                      });
                      userUnsubscribers.push(unsubscribe);
                  }
              }
          });

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`);
      }
  }
};

/* Get users block me in Firebase */
export const GetBlockUsersToMe = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const q = query(collection(db, "users"),where("blockedUsers", "array-contains", {userId: state.user.userId,userName: state.user.userName}));

            unsubscribeBockUserToMe = onSnapshot(q, (querySnapshot) => {
                const aBlockMeUser = [];

                querySnapshot.docChanges().forEach((change) => {
                  if (change.type === "added") {
                    aBlockMeUser.push(change.doc.id);
                  } else if (change.type === "modified") {
                    aBlockMeUser.push(change.doc.id);
                  }
                });

                
                dispatch({ type: 'UPDATE_USERS_BLOCK_ME_STATE', payload: aBlockMeUser });
            });

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update content message in Firebase */
export const UpdateContentMessage = (data) => {
    return async (dispatch, getState) => {
        try {

            await Promise.all([updateDoc(doc(db,"conversations",data.currentConversationId,"messages",data.messageId.toString()),{
                contentBlur: false,
            }), updateDoc(doc(db, "users", data.currentUser), {
                hearts: data.totalHearts,
            })]);

            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Get messages by chat in Firebase */
export const GetMessages = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            let q;
            let typeStatus;
            const messages = [];
            const pageSize = data.pageSize || 20;
            let currentChat = {
              id: state.currentConversationId
            };

            let index = state.allConversations.findIndex((value) => value.conversationId === state.currentConversationId);

            if(index === -1){
              const q = query(
                collection(db, `conversations`),
                where("participants", "array-contains", state.user.userId))
                
              const querySnapshot = await getDocs(q);

              querySnapshot.forEach((doc) => {
                if(doc.data().participants.find((participant) => participant === state.currentChat.userId)) {
                  currentChat.id = doc.id;
                  currentChat.aDeleted = doc.data()?.aDeleted;
                }
              });
            }

            if (data.startAfter) {
                const docRef = doc(db,`conversations/${data.conversationId}/messages/${data.startAfter}`);
                const snapshot = await getDoc(docRef);

                if (snapshot.exists() && state.currentChat.messages.length !== 0) {
                  const iDeleted = state.allConversations[index]?.aDeleted?.findIndex((value) => value.userId === state.user.userId);
                  if (iDeleted !== -1) {
                    // DELETED MESSAGES
                    q = query(
                      collection(db, `conversations/${data.conversationId}/messages`),
                      where(
                        "timestamp",
                        ">",String(state.allConversations[index]?.aDeleted[iDeleted]?.timestampMessage)
                      ),
                      orderBy("timestamp", "desc"),
                      startAfter(snapshot),
                      limit(pageSize)
                    );
                  } else {
                    // NOT DELETED MESSAGES
                    q = query(
                      collection(db, `conversations/${data.conversationId}/messages`),
                      orderBy("timestamp", "desc"),
                      startAfter(snapshot),
                      limit(pageSize)
                    );
                  }
                } else if (state.currentChat.messages.length !== 0) {
                  q = query(
                    collection(db, `conversations/${data.conversationId}/messages`),
                    orderBy("timestamp", "desc"),
                    limit(pageSize)
                  );
                }
            } else {
                try {
                  // INITIAL NOT SCROLL
                  if (index !== -1) {
                    const iDeleted = state.allConversations[index]?.aDeleted?.findIndex(
                      (value) => value.userId === state.user.userId
                    );
                    if (iDeleted !== -1) {
                      // DELETED MESSAGE
                      q = query(
                        collection(db, `conversations/${data.conversationId}/messages`),
                        where(
                          "timestamp",
                          ">", String(state.allConversations[index]?.aDeleted[iDeleted]?.timestampMessage)
                        ),
                        orderBy("timestamp", "desc"),
                        limit(pageSize)
                      );
                    } else {
                      // NOT DELETED MESSAGE
                      q = query(
                        collection(db, `conversations/${data.conversationId}/messages`),
                        orderBy("timestamp", "desc"),
                        limit(pageSize)
                      );
                    }
                  }
                  else{
                    if(currentChat?.id !== undefined){
                      const iDeleted = currentChat?.aDeleted?.findIndex(
                        (value) => value.userId === state.user.userId
                      );
                      if (iDeleted !== -1) {
                        // DELETED MESSAGE
                        q = query(
                          collection(db, `conversations/${currentChat.id}/messages`),
                          where(
                            "timestamp",
                            ">", String(currentChat?.aDeleted[iDeleted]?.timestampMessage)
                          ),
                          orderBy("timestamp", "desc"),
                          limit(pageSize)
                        );
                      } else {
                        // NOT DELETED MESSAGE
                        q = query(
                          collection(db, `conversations/${currentChat.id}/messages`),
                          orderBy("timestamp", "desc"),
                          limit(pageSize)
                        );
                      }
                    }
                  }
                } catch (error) {
                  console.log(error);
                }
            }

            if (q !== undefined) {
              unsubscribeMessages = onSnapshot(q, (querySnapshot) => {
                  const addedMessages = [];
                  const receiveMessages = [];
            
                  querySnapshot.docChanges().forEach((change) => {
                      if (change.type === "added") {
                          typeStatus = "added";
                          receiveMessages.push({ ...change.doc.data(), id: change.doc.id });
                      }
              
                      if (change.type === "modified") {
                          typeStatus = "modified";
                          dispatch(UpdateReadMessage(change.doc.data()));
                      }
                  });
            
                  dispatch(UpdateCurrentParticipants(data.conversationId));
            
                  if (receiveMessages.length === 1) {
                      dispatch(UpdateMessagesConversationOne({
                          ...receiveMessages[0],
                          id: receiveMessages[0].timestamp,
                      }));
                  } else {
                      querySnapshot.docs.forEach((snap) => {
                        addedMessages.push({ ...snap.data(), id: snap.data().timestamp });
                      });
            
                      if (querySnapshot.docs[querySnapshot.docs.length - 1]) {
                        const lastDocId =
                          querySnapshot.docs[querySnapshot.docs.length - 1].id;
                        if (lastDocId) {

                          dispatch({ type: 'UPDATE_CURRENT_LASTDOC_STATE', payload: lastDocId});
                        }
                      }
            
                      const newMessages = [...addedMessages.reverse(), ...messages];
                      messages.unshift(...addedMessages.reverse());

                      if (typeStatus === "added")
                        dispatch(UpdateMessagesConversation(newMessages));
                  }
              });
            }
            return 'Ok';
        } catch (error) {
            console.log(`Error GetMessages ${error}`)
        }
    }
};

/* Get typing chatbox in Firebase */
export const GetTyping = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            const q = query(collection(db, `conversations/${data}/typing`));
        
            unsubscribeTyping = onSnapshot(q, async (querySnapshot) => {
                const userType = [];
        
                for (const change of querySnapshot.docChanges()) {
                  userType.push(change.doc.data());
                }
        
                if (userType.length !== 0) {
                  dispatch(UpdateTyping(userType));
                }
            });


            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

const addMessageNotLoaded = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const currentChat = data.newConversation;
    let index = 0;

    let aReadBy;
    const timestampformatunix = Math.floor(new Date().getTime() / 1000);

    if (state.currentConversationId === null) {
      dispatch({ type: 'UPDATE_CURRENT_CONVERSATION_ID_STATE', payload: currentChat.id})
    };

    const aMessageReadBy = currentChat.participants.map((i) => {
      return {
        userId: i,
        read: false,
      };
    });

    let aDeletedConversation = [];

    if (currentChat.aDeleted.length !== 0) {
      const isDeleted = currentChat.aDeleted.some(e => e.isDeleted === true);
      let aDeleted = [...currentChat.aDeleted];
      if (isDeleted) {
        for (let i = 0; i < aDeleted.length; i++) {
          if (aDeleted[i].isDeleted) {
            aDeleted[i].isDeleted = false;
          }
        }
      }
      aDeletedConversation = aDeleted;
    }

    if (state.currentChat.messages.length === 0) {
      aReadBy = currentChat.participants.map((i) => {
        return {
          userId: i,
          unReadCounter: data.message.type === 0 || data.message.type === 3 ? 1 : 0,
          unReadContentCounter: data.message.type === 1 || data.message.type === 2 ? 1 : 0,
          lastProvisionalTimestamp: "",
        };
      });
          
    aReadBy.push({
      userId: state.user.userId,
      unReadCounter: 0,
      unReadContentCounter: 0,
      lastProvisionalTimestamp: "",
    })
              } else {
                  aReadBy = currentChat.participants.map((i) => {
                    return {
                      userId: i,
                      unReadCounter: data.message.type === 0 || data.message.type === 3
                        ? 1 + state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 0 || obj.type === 3)
                          ? acc + 1
                          : acc,0)
                        : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 0 || obj.type === 3)
                          ? acc + 1
                          : acc,0),
                      unReadContentCounter: data.message.type === 1 || data.message.type === 2
                        ? 1 + state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 1 || obj.type === 2)
                          ? acc + 1
                          : acc,0)
                        : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 1 || obj.type === 2)
                          ? acc + 1
                          : acc,0),
                      lastProvisionalTimestamp: "",
                    };
                  });
          
                  aReadBy.push({
                    userId: state.user.userId,
                    unReadCounter: data.message.type === 0 || data.message.type === 3
                      ? state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 0 || obj.type === 3)
                        ? acc + 1
                        : acc,0)
                      : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 0 || obj.type === 3)
                        ? acc + 1
                        : acc,0),
                    unReadContentCounter: data.message.type === 1 || data.message.type === 2
                      ? state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 1 || obj.type === 2)
                        ? acc + 1
                        : acc,0)
                      : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 1 || obj.type === 2)
                        ? acc + 1
                        : acc,0),
                    lastProvisionalTimestamp: "",
                  })
              }
          
                try {
                  const conversationRef = doc(db,"conversations",currentChat.id,"messages",timestampformatunix.toString());
          
                  await setDoc(conversationRef, {
                    content: data.message.content,
                    senderId: data.message.sender,
                    timestamp: timestampformatunix.toString(),
                    contentUrl: data.message.contentUrl,
                    contentBlur: data.message.heartActive === 0 ? false : true,
                    contentId:
                      data.message.contentId === undefined
                        ? timestampformatunix
                        : data.message.contentId,
                    type: data.message.type,
                    readBy: aMessageReadBy,
                    isDeleted: [],
                    isSendAll: false,
                    platform: data.message.platform ? "Mobile" : "Desk",
                    hearts: data.message.heartActive,
                    id: timestampformatunix,
                  });

                  await updateDoc(doc(db, "conversations", currentChat.id), {
                    lastMessage: {
                      content: data.message.content,
                      senderId: data.message.sender,
                      timestamp: timestampformatunix.toString(),
                      type: data.message.type,
                      isSendAll: false,
                      sendAllTimestamp: "",
                      isNewConversation: false,
                    },
                    readByCounter: aReadBy,
                    aDeleted: aDeletedConversation
                  });
                  dispatch(
                    GetMessages({
                    conversationId: currentChat.id,
                    pageSize: 20,
                  }))

                   dispatch(GetConversations({
                    userId: currentChat?.userId,
                    userName: currentChat?.username,
                  }));
                } catch (error) {
                  console.log("Error adding message:", error.message);
                }
  }
}

/* Save Message in Firebase */
export const AddMessage = (data) => {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            let aReadBy;
            let messageToSend;
            const timestampformatunix = Math.floor(new Date().getTime() / 1000);
            const uniqueId = uuidv4();

            const getParticipantInformation = async (document) => {
              const snap = await getDoc(doc(db, "users", document));
              return {
                username: snap.data().userName,
                userId: document,
                name: snap.data().name,
                isGuest: snap.data().isGuest,
                photoUrl: snap.data().photoUrl,
              };
            };

            let index = state.allConversations.findIndex((value) => value.participants[0] === state.currentChat.userId);
            
            if (index === -1) {
              const q = query(collection(db, `conversations`), where("participants", "array-contains", state.user.userId));
              const querySnapshot = await getDocs(q);
              for (const doc of querySnapshot.docs) {
                if (doc.data().participants.find(participant => participant === state.currentChat.userId)) {
                  const participantsInformationPromises = doc.data().participants.map(getParticipantInformation);
                  const participantsInformation = await Promise.all(participantsInformationPromises);
          
                  const newConversation = {
                    ...doc.data(),
                    participants: doc.data().participants.filter(e => e !== state.user.userId),
                    conversationId: doc.id,
                    id: doc.id,
                    participantsInformation,
                  };
                  
                  dispatch(addMessageNotLoaded({
                    ...data,
                    newConversation,
                  }));
                  return "Ok";
                }
              }
            }
            setTimeout(() => {
              index = state.allConversations.findIndex((value) => value.participants[0] === state.currentChat.userId);
            }, 5000);

            if (index === -1) {

                const docRef = doc(db, "conversations", uniqueId);
          
                await setDoc(docRef, {
                  participants: [state.currentChat.userId, state.user.userId],
                  group: false,
                  lastMessage: {
                    content: "",
                    senderId: data.message.sender,
                    timestamp: timestampformatunix.toString(),
                    type: 0,
                    isSendAll: false,
                    sendAllTimestamp: "",
                    isNewConversation: false,
                  },
                  readByCounter: [],
                  aDeleted: [],
                  isProfilePicChanged: false,
                });

                const setParticipantDoc = async (userId, username, photoUrl) => {
                  const conversationRef = doc(db, "conversations", uniqueId, "participants", userId);
                  await setDoc(conversationRef, { username, photoUrl });
                }
                
                const {
                  currentChat: { userId: currentUserId, username: currentUsername, photoUrl: currentPhotoUrl },
                  user: { userId: ownUserId, userName: ownUsername, profilePicUrl: ownProfilePicUrl }
                } = state;
                
                await Promise.all([
                  setParticipantDoc(currentUserId, currentUsername, currentPhotoUrl),
                  setParticipantDoc(ownUserId, ownUsername, ownProfilePicUrl)
                ]);
          
                const uuidMessage = timestampformatunix.toString();
          
                const subcollectionRefMessages = doc(db,"conversations",uniqueId,"messages",uuidMessage);
          
                const aMessageReadBy = [{
                  userId: state.currentChat.userId,
                  read: false,
                }];
          
                const messageData = {
                  content: data.message.content,
                  senderId: data.message.sender,
                  timestamp: timestampformatunix.toString(),
                  contentUrl: data.message.contentUrl,
                  contentBlur: data.message.heartActive === 0 ? false : true,
                  contentId:
                    data.message.contentId === undefined
                      ? timestampformatunix
                      : data.message.contentId,
                  type: data.message.type,
                  readBy: aMessageReadBy,
                  isDeleted: [],
                  isSendAll: false,
                  platform: data.message.platform ? "Mobile" : "Desk",
                  hearts: data.message.heartActive,
                  id: timestampformatunix.toString(),
                }

                const updateData = {
                  lastMessage: {
                    content: data.message.content,
                    senderId: data.message.sender,
                    timestamp: timestampformatunix.toString(),
                    type: data.message.type,
                    isSendAll: false,
                    sendAllTimestamp: "",
                    isNewConversation: false,
                  },
                  readByCounter: [
                    {
                      userId: state.currentChat.userId,
                      unReadCounter: data.message.type === 0 || data.message.type === 3 ? 1 : 0,
                      unReadContentCounter: data.message.type === 1 || data.message.type === 2 ? 1 : 0,
                      lastProvisionalTimestamp: "",
                    },
                    {
                      userId: state.user.userId,
                      unReadCounter: 0,
                      unReadContentCounter: 0,
                      lastProvisionalTimestamp: "",
                    }
                  ],
                }

                const setDocPromise = setDoc(subcollectionRefMessages, messageData);
                const updateDocPromise = updateDoc(doc(db, "conversations", uniqueId), updateData);
          
                try {
                  await Promise.all([setDocPromise, updateDocPromise]);
                } catch (error) {
                  console.log("error", error);
                }
          
                dispatch({ type: 'UPDATE_CURRENT_WITHOUT_CONVERSATION_STATE', payload: false});
                dispatch({ type: 'UPDATE_CURRENT_CONVERSATION_ID_STATE', payload: uniqueId});
            } else {
              if (state.currentConversationId === null) dispatch({ type: 'UPDATE_CURRENT_CONVERSATION_ID_STATE', payload: state.allConversations[index].conversationId});

              const indexExist = state.allConversations.findIndex((value) => value.conversationId === ((state.currentConversationId === null || state.currentConversationId === undefined) ? state.allConversations[index].conversationId : state.currentConversationId));
              const aMessageReadBy = state.allConversations[indexExist].participants.map((i) => {
                return {
                  userId: i,
                  read: false,
                };
              });

              let aDeletedConversation = [];

              if (state.allConversations[indexExist].aDeleted.length !== 0) {

                const isDeleted = state.allConversations[indexExist].aDeleted.some(e => e.isDeleted === true);

                let aDeleted = [...state.allConversations[indexExist].aDeleted];

                if (isDeleted) {
                  for (let i = 0; i < aDeleted.length; i++) {
                    if (aDeleted[i].isDeleted) {
                      aDeleted[i].isDeleted = false;
                    }
                  }
                }

                aDeletedConversation = aDeleted;
              }
              if (state.currentChat.messages.length === 0) {
                aReadBy = state.allConversations[indexExist].participants.map((i) => {
                  return {
                    userId: i,
                    unReadCounter: data.message.type === 0 || data.message.type === 3 ? 1 : 0,
                    unReadContentCounter: data.message.type === 1 || data.message.type === 2 ? 1 : 0,
                    lastProvisionalTimestamp: "",
                  };
                });
                
                aReadBy.push({
                  userId: state.user.userId,
                  unReadCounter: 0,
                  unReadContentCounter: 0,
                  lastProvisionalTimestamp: "",
                })
              } else {
                aReadBy = state.allConversations[indexExist].participants.map((i) => {
                    return {
                      userId: i,
                      unReadCounter: data.message.type === 0 || data.message.type === 3
                        ? 1 + state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 0 || obj.type === 3)
                          ? acc + 1
                          : acc,0)
                        : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 0 || obj.type === 3)
                          ? acc + 1
                          : acc,0),
                      unReadContentCounter: data.message.type === 1 || data.message.type === 2
                        ? 1 + state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 1 || obj.type === 2)
                          ? acc + 1
                          : acc,0)
                        : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === i && (obj.type === 1 || obj.type === 2)
                          ? acc + 1
                          : acc,0),
                      lastProvisionalTimestamp: "",
                    };
                  });
          
                  aReadBy.push({
                    userId: state.user.userId,
                    unReadCounter: data.message.type === 0 || data.message.type === 3
                      ? state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 0 || obj.type === 3)
                        ? acc + 1
                        : acc,0)
                      : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 0 || obj.type === 3)
                        ? acc + 1
                        : acc,0),
                    unReadContentCounter: data.message.type === 1 || data.message.type === 2
                      ? state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 1 || obj.type === 2)
                        ? acc + 1
                        : acc,0)
                      : state.currentChat.messages.reduce((acc, obj) => obj.readBy[0].read === false && obj.readBy[0].userId === state.user.userId && (obj.type === 1 || obj.type === 2)
                        ? acc + 1
                        : acc,0),
                    lastProvisionalTimestamp: "",
                  })
              }
          
                try {
                  await updateDoc(doc(db, "conversations", (data.currentConversationId === null || data.currentConversationId === undefined) ? state.allConversations[index].conversationId : data.currentConversationId), {
                    lastMessage: {
                      content: data.message.content,
                      senderId: data.message.sender,
                      timestamp: timestampformatunix.toString(),
                      type: data.message.type,
                      isSendAll: false,
                      sendAllTimestamp: "",
                      isNewConversation: false,
                    },
                    readByCounter: aReadBy,
                    aDeleted: aDeletedConversation
                  });
                  
                  const conversationRef = doc(db,"conversations", (data.currentConversationId === null || data.currentConversationId === undefined) ? state.allConversations[index].conversationId : data.currentConversationId,"messages",timestampformatunix.toString());
          
                  await setDoc(conversationRef, {
                    content: data.message.content,
                    senderId: data.message.sender,
                    timestamp: timestampformatunix.toString(),
                    contentUrl: data.message.contentUrl,
                    contentBlur: data.message.heartActive === 0 ? false : true,
                    contentId:
                      data.message.contentId === undefined
                        ? timestampformatunix
                        : data.message.contentId,
                    type: data.message.type,
                    readBy: aMessageReadBy,
                    isDeleted: [],
                    isSendAll: false,
                    platform: data.message.platform ? "Mobile" : "Desk",
                    hearts: data.message.heartActive,
                    id: timestampformatunix,
                  });
                } catch (error) {
                  console.log("Error adding message:", error.message);
                }
            }


            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update typing chat in Firebase */
export const UpdateTypingChat = (data) => {
    return async (dispatch, getState) => {
        try {

          const state = getState();

          if (data.conversationId !== null) {
            if (data.isTyping) {
              const conversationDoc = await getDoc(
                doc(
                  db,
                  "conversations",
                  data.conversationId,
                  "typing",
                  data.conversationId
                )
              );
        
              if (conversationDoc.exists()) {
                await updateDoc(
                  doc(
                    db,
                    "conversations",
                    data.conversationId,
                    "typing",
                    data.conversationId
                  ),
                  {
                    userId: data.userId,
                    username: data.username,
                    photoUrl: data.photoUrl,
                  }
                );
              } else {
                await setDoc(
                  doc(
                    db,
                    "conversations",
                    data.conversationId,
                    "typing",
                    data.conversationId
                  ),
                  {
                    userId: data.userId,
                    username: data.username,
                    photoUrl: data.photoUrl,
                  }
                );
              }
            } else {
              const conversationDoc = await getDoc(
                doc(
                  db,
                  "conversations",
                  data.conversationId,
                  "typing",
                  data.conversationId
                )
              );
        
              if (conversationDoc.exists()) {
                await updateDoc(
                  doc(
                    db,
                    "conversations",
                    data.conversationId,
                    "typing",
                    data.conversationId
                  ),
                  {
                    userId: "",
                    username: "",
                    photoUrl: "",
                  }
                );
              } else {
                await setDoc(
                  doc(
                    db,
                    "conversations",
                    data.conversationId,
                    "typing",
                    data.conversationId
                  ),
                  {
                    userId: "",
                    username: "",
                    photoUrl: "",
                  }
                );
              }
            }
          }

          return 'Ok';
        } catch (error) {
          console.log(`Error UpdateTypingChat ${error}`)
        }
    }
};

/* Update read message in Firebase */
export const ReadMessage = (data) => {
    return async (dispatch, getState) => {
        try {

          const state = getState();

          const index = state.allConversations.findIndex(
            (value) => value.conversationId === data
          );
          
          const indexRead = state.allConversations[index].readByCounter.findIndex(
            (value) => value.userId === state.user.userId
          );
          
          if (indexRead !== -1 && (state.allConversations[index].readByCounter[indexRead].unReadCounter !== 0 || (state.allConversations[index].readByCounter[indexRead].unReadContentCounter !== 0 && state.allConversations[index].readByCounter[indexRead].unReadContentCounter !== undefined))) {
          
            const aReadyByCounter = JSON.parse(JSON.stringify(state.allConversations[index].readByCounter));
            aReadyByCounter[indexRead].unReadCounter = 0;
            aReadyByCounter[indexRead].unReadContentCounter = 0;
            aReadyByCounter[indexRead].lastProvisionalTimestamp = aReadyByCounter[indexRead].lastProvisionalTimestamp;
          
            await updateDoc(
              doc(db, "conversations", data), {
                readByCounter: aReadyByCounter,
              }
            );
          }

          return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update unblock user in Firebase */
export const UnblockUser = (data) => {
  return async (dispatch, getState) => {
       const timestampformatunix = Math.floor(new Date().getTime() / 1000);
        try {

            const state = getState();

            let response;

            if (data.toConversationID === undefined) {
                response = await data.post(`${Url}/unblock/${state.user.userId}`, {
                uId: state.currentChat.userId,
                });
            } else {
                response = await data.api.post(
                `${Url}/unblock/${state.user.userId}`,
                { uId: data.toConversationID }
                );
            }

            const responseData = await response.data;

            if (responseData?.success) {
              const newBlockUsers = [...state.blockUsers];
      
              let index;
      
              if (data.toConversationID === undefined) {
                index = newBlockUsers.findIndex(
                  (usuario) => usuario.userId === state.currentChat.userId
                );
              } else {
                index = newBlockUsers.findIndex(
                  (usuario) => usuario.userId === data.toConversationID
                );
              }
      
              if (index !== -1) {
                newBlockUsers.splice(index, 1);

                dispatch({ type: 'UPDATE_BLOCK_USERS_DATA_STATE', payload: newBlockUsers});
      
                const formatBlock = newBlockUsers.map((index) => {
                  return {
                    userId: index.userId,
                    userName: index.userName,
                  };
                });
      
                await updateDoc(doc(db, "users", state.user.userId), {
                  blockedUsers: formatBlock,
                });
                const indexReceiver = state.allConversations.findIndex(
            (value) => value.participants[0] === state.currentChat.userId
          );
        const getUserIndex = state.allConversations[indexReceiver].aDeleted.findIndex((value) => value.userId == state.user.userId);

                let aConversations = [];
               if (getUserIndex !== -1) {
                aConversations.push({
                      userId: state.user.userId,
                      timestampMessage:  state.allConversations[indexReceiver].aDeleted[getUserIndex]?.timestampMessage,
                      isDeleted: true
                      });
        }
        await updateDoc(doc(db, "conversations", state.allConversations[indexReceiver].conversationId), {aDeleted: aConversations});
        return true;
          }
          }
          return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
            if(error.message.includes("404")){
              const state = getState();
              const newBlockUsers = [...state.blockUsers];
      
              let index;
      
              if (data.toConversationID === undefined) {
                index = newBlockUsers.findIndex(
                  (usuario) => usuario.userId === state.currentChat.userId
                );
              } else {
                index = newBlockUsers.findIndex(
                  (usuario) => usuario.userId === data.toConversationID
                );
              }
      
              if (index !== -1) {
                newBlockUsers.splice(index, 1);

                dispatch({ type: 'UPDATE_BLOCK_USERS_DATA_STATE', payload: newBlockUsers});
      
                const formatBlock = newBlockUsers.map((index) => {
                  return {
                    userId: index.userId,
                    userName: index.userName,
                  };
                });
      
                await updateDoc(doc(db, "users", state.user.userId), {
                  blockedUsers: formatBlock,
                });
      let aConversations = [];
                aConversations.push({
                userId: state.user.userId,
                timestampMessage:  timestampformatunix.toString(),
                isDeleted: true
              });
      
              await updateDoc(
                doc(
                  db,
                  "conversations",
                  state.allConversations[index].conversationId
                ),
                {
                  aDeleted: aConversations,
                }
              );
                return true;
              }
            }
        }
    }
};

/* Update unblock user in Firebase */
export const DeleteConversations = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            let index;

            if (state.currentConversationId === null) {
              const converationId = state.allConversations.findIndex(
                (value) => value.participants[0] === state.currentChat.userId
              );
        
              index = state.allConversations.findIndex(
                (value) =>
                  value.conversationId ===
                  state.allConversations[converationId].conversationId
              );
            } else {
              index = state.allConversations.findIndex(
                (value) => value.conversationId === state.currentConversationId
              );
            }
            const aConversations = [...state.allConversations[index].aDeleted];
            const indexConversations = aConversations.findIndex(
              (value) => value.userId === state.user.userId
            );
      
            if (indexConversations !== -1) {
              aConversations.splice(indexConversations, 1);
              aConversations.push({
                userId: state.user.userId,
                timestampMessage:  state.allConversations[index].lastMessage.isSendAll === true ? state.allConversations[index].lastMessage.sendAllTimestamp : state.allConversations[index].lastMessage.timestamp,
                isDeleted: true
              });
      
              await updateDoc(
                doc(
                  db,
                  "conversations",
                  state.allConversations[index].conversationId
                ),
                {
                  aDeleted: aConversations,
                }
              );
      
              dispatch(DeleteConversationOption(state.allConversations[index]));
      
              return;
            } else {
              aConversations.push({
                userId: state.user.userId,
                timestampMessage: state.allConversations[index].lastMessage.isSendAll === true ? state.allConversations[index].lastMessage.sendAllTimestamp : state.allConversations[index].lastMessage.timestamp,
                isDeleted: true
              });
      
              await updateDoc(
                doc(
                  db,
                  "conversations",
                  state.allConversations[index].conversationId
                ),
                {
                  aDeleted: aConversations,
                }
              );
      
              dispatch(DeleteConversationOption(state.allConversations[index]));
      
              return;
            }


            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update unblock user in Firebase */
export const UpdateHeartsOption = (data) => {
    return async (dispatch, getState) => {
        try {

            const state = getState();

            await updateDoc(doc(db, "users", data.userId), {
                hearts: data.hearts,
            });


            return 'Ok';
        } catch (error) {
            console.log(`Error updateOnlineUser ${error}`)
        }
    }
};

/* Update block user in Firebase */
export const BlockUser = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();
          const uniqueId = uuidv4();
          const timestampformatunix = Math.floor(new Date().getTime() / 1000);
    
          const payload = { uId: state.currentChat.userId };
    
          const index = state.allConversations.findIndex(
            (value) => value.participants[0] === state.currentChat.userId
          );
    
          if (index !== -1) {
            const response = await data.post(
              `${Url}/block/${state.user.userId}`,
              payload
            );
    
            const responseData = await response.data;
    
            if (responseData?.success) {
              if (state.blockUsers.length === 0) {
                await updateDoc(doc(db, "users", state.user.userId), {
                  blockedUsers: [
                    {
                      userId: state.currentChat.userId,
                      userName: state.currentChat.username,
                    },
                  ],
                });
    
                dispatch(BlockUsersAddOption([{
                  userId: state.currentChat.userId,
                  userName: state.currentChat.username,
                  photoUrl: state.currentChat.photoUrl,
                  name: state.currentChat.name
                }]));
              } else {
                const formatBlock = state.blockUsers.map((index) => {
                  return {
                    userId: index.userId,
                    userName: index.userName,
                  };
                });
                await updateDoc(doc(db, "users", state.user.userId), {
                  blockedUsers: [
                    ...formatBlock,
                    {
                      userId: state.currentChat.userId,
                      userName: state.currentChat.username,
                    },
                  ],
                });
    
                dispatch(BlockUsersAddOption([...state.blockUsers,{
                  userId: state.currentChat.userId,
                  userName: state.currentChat.username,
                  photoUrl: state.currentChat.photoUrl,
                  name: state.currentChat.name
                }]));
              }
            }
            
          } else {
            const docRef = doc(db, "conversations", uniqueId);
    
            await setDoc(docRef, {
              participants: [state.currentChat.userId, state.user.userId],
              group: true,
              lastMessage: {
                content: "",
                senderId: state.user.userId,
                timestamp: timestampformatunix.toString(),
                type: 0,
                isSendAll: false,
                sendAllTimestamp: "",
                isNewConversation: false,
              },
              readByCounter: [],
              aDeleted: [],
              isProfilePicChanged: false,
            });
    
            const conversationRef = doc(
              db,
              "conversations",
              uniqueId,
              "participants",
              state.currentChat.userId
            );
            const conversationRefSecond = doc(
              db,
              "conversations",
              uniqueId,
              "participants",
              state.user.userId
            );

            const conversationRefUpdate = doc(db, "conversations", uniqueId);
    
            const participantFirst = setDoc(conversationRef, {
              username: state.currentChat.username,
              photoUrl: state.currentChat.photoUrl,
            });
    
            const participantSecond = setDoc(conversationRefSecond, {
              username: state.user.userName,
              photoUrl: state.user.profilePicUrl,
            });
    
            const conversationUpdate = updateDoc(conversationRefUpdate, {
              participants: [state.currentChat.userId, state.user.userId],
              group: false,
              lastMessage: {
                content: "",
                senderId: state.user.userId,
                timestamp: Math.floor(new Date().getTime() / 1000).toString(),
                type: 0,
                isSendAll: false,
                sendAllTimestamp: "",
                isNewConversation: false,
              },
              readByCounter: [],
              aDeleted: [],
              isProfilePicChanged: false,
            });
    
            await Promise.all([
              participantFirst,
              participantSecond,
              conversationUpdate,
            ]);
    
            const response = await data.post(
              `${Url}/block/${state.user.userId}`,
              payload
            );
    
            const responseData = await response.data;
    
            if (responseData?.success) {
              if (state.blockUsers.length === 0) {
                await updateDoc(doc(db, "users", state.user.userId), {
                  blockedUsers: [
                    {
                      userId: state.currentChat.userId,
                      userName: state.currentChat.username,
                    },
                  ],
                });
    
                dispatch(BlockUsersAddOption([{
                  userId: state.currentChat.userId,
                  userName: state.currentChat.username,
                  photoUrl: state.currentChat.photoUrl,
                  name: state.currentChat.name
                }]));
              } else {
                const formatBlock = state.blockUsers.map((index) => {
                  return {
                    userId: index.userId,
                    userName: index.userName,
                  };
                });
                await updateDoc(doc(db, "users", state.user.userId), {
                  blockedUsers: [
                    ...formatBlock,
                    {
                      userId: state.currentChat.userId,
                      userName: state.currentChat.username,
                    },
                  ],
                });
    
                dispatch(BlockUsersAddOption([...state.blockUsers,{
                  userId: state.currentChat.userId,
                  userName: state.currentChat.username,
                  photoUrl: state.currentChat.photoUrl,
                  name: state.currentChat.name
                }]));
              }
            }
          }
        const getUserIndex = state.allConversations[index].aDeleted.findIndex((value) => value.userId == state.user.userId);

        let aConversations = [];
        if (getUserIndex !== -1) {
          aConversations.push({
                userId: state.user.userId,
                timestampMessage:  state.allConversations[index].aDeleted[getUserIndex]?.timestampMessage,
                isDeleted: false
                });
        }
        
        const indexDelete = state.allConversations[index].aDeleted.findIndex((value) => value.userId === state.user.userId);
        const indexRead = state.allConversations[index].readByCounter.findIndex((value) => value.userId === state.user.userId);
        const newReadByCounter = state.allConversations[index].readByCounter;
        if(indexDelete !== -1 && indexRead !== -1 && state.allConversations[index].lastMessage.timestamp === state.allConversations[index].aDeleted[0].timestampMessage){
          newReadByCounter[indexRead].lastProvisionalTimestamp = timestampformatunix.toString();
          await updateDoc(doc(db, "conversations", state.allConversations[index].conversationId), {
            readByCounter: newReadByCounter,
            aDeleted: aConversations
          });
          return "Ok";
        }

        await updateDoc(doc(db, "conversations", state.allConversations[index].conversationId), {aDeleted: aConversations});
        return 'Ok';
      } catch (error) {
        console.log(`Error updateOnlineUser ${error}`)
        if(error.message.includes("409")){
          const state = getState();
          if (state.blockUsers.length === 0) {
            await updateDoc(doc(db, "users", state.user.userId), {
              blockedUsers: [
                {
                  userId: state.currentChat.userId,
                  userName: state.currentChat.username,
                },
              ],
            });

            dispatch(BlockUsersAddOption([{
              userId: state.currentChat.userId,
              userName: state.currentChat.username,
              photoUrl: state.currentChat.photoUrl,
              name: state.currentChat.name
            }]));
          } else {
            const formatBlock = state.blockUsers.map((index) => {
              return {
                userId: index.userId,
                userName: index.userName,
              };
            });
            await updateDoc(doc(db, "users", state.user.userId), {
              blockedUsers: [
                ...formatBlock,
                {
                  userId: state.currentChat.userId,
                  userName: state.currentChat.username,
                },
              ],
            });

            dispatch(BlockUsersAddOption([...state.blockUsers,{
              userId: state.currentChat.userId,
              userName: state.currentChat.username,
              photoUrl: state.currentChat.photoUrl,
              name: state.currentChat.name
            }]));
          }
        }
      }
}
};

/* Update block user in Firebase */
export const UpdateUserDataSignUpOption = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          const docRef = doc(db, "users", `${data.userId}`);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            await updateDoc(docRef, {
              bio: data.bio,
              dob: data.dob,
              isOnline: true,
              name: data.name.replace(/\s{2,}/g, " ").trim(),
              userName: data.userName,
              userId: data.userId,
              privacyTag: docSnap.data().privacyTag === null || docSnap.data().privacyTag === undefined ? "EveryOne" : docSnap.data().privacyTag,
              totalFollowers: 0,
              photoUrl: data.photoUrl == undefined ? null : data.photoUrl,
              phoneNumber: data.phoneNumber,
              createdAt: `${Date.now()}`,
              pushToken: null,
              isGuest: data.isGuest,
              hearts: data.hearts,
            });
          } else {
            await setDoc(docRef, {
              bio: data.bio,
              dob: data.dob,
              isOnline: true,
              name: data.name.replace(/\s{2,}/g, " ").trim(),
              userName: data.userName,
              userId: data.userId,
              privacyTag: "EveryOne",
              totalFollowers: 0,
              photoUrl: data.photoUrl == undefined ? null : data.photoUrl,
              phoneNumber: data.phoneNumber,
              createdAt: `${Date.now()}`,
              pushToken: null,
              isGuest: data.isGuest,
              hearts: data.hearts,
            });
          }

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Update block user in Firebase */
export const UpdateUserProfile = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          await updateDoc(doc(db, "users", state.user.userId), {
            bio: data.bio,
            name: data.name,
            userName: data.userName,
          });
    
          dispatch({ type: 'UPDATE_USER_PROFILE_DATA_STATE', payload: {
            bio: data.bio,
            name: data.name,
            userName: data.userName,
          }});

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Update block user in Firebase */
export const UpdateUserProfilePicConversation = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          const q = query(
            collectionGroup(db, "participants"),
            where("username", "==", state.user.userName)
          );

          const querySnapshot = await getDocs(q);
    
          await updateDoc(doc(db, "users", state.user.userId), {
            photoUrl: data.profilePicUrl,
            userName: data.username,
            name: data.name,
            bio: data.bio,
          });
    
          querySnapshot.forEach(async (docSnap) => {
            const subcollectionDocRef = docSnap.ref;
            const conversationParents = docSnap.ref.parent.parent.id;
    
            await updateDoc(subcollectionDocRef, {
              photoUrl: data.profilePicUrl,
              username: data.username,
            });
    
            await updateDoc(doc(db, "conversations", conversationParents), {
              isProfilePicChanged: true,
            });
          });

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Update block user in Firebase */
export const UpdatePhoneNumberUser = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          await updateDoc(doc(db, "users", state.user.userId), {
            phoneNumber: data.phoneNumber,
          });

          dispatch({ type: 'UPDATE_USER_PHONE_NUMBER_DATA_STATE', payload: data.phoneNumber });

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};

/* Update block user in Firebase */
export const UpdatePrivacytag = (data) => {
  return async (dispatch, getState) => {
      try {

          const state = getState();

          await updateDoc(doc(db, "users", state.user.userId), {
            privacyTag: data.privacyTag,
          });

          dispatch({ type: 'UPDATE_USER_DATA_PRIVACYTAG_STATE', payload: data.privacyTag });

          return 'Ok';
      } catch (error) {
          console.log(`Error updateOnlineUser ${error}`)
      }
  }
};



/*  ***************************************************************************     */



/* Stop onSnapshot conversations in Firebase */
export const stopUnsubscribeConversations = () => {
    return (dispatch) => {
        unsubscribeList.forEach(unsub => unsub());
        unsubscribeList = []; 
        return Promise.resolve();
    };
};

/* Stop onSnapshot notifications in Firebase */
export const stopUnsubscribeNotifications = () => {
    return (dispatch) => {
        if (unsubscribeNotifications) {
            unsubscribeNotifications();
        }
    };
};

/* Stop onSnapshot user data in Firebase */
export const stopUnsubscribeUserData = () => {
    return (dispatch) => {
        if (unsubscribeUserData) {
            unsubscribeUserData();
        }
    };
};

/* Stop onSnapshot current chat user data in Firebase */ 
export const stopUnsubscribeCurrentChatUser = () => {
    return (dispatch) => {
        if (unsubscribeCurrentChatUser) {
            unsubscribeCurrentChatUser();
        }
    };
};

/* Stop onSnapshot block user to me in Firebase */ 
export const stopUnsubscribeBockUserToMe = () => {
    return (dispatch) => {
        if (unsubscribeBockUserToMe) {
            unsubscribeBockUserToMe();
        }
    };
};

/* Stop onSnapshot messages in Firebase */ 
export const stopUnsubscribeMessages = () => {
    return (dispatch) => {
        if (unsubscribeMessages) {
            unsubscribeMessages();
        }
    };
};

/* Stop onSnapshot typing in Firebase */ 
export const stopUnsubscribeTyping = () => {
    return (dispatch) => {
        if (unsubscribeTyping) {
            unsubscribeTyping();
        }
    };
};

/* Stop onSnapshot typing in Firebase */ 
export const stopUnsubscribeBlockUser = () => {
  return (dispatch) => {
      if (unsubscribeBlockUser) {
        unsubscribeBlockUser();
      }
  };
};

