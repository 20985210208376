import settingCashOutIcon from "../../assets/icons/moneybagIcon1.png";
import settingContentIcon from "../../assets/icons/settingContentIcon.png";
import settingFollowerIcon from "../../assets/icons/settingFollowerIcon.png";
import settingViewsIcon from "../../assets/icons/settingViewsIcon.png";

const performanceData = [
  {
    id: 0,
    label: "Number of followers",
    imgUrl: settingFollowerIcon,
  },
  {
    id: 1,
    label: "Unlocked content",
    imgUrl: settingContentIcon,
  },
  {
    id: 2,
    label: "How many viewed my profile",
    imgUrl: settingViewsIcon,
  },
  {
    id: 3,
    label: "Earnings to date",
    imgUrl: settingCashOutIcon,
  },
];
export default performanceData;
