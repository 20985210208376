import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "usetheform";
import EmojiSVG from "../../../assets/icons/emojipicker.png";
import Picker from "emoji-picker-react";
import "./Styles.css";

const useClickOutPicker = (cb) => {
  const ref = useRef(null);
  useEffect(() => {
    const clickOut = (e) => {
      if (!ref.current?.contains(e.target)) {
        cb(e);
      }
    };
    window.addEventListener("click", clickOut);
    return () => {
      window.removeEventListener("click", clickOut);
    };
  }, [cb]);
  return ref;
};

export const EmojiPicker = (props) => {
  const [showEmojiPicker, togglePicker] = useState(() => false);

  const toggleEmojiPicker = () => togglePicker((prev) => !prev);
  const onEmojiClick = (e, emojiObject) => {
    props.onEmoji(emojiObject.emoji);
  };

  const refPicker = useClickOutPicker(() => {
    showEmojiPicker && togglePicker(false);
  });

  return (
    <div ref={refPicker} className="p-2">
      <button
        disabled={props.disabled}
        type="button"
        onClick={toggleEmojiPicker}
        className="btn-emoji"
      >
        <img alt="Add Emoji" src={EmojiSVG} className="img-emoji" />
      </button>
      {showEmojiPicker && (
        <div className="emoji-picker-wrapper">
          <Picker onEmojiClick={onEmojiClick} />
        </div>
      )}
    </div>
  );
};