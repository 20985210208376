import "../../../styles/LinkSocial.scss";
import { useNavigate } from "react-router-dom";
import data from "../SettingsData/LinkApps.json";
import { Url, useAxios } from "../../../axios/authAPI";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSettingSidebarToggleState, ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser } from "../../../redux/actions/index";
import { useEffect, useState } from "react";

const LinkSocial = () => {

  let api = useAxios();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const dynamicStyles = {
    height: `${screenHeight - 72}px`,
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {!(isGuest === true)
        ? (
          <div className="flex flex-col relative link-social overflow-y-scroll" style={dynamicStyles}>
            <div className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949] w-full"
              onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
                <div className="sm:hidden pt-[8px]">
                  <button type="submit" className="mr-[8px]">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                    </svg>
                  </button>
                </div>
              <h1 className="text-[#494949] font-boboto font-bold title-profile">Link social media</h1>
            </div>
            <div className="flex items-center justify-center flex-wrap w-4/5 lg:w-3/5 mx-auto my-8 py-6 pt-[9vh] md:pt-[15vh]  2xl:pt-[26vh] ">
              {data.map((icon) => {
                return (
                  <a href={`https://${icon.link}`} target="_blank" key={icon.title} rel="noreferrer">
                    <div className="py-8 flex flex-row wrap justify-evenly px-8">
                      <img src={icon.img} className="rounded-full w-20 h-20 cursor-pointer" alt={icon.title} />
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
          )
        : (
          <div className="profile">
            <div className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
              <div className="sm:hidden pt-[8px] px-6">
                <button type="submit" className="mr-[8px]">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                  </svg>
                </button>
              </div>
              <div className="px-[10px]">
                <p className="text-[#494949] font-boboto title-profile-guest">
                  This feature is not available for guest user.
                  <button
                    type="submit"
                    size="small"
                    className="text-[#494949] font-boboto btn-profile-guest"
                    onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                  >
                    Create an account or Sign in
                  </button>
                </p>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default LinkSocial;
