import "../../styles/PurchasePop.scss";
import { useSelector, useDispatch } from "react-redux";
import { UnblockUser, DeleteConversations } from '../../redux/actions/index';
import { useAxios } from "../../axios/authAPI";
import { useLocation, useNavigate } from "react-router-dom";
import FavichatLoader from "../../assets/gif/Favichat1.gif";
import React, { useState, useLayoutEffect } from "react";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
  
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
  
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
        setIsMobile(window.innerWidth < 640);
      }
  
      window.addEventListener("resize", updateSize);
      updateSize();
  
      return () => window.removeEventListener("resize", updateSize);
    }, []);
  
    return [size, isMobile, location];
}

const BlockForm = () => {
  
    let api = useAxios();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [size, isMobile, location] = useWindowSize();
    const userIsDisable = useSelector((state) => state.currentChat.isDisable);

    const [showDiv, setShowDiv] = useState(true);
    const [loadingUnblock, setLoadingUnblock] = useState(false);

    const handleUnblockUser = () => {
        setLoadingUnblock(true);
        dispatch(UnblockUser(api)).then(() => {
            setLoadingUnblock(false);
        })
    }

    const handleDelete = () => {
        dispatch(DeleteConversations()).then(() => {
          navigate("/inbox/start");
        });
    }

    return (
        <>
            {loadingUnblock && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-70 transition-opacity"></div>
                        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full items-center justify-center modal-desk-block-user-pop">
                            <div className="p-3 flex items-center justify-center flex-col">
                                <img
                                className="loading-dots-modal"
                                src={FavichatLoader}
                                alt=""
                                />
                                <span className="text-sm pt-4 font-bold">Unblocking...</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={`flex items-center justify-center px-4 py-3 border-t bg-white bottom-0 landscape:sticky landscape:bottom-auto md:sticky portrait:fixed ${isMobile ? 'w-screen' : ''} ${showDiv ? '' : 'w-screen'}`}>
                <div className="flex ">
                    {!userIsDisable && <button className="btn-block-form flex items-center" onClick={() => handleUnblockUser()}> 
                        Unblock
                    </button>}
                    <button className="btn-block-form flex items-center" onClick={() => handleDelete()}> 
                        Delete
                    </button>
                </div>
            </div>
        </>

    );
};

export default BlockForm;