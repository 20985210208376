import { Switch } from "@headlessui/react";
import { Url, useAxios } from "../../../axios/authAPI";
import { useNavigate } from "react-router-dom";
import "../../../styles/MessagingPrivacy.scss";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import FavichatLoading from "../../../assets/gif/Favichat1.gif";
import { UpdateSettingSidebarToggleState, UpdatePrivacytag, ResetNotUserState, UpdateIsGuestState, UpdateOnlineUser } from "../../../redux/actions/index";

const FaviSwitch = ({ enabled, onChange, value }) => {
  return (
    <Switch
      checked={enabled}
      onChange={(e) => onChange(parseInt(value))}
      className={`relative inline-flex items-center h-6 rounded-full w-11 transition-all duration-200 ${
        enabled ? "bg-sky-300" : "bg-gray-400"
      }`}
    >
       <span
        className={`${
          enabled ? "translate-x-6  bg-sky-500" : "translate-x-1 bg-white"
        } inline-block w-4 h-4 transform  rounded-full transition-transform duration-200`}
      />
      <div
        className={`absolute inset-y-0 ${
          enabled ? "left-1 " : "right-1"
        } flex items-center justify-center`}
      >
        {enabled ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-4 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12.75l6 6 9-13.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="white"
            className="w-4 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15"
            />
          </svg>
        )}
      </div>
    </Switch>
  );
};

const MessagingPrivacy = () => {

  let api = useAxios();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const defaultData = [
    {
      id: 1,
      title: "Only receive messages from people I follow",
      value: "Followers",
      checked: false,
    },
    { id: 2, title: "No one can message me", value: "NoOne", checked: false },
    {
      id: 3,
      title: "Everyone can message me",
      value: "EveryOne",
      checked: true,
    },
  ];

  const userData = useSelector((state) => state.user);
  const isGuest = useSelector((state) => state.isGuest);
  const userId = useSelector((state) => state.user.userId);

  const [data, setData] = useState();
  const [checkBox, setCheckBox] = useState([]);
  const [loader, setLoader] = useState(false);
  

  const handleChange = async (e) => {
    const value = e;

    const modifiedData = [...data];

    let checkboxData = [];

    modifiedData.map((item) => {
      item.checked = item.id === +value;

      if (item.checked) {
        checkboxData.push(item);
      }
    });

    setData(modifiedData);
    setCheckBox(checkboxData);
    handlePrivacy(checkboxData);
  };

  useEffect(() => {
    setLoader(true);
    const displayCheck = async () => {
      defaultData.forEach(function (a) {
        if (a.value == userData.privacyTag) {
          a.checked = true;
        } else {
          a.checked = false;
        }
      });
      setData(defaultData);
    };

    displayCheck();
    setLoader(false);
  }, [userData]);

  const handlePrivacy = (privacy) => {
    dispatch(
      UpdatePrivacytag({
        userId: userId,
        privacyTag: privacy[0]?.value,
      })
    );
  };

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  return (
    <>
      {!(isGuest == true) ? (
        <div>
          <div
            className="flex items-center px-[30px] py-[10px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]"
            onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}
          >
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px]">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15 19l-7-7 7-7"
                  ></path>
                </svg>
              </button>
            </div>
            <p className="text-[#494949] font-boboto font-bold title-profile">
              Messaging privacy
            </p>
          </div>
          <div className="h-screen flex flex-col">
            <div className="px-5 mt-10 sm:px-10 sm:mt-16 flex-1 ">
              {data &&
                data.map((x) => {
                  return (
                    <div
                      className="flex items-center flex-1  py-4 border-t-2 border-gray-100"
                      key={x.title}
                    >
                      <div className="w-4/5 text-left pl-4">
                        <p className="w-full text-gray-600 text-lg">
                          {x.title}
                        </p>
                      </div>
                      <div className="flex  pl-12 ml-2">
                        <FaviSwitch
                          enabled={x.checked}
                          onChange={handleChange}
                          value={x.id.toString()}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {loader && (
              <img src={FavichatLoading} className="signin-img-Loading" />
            )}
          </div>
        </div>
      ) : (
        <div className="profile">
          <div className="flex items-center py-[40px] max-sm:px-[1rem] max-sm:py-[.5rem] text-start text-[#494949]" onClick={(e) => dispatch(UpdateSettingSidebarToggleState(true))}>
            <div className="sm:hidden pt-[8px]">
              <button type="submit" className="mr-[8px] px-6">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                </svg>
              </button>
            </div>
            <div className="px-[10px]">
              <p className="text-[#494949] font-boboto title-profile-guest">
                This feature is not available for guest user.
                <button
                  type="submit"
                  size="small"
                  className="text-[#494949] font-boboto btn-profile-guest"
                  onClick={() => {
                      dispatch(
                        UpdateOnlineUser({
                          online: false,
                        })
                      ).then(() => {
                        guestUserLogout();
                      });
                    }}
                >
                  Create an account or Sign in
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessagingPrivacy;
