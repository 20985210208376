/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import { Grid } from "@giphy/react-components";
import ResizeObserver from "react-resize-observer";
import { GiphyFetch } from "@giphy/js-fetch-api";

const GifModal = ({ onGifClick, searchText, type, setLoadGid }) => {
  const giphyFetch = new GiphyFetch("jtX8axPRuR2laSjMg8QGW1pGSAjIwTV8");
  const maxWidth = 600;
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    ShowGifData();
  }, [searchText, type, width]);

  const ShowGifData = useCallback(() => {
    const fetchGifs = (offset) => giphyFetch.trending({ offset, limit: 10 });

    const fetchSearchGifs = (offset) =>
      giphyFetch.search(searchText, { offset, limit: 10 });
    if (fetchGifs.length > 0 || fetchSearchGifs.length > 0) {
    }
    return (
      <>
        <Grid
          onGifClick={onGifClick}
          fetchGifs={type === "search" ? fetchSearchGifs : fetchGifs}
          width={width}
          columns={3}
          gutter={6}
          isPaginationEnabled={true}
          hideAttribution={true}
          onGifsFetched={setLoadGid(true)}
        />
        <ResizeObserver
          onResize={({ width }) => {
            const newWidth = width <= maxWidth ? width : maxWidth;
            setWidth(newWidth - 30);
          }}
        />
      </>
    );
  }, [searchText, type, width]);

  return <>{<ShowGifData />}</>;
};
export default GifModal;
