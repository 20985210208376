import '../../styles/LandingPage.scss';
import React, { useState } from "react";

const InfoPopUp = ({ isMobile }) => {
  
  const [isOpen, setIsOpen] = useState(true);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const timeout = setTimeout(() => {
    setIsOpen(false);
  },5000);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="fixed inset-x-0 bottom-0 z-50">
      <div
        className={`${isOpen && isMobile ? "translate-y-0" : "translate-y-full hidden"} w-full h-64 bg-white shadow-xl rounded-t-3xl transition-transform duration-300 ease-in-out`}
        style={{ border: '1px solid #8f8f8f'}}
      >
        <div className="flex justify-between items-center rounded-t-3xl p-4">
          <button
            className="p-2 rounded-full transition-colors duration-300"
            onClick={handleClose}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 close-Drawer-LandingPage">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex justify-center h-full text-xl">
          <div className="text-center">
            <p className="mt-4 pb-6 px-2.5">
              Hi, 👋 welcome to Favichat! Chat with your favorite creators and friends.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoPopUp;
