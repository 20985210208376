import * as Yup from "yup";
import "../../styles/Login.scss";
import React, { useState } from "react";
import { Url } from "../../axios/authAPI";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import "../../styles/NewConfirmPassword.scss";
import { authApi } from "../../axios/authAPI";
import { Logo } from "../../components/Logo/Logo";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import FavichatLoading from "../../assets/gif/Favichat1.gif";
import FooterRelative from "../../components/Footer/FooterRelative";


const NewConfirmPassword = ({ userName }) => {

  const [value, setValue] = useState();
  const [loader, setLoader] = useState(false);
  const [showError, setShowError] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const [showResendCode, setShowResendCode] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const navigate = useNavigate();

  const userNameCode = useSelector((state) => state.userNameCode);

  const submitData = {
    userName: userName,
    code: "",
    password: "",
  };

  let password;

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password should not be blank")
      .min(
        8,
        "Password must have at least 8 characters \nMinimum 1 uppercase \nMinimum 1 lowercase \nMinimum 1 number\nMinimum 1 special character."
      )
      .max(
        25,
        "Password must have at least 8 characters \nMinimum 1 uppercase \nMinimum 1 lowercase \nMinimum 1 number\nMinimum 1 special character."
      )
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        "Password must have at least 8 characters \nMinimum 1 uppercase \nMinimum 1 lowercase \nMinimum 1 number\nMinimum 1 special character."
      ),
    passwordRepeat: Yup.string()
      .required("Confirm Password should not be blank")
      .min(8, "Password and Confirm Password must be the same")
      .max(25, "Password and Confirm Password must be the same")
      .oneOf(
        [Yup.ref("password")],
        "Password and Confirm password must be the same."
      ),
    code: Yup.string()
      .required("Please enter the 6 digit code")
      .min(0, "Please enter the 6 digit code")
      .max(6, "Please enter the 6 digit code")
      .matches("^[0-9]*$", "Code contains only numbers."),
  });

  const { register, handleSubmit, formState: { errors }, watch, reset, getValues} = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  password = watch("password", "");

  const handleResetPassword = async (data) => {
    if (data.code.length !== 6)
      return setShowError("Please enter the 6 digit code");
    setShowError("");
    setLoader(true);
    submitData.password = data.password;
    submitData.code = data.code;
    authApi
      .post(`${Url}/users/password/reset`, submitData)
      .then((res) => {
        navigate("/login");
        setLoader(false);
      })
      .catch((err) => {
        setShowError("Please enter the correct 6 digit code");
        setLoader(false);
      });
  };

  const sendOTP = async () => {
    setShowResendCode(true);

    const jsonBody = { userName: userNameCode };

    authApi
      .post(`${Url}/users/password/send`, jsonBody)
      .then((res) => setShowResendCode(false))
      .catch((err) => {
        setShowError(err?.response?.data?.error?.message);
      });
  };

  const handleChange = (event) => {
    const result = event.target.value.replace(/\D/g, "");
    setValue(result);
  };

  return (
    <div className="h-screen w-screen flex flex-col fixed items-center justify-start overflow-y-auto fixed top-0 left-0">
      <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-4 sm:px-6 lg:px-8">
          <Logo />
        <div className="mx-auto max-w-lg text-center">
          <form
            className="mx-auto mb-0 max-w-md space-y-4 forgotPassword-form-tailwind"
            onSubmit={handleSubmit((data) => handleResetPassword(data))}
          >
            <h4 className="text-lg login-entry-title-new-confirm-password-tailwind">
              Enter code sent to your phone number
            </h4>
            <div className="">
              <div className="form-password-box">
                <div className="">
                  <input
                    className="w-full border rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm forgotPassword-input-tailwind"
                    type="otp"
                    placeholder="Code"
                    maxlength="6"
                    {...register("code")}
                    value={value}
                    onChange={handleChange}
                  />
                  {errors?.code?.message === "Please enter the 6 digit code" ?(
                    <p className="text-red-500 text-sm items-center justify-between signin-label-error-input">{errors?.code?.message}</p>
                  ) : (
                    <p className="text-red-500 text-sm items-center justify-between signin-label-error-input">{showError}</p>
                  )}
                </div>
              </div>

              <div className="">
                <button
                  onClick={() => sendOTP()}
                  className={
                    showResendCode === true
                      ? "disabled-resend-otp-tailwind"
                      : "resend-otp-tailwind"
                  }
                  disabled={showResendCode}
                >
                  Resend code
                </button>
              </div>
            </div>

            <h4 className="text-lg login-entry-title-new-confirm-password-tailwind">
              Enter your new password
            </h4>

            <div className="mx-auto mb-0 max-w-md space-y-4 forgotPassword-form-tailwind">
              <div>
                <div className="relative">
                  <input type={!passwordShown ? "password" : "text"} placeholder="Password" className="w-full border rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm signin-input-field" {...register("password", { required: "Password should not be blank" })} />
                  <i
                    className=""
                    onClick={(e) => setPasswordShown(!passwordShown)}
                  >
                    {passwordShown ? (
                      <span onClick={() => setPasswordShown(true)} className="absolute inset-y-0 right-0 grid place-content-center px-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 signin-input-field-iconeye">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                      </span>
                    ) : (
                      <span onClick={() => setPasswordShown(false)} className="absolute inset-y-0 right-0 grid place-content-center px-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 signin-input-field-iconeye">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />    
                        </svg>
                      </span>
                    )}
                  </i>
                </div>
                <p className="text-red-500 text-sm signin-label-error-input">
                  {errors?.password?.message}
                </p>
              </div>
              {/* re-enter password */}
              <div className="mx-auto mb-0 max-w-md space-y-4 forgotPassword-form-tailwind">
                <div>
                  <div className="relative">
                    <input
                      type={confirmPasswordShown ? "text" : "password"}
                      className="w-full border rounded-lg border-gray-200 p-4 pr-12 text-sm shadow-sm signin-input-field"
                      placeholder="Re-enter password"
                      {...register("passwordRepeat")}
                    />
                    <i
                      className=""
                      onClick={(e) =>
                        setConfirmPasswordShown(!confirmPasswordShown)
                      }
                    >
                      {confirmPasswordShown ? (
                        <span onClick={() => setPasswordShown2(true)} className="absolute inset-y-0 right-0 grid place-content-center px-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 signin-input-field-iconeye">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                        </span>
                      ) : (
                        <span onClick={() => setPasswordShown2(false)} className="absolute inset-y-0 right-0 grid place-content-center px-4">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6 signin-input-field-iconeye">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                          </svg>
                        </span>
                      )}
                    </i>
                  </div>
                  {/* erro messages */}
                  <p className="text-red-500 text-sm signin-label-error-input">
                    {errors?.passwordRepeat?.message}
                  </p>    
                </div>
              </div>
            </div>
            {loader ? (
              <div className="flex items-center mt-4 justify-center signin-text-accountsignin-img-Loading">
                <img src={FavichatLoading} className="signin-img-Loading"/>
              </div>
            ) : (
              <div
                className=""
                onClick={console.log("button clicked")}
              >
                <div className="flex items-center justify-between">
                <button className="inline-block rounded-lg bg-blue-500 px-5 py-3 text-white gradient-send-code-new-confirm-password-tailwind" type="submit" disabled={false}>
                  Change password
                </button>
              </div>
              </div>
            )}
          </form>
          <div className="flex items-center justify-center signin-text-account-new-confirm-password">
            <p className="mr-2 signin-text-account">
              Don't have an account?
            </p>
            <Link to="/sign-up"  className='text-sm text-blue-500 cursor-pointer signin-link-new-confirm-password'>Sign Up</Link>
          </div>
        </div>
        <div>
            <FooterRelative/>
        </div>
      </div>
    </div>
  );
};

export default NewConfirmPassword;
