import React from "react";
import Favichat from "../../assets/gif/Favichat.gif";
import "../../styles/FavichatLoader.scss";

const FavichatLoader = () => {
  return (
    <div className="loader_animation">
      <img src={Favichat} alt="" />
    </div>
  );
};

export default FavichatLoader;
