import UserProfile from "./UserProfile";
import { Url } from "../../axios/authAPI";
import { useAxios } from "../../axios/authAPI";
import UserSearch from "../UserSearch/UserSearch";
import UserModal from "../UserDetailsModal/UserModal";
import { useSelector, useDispatch } from "react-redux";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  UpdateInterestPageState,
  CleanInterestState,
} from "../../redux/actions/index";

const InterestUserPage = ({ interestName, id }) => {
  let api = useAxios();
  const dispatch = useDispatch();
  const inputRefSearch = useRef(null);

  const [pk, setPK] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [followerLike, setFollowerLike] = useState();
  const [stopScrollFn, setStopScrollFn] = useState(true);
  const [creatorDetails, setCreatorDetails] = useState();
  const [userDataLoading, setUserDataLoading] = useState(true);
  const [sendAllModalToggle, setSendAllModalToogle] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const userData = useSelector((state) => state.user);
  const interestDataUser = useSelector((state) => state.dataInterest);

  const getUsersByInterest = useCallback(async (pk) => {
    setStopScrollFn(false);
    try {
      if (typeof pk === "object") {
        const response = await api.post(`${Url}/users/interests/list`, {
          limit: 100,
          interestId: id,
          userId: userData.userId,
          lastRec: pk,
        });
        const responseData = response?.data?.data;
        if (responseData.lastRec !== "NO MORE DATA") {
          setPK(responseData.lastRec);
          dispatch(UpdateInterestPageState(responseData?.values));
          setStopScrollFn(true);
          setLoading(true);
          setUserDataLoading(false);
        } else {
          dispatch(UpdateInterestPageState(responseData?.values));
          setStopScrollFn(false);
          setLoading(false);
          setUserDataLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setUserDataLoading(false);
      setStopScrollFn(false);
    }
  }, []);

  useEffect(() => {
    dispatch(CleanInterestState());
    const handleUserDataNew = async () => {
      setLoading(true);
      const response = await api.post(`${Url}/users/interests/list`, {
        limit: 100,
        interestId: id,
        userId: userData.userId,
      });
      const responseData = await response?.data?.data;
      setPK(responseData.lastRec);
      dispatch(UpdateInterestPageState(responseData?.values));
      setLoading(false);
      setUserDataLoading(false);
    };

    handleUserDataNew();
  }, []);

  const handleScrollCheck = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    if (scrollHeight - scrollTop <= clientHeight + 100) {
      setLoading(true);
      getUsersByInterest(pk);
    }
  };

  const handleCloseProfile = (bool) => {
    setShowModal(false);
    setCreatorDetails("");
  };

  const handleInfo = (id, act) => {
    setShowModal(true);
    setCreatorDetails(id);
    setFollowerLike(act);
  };

  const usersMappedData = useMemo(() => {
    return interestDataUser.map((x) => {
      return <UserProfile list={x} handleInfo={handleInfo} />;
    });
  }, [interestDataUser]);

  const handleResize = () => {
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const dynamicStyles = {
    height: `${screenHeight - 48}px`,
  };

  return (
    <div style={dynamicStyles} className={`w-screen flex flex-col justify-start top-0 left-0 relative ${ sendAllModalToggle ? "content-discovery-interest active" : "content-discovery-interest"}`} onScroll={stopScrollFn ? handleScrollCheck : undefined}>
      <div className="flex justify-between w-full h-20 pt-4 md:pt-4" id="topScroll">
        <div className="flex items-center div-title-header-discovery">
          <h1 className="title-header-following-discovery">
            {decodeURIComponent(interestName)}
          </h1>
        </div>
        <div className="flex justify-start items-center div-search-header-discovery">
          <UserSearch inputRefSearch={inputRefSearch} />
        </div>
      </div>

      {userDataLoading ? (
        <>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mt-2 md:mt-6">
            <div className="w-full flex flex-col justify-start">
              <div className="flex flex-row justify-between mt-6 skeleton-avatar-discovery">
                {Array(10)
                  .fill(1)
                  .map((card, index) => (
                    <div
                      className="animate-pulse bg-gray-200 h-16 w-16 md:h-28 md:w-28 rounded-full flex-none"
                      key={index}
                    ></div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <ul className="user-followers-container-following">
          {usersMappedData}
        </ul>
      )}

      {showModal && (
        <UserModal
          info={creatorDetails}
          close={handleCloseProfile}
          followerLike={followerLike}
        />
      )}
    </div>
  );
};

export default InterestUserPage;
