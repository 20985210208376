/* eslint-disable react-hooks/exhaustive-deps */
import { Url } from "../../axios/authAPI";
import { useAxios } from "../../axios/authAPI";
import { useNavigate } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import LargeSizePopUp from "../PopUp/LargeSizePopUp";
import { storage } from "../../Config/configurations";
import { useSelector, useDispatch } from "react-redux";
import FavichatBig from "../../assets/gif/Favichat1.gif";
import React, { useState, useEffect, useRef } from "react";
import { UpdateOnlineUser } from "../../redux/actions/index";
import { CircularProgressbar } from "react-circular-progressbar";
import { useAppBreakpoints } from "../../hooks/useAppBreakpoints";
import GuestToastBar from "../../components/GuestComponents/Toast/Toast";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import {
  ResetNotUserState,
  AddMessage,
  UpdateIsGuestState,
} from "../../redux/actions/index";

function ContentModal({
  setContentModal,
  name,
  toConversationID,
  id,
  infiniteScrollRef,
}) {
  const [type, setType] = useState(1);
  const [preview, setPreview] = useState();
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [packages, setPackages] = useState([]);
  const [contentUrl, setContentUrl] = useState("");
  const [heartActive, setHeartActive] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [textMessage, setTextMessage] = useState("");
  const [uploadBlur, setUploadBlur] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [fileLargeError, setFileLargeError] = useState(false);
  const [showGuestPopUp, setShowGuestPopUp] = useState(false);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [isChatUserGuest, setIsChatUserGuest] = useState(false);
  const [guestUserName, setGuestUserName] = useState("");

  let api = useAxios();
  const canvasRef = useRef(null);
  const inputFileRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useAppBreakpoints();
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const userId = useSelector((state) => state.user.userId);
  const currentConversationId = useSelector(
    (state) => state.currentConversationId
  );
  const chat = useSelector((state) => state);
  const username = useSelector((state) => state.currentChat);
  const isGuest = useSelector((state) => state.isGuest);
  const currentUserId = useSelector((state) => state.user);

  const handleCloseAlert = () => {
    setFileLargeError(false);
    setSelectedFile();
    setPreview(undefined);
  };

  const uploadContent = (file) => {
    if (!file) return;

    const sotrageRef = ref(storage, `content/${Date.now()}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setContentUrl(downloadURL);
          setUploadBlur(true);
        });
      }
    );
  };

  const onSelectFile = async (e) => {
    setIsDialogboxOpen(false);
    setUploadBlur(false);
    if (!e.target.files || e.target.files.length === 0) {
      return;
    } else if (e.target.files[0].type.includes("image/")) {
    } else if (e.target.files[0].type.includes("video/")) {
    } else {
      uploadContent("");
      setContentUrl("");
      setSelectedFile("");
      setIsDialogboxOpen(true);
      setDialogMessage(
        "File format not supported. Please, check support page for details on the accepted formats."
      );
      return;
    }
    if (e.target.files[0].size / 1000000 > 80) {
      setContentUrl("");
      setSelectedFile("");
      setFileLargeError(true);
      return;
    }

    if (e.target.files[0].type.includes("image/")) {
      setType(1);
    } else {
      setType(2);
    }

    setContentUrl("");

    const file = e.target.files[0];

    setSelectedFile(file);
    uploadContent(file);
  };

  const handleClick = (id) => {
    if (isGuest && id.pricePlan !== "Free") {
      setShowGuestPopUp(true);
    } else {
      setHeartActive(id);
    }
  };

  const guestUserLogout = () => {
    if (isGuest === true) {
      dispatch(ResetNotUserState());
      dispatch(UpdateIsGuestState(true));
      navigate("/?isGuest=true");
    }
  };

  const getHeartPackage = async () => {
    api
      .get(`${Url}/heartPackages/list?limit=200&tag=post`)
      .then((res) => res.data)
      .then(
        (result) => {
          setLoader(false);
          const jsonFree = {
            noOfHearts: 0,
            pricePlan: "Free",
          };
          let arrPackage = result?.data?.value.slice();
          arrPackage.splice(0, 0, jsonFree);
          setPackages(arrPackage);
        },
        (error) => {}
      );
  };

  const smoothScroll = (element, target, duration) => {
    let start = element.scrollTop,
        change = target - start,
        startTime = performance.now(),
        val, now, elapsed, t;
  
    function animateScroll(currentTime) {
      now = currentTime;
      elapsed = (now - startTime) / 1000;
      t = (elapsed / duration);
  
      element.scrollTop = start + change * Math.easeInOutQuad(t);
  
      if (t < 1) window.requestAnimationFrame(animateScroll);
    }
  
    window.requestAnimationFrame(animateScroll);
  };
  
  Math.easeInOutQuad = function (t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };
  
  const sendPost = async (e) => {
    setLoader(true);

    const payload = {
      content: contentUrl,
      hearts: heartActive !== 0 ? heartActive.noOfHearts : heartActive,
      uid: userId,
      expireDays: 0,
      contentBlur: contentUrl,
      isSafari: false,
    };

    api
      .post(`${Url}/content/post`, payload)
      .then((response) => response.data)
      .then((result) => {
        dispatch(
          AddMessage({
            currentConversationId: currentConversationId,
            message: {
              content: /^[\r\n]*$/.test(textMessage) || /^[\n ]+$/.test(textMessage) ? "" : textMessage.trim(),
              sender: userId,
              type: contentUrl === "" ? 0 : type,
              contentUrl: contentUrl,
              heartActive:
                heartActive !== 0 ? heartActive.noOfHearts : heartActive,
              platform: isMobile,
              contentId: result.data.id,
            },
          })
        )
          .then(() => {
            setLoader(false);
            setTextMessage("");
            setContentModal(false);

            const scrollDiv = infiniteScrollRef.current;
            if (scrollDiv && scrollDiv.el) {
              let targetPosition = scrollDiv.el.scrollHeight;
              smoothScroll(scrollDiv.el, targetPosition, 1);
            }
          })
          .catch(() => {
            setLoader(false);
            setTextMessage("");
            setIsDialogboxOpen(true);
            setDialogMessage("Connection error, please try again later");
          });
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  const handleCloseDialog = () => {
    setIsDialogboxOpen(false);
  };

  useEffect(() => {
    if (username.name !== undefined && username.isGuest) {
      setIsChatUserGuest(true);
      setGuestUserName("Guest " + username.username.slice(-5));
    }
  }, [chat, username, isChatUserGuest]);

  useEffect(() => {
    getHeartPackage();
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const eventKeyPress = (event) => {
    if (event.key === 'Enter') {
      if (!textMessage.trim()) {
        event.preventDefault();
      }
    }
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen">
        <div
          className={`fixed inset-0 ${
            showGuestPopUp
              ? "bg-gray-800 bg-opacity-60"
              : "bg-gray-500 bg-opacity-50"
          } transition-opacity`}
        />
        {fileLargeError && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50 transition-opacity ">
            <LargeSizePopUp handleCloseAlert={handleCloseAlert} />
          </div>
        )}
        <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full items-center justify-center modal-desk-send-all-discovery">
          <div className="p-3 flex items-center justify-center flex-col">
            {showGuestPopUp && (
              <>
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-500 bg-opacity-50 transition-opacity ">
                  <div className={`fixed md:h-32 md:w-[625px] bg-white px-3 pt-12 pb-6 shadow-lg ${isMobile ? "rounded-t-3xl" : "rounded-t-3xl rounded-b-3xl top-20"}`} style={{ bottom: 0 }} >
                    <button
                      className="absolute top-1 left-1 m-4"
                      onClick={() => {
                        setShowGuestPopUp(false);
                      }}
                    >
                      <svg
                        className="h-7 w-7 text-gray-700"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                    <div className="flex items-center justify-center div-guest-send-all-discovery">
                      <p>
                        To send locked content, please
                        <button
                          onClick={() => {
                            dispatch(
                              UpdateOnlineUser({
                                online: false,
                              })
                            ).then(() => {
                              guestUserLogout();
                            });
                          }}
                        >
                          Create an account or Sign in
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}

            <button
              className="absolute top-2 left-2 m-2"
              onClick={(e) => setContentModal(false)}
            >
              <svg
                className="h-7 w-7 text-gray-700"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <section className="w-full">
              <div className="mx-auto grid max-w-screen-2xl grid-cols-1 lg:grid-cols-3">
                <div className="flex justify-center md:pt-4 md:pr-3 div-file-right-send-all-discovery mb-4">
                  <div className="col-span-1 relative mt-3">
                    <input
                      id="fileInput"
                      type="file"
                      onChange={onSelectFile}
                      className={`block pb-5 w-full text-sm text-slate-500
                              file:mr-4 file:py-2 file:px-4 file:cursor-pointer
                              file:rounded-full file:border-0
                              file:text-sm file:font-semibold
                              file:bg-gray-100 file:text-black-70
                              hover:file:bg-gray-200 pt-2`}
                      accept="video/mp4,video/x-m4v,video/*,image/*"
                    />
                    {type === 3 && (
                      <img
                        src={preview}
                        alt=""
                        // className={`${
                        //   selectedFile?.type?.includes("gif") &&
                        //   "img-gif"
                        // } img-send-all--files-discovery`}
                      />
                    )}
                    {selectedFile && selectedFile.type.includes("image/") ? (
                      <img
                        src={preview}
                        alt=""
                        className="video-send-all-files-discovery mx-auto"
                      />
                    ) : (
                      type != 3 &&
                      contentUrl && (
                        <video
                          className="video-send-all-files-discovery mx-auto"
                          autoPlay="true"
                          playsInline
                          loop="true"
                          controls
                        >
                          <source src={contentUrl}></source>
                        </video>
                      )
                    )}
                    {progress === 0 || progress === 100 ? (
                      ""
                    ) : (
                      <>
                        {type === 2 && !contentUrl ? (
                          <div className="loading-content-video-send-all-discovery">
                            <CircularProgressbar
                              value={progress}
                              text={`${progress}%`}
                            />
                          </div>
                        ) : (
                          <div className="loading-content-send-all-discovery">
                            <CircularProgressbar
                              value={progress}
                              text={`${progress}%`}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-span-2 text-center">
                  <div className="px-6 mx-auto max-w-[48rem]">
                    <div className="flex flex-col justify-center items-center">
                      <p className="p-right-send-all-discovery">
                        Select price for locked content
                      </p>
                      <div className="flex justify-between items-center div-right-hearts-send-all-discovery">
                        {packages?.map((data) => (
                          <div
                            className={
                              heartActive.id === data.id
                                ? "flex flex-col justify-center items-center maped-heard-active"
                                : isMobile === true
                                ? "flex flex-col justify-center items-center maped-heard-mobile"
                                : "flex flex-col justify-center items-center maped-heard"
                            }
                            key={data.id}
                            onClick={(e) => handleClick(data)}
                          >
                            {heartActive.id === data.id ? (
                              <svg
                                className="w-8 h-8 icon-heart-send-all-discovery"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 1900 1900"
                                id="Heart"
                                fill="currentColor"
                              >
                                <path
                                  d="M896 1664q-26 0-44-18l-624-602q-10-8-27.5-26T145 952.5 77 855 23.5 734 0 596q0-220 127-344t351-124q62 0 126.5 21.5t120 58T820 276t76 68q36-36 76-68t95.5-68.5 120-58T1314 128q224 0 351 124t127 344q0 221-229 450l-623 600q-18 18-44 18z"
                                  fill="currentColor"
                                  className="color000000 svgShape"
                                ></path>
                              </svg>
                            ) : (
                              <svg
                                className="w-8 h-8 icon-heart-send-all-discovery"
                                fill="currentColor"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="-23.59 -23.59 518.88 518.88"
                                stroke="currentColor"
                                strokeWidth="15.566133000000002"
                              >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path d="M433.601,67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7,13.6-92.4,38.3l-12.9,12.9l-13.1-13.1 c-24.7-24.7-57.6-38.4-92.5-38.4c-34.8,0-67.6,13.6-92.2,38.2c-24.7,24.7-38.3,57.5-38.2,92.4c0,34.9,13.7,67.6,38.4,92.3 l187.8,187.8c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-3.9l188.2-187.5c24.7-24.7,38.3-57.5,38.3-92.4 C471.801,124.501,458.301,91.701,433.601,67.001z M414.401,232.701l-178.7,178l-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3 s10.7-53.7,30.3-73.2c19.5-19.5,45.5-30.3,73.1-30.3c27.7,0,53.8,10.8,73.4,30.4l22.6,22.6c5.3,5.3,13.8,5.3,19.1,0l22.4-22.4 c19.6-19.6,45.7-30.4,73.3-30.4c27.6,0,53.6,10.8,73.2,30.3c19.6,19.6,30.3,45.6,30.3,73.3 C444.801,187.101,434.001,213.101,414.401,232.701z"></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                            )}

                            {data.pricePlan === "Free" ? (
                              <p className="p-icon-heart-send-all-discovery">
                                Send
                              </p>
                            ) : (
                              <p className="p-icon-heart-send-all-discovery">
                                {data.noOfHearts}
                              </p>
                            )}
                            {data.pricePlan === "Free" ? (
                              <p className="p-icon-heart-send-all-discovery">
                                {data.pricePlan}
                              </p>
                            ) : (
                              <p className="p-icon-heart-send-all-discovery">
                                ${data.pricePlan}
                              </p>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className="flex flex-col justify-end items-end w-full pt-2">
                        <textarea
                          cols="40"
                          rows="6"
                          placeholder="Add description…"
                          value={textMessage}
                          onChange={(e) =>
                            setTextMessage(e.target.value.substring(0, 300))
                          }
                          onKeyPress={eventKeyPress}
                          className="textarea-send-all-discovery"
                        />
                        <div>
                          <p>{textMessage.length}/300</p>
                        </div>
                      </div>
                      {uploadBlur && (
                        <div className={`flex flex-row justify-center items-center w-[60%] sm:w-[50%] pt-4 ${isIOS && "standalone" in window.navigator && window.navigator.standalone ? "pb-4" : ""}`}>
                          {loader ? (
                            <div className="py-3">
                              <img
                                src={FavichatBig}
                                alt=""
                                className="save_ripple_loader"
                              />
                            </div>
                          ) : isChatUserGuest == "true" || isChatUserGuest ? (
                            <button
                              className="inline-block rounded-lg bg-blue-500 px-2 py-3 whitespace-nowrap text-white button-upload-content max-w-[500px] sm:max-w-[250px]"
                              onClick={sendPost}
                            >
                              {`Send to ${guestUserName}`}
                            </button>
                          ) : (
                            <button
                              className="inline-block rounded-lg bg-blue-500 px-2 py-3 whitespace-nowrap text-white button-upload-content max-w-[500px] sm:max-w-[250px]"
                              onClick={sendPost}
                            >
                              {username.name !== undefined &&
                              username?.name?.length > 10
                                ? `Send to ${username.name.slice(0, 10)}...`
                                : `Send to ${username.name}`}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

      <canvas
        ref={canvasRef}
        width={300}
        height={300}
        style={{ display: "none" }}
      />

      <GuestToastBar
        isOpened={isDialogboxOpen}
        handleClose={handleCloseDialog}
        isOnlyMsg
        message={dialogMessage}
      />
    </div>
  );
}
export default ContentModal;
